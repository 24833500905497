import { Dayjs } from "dayjs"

export enum ConteudoTypes {
    CONTEUDO_TABLE_REQUEST = 'conteudo/CONTEUDO_TABLE_REQUEST',
    CONTEUDO_TABLE_REQUEST_SUCCESS = 'conteudo/CONTEUDO_TABLE_RQUEST_SUCCESS',
    CONTEUDO_TABLE_REQUEST_FAILURE = 'conteudo/CONTEUDO_TABLE_REQUEST_FAILURE',

    CONTEUDO_ATIVA_REQUEST = 'conteudo/CONTEUDO_ATIVA_REQUEST',
    CONTEUDO_ATIVA_REQUEST_SUCCESS = 'conteudo/CONTEUDO_ATIVA_REQUEST_SUCCESS',
    CONTEUDO_ATIVA_REQUEST_FAILURE = 'conteudo/CONTEUDO_ATIVA_REQUEST_FAILURE',

    CONTEUDO_INATIVA_REQUEST = 'conteudo/CONTEUDO_INATIVA_REQUEST',
    CONTEUDO_INATIVA_REQUEST_SUCCESS = 'conteudo/CONTEUDO_INATIVA_REQUEST_SUCCESS',
    CONTEUDO_INATIVA_REQUEST_FAILURE = 'conteudo/CONTEUDO_INATIVA_REQUEST_FAILURE',
    
    CLEAR_CONTEUDO_DATA = 'conteudo/CLEAR_CONTEUDO_DATA',

    CONTEUDO_DATA_REQUEST = 'conteudo/CONTEUDO_DATA_REQUEST',
    CONTEUDO_DATA_REQUEST_SUCCESS = 'conteudo/CONTEUDO_DATA_REQUEST_SUCCESS',
    CONTEUDO_DATA_REQUEST_FAILURE = 'conteudo/CONTEUDO_DATA_REQUEST_FAILURE',

    CONTEUDO_SAVE_REQUEST = 'conteudo/CONTEUDO_SAVE_REQUEST',
    CONTEUDO_SAVE_REQUEST_SUCCESS = 'conteudo/CONTEUDO_SAVE_REQUEST_SUCCESS',
    CONTEUDO_SAVE_REQUEST_FAILURE = 'conteudo/CONTEUDO_SAVE_REQUEST_FAILURE',

    CONTEUDO_UPDATE_REQUEST = 'conteudo/CONTEUDO_UPDATE_REQUEST',
    CONTEUDO_UPDATE_REQUEST_SUCCESS = 'conteudo/CONTEUDO_UPDATE_REQUEST_SUCCESS',
    CONTEUDO_UPDATE_REQUEST_FAILURE = 'conteudo/CONTEUDO_UPDATE_REQUEST_FAILURE',

    CONTEUDO_PUSH_REQUEST = 'conteudo/CONTEUDO_PUSH_REQUEST',
    CONTEUDO_PUSH_REQUEST_SUCCESS = 'conteudo/CONTEUDO_PUSH_REQUEST_SUCCESS',
    CONTEUDO_PUSH_REQUEST_FAILURE = 'conteudo/CONTEUDO_PUSH_REQUEST_FAILURE',

    CONTEUDO_TIPO_REQUEST = 'conteudo/CONTEUDO_TIPO_REQUEST',
    CONTEUDO_TIPO_REQUEST_SUCCESS = 'conteudo/CONTEUDO_TIPO_REQUEST_SUCCESS',
    CONTEUDO_TIPO_REQUEST_FAILURE = 'conteudo/CONTEUDO_TIPO_REQUEST_FAILURE',
}

export interface ConteudoObjTable {
    id?: number,
    idConteudo?: number,
    nmConteudo?: string,
    dsConteudo?: string,
    dtConteudoInicio?: null | Dayjs | string,
	dtConteudoFim?: null | Dayjs | string,
    nmConteudoTipo?: string,
    dsTempoEstimado?: string,
}

export interface ConteudoTipo {
    idConteudoTipo?: number,
    nmConteudoTipo?: string,
    dsConteudoTipo?: string
}

export interface ConteudoObj {
    idConteudo?: number,
    nmConteudo?: string,
    dsConteudo?: string,
    dtConteudoInicio?: null | Dayjs | string,
	dtConteudoFim?: null | Dayjs | string,
    dsTempoEstimado?: string,
    pathConteudo?: string | null,
    imgConteudo?: string | null,
    conteudoTipo?: ConteudoTipo | null,
    urlCompartilhamentoConteudo?: string,
	idDatabaseStatus?: number | null,
	icLivre?: number | null,
    isExterno?: number | null,
    onApp?: number | null,
    urlExternaConteudo?: string, 
    conteudoMuncipios?: any | null,
    conteudoPerfis?: any | null,
    conteudoTemas?: any | null,
}


export interface ConteudoTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface ConteudoPage {
    readonly conteudo: ConteudoObj | null,
    readonly table: ConteudoTable | null,
    readonly conteudoTipo: any | null,
}

export interface ConteudoState {
    readonly data: ConteudoPage,
    readonly loading: boolean,
    readonly loadingPersis: boolean,
    readonly loadingTable: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}