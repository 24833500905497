import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getSegurancaNoTrabalhoDataFailure,
    getSegurancaNoTrabalhoDataSuccess,
    getSegurancaNoTrabalhoTableFailure,
    getSegurancaNoTrabalhoTableSuccess,
    onAtivarSegurancaNoTrabalhoFailure,
    onAtivarSegurancaNoTrabalhoSuccess,
    onInativarSegurancaNoTrabalhoFailure,
    onInativarSegurancaNoTrabalhoSuccess,
    onSaveSegurancaNoTrabalhoFailure, 
    onSaveSegurancaNoTrabalhoSuccess,
    onUpdateSegurancaNoTrabalhoFailure,
    onUpdateSegurancaNoTrabalhoSuccess,
    onNotificacaoSegurancaNoTrabalhoFailure,
    onNotificacaoSegurancaNoTrabalhoSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getSegurancaNoTrabalhoTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('segurancaNoTrabalho/listAllSegurancaNoTrabalhoTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getSegurancaNoTrabalhoTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getSegurancaNoTrabalhoTableFailure(error.message));
    }
};


function* onInativarSegurancaNoTrabalho(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('segurancaNoTrabalho/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarSegurancaNoTrabalhoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarSegurancaNoTrabalhoFailure(error.message));
    }
};

function* onAtivarSegurancaNoTrabalho(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('segurancaNoTrabalho/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarSegurancaNoTrabalhoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarSegurancaNoTrabalhoFailure(error.message));
    }
};

function* getSegurancaNoTrabalhoData(data: any): any {
    const idSegurancaNoTrabalho = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`segurancaNoTrabalho/findById?idSegurancaNoTrabalho=${idSegurancaNoTrabalho}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getSegurancaNoTrabalhoDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getSegurancaNoTrabalhoDataFailure(error.message));
    }
};

function* onSaveSegurancaNoTrabalho(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('segurancaNoTrabalho/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveSegurancaNoTrabalhoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveSegurancaNoTrabalhoFailure(error.message));
    }
};

function* onUpdateSegurancaNoTrabalho(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('segurancaNoTrabalho/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateSegurancaNoTrabalhoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateSegurancaNoTrabalhoFailure(error.message));
    }
};

function* onNotificacaoSegurancaNoTrabalho(data: any): any {
    const idSegurancaNoTrabalho = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`segurancaNoTrabalho/notificaSegurancaNoTrabalho?idSegurancaNoTrabalho=${idSegurancaNoTrabalho}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoSegurancaNoTrabalhoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoSegurancaNoTrabalhoFailure(error.message));
    }
};

export {
    getSegurancaNoTrabalhoData,
    getSegurancaNoTrabalhoTable,
    onAtivarSegurancaNoTrabalho,
    onInativarSegurancaNoTrabalho,
    onSaveSegurancaNoTrabalho,
    onUpdateSegurancaNoTrabalho,
    onNotificacaoSegurancaNoTrabalho,
}
