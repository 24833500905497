import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'

import { TrilhaObj } from "../../store/ducks/trilha/types";
import ListaTemaComponent from "../ListaTemaComponent";
import ListaAvaliacaoComponent from "../ListaAvaliacaoComponent";
import ListaCursoComponent from "../ListaCursoComponent";
import TabPanel from "../TabPanel";
import { FormHelperText, InputLabel, List, ListItem, MenuItem, Select } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const defaultTrilha: TrilhaObj = {
  nmTrilha: '',
  dsTrilha: '',
  dtTrilhaInicio: null,
  dtTrilhaFim: null,
  dsTempoEstimado: '',
  imgTrilha: '',
  urlCompartilhamentoTrilha: '',
  tema: [],
  trilhaCursos: [],
  trilhaAvaliacoes: []
};

const ModalCadastroTrilha = (props: any) => {

  const [isEdicao, setIsEdicao] = useState(false);
  const [trilha, setTrilha] = useState(defaultTrilha);
  const [capaTrilha, setCapaTrilha] = useState('');
  const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
  const [cursoOrdenados, setCursoOrdenados] = useState([]);
  const [tab, setTab] = useState(0);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [imgTrilhaErro, setImgTrilhaErro] = useState(false);
  const [nmTrilhaErro, setNmTrilhaErro] = useState(false);
  const [dsTrilhaErro, setDsTrilhaErro] = useState(false);
  const [dtIniTrilhaErro, setDtIniTrilhaErro] = useState(false);
  const [dtFimTrilhaErro, setDtFimTrilhaErro] = useState(false);
  const [dsTempoEstimadoErro, setDsTempoEstimadoErro] = useState(false);
  const [temaErro, setTemaErro] = useState(false);
  const [cursoErro, setCursoErro] = useState(false);

  useEffect(() => {
    if (props.trilhaData) {
      handleRender(props.trilhaData);
    }
  }, [props.trilhaData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (!imgTrilhaErro && !nmTrilhaErro && !dsTrilhaErro && !dtIniTrilhaErro && !dtFimTrilhaErro && !dsTempoEstimadoErro && !temaErro && !cursoErro
      && trilha.imgTrilha !== '' && trilha.nmTrilha !== '' && trilha.dsTrilha !== '' && trilha.dtTrilhaInicio !== null && trilha.dtTrilhaFim !== null
      && trilha.dsTempoEstimado !== '' && trilha.tema.length !== 0 && trilha.trilhaCursos.length !== 0) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [trilha, imgTrilhaErro, nmTrilhaErro, dsTrilhaErro, dtIniTrilhaErro, dtFimTrilhaErro, dsTempoEstimadoErro, temaErro, cursoErro])


  const validaOrdenacao = (array: any) => {
    let ordenado = array.map((item: any, idx: number) => {
      let obj = cursoOrdenados.find((_item: any) => _item.idCurso == item.idCurso);
      if (obj != undefined) {
        item = obj;
      } else {
        item.ordemCurso = cursoOrdenados.length + 1;
      }
      return item;
    });

    setCursoOrdenados(ordenado);
    setTrilha({
      ...trilha,
      trilhaCursos: ordenado,
    });

  }

  const handleRender = (trilhaData: any) => {
    if (trilhaData) {
      delete trilhaData.tema.dsTema;
      setIsEdicao(true)
      setTrilha({
        ...trilhaData
      });
      setCapaTrilha('');
    }
  }

  const handleClose = () => {
    setIsEdicao(false);
    setTrilha(defaultTrilha);
    setCapaTrilha('');
    setTab(0);
    setInativaGravar(true);
    setNmTrilhaErro(false);
    setDsTrilhaErro(false);
    setDtIniTrilhaErro(false);
    setDtFimTrilhaErro(false);
    setDsTempoEstimadoErro(false);
    setTemaErro(false);
    setCursoErro(false);
    setImgTrilhaErro(false);

    props.closeModal();
  }

  const handlePersist = (e: any) => {
    e.preventDefault();

    const formTrilha = new FormData();

    if (trilha.trilhaCursos && trilha.trilhaCursos.length > 0) {
      trilha.trilhaCursos.forEach((curso: any, idx: number) => {
        if (curso.ordemCurso == undefined || curso.ordemCurso == 0) {
          curso.ordemCurso = (idx + 1);
        }
      })
    }

    formTrilha.append('trilha', JSON.stringify(trilha));

    if (capaTrilha != '') {
      formTrilha.append('fileImg', capaTrilha);
    }


    props.persist(isEdicao, formTrilha);
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if (res !== 'backdropClick') {
        handleClose()
      }
    }}
    >
      <Box sx={style} >
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? 'Editar Trilha' : 'Cadastro de nova Trilha'}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <TabPanel value={tab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6} >
                {
                  isEdicao && trilha.imgTrilha && capaTrilha == '' ? (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <div>
                        <Link className="buttonArquivo" href={trilha.imgTrilha} underline="none" target="_blank" >
                          <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                        </Link>
                        <Button variant="text" color="error" onClick={() => {
                          setImgTrilhaErro(true);
                          setTrilha({
                            ...trilha,
                            imgTrilha: ''
                          })
                        }}>Remover</Button>
                      </div>
                    </FormControl>
                  ) : (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <Input type='file' error={imgTrilhaErro} onChange={(e: any) => {
                        if (Array.from(e.target.files).length !== 0) {
                          if (validaNomeArquivo(e.target.files[0].name)) {
                            setImgTrilhaErro(Array.from(e.target.files).length === 0);
                            setTrilha({
                              ...trilha,
                              imgTrilha: e.target.files[0].name || ''
                            })
                            setCapaTrilha(e.target.files[0])
                          } else {
                            e.target.value = "";
                            setImgTrilhaErro(true);
                          }
                        } else {
                          e.target.value = "";
                          setImgTrilhaErro(true);
                        }
                      }} />
                      {imgTrilhaErro ? <FormHelperText error={imgTrilhaErro}>Campo Obrigatório.</FormHelperText> : null}
                    </FormControl>
                  )
                }
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={5} >
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={trilha.nmTrilha}
                  label="Nome do Trilha"
                  error={nmTrilhaErro}
                  helperText={nmTrilhaErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setNmTrilhaErro(e.target.value === "");
                    setTrilha({
                      ...trilha,
                      nmTrilha: e.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={trilha.dsTrilha}
                  label="Descrição do Trilha"
                  error={dsTrilhaErro}
                  helperText={dsTrilhaErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDsTrilhaErro(e.target.value === "");
                    setTrilha({
                      ...trilha,
                      dsTrilha: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtIniTrilhaErro(true);
                      } else {
                        setDtIniTrilhaErro(false);
                      }
                    }}
                    label="Data de Início"
                    value={trilha.dtTrilhaInicio}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtIniTrilhaErro(false);
                      setTrilha({
                        ...trilha,
                        dtTrilhaInicio: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtIniTrilhaErro} helperText={dtIniTrilhaErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtFimTrilhaErro(true);
                      } else {
                        setDtFimTrilhaErro(false);
                      }
                    }}
                    label="Data Final"
                    value={trilha.dtTrilhaFim}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtFimTrilhaErro(false);
                      setTrilha({
                        ...trilha,
                        dtTrilhaFim: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtFimTrilhaErro} helperText={dtFimTrilhaErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={trilha.dsTempoEstimado}
                  label="Duração em Minutos"
                  error={dsTempoEstimadoErro}
                  helperText={dsTempoEstimadoErro ? "Campo Obrigatório." : ''}
                  onChange={(e) => {
                    setDsTempoEstimadoErro(e.target.value === "");
                    setTrilha({
                      ...trilha,
                      dsTempoEstimado: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaTemaComponent
                    error={temaErro}
                    temas={trilha.tema}
                    multiple={false}
                    onChangeTemas={(value: any) => {
                      setTemaErro(value.length === 0)
                      setTrilha({
                        ...trilha,
                        tema: value[0] ? value[0] : {}
                      })
                    }}
                  />
                  {temaErro ? <FormHelperText error={temaErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>


              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaCursoComponent
                    cursos={trilha.trilhaCursos}
                    onChangeCursos={(value: any) => {
                      setCursoErro(value.length === 0)
                      setTrilha({
                        ...trilha,
                        trilhaCursos: value
                      });
                      validaOrdenacao(value);
                    }}
                  />
                  {cursoErro ? <FormHelperText error={cursoErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>

              </Grid>

              <Grid item xs={6}>
                <ListaAvaliacaoComponent
                  avaliacoes={trilha.trilhaAvaliacoes}
                  onChangeAvaliacoes={(value: any) => setTrilha({
                    ...trilha,
                    trilhaAvaliacoes: value
                  })}
                />
              </Grid>

              {
                trilha.urlCompartilhamentoTrilha ? (
                  <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      disabled
                      sx={{ width: '80%' }}
                      value={trilha.urlCompartilhamentoTrilha}
                      label="Link de Compartilhamento"
                    />
                    <Tooltip title={copiado}>
                      <IconButton sx={{ fontSize: 25, marginLeft: '5px', trilhar: 'pointer' }} onClick={() => {
                        navigator.clipboard.writeText(trilha.urlCompartilhamentoTrilha || '').then(() => {
                          setCopiado('Copiado!');
                        }).catch(() => {
                          setCopiado('Não foi possível copiar o link de compartilhamento!');
                        }).finally(() => {
                          setTimeout(() => {
                            setCopiado('Copiar link de compartilhamento');
                          }, 1500);
                        });
                      }} >
                        <Icon >content_copy</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null
              }
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div style={{ maxHeight: 400, overflow: 'auto', width: '100%' }}>
              <List className="cursoOrdemList" >
                {
                  trilha.trilhaCursos && trilha.trilhaCursos.length > 0 ? (
                    trilha.trilhaCursos.map((curso: any, idx: number) => (
                      <ListItem key={`${curso.idCurso}`}>
                        <TextField
                          disabled
                          style={{ width: 400 }}
                          value={curso.nmCurso}
                          label="Curso"
                        />
                        <FormControl sx={{ width: 200, marginLeft: '1em' }} >
                          <InputLabel id="demo-controlled-open-select-label">Ordem do Curso</InputLabel>
                          <Select
                            value={curso.ordemCurso != 0 ? curso.ordemCurso : (idx + 1)}
                            label="Ordem do Curso"
                            onChange={(e) => {
                              let auxArr = trilha.trilhaCursos;
                              auxArr[idx].ordemCurso = parseInt(e.target.value.toString());
                              setTrilha({
                                ...trilha,
                                trilhaCursos: auxArr
                              })
                            }}
                          >
                            {
                              trilha.trilhaCursos.map((item: any, _idx: any) => (
                                <MenuItem key={`listItemOrdem-${idx}-${_idx}`} value={(_idx + 1)}>{(_idx + 1)}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </ListItem>
                    ))
                  ) : null
                }
              </List>
            </div>
          </TabPanel>
          <Divider style={{ marginTop: '1em' }} />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
              <Tab label="Informações" />
              <Tab label="Ordenação de Cursos" disabled={!(trilha.trilhaCursos && trilha.trilhaCursos.length > 0)} />
            </Tabs>
          </Box>
          <div className="cadastroFooter">
            <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
            {
              isEdicao ?
                <Button variant="contained" onClick={() => {
                  props.enviaNotificacao(trilha.idTrilha)
                }}>Enviar Notificação</Button> : null
            }
          </div>
        </form>
      </Box>

    </Modal>
  )

};

export default ModalCadastroTrilha;