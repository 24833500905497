import { Reducer } from "@reduxjs/toolkit";
import { ChatBotSaudacaoState, ChatBotSaudacaoTypes } from "./types";

const INITIAL_STATE: ChatBotSaudacaoState = {
    data: {
        table: null,
        saudacao: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<ChatBotSaudacaoState> = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_TABLE_REQ:
            return {
                ...state,
                error: false,
                loadingTable: true
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_TABLE_REQ_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_TABLE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_REQ:
            return {
                ...state,
                error: false,
                loading: true
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_REQ_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    saudacao: action.payload.data
                }
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loading: false,
                data: {
                    ...state.data,
                    saudacao: null
                }
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_SAVE_REQ:
        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_UPDATE_REQ:
            return {
                ...state,
                error: false,
                loadingPersis: true
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_SAVE_REQ_SUCCESS:
            alert('Saudação cadastrada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    saudacao: action.payload.data
                }
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_SAVE_REQ_FAILURE:
        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_UPDATE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loadingPersis: false,
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_UPDATE_REQ_SUCCESS:
            alert('Saudação alterada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    saudacao: action.payload.data
                }
            };


        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_DELETE_REQ:
                return {
                    ...state,
                    error: false,
                    loadingInativacao: true
                };
    
        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_DELETE_REQ_SUCCESS:
            alert('Saudação(ões) excluída(s) com sucesso!');
            return {
                ...state,
                error: false,
                loadingInativacao: false,
            };

        case ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_DELETE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loadingInativacao: false,
            };

        case ChatBotSaudacaoTypes.CLEAR_CHATBOT_SAUDACAO:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null,
                data:{
                    ...state.data,
                    saudacao: null
                }
            }

        default :
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
}

export default reducer;