import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import GenericTable from "../GenericTable/GenericTable";
import {
  JustificativaObjTable,
  JustificativaTable,
} from "../../store/ducks/justificativa/types";

const just: JustificativaObjTable[] = [];
const col: GridColDef[] = [];

const JustificativaTableComponent = (props: any) => {
  const [txtBusca, setTxtBusca] = useState("");
  const [columns, setColumns] = useState(col);
  const [listJustificativa, setListJustificativa] = useState(just);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [numberElements, setNumberElements] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    generateColumnsTable();
  }, []);

  useEffect(() => {
    if (props.data) {
      tranformObjectTable(props.data);
    }
  }, [props.data]);

  const generateColumnsTable = () => {
    const _columns: GridColDef[] = [
      { field: "nomeUsuario", headerName: "Nome Usuário", width: 300 },
      { field: "perfilUsuario", headerName: "Perfil Usuário", width: 300 },
      { field: "estadoUsuario", headerName: "Estado Usuário", width: 300 },
      {
        field: "municipioUsuario",
        headerName: "Municipio Usuário",
        width: 300,
      },
      { field: "avaliacao", headerName: "Avaliação", width: 300 },
      {
        field: "dsTipoJustificativa",
        headerName: "Tipo Justificativa",
        width: 300,
      },
    ];

    setColumns(_columns);
  };

  const tranformObjectTable = (obj: JustificativaTable) => {
    setSize(obj.size);
    setPage(obj.number);
    setTotalElements(obj.totalElements);
    setTotalPages(obj.totalPages);
    setNumberElements(obj.numberOfElements);
    setOffset(obj.pageable.offset);

    let list: JustificativaObjTable[] = obj.content.map((item: any) => {
      return {
        id: item.idJustificativa,
        idJustificativa: item.idJustificativa,
        idTipoJustificativa: item.tipoJustificativa.idTipoJustificativa,
        nomeUsuario: item.usuario.pessoaFisica.nmPessoaFisica,
        perfilUsuario: item.usuario.perfil.nmPerfil,
        estadoUsuario: item.usuario.pessoaFisica.municipio.estado.dsEstado,
        municipioUsuario: item.usuario.pessoaFisica.municipio.nmMunicipio,
        avaliacao: item.avaliacao.nmAvaliacao,
        dsTipoJustificativa: item.tipoJustificativa.dsTipoJustificativa,
      };
    });
    setListJustificativa(list);
  };

  const onPageChange = (pageIdx: any) => {
    props.body.pagina = pageIdx - 1;
    setPage(pageIdx - 1);
    props.onChange(props.body);
  };

  const onSizeChange = (_size: any) => {
    props.body.tamanho = _size;
    setSize(_size);
    props.onChange(props.body);
  };

  const onChangeBusca = (txt: string) => {
    props.body.txtBusca = txt;
    setTxtBusca(txt);
    if (typeof txt !== "number") {
      if (txt.length >= 3) {
        props.onChange(props.body);
      } else {
        props.body.txtBusca = "";
        props.onChange(props.body);
      }
    } else {
      props.body.txtBusca = props.body.txtBusca.toString();
      props.onChange(props.body);
    }
  };

  const onSortChange = (sort: any) => {
    if (sort != undefined) {
      let colunaSort =
        sort.field && sort.field == "dsTipoJustificativa"
          ? "justTipo.dsTipoJustificativa"
          : "justTipo.dsTipoJustificativa";

      props.body.coluna = colunaSort;
      props.body.ordem = sort.sort.toUpperCase();
    } else {
      props.body.coluna = "justTipo.dsTipoJustificativa";
      props.body.ordem = "ASC";
    }
    props.onChange(props.body);
  };

  return (
    <GenericTable
      loading={props.loading}
      titleTable="Justificativas Cadastradas"
      rows={listJustificativa}
      columns={columns}
      page={page}
      totalPages={totalPages}
      onPageChange={onPageChange}
      offset={offset}
      numberElements={numberElements}
      totalElements={totalElements}
      size={size}
      sizeChange={onSizeChange}
      txtBusca={txtBusca}
      onChangePesquisa={onChangeBusca}
      selection={props.selection}
      idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
      sortChange={onSortChange}
      select={props.select}
      selectData={props.selectData}
      selectPlaceholder={props.selectPlaceholder}
    />
  );
};

export default JustificativaTableComponent;
