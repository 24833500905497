import React, { useEffect, useRef, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { PerfilObjTable } from "../../store/ducks/perfil/types";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const defaultPerfil: PerfilObjTable = {
    nmPerfil: '',
    dsPerfil: ''
};

const ModalCadastroPerfil = (props: any) => {

    const [isEdicao, setIsEdicao] = useState(props.isEdicao);
    const [perfil, setPerfil] = useState<PerfilObjTable>(defaultPerfil);

    useEffect(() => {
        if(props.perfilData){
            setIsEdicao(true);
            setPerfil({
                ...perfil,
                ...props.perfilData
            });
        }
    }, [props.perfilData]);

    const handlePersist = (e: any) => {
        e.preventDefault();
        
        const perfilPersist: PerfilObjTable = {
            idPerfil: perfil.idPerfil,
            nmPerfil: perfil.nmPerfil,
            dsPerfil: perfil.dsPerfil
        };

        props.persistPerfil(isEdicao, perfilPersist);
    }

    const handleClose = () => {
        setIsEdicao(false);
        setPerfil(defaultPerfil);

        props.closeModal();
    }

    const buscaPerfilExistente = () => {
        if(!isEdicao && perfil.nmPerfil != '' && perfil.dsPerfil != '') {
            props.buscaPerfilExistente(perfil);
        }
    }

    return (
        <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if(res !== 'backdropClick') {
        handleClose()
      }
    }}
        >
            <Box sx={style} >
                <div className="modalHead">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isEdicao ? 'Editar de Público Alvo' : 'Cadastro de novo Público Alvo'}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Fechar
                    </Button>
                </div>
                <Divider />
                <form className="modalMid-form" onSubmit={handlePersist}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <TextField
                                required
                                sx={{width: '100%'}}
                                value={perfil.nmPerfil}
                                label="Nome do Público Alvo"
                                onChange={(e) => {
                                    setPerfil({
                                        ...perfil,
                                        nmPerfil: e.target.value
                                    })
                                }}
                                onBlur={buscaPerfilExistente}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                sx={{width: '100%'}}
                                required
                                value={perfil.dsPerfil}
                                label="Descrição do Público Alvo"
                                onChange={(e) => {
                                    setPerfil({
                                        ...perfil,
                                        dsPerfil: e.target.value
                                    })
                                }}
                                onBlur={buscaPerfilExistente}
                            />
                        </Grid>
                    </Grid>
                    <div className="cadastroFooter">
                        <Divider />
                        <Button variant="contained" type="submit">{isEdicao ? 'Gravar' : 'Cadastrar' }</Button>
                    </div>
                </form>
            </Box>
            
        </Modal>
    )

};

export default ModalCadastroPerfil;