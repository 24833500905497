import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { UsuarioObj } from "../../store/ducks/usuario/types";
import { mascaraCpf, validaEmail, verificarCPF } from "../../services/Uteis";
import { FormHelperText } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultUsuario: UsuarioObj = {
  cpf: "",
  emailPessoaFisica: "",
  loginNet: "",
  nmPessoaFisica: "",
  tipoProfissional: "",
  senha: "",
  idEstado: "",
  idMunicipio: "",
  idUnidadeNegocio: "",
  idPerfil: "",
  idRegional: "",
  idCluster: "",
  idGrupo: "",
  idSubcluster: "",
};

const ModalCadastroUsuario = (props: any) => {
  const [isEdicao, setIsEdicao] = useState(props.isEdicao);
  const [usuario, setUsuario] = useState(defaultUsuario);
  const [inativaGravar, setInativaGravar] = React.useState(true);
  const [nameError, setNameError] = React.useState(false);
  const [emailPessoaFisicaError, setEmailPessoaFisicaError] =
    React.useState(false);
  const [logNetError, setLogNetError] = React.useState(false);
  const [cpfError, setCpfError] = React.useState(false);
  const [senhaError, setSenhaError] = React.useState(false);
  const [tipoProfissionalError, setTipoProfissionalError] =
    React.useState(false);
  const [idPerfilError, setIdPerfilError] = React.useState(false);
  const [idUnidadeNegocioError, setIdUnidadeNegocioError] =
    React.useState(false);
  const [idEstadoError, setIdEstadoError] = React.useState(false);
  const [idMunicipioError, setIdMunicipioError] = React.useState(false);
  const [idGrupoError, setIdGrupoError] = React.useState(false);
  const [idClusterError, setIdClusterError] = React.useState(false);
  const [idSubclusterError, setIdSubclusterError] = React.useState(false);

  const [regional, setRegional] = React.useState<any>();
  const [grupo, setGrupo] = React.useState<any>();
  const [cluster, setCluster] = React.useState<any>();
  const [subcluster, setSubcluster] = React.useState<any>();

  useEffect(() => {
    if (
      usuario.loginNet === "" ||
      logNetError ||
      usuario.cpf === "" ||
      !verificarCPF(usuario.cpf) ||
      usuario.emailPessoaFisica === "" ||
      !validaEmail(usuario.emailPessoaFisica) ||
      usuario.senha === "" ||
      senhaError ||
      usuario.nmPessoaFisica === "" ||
      nameError ||
      usuario.tipoProfissional === "" ||
      tipoProfissionalError ||
      usuario.idPerfil === "0" ||
      idPerfilError ||
      usuario.idUnidadeNegocio === "" ||
      idUnidadeNegocioError ||
      usuario.idEstado === "" ||
      idEstadoError ||
      usuario.idMunicipio === "" ||
      idMunicipioError ||
      usuario.idGrupo === "" ||
      idGrupoError ||
      usuario.idCluster === "" ||
      idClusterError ||
      usuario.idSubcluster === "" ||
      idSubclusterError
    ) {
      setInativaGravar(true);
    } else {
      setInativaGravar(false);
    }
  }, [usuario]);

  useEffect(() => {
    if (props.regional !== null) {
      setUsuario((prevUsario) => ({
        ...prevUsario,
        idRegional: props.regional.idRegional,
      }));
      setRegional(props.regional);
    } else {
      setUsuario({
        ...usuario,
        idRegional: "",
      });
      setRegional("");
    }
  }, [props.regional]);

  useEffect(() => {
    if (props.subcluster !== null && props.subcluster !== undefined) {
      console.log(props);
      /* console.log(`idSubcluster: ${props.subcluster.idSubcluster}`); */
      setUsuario((prevUsario) => ({
        ...prevUsario,
        idSubcluster: props.subcluster.idSubcluster,
      }));
      setSubcluster(props.subcluster);
    } else {
      setUsuario({ ...usuario, idSubcluster: "" });
      setSubcluster("");
    }

    if (props.grupo !== null && props.grupo !== undefined) {
      /* console.log(`idGrupo: ${props.grupo.idGrupo}`); */
      setUsuario((prevUsario) => ({
        ...prevUsario,
        idGrupo: props.grupo.idGrupo,
      }));
      setGrupo(props.grupo);
    } else {
      setUsuario({ ...usuario, idGrupo: "" });
      setGrupo("");
    }

    if (props.cluster !== null && props.cluster !== undefined) {
      /* console.log(`idCluster: ${props.cluster.idCluster}`); */
      setUsuario((prevUsario) => ({
        ...prevUsario,
        idCluster: props.cluster.idCluster,
      }));
      setCluster(props.cluster);
    } else {
      setUsuario({ ...usuario, idCluster: "" });
      setCluster("");
    }
  }, [props.subcluster, props.grupo, props.cluster]);

  useEffect(() => {
    if (props.usuarioData) {
      setIsEdicao(true);
      props.changeEstado(
        props.usuarioData.pessoaFisica.municipio.estado.idEstado
      );
      setUsuario({
        ...props.usuarioData.pessoaFisica,
        idUsuario: props.usuarioData.idUsuario,
        idPerfil: props.usuarioData.perfil.idPerfil,
        idUnidadeNegocio:
          props.usuarioData.pessoaFisica.unidadeNegocio.idUnidadeNegocio,
        idEstado: props.usuarioData.pessoaFisica.municipio.estado.idEstado,
        idMunicipio: props.usuarioData.pessoaFisica.municipio.idMunicipio,

        idRegional:
          props.usuarioData.pessoaFisica.municipio.regional.idRegional,
        idGrupo: props.usuarioData.pessoaFisica.municipio.grupo.idGrupo,
        idCluster: props.usuarioData.pessoaFisica.municipio.cluster.idCluster,
        idSubcluster:
          props.usuarioData.pessoaFisica.municipio.subcluster.idSubcluster,
      });
      setRegional(props.usuarioData.pessoaFisica.municipio.regional);

      setGrupo({
        idGrupo: props.usuarioData.pessoaFisica.municipio.grupo.idGrupo,
        dsGrupo: props.usuarioData.pessoaFisica.municipio.grupo.dsGrupo,
        nmGrupo: props.usuarioData.pessoaFisica.municipio.grupo.nmGrupo,
      });
      setCluster({
        idCluster: props.usuarioData.pessoaFisica.municipio.cluster.idCluster,
        dsCluster: props.usuarioData.pessoaFisica.municipio.cluster.dsCluster,
        nmCluster: props.usuarioData.pessoaFisica.municipio.cluster.nmCluster,
      });
      setSubcluster({
        idSubcluster:
          props.usuarioData.pessoaFisica.municipio.subcluster.idSubcluster,
        dsSubcluster:
          props.usuarioData.pessoaFisica.municipio.subcluster.dsSubcluster,
        nmSubcluster:
          props.usuarioData.pessoaFisica.municipio.subcluster.nmSubcluster,
      });
      props.getGroupClusterSubclusterData();
    }
  }, [props.usuarioData]);

  const handleClose = () => {
    setIsEdicao(false);
    setUsuario(defaultUsuario);
    setRegional("");
    setIdGrupoError(false);
    setIdClusterError(false);
    setIdSubclusterError(false);
    setNameError(false);
    setEmailPessoaFisicaError(false);
    setCpfError(false);
    setSenhaError(false);
    setTipoProfissionalError(false);
    setIdPerfilError(false);
    setIdUnidadeNegocioError(false);
    setIdEstadoError(false);
    setIdMunicipioError(false);
    setGrupo("");
    setCluster("");
    setSubcluster("");
    props.closeModal();
  };

  const handlePersist = (e: any) => {
    e.preventDefault();

    const usuarioPersis: any = {
      ...usuario,
    };

    delete usuarioPersis.municipio;
    delete usuarioPersis.perfil;
    delete usuarioPersis.unidadeNegocio;
    delete usuarioPersis.idCluster;
    delete usuarioPersis.idGrupo;
    delete usuarioPersis.idSubcluster;


    props.persistUsuario(isEdicao, usuarioPersis);
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? "Alteração de usuário" : "Cadastro de novo usuário"}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={usuario.nmPessoaFisica}
                label="Nome Completo"
                error={nameError}
                helperText={nameError ? "Campo Obrigatório." : ""}
                onChange={(e) => {
                  setNameError(e.target.value === "");
                  setUsuario({
                    ...usuario,
                    nmPessoaFisica: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                required
                value={usuario.emailPessoaFisica}
                label="E-Mail"
                error={
                  emailPessoaFisicaError && usuario.emailPessoaFisica !== ""
                }
                helperText={
                  emailPessoaFisicaError && usuario.emailPessoaFisica !== ""
                    ? "Insira um e-mail válido"
                    : ""
                }
                onChange={(e) => {
                  setEmailPessoaFisicaError(!validaEmail(e.target.value));
                  setUsuario({
                    ...usuario,
                    emailPessoaFisica: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                required
                inputProps={{ minLength: 7 }}
                sx={{ width: "100%" }}
                value={usuario.loginNet}
                label="Login"
                error={logNetError}
                helperText={
                  logNetError ? "O login precisa ter pelo menos 7 dígitos" : ""
                }
                onChange={(e) => {
                  setLogNetError(
                    e.target.value.length < 7 && e.target.value.length !== 0
                  );
                  setUsuario({
                    ...usuario,
                    loginNet: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                required
                disabled={isEdicao}
                value={mascaraCpf(usuario.cpf)}
                sx={{ width: "100%" }}
                label="CPF"
                error={cpfError && usuario.cpf !== ""}
                helperText={
                  cpfError && usuario.cpf !== "" ? "Insira um CPF válido" : ""
                }
                onChange={(e) => {
                  setCpfError(!verificarCPF(e.target.value));
                  setUsuario({
                    ...usuario,
                    cpf: mascaraCpf(e.target.value.trim()),
                  });
                }}
              />
            </Grid>

            {!isEdicao ? (
              <Grid item xs={3}>
                <TextField
                  required
                  value={usuario.senha}
                  sx={{ width: "100%" }}
                  label="Senha"
                  error={senhaError}
                  helperText={senhaError ? "Campo Obrigatório." : ""}
                  onChange={(e) => {
                    setSenhaError(e.target.value === "");
                    setUsuario({
                      ...usuario,
                      senha: e.target.value,
                    });
                  }}
                />
              </Grid>
            ) : null}

            <Grid item xs={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Tipo Profissional
                </InputLabel>
                <Select
                  required
                  value={usuario.tipoProfissional}
                  label="Tipo Profissional"
                  defaultValue={""}
                  error={tipoProfissionalError}
                  onChange={(e) => {
                    setTipoProfissionalError(e.target.value === "");
                    setUsuario({
                      ...usuario,
                      tipoProfissional: e.target.value,
                    });
                  }}
                >
                  <MenuItem value="">Nenhum...</MenuItem>
                  <MenuItem value={"ASSOCIADO"}>Associado</MenuItem>
                  <MenuItem value={"TERCEIRO"}>Terceiro</MenuItem>
                </Select>
                {tipoProfissionalError ? (
                  <FormHelperText error={tipoProfissionalError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Perfil
                </InputLabel>
                <Select
                  required
                  value={usuario.idPerfil}
                  label="Perfil"
                  defaultValue={"0"}
                  error={idPerfilError}
                  onChange={(e) => {
                    setIdPerfilError(e.target.value === "0");
                    setUsuario({
                      ...usuario,
                      idPerfil: parseInt(e.target.value.toString()),
                    });
                  }}
                >
                  <MenuItem value="0">Perfil...</MenuItem>
                  {props.perfis
                    ? props.perfis.map((item: any, idx: any) => (
                        <MenuItem key={"perf-" + idx} value={item.idPerfil}>
                          {item.nmPerfil}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idPerfilError ? (
                  <FormHelperText error={idPerfilError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Unidade de Negócio
                </InputLabel>
                <Select
                  required
                  value={usuario.idUnidadeNegocio}
                  label="Unidade de Negócio"
                  defaultValue={""}
                  error={idUnidadeNegocioError}
                  onChange={(e) => {
                    setIdUnidadeNegocioError(e.target.value === "");
                    setUsuario({
                      ...usuario,
                      idUnidadeNegocio: parseInt(e.target.value.toString()),
                    });
                  }}
                >
                  <MenuItem value="">Unidade de Negócio...</MenuItem>
                  {props.unidadesNegocio
                    ? props.unidadesNegocio.map((item: any, idx: any) => (
                        <MenuItem
                          key={"uniNegocio-" + idx}
                          value={item.idUnidadeNegocio}
                        >
                          {item.nmUnidadeNegocio}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idUnidadeNegocioError ? (
                  <FormHelperText error={idUnidadeNegocioError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Estado
                </InputLabel>
                <Select
                  required
                  value={usuario.idEstado}
                  label="Estado"
                  defaultValue={""}
                  error={idEstadoError}
                  onChange={(e) => {
                    setIdEstadoError(e.target.value === "");
                    setUsuario({
                      ...usuario,
                      idEstado: parseInt(e.target.value.toString()),
                      idMunicipio: "",
                    });
                    setRegional("");
                    props.changeEstado(e.target.value);
                  }}
                >
                  <MenuItem value="">Estado...</MenuItem>
                  {props.estados
                    ? props.estados.map((item: any, idx: any) => (
                        <MenuItem key={"estado-" + idx} value={item.idEstado}>
                          {item.dsEstado + " - " + item.nmEstado}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idEstadoError ? (
                  <FormHelperText error={idEstadoError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Município
                </InputLabel>
                <Select
                  required
                  value={usuario.idMunicipio}
                  label="Município"
                  defaultValue={""}
                  error={idMunicipioError}
                  onChange={(e) => {
                    setIdMunicipioError(e.target.value === "");
                    setUsuario({
                      ...usuario,
                      idMunicipio: parseInt(e.target.value.toString()),
                    });
                    if (e.target.value !== "") {
                      props.changeMunicipio(e.target.value);
                    }
                  }}
                >
                  <MenuItem value="">Município...</MenuItem>
                  {props.municipios
                    ? props.municipios.map((item: any, idx: any) => (
                        <MenuItem
                          key={"munici-" + idx}
                          value={item.idMunicipio}
                        >
                          {item.nmMunicipio}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idMunicipioError ? (
                  <FormHelperText error={idMunicipioError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled
                value={regional ? regional.nmRegional : ""}
                sx={{ width: "100%" }}
                label="Regional"
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled
                value={grupo ? grupo.nmGrupo : ""}
                sx={{ width: "100%" }}
                label="Grupo"
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled
                value={cluster ? cluster.nmCluster : ""}
                sx={{ width: "100%" }}
                label="Cluster"
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled
                value={subcluster ? subcluster.nmSubcluster : ""}
                sx={{ width: "100%" }}
                label="Subcluster"
              />
            </Grid>
          </Grid>
          <div className="cadastroFooter" style={{ display: "flex" }}>
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">
              {isEdicao ? "Gravar" : "Cadastrar"}
            </Button>
            {(isEdicao || usuario.idUsuario) && (
                <Button
                  variant="contained"
                  type="submit"
                  disabled
                  style={{
                    marginLeft: "auto",
                    userSelect: "text",
                    color: "#d32f2f",
                  }}
                >
                  <b>ID:</b>
                  <strong>{" " + usuario.idUsuario}</strong>
                </Button>
              )}
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroUsuario;
