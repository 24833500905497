import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";
import * as SubclusterAction from "../../store/ducks/subcluster/actions";
import * as UtilsAction from "../../store/ducks/utils/actions";

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";
import Loading from "../../Components/LoadingComponent";
import ModalCadastroSubcluster from "../../Components/ModalCadastroSubcluster";
import SubclusterTableComponent from "../../Components/TableSubcluster";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
  pagina: 0,
  tamanho: 10,
  ordem: "ASC",
  coluna: "nmSubcluster",
  txtBusca: "",
};

function Subcluster({
  subcluster,
  getSubclusterTable,
  getSubclusterData,
  onSaveSubcluster,
  onUpdateSubcluster,
  onInativarSubcluster,
  clearSubclusterData,
  getAllClusterList,
  utils,
}: any) {
  const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
  const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
  const [body, setBody] = useState(_body);
  const [table, setTable] = useState(null);
  const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
  const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
  const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

  useEffect(() => {
    if (subcluster.error) {
      alert(
        "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
          subcluster.errorMessage
      );
    }
  }, [subcluster.error]);

  useEffect(() => {
    if (!modalCadastroOpen) {
      getSubclusterTableData();
    }
  }, [body, modalCadastroOpen]);

  useEffect(() => {
    if (subcluster.data.table != null) {
      setTable(subcluster.data.table);
    }
  }, [subcluster.data]);

  useEffect(() => {
    getAllClusterList();
  }, []);

  useEffect(() => {
    if (!subcluster.loadingInativacao) {
      getSubclusterTable(body);
    }
  }, [subcluster.loadingInativacao]);

  useEffect(() => {
    if (!subcluster.loadingPersis) {
      handleCloseAndReload();
    }
  }, [subcluster.loadingPersis]);

  const getSubclusterTableData = () => {
    getSubclusterTable(body);
  };

  const handleCadastro = () => {
    clearSubclusterData();
    setModalCadastroOpen(true);
  };

  const handleEdicao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione um Subcluster que gostaria de alterar");
    } else if (idsSelecionado.length > 1) {
      alert("Selecione apenas um Subcluster para alteração!");
    } else {
      getSubclusterData(idsSelecionado[0]);
      setModalCadastroOpen(true);
    }
  };

  const changeTable = (__body: any) => {
    setBody({ ...__body });
  };

  const handlePersistSubcluster = (edicao: boolean, subcluster: any) => {
    if (edicao) {
      onUpdateSubcluster(subcluster);
    } else {
      onSaveSubcluster(subcluster);
    }
  };

  const handleCloseAndReload = () => {
    clearSubclusterData();
    setModalCadastroOpen(false);
    setIdSelecionado([]);
  };

  const handleInativacao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione os Subclusters que gostaria de inativar");
    } else {
      setAlertConfirmacaoOpen(true);
      setAlertConfirmacaoTexto(EntidadesAcoes.SUBCLUSTER_EXCLUSAO);
      setAlertConfirmacaoAcao(() => () => {
        onInativarSubcluster(idsSelecionado);
        setAlertConfirmacaoOpen(false);
      })
    }
  };

  function unmountAlertConfirmacao() {
    setAlertConfirmacaoTexto('');
    setAlertConfirmacaoAcao(() => () => {})
    setAlertConfirmacaoOpen(false);
  }

  return (
    <div className="pageBox">
      <Loading loading={subcluster.loading || subcluster.loadingPersis} />
      <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
      <ModalCadastroSubcluster
        modalCadastroOpen={modalCadastroOpen}
        closeModal={() => handleCloseAndReload()}
        subclustersData={subcluster.data.subcluster}
        persistSubcluster={handlePersistSubcluster}
        getSubclusterData={(idSubcluster: any) =>
          getSubclusterData(idSubcluster)
        }
        listAllClusters={utils.data.clusters}
      />
      <div className="menuLateral">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            width: "100%",
          }}
        >
          Ações
          <AcaoMenu
            onCadastro={handleCadastro}
            onEditar={handleEdicao}
            onInativar={handleInativacao}
          />
        </Typography>
      </div>
      <div className="table">
        <SubclusterTableComponent
          loading={subcluster.loadingTable}
          data={table}
          body={body}
          onChange={changeTable}
          selection={idsSelecionado}
          idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  subcluster: state.subcluster,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...SubclusterAction, ...UtilsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Subcluster);
