import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { getRelatorioFailure, getRelatorioSuccess } from "./actions";
import { refreshToken } from "../auth/actions";

function* getRelatorio(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("relatorio/gerarByTipo", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then(async (res) => {
        const blobTxt = await new Blob([res.data]).text();
        if (
          blobTxt ==
            "Atenção! Essa extração terá um maior volume de dados e por isso notificaremos você por e-mail quando finalizarmos!" ||
          blobTxt == "Não foram encontrados dados para este(s) filtro(s)!"
        ) {
          alert(blobTxt);
          return;
        }
        alert("Ação realizada com sucesso!");
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          res.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  try {
    yield put(getRelatorioSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getRelatorioFailure(error.message));
  }
}

export { getRelatorio };
