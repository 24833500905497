import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import {
  getJustificativaDataFailure,
  getJustificativaDataSuccess,
  getJustificativaTableFailure,
  getJustificativaTableSuccess,
  getJustificativaTipoListFailure,
  getJustificativaTipoListSuccess,
  onInativarJustificativaFailure,
  onInativarJustificativaSuccess,
  onSaveJustificativaFailure,
  onSaveJustificativaSuccess,
  onUpdateJustificativaFailure,
  onUpdateJustificativaSuccess
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getJustificativaTable(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem('accessToken');

  const doRequest = () => {
    return api.post('justificativa/listAllJustificativaTable', body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },

    }).then(res => res.data)

  }

  try {

    yield put(getJustificativaTableSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getJustificativaTableFailure(error.message));
  }
};

function* onInativarJustificativa(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem('accessToken');

  const doRequest = () => {
    return api.post(`justificativa/desativarJustificativa`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },

    }).then(res => res.data)

  }

  try {

    yield put(onInativarJustificativaSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onInativarJustificativaFailure(error.message));
  }
};

function* getJustificativaData(data: any): any {
  const idJustificativa = data.payload;
  const token = localStorage.getItem('accessToken');

  const doRequest = () => {
    return api.get(`justificativa/${idJustificativa}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(res => res.data)

  }

  try {
    yield put(getJustificativaDataSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getJustificativaDataFailure(error.message));
  }
};

function* onSaveJustificativa(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem('accessToken');

  const doRequest = () => {
    return api.post('justificativa/save', body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },

    }).then(res => res.data)

  }

  try {

    yield put(onSaveJustificativaSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onSaveJustificativaFailure(error.message));
  }
};

function* getJustificativaTipoList(): any {
  const token = localStorage.getItem('accessToken');

  const doRequest = () => {
    return api.get('justificativa/listAllTipoJustificativa', {
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(res => res.data)

  }

  try {
    yield put(getJustificativaTipoListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getJustificativaTipoListFailure(error.message));
  }
}


function* onUpdateJustificativa(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem('accessToken');

  const doRequest = () => {
    return api.post('justificativa/save', body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },

    }).then(res => res.data)

  }

  try {

    yield put(onUpdateJustificativaSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onUpdateJustificativaFailure(error.message));
  }
};

export {
  getJustificativaData,
  getJustificativaTable,
  onSaveJustificativa,
  onUpdateJustificativa,
  onInativarJustificativa,
  getJustificativaTipoList
}
