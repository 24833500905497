import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import GenericTable from "../GenericTable/GenericTable";
import { GrupoObjTable, GrupoTable } from "../../store/ducks/grupo/types";

const grupo: GrupoObjTable[] = [];
const col: GridColDef[] = [];

const GrupoTableComponent = (props: any) => {
  const [txtBusca, setTxtBusca] = useState("");
  const [columns, setColumns] = useState(col);
  const [listGrupos, setListGrupos] = useState(grupo);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [numberElements, setNumberElements] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    generateColumnsTable();
  }, []);

  useEffect(() => {
    if (props.data) {
      tranformObjectTable(props.data);
    }
  }, [props.data]);

  const generateColumnsTable = () => {
    const _columns: GridColDef[] = [
      { field: "nmGrupo", headerName: "Nome", width: 300 },
      { field: "dsGrupo", headerName: "Descrição", width: 400 },
    ];

    setColumns(_columns);
  };

  const tranformObjectTable = (obj: GrupoTable) => {
    setSize(obj.size);
    setPage(obj.number);
    setTotalElements(obj.totalElements);
    setTotalPages(obj.totalPages);
    setNumberElements(obj.numberOfElements);
    setOffset(obj.pageable.offset);

    let list: GrupoObjTable[] = obj.content.map((content: any) => {
      const item = [content.idGrupo, content.nmGrupo, content.dsGrupo];
      return {
        id: item[0],
        idGrupo: item[0],
        nmGrupo: item[1],
        dsGrupo: item[2],
      };
    });

    setListGrupos(list);
  };

  const onPageChange = (pageIdx: any) => {
    props.body.pagina = pageIdx - 1;
    setPage(pageIdx - 1);
    props.onChange(props.body);
  };

  const onSizeChange = (_size: any) => {
    props.body.tamanho = _size;
    setSize(_size);
    props.onChange(props.body);
  };

  const onChangeBusca = (txt: string) => {
    props.body.txtBusca = txt;
    setTxtBusca(txt);
    if (txt.length >= 3) {
      props.onChange(props.body);
    } else {
      props.body.txtBusca = "";
      props.onChange(props.body);
    }
  };

  const onSortChange = (sort: any) => {
    if (sort != undefined) {
      let colunaSort =
        sort.field && sort.field == "nmGrupo"
          ? "nmGrupo"
          : sort.field == "dsGrupo"
          ? "dsGrupo"
          : "nmGrupo";

      props.body.coluna = colunaSort;
      props.body.ordem = sort.sort.toUpperCase();
    } else {
      props.body.coluna = "nmGrupo";
      props.body.ordem = "ASC";
    }
    props.onChange(props.body);
  };

  return (
    <GenericTable
      loading={props.loading}
      titleTable="Grupos Cadastrados"
      rows={listGrupos}
      columns={columns}
      page={page}
      totalPages={totalPages}
      onPageChange={onPageChange}
      offset={offset}
      numberElements={numberElements}
      totalElements={totalElements}
      size={size}
      sizeChange={onSizeChange}
      txtBusca={txtBusca}
      onChangePesquisa={onChangeBusca}
      selection={props.selection}
      idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
      sortChange={onSortChange}
    />
  );
};

export default GrupoTableComponent;
