import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";

import { ApplicationState } from "../store";
import { doLogoutApp, refreshToken } from "../store/ducks/auth/actions";

import LoginPage from "../Pages/Login/LoginPage";
import DrawerMenu from "../Components/DrawerMenu/DrawerMenu";
import Usuario from "../Pages/Usuario/Usuario";
import Perfil from "../Pages/Perfil/Perfil";
import Conteudo from "../Pages/Conteudo/Conteudo";
import Avaliacao from "../Pages/Avaliacao/Avaliacao";
import Tema from "../Pages/Tema/Tema";
import Curso from "../Pages/Curso/Curso";
import PesquisaExterna from "../Pages/PesquisaExterna/PesquisaExterna";
import Trilha from "../Pages/Trilha/Trilha";
import Relatorio from "../Pages/Relatorio/Relatorio";
import Treinamento1Ponto from "../Pages/Treinamento1Ponto/Treinamento1Ponto";
import Comunicado from "../Pages/Comunicado/Comunicado";
import Destaque from "../Pages/Destaque/Destaque";
import Justificativa from "../Pages/Justificativa/Justificativa";
import Cluster from "../Pages/Cluster/Cluster";
import Subcluster from "../Pages/Subcluster/Subcluster";
import Grupo from "../Pages/Grupo/Grupo";
import Story from "../Pages/Story/Story";
import Municipio from "../Pages/Municipio/Municipio";
import SegurancaNoTrabalho from "../Pages/SegurancaNoTrabalho/SegurancaNoTrabalho";
import Categoria from "../Pages/Chatbot/Categoria/Categoria";
import Nivel from "../Pages/Chatbot/Nivel/Nivel";
import Saudacao from "../Pages/Chatbot/Saudacao/Saudacao";
import Diagrama from "../Pages/Chatbot/Diagrama/Diagrama";

const AppRouter = ({ auth, doLogoutApp, refreshToken }: any) => {
  const [authorized, setAuthorized] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isTreinamentoTecnico, setIsTreinamentoTecnico] = useState(true);

  useEffect(() => {
    if (auth.data.authorized) {
      localStorage.setItem("accessToken", auth.data.accessToken);
      localStorage.setItem("chaveToken", auth.data.chaveToken);
    }
  }, [auth.data.accessToken]);

  useEffect(() => {
    setAuthorized(auth.data.authorized);
    const currentUserIsAdmin = auth.data.idPerfil === 1;
    const currentUserIsTreinamentoTecnico = auth.data.idPerfil === 30;
    setIsAdmin(currentUserIsAdmin);
    setIsTreinamentoTecnico(currentUserIsTreinamentoTecnico);
    if (auth.data.authorized) {
      localStorage.setItem("accessToken", auth.data.accessToken);
      localStorage.setItem("chaveToken", auth.data.chaveToken);
    }
  }, [auth.data.authorized]);

  return (
    <Router>
      {authorized ? (
        <Routes>
          {(isAdmin || isTreinamentoTecnico) && (
            <Route path="*" element={<Navigate replace to={"/usuarios"} />} />
          )}
          {!isAdmin && (
            <Route
              path="*"
              element={<Navigate replace to={"/justificativas"} />}
            />
          )}
          <Route
            path="/"
            element={
              <DrawerMenu
                {...auth.data}
                logoutApp={doLogoutApp}
                refreshToken={refreshToken}
              />
            }
          >
            {(isAdmin || isTreinamentoTecnico) && (
              <>
                <Route path="/usuarios" element={<Usuario />} />
                <Route path="/relatorios" element={<Relatorio />} />
              </>
            )}

            {isAdmin && (
              <>
                <Route path="/perfis" element={<Perfil />} />
                <Route path="/conteudos" element={<Conteudo />} />
                <Route path="/destaques" element={<Destaque />} />
                <Route path="/avaliacoes" element={<Avaliacao />} />
                <Route path="/pesquisa" element={<PesquisaExterna />} />
                <Route path="/temas" element={<Tema />} />
                <Route path="/cursos" element={<Curso />} />
                <Route path="/segurancaDoTrabalho" element={<SegurancaNoTrabalho />} />
                <Route path="/trilhas" element={<Trilha />} />
                <Route path="/t1" element={<Treinamento1Ponto />} />
                <Route path="/comunicados" element={<Comunicado />} />
                <Route path="/clusters" element={<Cluster />} />
                <Route path="/subclusters" element={<Subcluster />} />
                <Route path="/grupos" element={<Grupo />} />
                {/*  */}
                <Route path="/municipios" element={<Municipio />} />
                <Route path="/chatbot/categoria" element={<Categoria />} />
                <Route path="/chatbot/nivel" element={<Nivel />} />
                <Route path="/chatbot/saudacao" element={<Saudacao />} />
                <Route path="/chatbot/diagrama" element={<Diagrama />} />
              </>
            )}
            <Route path="/justificativas" element={<Justificativa />} />
            <Route path="/story" element={<Story />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      )}
    </Router>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  state: state,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ doLogoutApp, refreshToken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
