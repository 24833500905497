import { Reducer } from "@reduxjs/toolkit";
import { ChatBotCategoriaState, ChatBotCategoriaTypes } from "./types";

const INITIAL_STATE: ChatBotCategoriaState = {
    data: {
        table: null,
        categoria: null,
        lista: [],
        diagrama: [],
    },
    error: false,
    errorChave: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<ChatBotCategoriaState> = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_TABLE_REQ:
            return {
                ...state,
                error: false,
                loadingTable: true
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_TABLE_REQ_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_TABLE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: null
                }
            };

            case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DIAGRAMA_REQ:
                return {
                    ...state,
                    error: false,
                    loading: true
                };
    
            case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DIAGRAMA_REQ_SUCCESS:
                return {
                    ...state,
                    error: false,
                    loading: false,
                    data: {
                        ...state.data,
                        diagrama: action.payload.data
                    }
                };
    
            case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DIAGRAMA_REQ_FAILURE:
                return {
                    ...state,
                    error: true,
                    errorMessage: action.payload.err,
                    loading: false,
                    data: {
                        ...state.data,
                        diagrama: []
                    }
                };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_REQ:
            return {
                ...state,
                error: false,
                loading: true
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_REQ_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    categoria: action.payload.data
                }
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loading: false,
                data: {
                    ...state.data,
                    categoria: null
                }
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_SAVE_REQ:
        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_UPDATE_REQ:
            return {
                ...state,
                error: false,
                loadingPersis: true
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_SAVE_REQ_SUCCESS:
            alert('Categoria cadastrada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    categoria: action.payload.data
                }
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_SAVE_REQ_FAILURE:
        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_UPDATE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loadingPersis: false,
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_UPDATE_REQ_SUCCESS:
            alert('Categoria alterada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    categoria: action.payload.data
                }
            };


        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DELETE_REQ:
                return {
                    ...state,
                    error: false,
                    loadingInativacao: true
                };
    
        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DELETE_REQ_SUCCESS:
            alert('Categoria excluída com sucesso!');
            return {
                ...state,
                error: false,
                loadingInativacao: false,
            };

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DELETE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err,
                loadingInativacao: false,
            };

        case ChatBotCategoriaTypes.CLEAR_CHATBOT_CATEGORIA:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null,
                data:{
                    ...state.data,
                    categoria: null
                }
            }

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_KEY_REQ:
        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_KEY_REQ_SUCCESS:
            return {
                ...state,
                errorChave: false
            }

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_KEY_REQ_FAILURE:
            alert(`Atenção\n${action.payload.err.data}`);
            return {
                ...state,
                errorChave: true
            }

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_LIST_REQ:
            return {
                ...state,
            }
    
        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_LIST_REQ_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    lista: action.payload.data
                }
            }

        case ChatBotCategoriaTypes.CHATBOT_CATEGORIA_LIST_REQ_FAILURE:
            return {
                ...state,
                data: {
                    ...state.data,
                    lista: []
                }
            }

        default :
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
}

export default reducer;