import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";
import * as MunicipioAction from "../../store/ducks/municipios/actions";

import { ApplicationState } from "../../store";
import Loading from "../../Components/LoadingComponent";
import ModalCadastroMunicipio from "../../Components/ModalCadastroMunicipio";
import MunicipioTableComponent from "../../Components/TableMunicipio";
import AcaoMunicipioMenu from "../../Components/AcaoMunicipioMenu";

const _body = {
  pagina: 0,
  tamanho: 10,
  ordem: "ASC",
  coluna: "nmMunicipio",
  txtBusca: "",
};

function Municipio({
  municipio,
  getMunicipioTable,
  getMunicipioData,
  onSaveMunicipio,
  clearMunicipioData,
  getGrupoClusterSubcluster,
}: any) {
  const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
  const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
  const [body, setBody] = useState(_body);
  const [table, setTable] = useState(null);

  useEffect(() => {
    if (municipio.error) {
      alert(
        "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
          municipio.errorMessage
      );
    }
  }, [municipio.error]);

  useEffect(() => {
    if (!modalCadastroOpen) {
      getMunicipioTableData();
    }
  }, [body, modalCadastroOpen]);

  useEffect(() => {
    if (municipio.data.table != null) {
      setTable(municipio.data.table);
    }
  }, [municipio.data]);

  useEffect(() => {
    if (!municipio.loadingInativacao) {
      getMunicipioTable(body);
    }
  }, [municipio.loadingInativacao]);

  useEffect(() => {
    if (!municipio.loadingPersis) {
      handleCloseAndReload();
    }
  }, [municipio.loadingPersis]);

  const getMunicipioTableData = () => {
    getMunicipioTable(body);
  };

  const onRelacionar = () => {
    if(idsSelecionado.length == 0) {
      alert('Selecione um município que gostaria de relacionar');
  } else if(idsSelecionado.length > 1) {
      alert('Selecione apenas um município para relacionar!');
  } else {
      getMunicipioData(idsSelecionado[0]);
      setModalCadastroOpen(true);
  }
  };

  const changeTable = (__body: any) => {
    setBody({ ...__body });
  };

  const handlePersistMunicipio = (municipio: any) => {
    onSaveMunicipio(municipio);
  };

  const handleCloseAndReload = () => {
    clearMunicipioData();
    setModalCadastroOpen(false);
    setIdSelecionado([]);
  };

  return (
    <div className="pageBox">
      <Loading loading={municipio.loading || municipio.loadingPersis} />
      <ModalCadastroMunicipio
        modalCadastroOpen={modalCadastroOpen}
        closeModal={() => handleCloseAndReload()}
        municipiosData={municipio.data.municipio}
        persistMunicipio={handlePersistMunicipio}
      />
      <div className="menuLateral">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            width: "100%",
          }}
        >
          Ações
          <AcaoMunicipioMenu onRelacionar={onRelacionar} />
        </Typography>
      </div>
      <div className="table">
        <MunicipioTableComponent
          loading={municipio.loadingTable}
          data={table}
          body={body}
          onChange={changeTable}
          selection={idsSelecionado}
          idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  municipio: state.municipio,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...MunicipioAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Municipio);
