import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { FormHelperText } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ListaAvaliacaoComponent from "../ListaAvaliacaoComponent";
import ListaPerfilComponent from "../ListaPerfilComponent";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultJustificativa: any = {
  idJustificativa: "",
  idEstado: "",
  idMunicipio: "",
  idUsuario: "",
  idAvaliacao: "",
  idTipoJustificativa: "",
  idPerfil: "",
  dtDesligRetorno: null,
};

const ModalCadastroJustificativa = (props: any) => {
  const [dtDesligRetornoError, setDtDesligRetornoError] = useState(false);
  const [idNvPerfilError, setIdNvPerfilError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [tpJustificativaError, setTpJustificativaError] = useState(false);
  const [idUsuarioError, setIdUsuarioError] = useState(false);
  const [idEstadoError, setIdEstadoError] = useState(false);
  const [idMunicipioError, setIdMunicipioError] = useState(false);
  const [isEdicao, setIsEdicao] = useState(props.isEdicao);
  const [justificativa, setJustificativa] = useState(defaultJustificativa);

  const tipo =
    props.listJustificativaTipo !== null
      ? props.listJustificativaTipo.find(
          (tipo: any) =>
            tipo.idTipoJustificativa === justificativa.idTipoJustificativa
        )?.dsTipoJustificativa ?? ""
      : "";
  const tpValidado =
    tipo === "Desligado"
      ? !!justificativa.dtDesligRetorno
      : tipo === "Licença"
      ? !!justificativa.dtDesligRetorno
      : tipo === "Promovido"
      ? !!justificativa.idPerfil
      : true;

  useEffect(() => {
    if (
      !!justificativa.idMunicipio &&
      !!justificativa.idAvaliacao &&
      !!justificativa.idEstado &&
      !!justificativa.idUsuario &&
      !!justificativa.idTipoJustificativa &&
      tpValidado
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    justificativa,
    dtDesligRetornoError,
    tpJustificativaError,
    idUsuarioError,
    idMunicipioError,
    idEstadoError,
    idNvPerfilError,
  ]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (props.justificativaData) {
      setIsEdicao(true);
      props.setLoading(true);
      props.changeEstado(
        props.justificativaData?.usuario?.pessoaFisica?.municipio?.estado
          ?.idEstado ?? ""
      );
      props.changeMunicipio(
        props.justificativaData?.usuario?.pessoaFisica?.municipio
          ?.idMunicipio ?? ""
      );
      const dadosJustificativa = {
        idJustificativa: props.justificativaData?.idJustificativa ?? "",
        idEstado:
          props.justificativaData?.usuario?.pessoaFisica?.municipio?.estado
            ?.idEstado ?? "",
        idMunicipio:
          props.justificativaData?.usuario?.pessoaFisica?.municipio
            ?.idMunicipio ?? "",
        idUsuario: props.justificativaData?.usuario.idUsuario ?? "",
        idAvaliacao: props.justificativaData?.avaliacao.idAvaliacao ?? "",
        idTipoJustificativa:
          props.justificativaData?.tipoJustificativa?.idTipoJustificativa ?? "",
        dtDesligRetorno: props.justificativaData?.dtDesligRetorno ?? null,
        idPerfil: props.justificativaData?.usuario?.perfil?.idPerfil ?? null,
      };
      setJustificativa({
        ...justificativa,
        ...dadosJustificativa,
      });
      props.setLoading(false);
    }
  }, [props.justificativaData]);

  const handlePersist = (e: any) => {
    e.preventDefault();

    const justificativaObj = {
      idJustificativa: justificativa.idJustificativa,
      tipoJustificativa: {
        idTipoJustificativa: justificativa.idTipoJustificativa,
      },
      gestor: {
        idUsuario: props.idUsuario,
      },
      usuario: {
        idUsuario: justificativa.idUsuario,
        perfil: {
          idPerfil: justificativa.idPerfil || null,
        },
      },
      avaliacao: {
        idAvaliacao: justificativa.idAvaliacao,
      },
      dtDesligRetorno: justificativa.dtDesligRetorno,
    };

    props.persistJustificativa(isEdicao, justificativaObj);
  };

  const handleClose = () => {
    setIsEdicao(false);
    setJustificativa(defaultJustificativa);

    props.closeModal();
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao
              ? "Editar Justificativa"
              : "Cadastro de nova Justificativa"}
          </Typography>
          <Button
            type="button"
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Estado
                </InputLabel>
                <Select
                  required
                  value={justificativa.idEstado}
                  label="Estado"
                  defaultValue={""}
                  error={idEstadoError}
                  onChange={(e) => {
                    setIdEstadoError(e.target.value === "");
                    setJustificativa({
                      ...justificativa,
                      idEstado: parseInt(e.target.value.toString()),
                      idMunicipio: "",
                    });
                    props.changeEstado(e.target.value);
                  }}
                >
                  <MenuItem value="">Estado...</MenuItem>
                  {props.estados
                    ? props.estados.map((item: any, idx: any) => (
                        <MenuItem key={"estado-" + idx} value={item.idEstado}>
                          {item.dsEstado + " - " + item.nmEstado}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idEstadoError ? (
                  <FormHelperText error={idEstadoError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Município
                </InputLabel>
                <Select
                  required
                  value={justificativa.idMunicipio}
                  label="Município"
                  defaultValue={""}
                  error={idMunicipioError}
                  onChange={(e) => {
                    setIdMunicipioError(e.target.value === "");
                    setJustificativa({
                      ...justificativa,
                      idMunicipio: parseInt(e.target.value.toString()),
                    });
                    props.changeMunicipio(e.target.value);
                  }}
                >
                  <MenuItem value="">Município...</MenuItem>
                  {props.municipios
                    ? props.municipios.map((item: any, idx: any) => (
                        <MenuItem
                          key={"munici-" + idx}
                          value={item.idMunicipio}
                        >
                          {item.nmMunicipio}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idMunicipioError ? (
                  <FormHelperText error={idMunicipioError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Nome usuário
                </InputLabel>
                <Select
                  required
                  value={justificativa.idUsuario}
                  label="Nome usuário"
                  defaultValue={""}
                  error={idUsuarioError}
                  onChange={(e) => {
                    setIdUsuarioError(e.target.value === "");
                    setJustificativa({
                      ...justificativa,
                      idUsuario: parseInt(e.target.value.toString()),
                    });
                  }}
                >
                  <MenuItem value="">Usuário...</MenuItem>
                  {props.usuarios
                    ? props.usuarios.map((item: any, idx: any) => (
                        <MenuItem key={"usua-" + idx} value={item.idUsuario}>
                          {item.pessoaFisica.nmPessoaFisica}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idMunicipioError ? (
                  <FormHelperText error={idMunicipioError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <ListaAvaliacaoComponent
                multiple={false}
                avaliacoes={
                  justificativa.idAvaliacao === ""
                    ? []
                    : [{ idAvaliacao: justificativa.idAvaliacao }]
                }
                onChangeAvaliacoes={(value: any) =>
                  setJustificativa({
                    ...justificativa,
                    idAvaliacao: value[0]?.idAvaliacao ?? "",
                  })
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Tipo
                </InputLabel>
                <Select
                  required
                  value={
                    justificativa.idTipoJustificativa != ""
                      ? parseInt(justificativa.idTipoJustificativa)
                      : 0
                  }
                  label="Tipo"
                  defaultValue={0}
                  error={tpJustificativaError}
                  onChange={(e) => {
                    setTpJustificativaError(e.target.value === "");
                    setJustificativa({
                      ...justificativa,
                      idTipoJustificativa: parseInt(e.target.value.toString()),
                      dtDesligRetorno: null,
                      idPerfil: "",
                    });
                  }}
                >
                  <MenuItem value={0}>Tipo...</MenuItem>
                  {props.listJustificativaTipo
                    ? props.listJustificativaTipo.map((item: any, idx: any) => (
                        <MenuItem
                          key={"justTipo-" + idx}
                          value={item.idTipoJustificativa}
                        >
                          {item.dsTipoJustificativa}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {idMunicipioError ? (
                  <FormHelperText error={idMunicipioError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              {tipo === "Desligado" || tipo === "Licença" ? (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DesktopDatePicker
                    className="w-full"
                    onError={(e) => {
                      if (e) {
                        setDtDesligRetornoError(true);
                      } else {
                        setDtDesligRetornoError(false);
                      }
                    }}
                    label={
                      tipo === "Desligado"
                        ? "Data de Desligamento"
                        : "Data de Retorno"
                    }
                    value={justificativa.dtDesligRetorno}
                    minDate={dayjs("1900-01-01")}
                    onChange={(newValue) => {
                      setDtDesligRetornoError(false);
                      setJustificativa({
                        ...justificativa,
                        dtDesligRetorno: newValue?.format(),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={dtDesligRetornoError}
                        helperText={
                          dtDesligRetornoError ? "Campo Obrigatório." : ""
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              ) : (
                tipo === "Promovido" && (
                  <ListaPerfilComponent
                    multiple={false}
                    justificativaCombo={true}
                    perfis={
                      justificativa.idPerfil === ""
                        ? []
                        : [{ idPerfil: justificativa.idPerfil }]
                    }
                    onChangePerfis={(state: any) => {
                      const newId = state[0]?.idPerfil ?? "";
                      setJustificativa({
                        ...justificativa,
                        idPerfil: newId,
                      });
                      setIdNvPerfilError(!newId);
                    }}
                  />
                )
              )}
            </Grid>
          </Grid>
          <div className="cadastroFooter">
            <Divider />
            <Button disabled={isDisabled} variant="contained" type="submit">
              {isEdicao ? "Gravar" : "Cadastrar"}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroJustificativa;
