import React, { useEffect, useState } from "react";
import { GridColDef } from '@mui/x-data-grid';
import GenericTable from "../GenericTable/GenericTable";
import { DestaqueObjTable, DestaqueTable } from "../../store/ducks/destaque/types";
import dayjs from "dayjs";
import Link from '@mui/material/Link';

const con: DestaqueObjTable[] = [];
const col: GridColDef[] = [];

const DestaqueTableComponent = (props: any) => {

    const [txtBusca, setTxtBusca] = useState('');
    const [columns, setColumns] = useState(col);
    const [listDestaque, setListDestaque] = useState(con);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numberElements, setNumberElements] = useState(0);
    const [offset, setOffset] = useState(0);
    const [contTipo, setContTipo] = useState([0]);

    useEffect(() => {
        generateColumnsTable();
    }, []);

    useEffect(() => {
        if(props.data){
            tranformObjectTable(props.data)
        }
    }, [props.data]);

    const generateColumnsTable = () => {
        const _columns: GridColDef[] = [
            { field: 'dsDestaque', headerName: 'Descrição', width: 250 },
            { field: 'nmConteudo', headerName: 'Conteúdo', width: 400 },
            { field: 'urlCapaDestaque', headerName: 'Imagem', width: 380, renderCell: ({row}) => {
                return <Link className="buttonArquivo" href={row.urlCapaDestaque} underline="none" target="_blank" >{row.urlCapaDestaque}</Link>
            }},
            { field: 'dtDestaqueInicio', headerName: 'Data Início', width: 95 },
            { field: 'dtDestaqueFim', headerName: 'Data Fim', width: 95 },
        ];

        setColumns(_columns);
    };

    const tranformObjectTable = (obj: DestaqueTable) => {
        setSize(obj.size);
        setPage(obj.number);
        setTotalElements(obj.totalElements);
        setTotalPages(obj.totalPages);
        setNumberElements(obj.numberOfElements);
        setOffset(obj.pageable.offset)

        let list: DestaqueObjTable[] = obj.content.map((item: any) => {
            return {
                id: item[0],
                idDestaque: item[0],
                dsDestaque: item[1],
                nmConteudo: item[2],
                urlCapaDestaque: item[3],
                dtDestaqueInicio: dayjs(item[4]).locale('pt-br').format('L'),
                dtDestaqueFim: dayjs(item[5]).locale('pt-br').format('L'),
            }
        });

        setListDestaque(list);
    };
    
    const onPageChange = (pageIdx: any) => {
        props.body.pagina = pageIdx - 1;
        setPage(pageIdx - 1)
        props.onChange(props.body);
    }

    const onSizeChange = (_size: any) => {
        props.body.tamanho = _size;
        setSize(_size);
        props.onChange(props.body);
    }

    const onChangeBusca = (txt: string) => {
        props.body.txtBusca = txt;
        setTxtBusca(txt);
        if(txt.length >= 3){
            props.onChange(props.body);
        } else {
            props.body.txtBusca = '';
            props.onChange(props.body);
        }
    }

    const onSortChange = (sort: any) => {
        if(sort != undefined) {
            let colunaSort = sort.field && 
            sort.field == "dsDestaque" ? 'dsDestaque' 
            : sort.field == "nmConteudo" ? 'c.nmConteudo'
            : sort.field == "dsDestaque" ? 'dsDestaque'
            : sort.field == "urlCapaDestaque" ? 'urlCapaDestaque'
            : sort.field == "dtDestaqueInicio" ? 'dtDestaqueInicio'
            : sort.field == "dtDestaqueFim" ? 'dtDestaqueFim' : 'dsDestaque' ;

            props.body.coluna = colunaSort;
            props.body.ordem = sort.sort.toUpperCase();
        } else {
            props.body.coluna = 'dsDestaque';
            props.body.ordem = 'ASC';
        }
        props.onChange(props.body);

    }


    return (
        <GenericTable 
            loading={props.loading}
            titleTable='Destaques Cadastrados'
            rows={listDestaque} 
            columns={columns} 
            page={page} 
            totalPages={totalPages} 
            onPageChange={onPageChange}
            offset={offset}
            numberElements={numberElements} 
            totalElements={totalElements}
            size={size}
            sizeChange={onSizeChange}
            txtBusca={txtBusca}
            onChangePesquisa={onChangeBusca}
            selection={props.selection}
            idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
            sortChange={onSortChange}
        />
    )
};

export default DestaqueTableComponent;