function mascaraCpf(cpf: string) {
    return cpf.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

function verificarCPF(cpf: string) {  
    // Remove os pontos/traço da expressão regular, caso exista
    cpf = cpf.replace(/[^\d]+/g,'');    
    if(cpf == '') return false;     

    // Elimina CPFs invalidos conhecidos    
    if (cpf.length != 11 ||         
    cpf == "00000000000" ||         
    cpf == "11111111111" ||         
    cpf == "22222222222" ||         
    cpf == "33333333333" ||         
    cpf == "44444444444" ||         
    cpf == "55555555555" ||         
    cpf == "66666666666" ||         
    cpf == "77777777777" ||         
    cpf == "88888888888" ||         
    cpf == "99999999999")       
    return false;         

    // Valida 1o digito 
    let add = 0;        

    for (let i=0; i < 9; i ++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);  
    }
    let rev = 11 - (add % 11);  
    if (rev == 10 || rev == 11) {
        rev = 0;
    }  

    if (rev != parseInt(cpf.charAt(9))) {
        return false;
    }   

    // Valida 2o digito 
    add = 0;    
    for (let i = 0; i < 10; i ++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
    }    
    rev = 11 - (add % 11);     
    if (rev == 10 || rev == 11) {
        rev = 0;
    }    
    if (rev != parseInt(cpf.charAt(10))) {
        return false;
    }  
    return true;   
}

function validaEmail(email: string) { 
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
} 

function validaNomeArquivo(nomeArquivo: string) {
    let validaNomeArquivo = /[^0-9a-zA-Z\.\_]/;
    if(nomeArquivo !== undefined) {
        if(validaNomeArquivo.test(nomeArquivo)){
            alert("Nome de Arquivo Inválido! O Nome do Arquivo não pode conter acentos, espaços ou caracteres especiais!");
            return false;
        } 
        return true;
    }
    return true;
}

export {
    mascaraCpf,
    verificarCPF,
    validaEmail,
    validaNomeArquivo
}