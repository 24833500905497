import { Reducer } from "@reduxjs/toolkit";
import { ChatBotNivelState, ChatBotNivelTypes } from "./types";

const INITIAL_STATE: ChatBotNivelState = {
    data: {
        table: null,
        nivel: null,
        lista: [],
        qtdChave: 0
    },
    error: false,
    errorChave: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<ChatBotNivelState> = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case ChatBotNivelTypes.CHATBOT_NIVEL_TABLE_REQ:
            return {
                ...state,
                error: false,
                loadingTable: true
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_TABLE_REQ_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_TABLE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err.data,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_REQ:
            return {
                ...state,
                error: false,
                loading: true
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_REQ_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    nivel: action.payload.data
                }
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err.data,
                loading: false,
                data: {
                    ...state.data,
                    nivel: null
                }
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_SAVE_REQ:
        case ChatBotNivelTypes.CHATBOT_NIVEL_UPDATE_REQ:
            return {
                ...state,
                error: false,
                loadingPersis: true
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_SAVE_REQ_SUCCESS:
            alert('Nível cadastrado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    nivel: action.payload.data
                }
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_SAVE_REQ_FAILURE:
        case ChatBotNivelTypes.CHATBOT_NIVEL_UPDATE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err.data,
                loadingPersis: false,
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_UPDATE_REQ_SUCCESS:
            alert('Nível alterado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    nivel: action.payload.data
                }
            };


        case ChatBotNivelTypes.CHATBOT_NIVEL_DELETE_REQ:
                return {
                    ...state,
                    error: false,
                    loadingInativacao: true
                };
    
        case ChatBotNivelTypes.CHATBOT_NIVEL_DELETE_REQ_SUCCESS:
            alert('Nível excluído com sucesso!');
            return {
                ...state,
                error: false,
                loadingInativacao: false,
            };

        case ChatBotNivelTypes.CHATBOT_NIVEL_DELETE_REQ_FAILURE:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.err.data,
                loadingInativacao: false,
            };

        case ChatBotNivelTypes.CLEAR_CHATBOT_NIVEL:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null,
                data:{
                    ...state.data,
                    nivel: null
                }
            }

        case ChatBotNivelTypes.CHATBOT_NIVEL_KEY_REQ:
        case ChatBotNivelTypes.CHATBOT_NIVEL_KEY_REQ_SUCCESS:
            return {
                ...state,
                errorChave: false
            }

        case ChatBotNivelTypes.CHATBOT_NIVEL_KEY_REQ_FAILURE:
            alert(`Atenção\n${action.payload.err.data}`);
            return {
                ...state,
                errorChave: true
            }

        case ChatBotNivelTypes.CHATBOT_NIVEL_LIST_REQ:
        case ChatBotNivelTypes.CHATBOT_NIVEL_QTD_CHAVES_REQ:
            return {
                ...state,
            }
    
        case ChatBotNivelTypes.CHATBOT_NIVEL_LIST_REQ_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    lista: action.payload.data
                }
            }

        case ChatBotNivelTypes.CHATBOT_NIVEL_LIST_REQ_FAILURE:
            return {
                ...state,
                data: {
                    ...state.data,
                    lista: []
                }
            }
    
        case ChatBotNivelTypes.CHATBOT_NIVEL_QTD_CHAVES_REQ_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    qtdChave: action.payload.data
                }
            }

        case ChatBotNivelTypes.CHATBOT_NIVEL_QTD_CHAVES_REQ_FAILURE:
            return {
                ...state,
                data: {
                    ...state.data,
                    qtdChave: 0
                }
            }

        default :
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
}

export default reducer;