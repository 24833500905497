import { action } from 'typesafe-actions';
import { UsuarioTypes } from './types';

export const getUsuarioTable = (data: any) => action(UsuarioTypes.USUARIO_TABLE_REQUEST, data);
export const getUsuarioTableSuccess = (data: any) => action(UsuarioTypes.USUARIO_TABLE_REQUEST_SUCCESS, { data });
export const getUsuarioTableFailure = (err: any) => action(UsuarioTypes.USUARIO_TABLE_REQUEST_FAILURE, { err });

export const onInativarUsuario = (data: any) => action(UsuarioTypes.USUARIO_INATIVAR, data);
export const onInativarUsuarioSuccess = (data: any) => action(UsuarioTypes.USUARIO_INATIVAR_SUCCESS, { data });
export const onInativarUsuarioFailure = (err: any) => action(UsuarioTypes.USUARIO_INATIVAR_FAILURE, { err });

export const onAtivarUsuario = (data: any) => action(UsuarioTypes.USUARIO_ATIVAR, data);
export const onAtivarUsuarioSuccess = (data: any) => action(UsuarioTypes.USUARIO_ATIVAR_SUCCESS, { data });
export const onAtivarUsuarioFailure = (err: any) => action(UsuarioTypes.USUARIO_ATIVAR_FAILURE, { err });

export const onImportarUsuario = (data: any) => action(UsuarioTypes.USUARIO_IMPORTAR, data);
export const onImportarUsuarioSuccess = (data: any) => action(UsuarioTypes.USUARIO_IMPORTAR_SUCCESS, { data });
export const onImportarUsuarioFailure = (err: any) => action(UsuarioTypes.USUARIO_IMPORTAR_FAILURE, { err });

export const onImportarUsuarioT5 = (data: any) => action(UsuarioTypes.USUARIO_IMPORTAR_T5, data);
export const onImportarUsuarioT5Success = (data: any) => action(UsuarioTypes.USUARIO_IMPORTAR_T5_SUCCESS, { data });
export const onImportarUsuarioT5Failure = (err: any) => action(UsuarioTypes.USUARIO_IMPORTAR_T5_FAILURE, { err });

export const onInativarMassa = (data: any) => action(UsuarioTypes.USUARIO_INATIVAR_MASSA, data);
export const onInativarMassaSuccess = (data: any) => action(UsuarioTypes.USUARIO_INATIVAR_MASSA_SUCCESS, { data });
export const onInativarMassaFailure = (err: any) => action(UsuarioTypes.USUARIO_INATIVAR_MASSA_FAILURE, { err });

export const onResetSenha = (data: any) => action(UsuarioTypes.USUARIO_RESETAR_SENHA, data);
export const onResetSenhaSuccess = (data: any) => action(UsuarioTypes.USUARIO_RESETAR_SENHA_SUCCESS, { data });
export const onResetSenhaFailure = (err: any) => action(UsuarioTypes.USUARIO_RESETAR_SENHA_FAILURE, { err });

export const onResetSenhaMassa = (data: any) => action(UsuarioTypes.USUARIO_RESETAR_SENHA_MASSA, data);
export const onResetSenhaMassaSuccess = (data: any) => action(UsuarioTypes.USUARIO_RESETAR_SENHA_MASSA_SUCCESS, { data });
export const onResetSenhaMassaFailure = (err: any) => action(UsuarioTypes.USUARIO_RESETAR_SENHA_MASSA_FAILURE, { err });

export const clearUsuarioData = () => action(UsuarioTypes.CLEAR_USUARIO_DATA);

export const getUsuarioData = (data: any) => action(UsuarioTypes.USUARIO_DATA, data);
export const getUsuarioDataSuccess = (data: any) => action(UsuarioTypes.USUARIO_DATA_SUCCESS, { data });
export const getUsuarioDataFailure = (err: any) => action(UsuarioTypes.USUARIO_DATA_FAILURE, { err });

export const onSaveUsuario = (data: any) => action(UsuarioTypes.USUARIO_SAVE, data);
export const onSaveUsuarioSuccess = (data: any) => action(UsuarioTypes.USUARIO_SAVE_SUCCESS, { data });
export const onSaveUsuarioFailure = (err: any) => action(UsuarioTypes.USUARIO_SAVE_FAILURE, { err });

export const onUpdateUsuario = (data: any) => action(UsuarioTypes.USUARIO_UPDATE, data);
export const onUpdateUsuarioSuccess = (data: any) => action(UsuarioTypes.USUARIO_UPDATE_SUCCESS, { data });
export const onUpdateUsuarioFailure = (err: any) => action(UsuarioTypes.USUARIO_UPDATE_FAILURE, { err });