import React from "react";
import { Button, Icon, IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MRT_ExpandedState, MRT_SortingState, MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useEffect, useMemo, useState } from "react";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const CustomToolbar = ({ txtBusca, onChangePesquisa, titleTable, onCriarCategoria }: any) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(txtBusca);
    }, [txtBusca]);

    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '10px' }}>
            <Typography variant="h6" noWrap component="div">
                {titleTable}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button style={{ marginRight: '1em' }} color="primary" onClick={() => { onCriarCategoria()}} variant="contained">
                    Criar Nova Categoria
                </Button>
                <TextField id="outlined-basic" value={value} label="Pesquisar" variant="outlined" size="small" onChange={e => {
                    setValue(e.target.value);
                    onChangePesquisa(e.target.value)
                }} />
            </div>
        </div>
    );
}

const CustomSelectItensPerPage = ({ size, onChageItensPerPage }: any) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ marginRight: '1em' }}>
                Linhas por página
            </div>
            <Select size="small" value={size || 25} onChange={e => onChageItensPerPage(e.target.value)} >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>
        </div>
    )
}

const CustomPagination = ({ page, pageCount, pageChange }: any) => {
    return (
        <Pagination
            color="standard"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => pageChange(value)}
        />
    );
}

const CustomFooterCount = ({ offset, numberElements, totalElements, page, pageCount, pageChange, size, onChageItensPerPage }: any) => {
    return (
        <Box sx={{ padding: "10px", display: "flex", width: '100%', justifyContent: 'space-between' }}>
            Mostrando de {offset + 1} até {offset + numberElements} de {totalElements} registros
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '600px' }}>
                <CustomSelectItensPerPage size={size} onChageItensPerPage={onChageItensPerPage} />
                <CustomPagination page={page} pageCount={pageCount} pageChange={pageChange} />
            </div>
        </Box >
    );
}

const RowsActions = ({ row, table, onExcluir, onEditar, onAddNivel }: any) => {
    return (
        <Box sx={{ display: 'flex', gap: '1rem', maxWidth: 200 }}>
            <Tooltip arrow placement="left" title="Editar">
                <IconButton onClick={() => { 
                    if(row.depth > 0) {
                        if(row.depth > 1) {
                            onEditar(EntidadesAcoes.SUBNIVEL, row.original.idNivel);
                        } else {
                            onEditar(EntidadesAcoes.NIVEL, row.original.idNivel);
                        }
                    } else {
                        onEditar(EntidadesAcoes.CATEGORIA, row.original.idCategoria);
                    }
                }}>
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title={row.depth > 0 ? row.depth > 1 ? "Excluir SubNível" : "Excluir Nível" : "Excluir Categoria"}>
                <IconButton color="error" onClick={() => {
                    if(row.depth > 0) {
                        if(row.depth > 1) {
                            onExcluir(EntidadesAcoes.SUBNIVEL, row.original.idNivel, row.original.titulo);
                        } else {
                            onExcluir(EntidadesAcoes.NIVEL, row.original.idNivel, row.original.titulo);
                        }
                    } else {
                        onExcluir(EntidadesAcoes.CATEGORIA, row.original.idCategoria, row.original.titulo);
                    }
                }}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title={row.depth > 0 ? "Criar SubNível" : "Criar Nível"}>
                <IconButton onClick={() => {
                    if(row.depth > 0) {
                        onAddNivel(EntidadesAcoes.SUBNIVEL, row.original.idNivel, row.original.titulo);
                    } else {
                        onAddNivel(EntidadesAcoes.NIVEL, row.original.idCategoria, row.original.titulo);
                    }
                 }}>
                    <Icon>add</Icon>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

const GenericTableTree = (props: any) => {

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [expanded, setExpanded] = useState<MRT_ExpandedState>({});
    const [pagination, setPagination] = useState({pageSize: 25, pageIndex: 0})
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(sorting && sorting.length > 0){
            props.sortChange(sorting[0])
        }
    }, [sorting]);

    useEffect(() => {
        setIsLoading(props.loading);
    }, [props.loading]);

    const pageChange = (page: any) => {
        setPagination({...pagination, pageIndex: page});
        props.onPageChange(page);
    }

    const sizeChange = (size: any) => {
        setPagination({...pagination, pageSize: size});
        props.sizeChange(size)
    }

    const pesquisaChange = (text: string) => {
        if(text.length > 3) {
            setExpanded(true);
        } else {
            setExpanded({});
        }
        props.onChangePesquisa(text)
    }

    return (
        <Box sx={{ width: '100%', backgroundColor: '#fff' }} >
            <CustomToolbar 
                {...props} 
                onChangePesquisa={(value: any) => pesquisaChange(value)}
                onCriarCategoria={props.onCriarCategoria} />
            <MaterialReactTable
                muiTablePaperProps={{
                    elevation: 0,
                }}
                muiTableHeadRowProps={{
                    style: {
                        boxShadow: "none",
                        maxHeight: "auto"
                    }
                }}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                columns={props.columns}
                data={props.rows}
                enableExpanding
                enableExpandAll
                enableRowActions
                renderRowActions={({ row, table }) => {
                    return (<RowsActions 
                        row={row} 
                        table={table} 
                        onExcluir={props.onExcluir} 
                        onEditar={props.onEditar} 
                        onAddNivel={props.onAddNivel} />)
                }}
                enableFullScreenToggle={false}
                enableColumnActions={false}
                localization={MRT_Localization_PT_BR}
                enableMultiSort={false}
                state={{sorting, expanded, pagination, isLoading}}
                onSortingChange={setSorting}
                onExpandedChange={setExpanded}
            />
            <CustomFooterCount 
                {...props} 
                onChageItensPerPage={(size: any) => sizeChange(size)}
                pageChange={(page: any) => pageChange(page)}
                />
        </Box>
    )
};

export default GenericTableTree;