import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import {
    getChatBotNivelDeleteFailure,
    getChatBotNivelDeleteSuccess,
    getChatBotNivelFailure,
    getChatBotNivelKeyEmUsoFailure,
    getChatBotNivelKeyEmUsoSuccess,
    getChatBotNivelListFailure,
    getChatBotNivelListSuccess,
    getChatBotNivelQtdChavesFailure,
    getChatBotNivelQtdChavesSuccess,
    getChatBotNivelSaveFailure,
    getChatBotNivelSaveSuccess,
    getChatBotNivelSuccess,
    getChatBotNivelTableFailure,
    getChatBotNivelTableSuccess,
    getChatBotNivelUpdateFailure,
    getChatBotNivelUpdateSuccess
} from "./actions";

function* getChatBotNivelTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('chatbot/nivel/lista-paginada', null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: body
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotNivelTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelTableFailure(error.response));
    }
}

function* getChatBotNivelSave(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('chatbot/nivel', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotNivelSaveSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelSaveFailure(error.response));
    }
}

function* getChatBotNivelUpdate(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('chatbot/nivel', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotNivelUpdateSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelUpdateFailure(error.response));
    }
}

function* getChatBotNivelDelete(data: any): any {
    const idNivel = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.delete('chatbot/nivel/delete-massa', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: idNivel
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotNivelDeleteSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelDeleteFailure(error.response));
    }
}

function* getChatBotNivel(data: any): any {
    const idNivel = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/nivel',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                idNivel
            }
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotNivelSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelFailure(error.response));
    }
}

function* getChatBotNivelKeyEmUso(data: any): any {
    const params = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/nivel/chave-em-uso',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                ...params
            }
            
        }).then( res => res.data)
    }

    try {
        yield put(getChatBotNivelKeyEmUsoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelKeyEmUsoFailure(error.response));
    }
}

function* getChatBotNivelList(data: any): any {
    const idCategoria = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/nivel/listar-por-categoria',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                idCategoria
            }
            
        }).then( res => res.data)
    }

    try {
        yield put(getChatBotNivelListSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelListFailure(error.response));
    }
}

function* getChatBotNivelQtdChaves(data: any): any {
    const params = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/nivel/contagem-filhos',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                ...params
            }
            
        }).then( res => res.data)
    }

    try {
        yield put(getChatBotNivelQtdChavesSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotNivelQtdChavesFailure(error.response));
    }
}

export {
    getChatBotNivel, getChatBotNivelDelete, getChatBotNivelKeyEmUso, getChatBotNivelSave, getChatBotNivelTable, getChatBotNivelUpdate, getChatBotNivelList, getChatBotNivelQtdChaves
};
