export enum TemaTypes {
    TEMA_TABLE_REQUEST = 'tema/TEMA_TABLE_REQUEST',
    TEMA_TABLE_REQUEST_SUCCESS = 'tema/TEMA_TABLE_RQUEST_SUCCESS',
    TEMA_TABLE_REQUEST_FAILURE = 'tema/TEMA_TABLE_REQUEST_FAILURE',

    TEMA_ATIVA_REQUEST = 'tema/TEMA_ATIVA_REQUEST',
    TEMA_ATIVA_REQUEST_SUCCESS = 'tema/TEMA_ATIVA_REQUEST_SUCCESS',
    TEMA_ATIVA_REQUEST_FAILURE = 'tema/TEMA_ATIVA_REQUEST_FAILURE',

    TEMA_INATIVA_REQUEST = 'tema/TEMA_INATIVA_REQUEST',
    TEMA_INATIVA_REQUEST_SUCCESS = 'tema/TEMA_INATIVA_REQUEST_SUCCESS',
    TEMA_INATIVA_REQUEST_FAILURE = 'tema/TEMA_INATIVA_REQUEST_FAILURE',

    CLEAR_TEMA_DATA = 'tema/CLEAR_TEMA_DATA',
    
    TEMA_DATA_REQUEST = 'tema/TEMA_DATA_REQUEST',
    TEMA_DATA_REQUEST_SUCCESS = 'tema/TEMA_DATA_REQUEST_SUCCESS',
    TEMA_DATA_REQUEST_FAILURE = 'tema/TEMA_DATA_REQUEST_FAILURE',

    TEMA_SAVE_REQUEST = 'tema/TEMA_SAVE_REQUEST',
    TEMA_SAVE_REQUEST_SUCCESS = 'tema/TEMA_SAVE_REQUEST_SUCCESS',
    TEMA_SAVE_REQUEST_FAILURE = 'tema/TEMA_SAVE_REQUEST_FAILURE',

    TEMA_UPDATE_REQUEST = 'tema/TEMA_UPDATE_REQUEST',
    TEMA_UPDATE_REQUEST_SUCCESS = 'tema/TEMA_UPDATE_REQUEST_SUCCESS',
    TEMA_UPDATE_REQUEST_FAILURE = 'tema/TEMA_UPDATE_REQUEST_FAILURE',

    TEMA_EXISTENTE_REQUEST = 'perfil/TEMA_EXISTENTE_REQUEST',
    TEMA_EXISTENTE_REQUEST_SUCCESS = 'perfil/TEMA_EXISTENTE_REQUEST_SUCCESS',
    TEMA_EXISTENTE_REQUEST_FAILURE = 'perfil/TEMA_EXISTENTE_REQUEST_FAILURE',
}

export interface TemaObjTable {
    id?: number,
    idTema?: number,
    dsTema?: string,
    nmTema?: string,
}

export interface TemaTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface TemaPage {
    readonly tema: any | null,
    readonly table: TemaTable | null,
}

export interface TemaState {
    readonly data: TemaPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}