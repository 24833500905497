import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { 
    deleteChatBotSaudacaoFailure,
    deleteChatBotSaudacaoSuccess,
    getChatBotSaudacaoFailure,
    getChatBotSaudacaoSuccess,
    getChatBotSaudacaoTableFailure,
    getChatBotSaudacaoTableSuccess,
    postChatBotSaudacaoSaveFailure,
    postChatBotSaudacaoSaveSuccess,
    putChatBotSaudacaoUpdateFailure,
    putChatBotSaudacaoUpdateSuccess
} from "./actions";

function* getChatBotSaudacaoTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('chatbot/saudacao/lista-paginada', null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: body
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotSaudacaoTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotSaudacaoTableFailure(error.message));
    }
}

function* postChatBotSaudacaoSave(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('chatbot/saudacao', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(postChatBotSaudacaoSaveSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(postChatBotSaudacaoSaveFailure(error.message));
    }
}

function* putChatBotSaudacaoUpdate(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('chatbot/saudacao', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(putChatBotSaudacaoUpdateSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(putChatBotSaudacaoUpdateFailure(error.message));
    }
}

function* deleteChatBotSaudacao(data: any): any {
    const idSaudacao = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.delete('chatbot/saudacao/delete-massa', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: idSaudacao
            
        }).then( res => res.data)
        
    }

    try {
        yield put(deleteChatBotSaudacaoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(deleteChatBotSaudacaoFailure(error.message));
    }
}

function* getChatBotSaudacao(data: any): any {
    const idSaudacao = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/saudacao',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                idSaudacao
            }
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotSaudacaoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotSaudacaoFailure(error.message));
    }
}

export {
    getChatBotSaudacao,
    postChatBotSaudacaoSave,
    putChatBotSaudacaoUpdate,
    deleteChatBotSaudacao,
    getChatBotSaudacaoTable
}