import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import * as TemaAction from '../../store/ducks/temas/actions';

import AcaoMenu from "../../Components/AcaoMenu";
import TemaTableComponent from "../../Components/TableTema";
import { ApplicationState } from "../../store";
import ModalCadastroTema from "../../Components/ModalCadastroTema";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmTema",
    txtBusca: ""
};

function Tema({
    tema,
    getTemaTable,
    getTemaData,
    onInativarTema,
    onSaveTema,
    onUpdateTema,
    clearTemaData,
    getTemaExistente
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(tema.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + tema.errorMessage);
        }
    }, [tema.error]);

    useEffect(() => {
        if(!tema.loadingPersis) {
            handleCloseAndReload()
        }
    }, [tema.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen){
            getTemaTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(tema.data.table != null){
            setTable(tema.data.table);
        }
    }, [tema.data]);

    useEffect(() => {
        if(!tema.loadingInativacao){
            getTemaTable(body);
        }
    }, [tema.loadingInativacao]);

    const getTemaTableData = () => {
        getTemaTable(body);
    }

    const handleCadastro = () => {
        clearTemaData();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione um tema que gostaria de alterar');
        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas um tema para alteração!');
        } else {
            getTemaData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
        
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione os temas que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.TEMAS_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarTema(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersitTema = (edicao: boolean, tema: any) => {
        if(edicao) {
            onUpdateTema(tema);
        } else {
            onSaveTema(tema);
        }
    }

    const handleCloseAndReload = () => {
        clearTemaData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={tema.loading || tema.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroTema 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()} 
                temaData={tema.data.tema}
                persistTema={handlePersitTema}
                buscaTemaExistente={(per: any) => getTemaExistente(per)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <TemaTableComponent 
                    loading={tema.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};


const mapStateToProps = (state: ApplicationState) => ({
    tema: state.tema,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...TemaAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tema);