import React from "react";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Icon from '@mui/material/Icon';

export default function AcaoMenu({onCadastro, onEditar, onInativar}: any) {

    return (
        <List >
            <ListItem className="menu-item" key='cadastrar' disablePadding sx={{ display: 'block' }}>
                <ListItemButton onClick={onCadastro} >
                    <ListItemIcon >
                        <Icon>add</Icon>
                    </ListItemIcon>
                    <ListItemText primary={"Cadastrar"} sx={{ opacity: 1}} />
                </ListItemButton>
            </ListItem>
            <ListItem className="menu-item" key='editar' disablePadding sx={{ display: 'block' }}>
                <ListItemButton onClick={onEditar} >
                    <ListItemIcon >
                        <Icon>edit</Icon>
                    </ListItemIcon>
                    <ListItemText primary={"Editar"} sx={{ opacity: 1}} />
                </ListItemButton>
            </ListItem>
            <ListItem className="menu-item" key='inativar' disablePadding sx={{ display: 'block' }}>
                <ListItemButton onClick={onInativar} >
                    <ListItemIcon >
                        <Icon>delete</Icon>
                    </ListItemIcon>
                    <ListItemText primary={"Excluir"} sx={{ opacity: 1}} />
                </ListItemButton>
            </ListItem>
        </List>
    )
}