import { Dayjs } from "dayjs"

export enum UrlExternaTypes {
    URL_EXTERNA_TABLE_REQUEST = 'urlExterna/URL_EXTERNA_TABLE_REQUEST',
    URL_EXTERNA_TABLE_REQUEST_SUCCESS = 'urlExterna/URL_EXTERNA_TABLE_RQUEST_SUCCESS',
    URL_EXTERNA_TABLE_REQUEST_FAILURE = 'urlExterna/URL_EXTERNA_TABLE_REQUEST_FAILURE',

    URL_EXTERNA_ATIVA_REQUEST = 'urlExterna/URL_EXTERNA_ATIVA_REQUEST',
    URL_EXTERNA_ATIVA_REQUEST_SUCCESS = 'urlExterna/URL_EXTERNA_ATIVA_REQUEST_SUCCESS',
    URL_EXTERNA_ATIVA_REQUEST_FAILURE = 'urlExterna/URL_EXTERNA_ATIVA_REQUEST_FAILURE',

    URL_EXTERNA_INATIVA_REQUEST = 'urlExterna/URL_EXTERNA_INATIVA_REQUEST',
    URL_EXTERNA_INATIVA_REQUEST_SUCCESS = 'urlExterna/URL_EXTERNA_INATIVA_REQUEST_SUCCESS',
    URL_EXTERNA_INATIVA_REQUEST_FAILURE = 'urlExterna/URL_EXTERNA_INATIVA_REQUEST_FAILURE',

    CLEAR_URL_EXTERNA_DATA = 'urlExterna/CLEAR_URL_EXTERNA_DATA',

    URL_EXTERNA_DATA_REQUEST = 'urlExterna/URL_EXTERNA_DATA_REQUEST',
    URL_EXTERNA_DATA_REQUEST_SUCCESS = 'urlExterna/URL_EXTERNA_DATA_REQUEST_SUCCESS',
    URL_EXTERNA_DATA_REQUEST_FAILURE = 'urlExterna/URL_EXTERNA_DATA_REQUEST_FAILURE',

    URL_EXTERNA_SAVE_REQUEST = 'urlExterna/URL_EXTERNA_SAVE_REQUEST',
    URL_EXTERNA_SAVE_REQUEST_SUCCESS = 'urlExterna/URL_EXTERNA_SAVE_REQUEST_SUCCESS',
    URL_EXTERNA_SAVE_REQUEST_FAILURE = 'urlExterna/URL_EXTERNA_SAVE_REQUEST_FAILURE',

    URL_EXTERNA_UPDATE_REQUEST = 'urlExterna/URL_EXTERNA_UPDATE_REQUEST',
    URL_EXTERNA_UPDATE_REQUEST_SUCCESS = 'urlExterna/URL_EXTERNA_UPDATE_REQUEST_SUCCESS',
    URL_EXTERNA_UPDATE_REQUEST_FAILURE = 'urlExterna/URL_EXTERNA_UPDATE_REQUEST_FAILURE',

    URL_EXTERNA_PUSH_REQUEST = 'urlExterna/URL_EXTERNA_PUSH_REQUEST',
    URL_EXTERNA_PUSH_REQUEST_SUCCESS = 'urlExterna/URL_EXTERNA_PUSH_REQUEST_SUCCESS',
    URL_EXTERNA_PUSH_REQUEST_FAILURE = 'urlExterna/URL_EXTERNA_PUSH_REQUEST_FAILURE',
}

export interface UrlExternaObjTable {
    id?: number,
    idUrlExterna?: number,
    nmUrlExterna?: string,
    dsUrlExterna?: string,
    linkUrlExterna?: string
}


export interface UrlExternaObj {
    idUrlExterna?: number | null,
    nmUrlExterna?: string | null,
	dsUrlExterna?: string | null,
	dataInicio?: null | Dayjs | string,
	dataFim?: null | Dayjs | string,
	linkUrlExterna?: string | null,
	idDatabaseStatus?: number | null,
	publicarMobile?: number | null
    urlMunicipios?: any | null,
    urlPerfis?: any | null
}


export interface UrlExternaTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface UrlExternaPage {
    readonly urlExterna: UrlExternaObj | null,
    readonly table: UrlExternaTable | null,
}

export interface UrlExternaState {
    readonly data: UrlExternaPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}