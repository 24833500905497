import React from "react";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Icon from '@mui/material/Icon';


export default function UsuarioMenu({ onCadastro, onEditar, onInativar, onAtivar, onImportar, onImportarT5, onInativarMassa, onResetSenha, onResetSenhaMassa }: any) {
  return (
    <List>
      <ListItem className="menu-item" key='cadastrar' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onCadastro} >
          <ListItemIcon >
            <Icon>person_add</Icon>
          </ListItemIcon>
          <ListItemText primary={"Cadastrar Usuário"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='editar' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onEditar} >
          <ListItemIcon >
            <Icon>manage_accounts</Icon>
          </ListItemIcon>
          <ListItemText primary={"Alterar Usuário"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='inativar' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onInativar} >
          <ListItemIcon >
            <Icon>person_off</Icon>
          </ListItemIcon>
          <ListItemText primary={"Inativar Usuário"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='ativar' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onAtivar}>
          <ListItemIcon >
            <Icon>person</Icon>
          </ListItemIcon>
          <ListItemText primary={"Ativar Usuário"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='importar' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onImportar} >
          <ListItemIcon >
            <Icon>group_add</Icon>
          </ListItemIcon>
          <ListItemText primary={"Importar Usuários"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='importar-t5' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onImportarT5} >
          <ListItemIcon>
            <Icon>group_add</Icon>
          </ListItemIcon>
          <ListItemText primary={"Importar Usuários para Avaliação T5"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='inativar_massa' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onInativarMassa} >
          <ListItemIcon >
            <Icon>group_remove</Icon>
          </ListItemIcon>
          <ListItemText primary={"Inativar em Massa"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='reset-senha' disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onResetSenha} >
          <ListItemIcon >
            <Icon>lock_reset</Icon>
          </ListItemIcon>
          <ListItemText primary={"Resetar Senha"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
      <ListItem className="menu-item" key='reset-senha-massa' disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={onResetSenhaMassa} >
            <ListItemIcon >
              <Icon>lock_reset</Icon>
            </ListItemIcon>
            <ListItemText primary={"Resetar Senha em Massa"} sx={{ opacity: 1 }} />
          </ListItemButton>
        </ListItem>
    </List>
  )
}