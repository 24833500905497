import { Dayjs } from "dayjs"

export enum TrilhaTypes {
    TRILHA_TABLE_REQUEST = 'trilha/TRILHA_TABLE_REQUEST',
    TRILHA_TABLE_REQUEST_SUCCESS = 'trilha/TRILHA_TABLE_RQUEST_SUCCESS',
    TRILHA_TABLE_REQUEST_FAILURE = 'trilha/TRILHA_TABLE_REQUEST_FAILURE',

    TRILHA_ATIVA_REQUEST = 'trilha/TRILHA_ATIVA_REQUEST',
    TRILHA_ATIVA_REQUEST_SUCCESS = 'trilha/TRILHA_ATIVA_REQUEST_SUCCESS',
    TRILHA_ATIVA_REQUEST_FAILURE = 'trilha/TRILHA_ATIVA_REQUEST_FAILURE',

    TRILHA_INATIVA_REQUEST = 'trilha/TRILHA_INATIVA_REQUEST',
    TRILHA_INATIVA_REQUEST_SUCCESS = 'trilha/TRILHA_INATIVA_REQUEST_SUCCESS',
    TRILHA_INATIVA_REQUEST_FAILURE = 'trilha/TRILHA_INATIVA_REQUEST_FAILURE',
    
    CLEAR_TRILHA_DATA = 'trilha/CLEAR_TRILHA_DATA',

    TRILHA_DATA_REQUEST = 'trilha/TRILHA_DATA_REQUEST',
    TRILHA_DATA_REQUEST_SUCCESS = 'trilha/TRILHA_DATA_REQUEST_SUCCESS',
    TRILHA_DATA_REQUEST_FAILURE = 'trilha/TRILHA_DATA_REQUEST_FAILURE',

    TRILHA_SAVE_REQUEST = 'trilha/TRILHA_SAVE_REQUEST',
    TRILHA_SAVE_REQUEST_SUCCESS = 'trilha/TRILHA_SAVE_REQUEST_SUCCESS',
    TRILHA_SAVE_REQUEST_FAILURE = 'trilha/TRILHA_SAVE_REQUEST_FAILURE',

    TRILHA_UPDATE_REQUEST = 'trilha/TRILHA_UPDATE_REQUEST',
    TRILHA_UPDATE_REQUEST_SUCCESS = 'trilha/TRILHA_UPDATE_REQUEST_SUCCESS',
    TRILHA_UPDATE_REQUEST_FAILURE = 'trilha/TRILHA_UPDATE_REQUEST_FAILURE',

    TRILHA_PUSH_REQUEST = 'trilha/TRILHA_PUSH_REQUEST',
    TRILHA_PUSH_REQUEST_SUCCESS = 'trilha/TRILHA_PUSH_REQUEST_SUCCESS',
    TRILHA_PUSH_REQUEST_FAILURE = 'trilha/TRILHA_PUSH_REQUEST_FAILURE',
}

export interface TrilhaObjTable {
    id?: number,
    idTrilha?: number,
    nmTrilha?: string,
    dsTrilha?: string,
    dsTempoEstimado?: string
    nmTema?: string,
    qtdCursos?: number
}


export interface TrilhaObj {
    idTrilha?: number,
    nmTrilha?: string,
    dsTrilha?: string,
    dtTrilhaInicio?: null | Dayjs | string,
	dtTrilhaFim?: null | Dayjs | string,
    dsTempoEstimado?: string,
    tema?: any | null,
    imgTrilha?: string | null,
    urlCompartilhamentoTrilha?: string,
	idDatabaseStatus?: number | null,
    trilhaCursos?: any | null,
    trilhaAvaliacoes?: any | null,
}


export interface TrilhaTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface TrilhaPage {
    readonly trilha: TrilhaObj | null,
    readonly table: TrilhaTable | null,
}

export interface TrilhaState {
    readonly data: TrilhaPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}