import { Dayjs } from "dayjs"

export enum StoryTypes {
    STORY_TABLE_REQUEST = 'story/STORY_TABLE_REQUEST',
    STORY_TABLE_REQUEST_SUCCESS = 'story/STORY_TABLE_RQUEST_SUCCESS',
    STORY_TABLE_REQUEST_FAILURE = 'story/STORY_TABLE_REQUEST_FAILURE',

    STORY_ATIVA_REQUEST = 'story/STORY_ATIVA_REQUEST',
    STORY_ATIVA_REQUEST_SUCCESS = 'story/STORY_ATIVA_REQUEST_SUCCESS',
    STORY_ATIVA_REQUEST_FAILURE = 'story/STORY_ATIVA_REQUEST_FAILURE',

    STORY_INATIVA_REQUEST = 'story/STORY_INATIVA_REQUEST',
    STORY_INATIVA_REQUEST_SUCCESS = 'story/STORY_INATIVA_REQUEST_SUCCESS',
    STORY_INATIVA_REQUEST_FAILURE = 'story/STORY_INATIVA_REQUEST_FAILURE',
    
    CLEAR_STORY_DATA = 'story/CLEAR_STORY_DATA',

    STORY_DATA_REQUEST = 'story/STORY_DATA_REQUEST',
    STORY_DATA_REQUEST_SUCCESS = 'story/STORY_DATA_REQUEST_SUCCESS',
    STORY_DATA_REQUEST_FAILURE = 'story/STORY_DATA_REQUEST_FAILURE',

    STORY_SAVE_REQUEST = 'story/STORY_SAVE_REQUEST',
    STORY_SAVE_REQUEST_SUCCESS = 'story/STORY_SAVE_REQUEST_SUCCESS',
    STORY_SAVE_REQUEST_FAILURE = 'story/STORY_SAVE_REQUEST_FAILURE',

    STORY_UPDATE_REQUEST = 'story/STORY_UPDATE_REQUEST',
    STORY_UPDATE_REQUEST_SUCCESS = 'story/STORY_UPDATE_REQUEST_SUCCESS',
    STORY_UPDATE_REQUEST_FAILURE = 'story/STORY_UPDATE_REQUEST_FAILURE',

    STORY_PUSH_REQUEST = 'story/STORY_PUSH_REQUEST',
    STORY_PUSH_REQUEST_SUCCESS = 'story/STORY_PUSH_REQUEST_SUCCESS',
    STORY_PUSH_REQUEST_FAILURE = 'story/STORY_PUSH_REQUEST_FAILURE',
}

export interface StoryObjTable {
    id?: number,
    idStory?: number,
    nmStory?: string,
    dsStory?: string,
    qtdConteudos?: number
}


export interface StoryObj {
    idStory?: number,
    nmStory?: string,
    dsStory?: string,
    dtStoryInicio?: null | Dayjs | string,
	dtStoryFim?: null | Dayjs | string,
    imgStory?: string | null,
    urlCompartilhamentoStory?: string,
	idDatabaseStatus?: number | null,
    storyMuncipios?: any | null,
    storyPerfis?: any | null,
    storyConteudos?: any | null,
}


export interface StoryTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface StoryPage {
    readonly story: StoryObj | null,
    readonly table: StoryTable | null,
}

export interface StoryState {
    readonly data: StoryPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}