import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';

import * as UtilsActions from '../../store/ducks/utils/actions';
import { ApplicationState } from "../../store";

function ListaTema({
    utils,
    getTemaList,
    temas,
    onChangeTemas,
    multiple,
    error
}: any) {

    const [temaList, setTemasList] = React.useState<any>([]);
    const [temaSelecionados, setTemasSelecionados] = React.useState<any>([]);
    const [selectAll, setSelectAll] =  React.useState(false);
    const [lazyItems, setLazyItems] = React.useState<any>([]);
    const [lazyLoading, setLazyLoading] = React.useState(false);

    const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    React.useEffect(() => {
        getTemaList()
    }, []);

    React.useEffect(() => {
        if(utils.data.tema){
            setTemasList(utils.data.tema);
            if(utils.data.tema.length > 0){
                setSelectAll(temas.length == utils.data.tema.length);
            }
        }
    }, [utils.data.tema]);

    React.useEffect(() => {
        if(temas){
            setTemasSelecionados(multiple ? temas.map((item: any) => item.idTema) : temas.idTema ? [temas.idTema] : []);
        }
    }, [temas]);

    const onLazyLoad = (event: any) => {
        setLazyLoading(true);

        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }

        loadLazyTimeout.current = setTimeout(() => {
            const { first, last } = event;
            const _lazyItems = [...lazyItems];

            for (let i = first; i < last; i++) {
                _lazyItems[i] = { label: `Item #${i}`, value: i };
            }

            setLazyItems(_lazyItems);
            setLazyLoading(false);
        }, 300);
    }

    return (
        <MultiSelect 
            style={{
                width: '100%'
            }}
            selectionLimit={multiple ? 99999 : 1} showSelectAll={multiple}
            value={temaSelecionados} 
            options={temaList} 
            display="chip"
            onChange={(e) => {
                if(e.value){
                    setTemasSelecionados(e.value); 
                    setSelectAll(e.value.length === temaList.length);
                    onChangeTemas(temaList.filter((muni: any) => e.value.includes(muni.idTema)));
                } else {
                    setTemasSelecionados([])
                    setSelectAll(false);
                    onChangeTemas([])
                }
            }} 
            selectAll={selectAll}
            optionLabel="nmTema"
            optionValue='idTema'
            placeholder={multiple ? "Selecione os Temas" : "Selecione o Tema" }
            filter 
            showClear={true}
            className={error ? "p-multiselect-error" : ''}
            maxSelectedLabels={3} 
            selectedItemsLabel={`${temaSelecionados ? temaSelecionados.length : 0} tema selecionados`}
            emptyFilterMessage='Não encontrado'
            filterPlaceholder='Busque por um tema'
            resetFilterOnHide={true}
            virtualScrollerOptions={{ lazy: true, onLazyLoad: onLazyLoad, itemSize: 43, showLoader: true, loading: lazyLoading, delay: 1, loadingTemplate: (options) => {
                return (
                    <div className="flex align-items-center p-2" style={{ height: '43px' }}>
                        <Skeleton width={options.even ? '70%' : '60%'} height="1.5rem" />
                    </div>
                )}
            }} 
        />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UtilsActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaTema);