import { Dayjs } from "dayjs"

export enum AvaliacaoTypes {
    AVALIACAO_TABLE_REQUEST = 'avaliacao/AVALIACAO_TABLE_REQUEST',
    AVALIACAO_TABLE_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_TABLE_RQUEST_SUCCESS',
    AVALIACAO_TABLE_REQUEST_FAILURE = 'avaliacao/AVALIACAO_TABLE_REQUEST_FAILURE',

    AVALIACAO_ATIVA_REQUEST = 'avaliacao/AVALIACAO_ATIVA_REQUEST',
    AVALIACAO_ATIVA_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_ATIVA_REQUEST_SUCCESS',
    AVALIACAO_ATIVA_REQUEST_FAILURE = 'avaliacao/AVALIACAO_ATIVA_REQUEST_FAILURE',

    AVALIACAO_INATIVA_REQUEST = 'avaliacao/AVALIACAO_INATIVA_REQUEST',
    AVALIACAO_INATIVA_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_INATIVA_REQUEST_SUCCESS',
    AVALIACAO_INATIVA_REQUEST_FAILURE = 'avaliacao/AVALIACAO_INATIVA_REQUEST_FAILURE',
    
    CLEAR_AVALIACAO_DATA = 'avaliacao/CLEAR_AVALIACAO_DATA',
    
    AVALIACAO_DATA_REQUEST = 'avaliacao/AVALIACAO_DATA_REQUEST',
    AVALIACAO_DATA_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_DATA_REQUEST_SUCCESS',
    AVALIACAO_DATA_REQUEST_FAILURE = 'avaliacao/AVALIACAO_DATA_REQUEST_FAILURE',

    AVALIACAO_SAVE_REQUEST = 'avaliacao/AVALIACAO_SAVE_REQUEST',
    AVALIACAO_SAVE_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_SAVE_REQUEST_SUCCESS',
    AVALIACAO_SAVE_REQUEST_FAILURE = 'avaliacao/AVALIACAO_SAVE_REQUEST_FAILURE',

    AVALIACAO_UPDATE_REQUEST = 'avaliacao/AVALIACAO_UPDATE_REQUEST',
    AVALIACAO_UPDATE_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_UPDATE_REQUEST_SUCCESS',
    AVALIACAO_UPDATE_REQUEST_FAILURE = 'avaliacao/AVALIACAO_UPDATE_REQUEST_FAILURE',

    AVALIACAO_TIPO_REQUEST = 'avaliacao/AVALIACAO_TIPO_REQUEST',
    AVALIACAO_TIPO_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_TIPO_REQUEST_SUCCESS',
    AVALIACAO_TIPO_REQUEST_FAILURE = 'avaliacao/AVALIACAO_TIPO_REQUEST_FAILURE',

    AVALIACAO_IMPORTAR = 'avaliacao/AVALIACAO_IMPORTAR',
    AVALIACAO_IMPORTAR_SUCCESS = 'avaliacao/AVALIACAO_IMPORTAR_SUCCESS',
    AVALIACAO_IMPORTAR_FAILURE = 'avaliacao/AVALIACAO_IMPORTAR_FAILURE',

    AVALIACAO_PERGUNTA_SAVE_REQUEST = 'avaliacao/AVALIACAO_PERGUNTA_SAVE_REQUEST',
    AVALIACAO_PERGUNTA_SAVE_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_PERGUNTA_SAVE_REQUEST_SUCCESS',
    AVALIACAO_PERGUNTA_SAVE_REQUEST_FAILURE = 'avaliacao/AVALIACAO_PERGUNTA_SAVE_REQUEST_FAILURE',

    AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST = 'avaliacao/AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST',
    AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST_SUCCESS = 'avaliacao/AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST_SUCCESS',
    AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST_FAILURE = 'avaliacao/AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST_FAILURE',

    PERGUNTA_RESPOSTA_SAVE_REQUEST = 'avaliacao/PERGUNTA_RESPOSTA_SAVE_REQUEST',
    PERGUNTA_RESPOSTA_SAVE_REQUEST_SUCCESS = 'avaliacao/PERGUNTA_RESPOSTA_SAVE_REQUEST_SUCCESS',
    PERGUNTA_RESPOSTA_SAVE_REQUEST_FAILURE = 'avaliacao/PERGUNTA_RESPOSTA_SAVE_REQUEST_FAILURE',

    AVALIACAO_PUSH_REQUEST = 'avaliacao/AVALIACAO_PUSH_REQUEST',
    AVALIACAO_PUSH_REQUEST_SUCCESS = 'conteudo/AVALIACAO_PUSH_REQUEST_SUCCESS',
    AVALIACAO_PUSH_REQUEST_FAILURE = 'conteudo/AVALIACAO_PUSH_REQUEST_FAILURE',
}

export interface AvaliacaoObjTable {
    id?: number,
    idAvaliacao?: number,
    dsAvaliacao?: string,
    nmAvaliacao?: string,
    nmAvaliacaoTipo?: string,
}

export interface AvaliacaoTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface AvaliacaoObj {
    idAvaliacao?: number,
    dsAvaliacao?: string,
    nmAvaliacao?: string,
    dtAvaliacaoInicio?: null | Dayjs | string,
	dtAvaliacaoFim?: null | Dayjs | string,
    icLivre?: number,
    icAleatorio?: number,
    icExibirAvaliacao?: number,
    avaliacaoTipo?: any | null,
    avaliacaoMuncipios?: any | null,
    avaliacaoPerfis?: any | null,
    avaliacaoPerguntas?: AvaliacaoPerguntaObj[]
}

export interface AvaliacaoPerguntaRespostaObj {
    dsAvaliacaoPerguntaResposta?: string,
    icCorreta?: number,
    idAvaliacaoPerguntaResposta?: number,
    idDatabaseStatus?: number,
    nrOrdem?: number,
    avaliacaoPergunta?: AvaliacaoPerguntaObj
}

export interface AvaliacaoPerguntaObj {
    avaliacao?: AvaliacaoObj,
    icPerguntaDissertativa?: number,
    idAvaliacaoPergunta?: number,
    idDatabaseStatus?: number,
    nmAvaliacaoPergunta?: string,
    nrOrdem?: number,
    avaliacaoPerguntaRespostas?: AvaliacaoPerguntaRespostaObj[]
    pathMidia?: string
    feedbackPergunta?: string
}

export interface AvaliacaoPage {
    readonly avaliacao: AvaliacaoObj | null,
    readonly table: AvaliacaoTable | null,
    readonly avaliacaoTipo: any | null,
}

export interface AvaliacaoState {
    readonly data: AvaliacaoPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly importSuccess: boolean,
}