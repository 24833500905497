import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import {
  getGrupoDataFailure,
  getGrupoDataSuccess,
  getGrupoTableFailure,
  getGrupoTableSuccess,
  onInativarGrupoFailure,
  onInativarGrupoSuccess,
  onSaveGrupoFailure,
  onSaveGrupoSuccess,
  onUpdateGrupoFailure,
  onUpdateGrupoSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getGrupoTable(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("grupo/listAllGrupoTable", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getGrupoTableSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getGrupoTableFailure(error.message));
  }
}

function* onInativarGrupo(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("grupo/desativarGrupo", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onInativarGrupoSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onInativarGrupoFailure(error.message));
  }
}

function* getGrupoData(data: any): any {
  const idGrupo = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`grupo/${idGrupo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getGrupoDataSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getGrupoDataFailure(error.message));
  }
}

function* onSaveGrupo(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("grupo/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onSaveGrupoSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onSaveGrupoFailure(error.message));
  }
}

function* onUpdateGrupo(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("grupo/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onUpdateGrupoSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onUpdateGrupoFailure(error.message));
  }
}

export {
  getGrupoData,
  onInativarGrupo,
  getGrupoTable,
  onSaveGrupo,
  onUpdateGrupo,
};
