import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import * as AvaliacaoAction from '../../store/ducks/avaliacao/actions';
import {setLoading} from '../../store/ducks/utils/actions';

import AcaoMenu from "../../Components/AcaoMenu";
import AvaliacaoTableComponent from "../../Components/TableAvaliacao";
import { ApplicationState } from "../../store";
import Loading from "../../Components/LoadingComponent";
import ModalCadastroAvaliacao from "../../Components/ModalCadastroAvaliacao";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmAvaliacao",
    txtBusca: ""
};

function Avaliacao({
    avaliacao,
    getAvaliacaoTable,
    getAvaliacaoData,
    onInativarAvaliacao,
    onSaveAvaliacao,
    onUpdateAvaliacao,
    clearAvaliacaoData,
    getAvaliacaoTipoList,
    onImportarAvalicaoPerguntaEResposta,
    onPersistAvaliacaoPergunta,
    onPersistPerguntaResposta,
    onEnviaNotificacaoAvaliacao,
    setLoading
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(avaliacao.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + avaliacao.errorMessage);
        }
    }, [avaliacao.error]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getAvaliacaoTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(avaliacao.data.table != null){
            setTable(avaliacao.data.table);
        }
    }, [avaliacao.data]);

    useEffect(() => {
        getAvaliacaoTipoList()
    }, [])

    useEffect(() => {
        if(avaliacao.importSuccess && avaliacao.data.avaliacao.idAvaliacao) {
            getAvaliacaoData(avaliacao.data.avaliacao.idAvaliacao);
        }
    }, [avaliacao.importSuccess])

    useEffect(() => {
        if(!avaliacao.loadingInativacao){
            getAvaliacaoTable(body);
        }
    }, [avaliacao.loadingInativacao]);

    const getAvaliacaoTableData = () => {
        getAvaliacaoTable(body);
    }

    const handleCadastro = () => {
        clearAvaliacaoData();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione um avaliacao que gostaria de alterar');
        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas um avaliacao para alteração!');
        } else {
            getAvaliacaoData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione os temas que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.AVALIACAO_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarAvaliacao(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersitAvaliacao = (edicao: boolean, avaliacao: any) => {
        if(edicao) {
            onUpdateAvaliacao(avaliacao);
        } else {
            onSaveAvaliacao(avaliacao);
        }
    }

    const handleCloseAndReload = () => {
        clearAvaliacaoData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={avaliacao.loading || avaliacao.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroAvaliacao
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() =>  handleCloseAndReload()}
                id={idsSelecionado[0]}
                avaliacaoData={avaliacao.data.avaliacao} 
                persistAvaliacao={handlePersitAvaliacao}
                handleImportacao={(imp: any) => onImportarAvalicaoPerguntaEResposta(imp)}
                persistPerguntas={(payload: any) => onPersistAvaliacaoPergunta(payload)}
                persisteResposta={(payload: any) => onPersistPerguntaResposta(payload)}
                enviaNotificacao={(idAvaliacao: any) => onEnviaNotificacaoAvaliacao(idAvaliacao)}
                getAvaliacaoData={(idAvaliacao: any) => getAvaliacaoData(idAvaliacao)}
                listAvaliacaoTipo={avaliacao.data.avaliacaoTipo}
                setIsLoading={(payload: any) => setLoading(payload)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <AvaliacaoTableComponent 
                    loading={avaliacao.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};


const mapStateToProps = (state: ApplicationState) => ({
    avaliacao: state.avaliacao,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...AvaliacaoAction, setLoading}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Avaliacao);