import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getUrlExternaDataFailure,
    getUrlExternaDataSuccess,
    getUrlExternaTableFailure,
    getUrlExternaTableSuccess,
    onAtivarUrlExternaFailure,
    onAtivarUrlExternaSuccess,
    onInativarUrlExternaFailure,
    onInativarUrlExternaSuccess,
    onSaveUrlExternaFailure, 
    onSaveUrlExternaSuccess,
    onUpdateUrlExternaFailure,
    onUpdateUrlExternaSuccess,
    onNotificacaoUrlExternaFailure,
    onNotificacaoUrlExternaSuccess
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getUrlExternaTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('urlExterna/listAllUrlExternaTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getUrlExternaTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getUrlExternaTableFailure(error.message));
    }
};


function* onInativarUrlExterna(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('urlExterna/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarUrlExternaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarUrlExternaFailure(error.message));
    }
};

function* onAtivarUrlExterna(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('urlExterna/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarUrlExternaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarUrlExternaFailure(error.message));
    }
};

function* getUrlExternaData(data: any): any {
    const idUrlExterna = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`urlExterna/findById?idUrlExterna=${idUrlExterna}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getUrlExternaDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getUrlExternaDataFailure(error.message));
    }
};

function* onSaveUrlExterna(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('urlExterna/save', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveUrlExternaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveUrlExternaFailure(error.message));
    }
};

function* onUpdateUrlExterna(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('urlExterna/update', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateUrlExternaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateUrlExternaFailure(error.message));
    }
};

function* onNotificacaoUrlExterna(data: any): any {
    const idUrlExterna = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`urlExterna/notificaUrlExterna?idUrlExterna=${idUrlExterna}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoUrlExternaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoUrlExternaFailure(error.message));
    }
};

export {
    getUrlExternaData,
    getUrlExternaTable,
    onAtivarUrlExterna,
    onInativarUrlExterna,
    onSaveUrlExterna,
    onUpdateUrlExterna,
    onNotificacaoUrlExterna
}
