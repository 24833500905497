import { action } from "typesafe-actions";
import { AvaliacaoTypes } from "./types";

export const getAvaliacaoTable = (data: any) => action(AvaliacaoTypes.AVALIACAO_TABLE_REQUEST, data);
export const getAvaliacaoTableSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_TABLE_REQUEST_SUCCESS, { data });
export const getAvaliacaoTableFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_TABLE_REQUEST_FAILURE, { err });

export const onInativarAvaliacao = (data: any) => action(AvaliacaoTypes.AVALIACAO_INATIVA_REQUEST, data);
export const onInativarAvaliacaoSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarAvaliacaoFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarAvaliacao = (data: any) => action(AvaliacaoTypes.AVALIACAO_ATIVA_REQUEST, data);
export const onAtivarAvaliacaoSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarAvaliacaoFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_ATIVA_REQUEST_FAILURE, { err });

export const clearAvaliacaoData = () => action(AvaliacaoTypes.CLEAR_AVALIACAO_DATA);

export const getAvaliacaoData = (data: any) => action(AvaliacaoTypes.AVALIACAO_DATA_REQUEST, data);
export const getAvaliacaoDataSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_DATA_REQUEST_SUCCESS, { data });
export const getAvaliacaoDataFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_DATA_REQUEST_FAILURE, { err });

export const onSaveAvaliacao = (data: any) => action(AvaliacaoTypes.AVALIACAO_SAVE_REQUEST, data);
export const onSaveAvaliacaoSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_SAVE_REQUEST_SUCCESS, { data });
export const onSaveAvaliacaoFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_SAVE_REQUEST_FAILURE, { err });

export const onUpdateAvaliacao = (data: any) => action(AvaliacaoTypes.AVALIACAO_UPDATE_REQUEST, data);
export const onUpdateAvaliacaoSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateAvaliacaoFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_UPDATE_REQUEST_FAILURE, { err });

export const getAvaliacaoTipoList = () => action(AvaliacaoTypes.AVALIACAO_TIPO_REQUEST);
export const getAvaliacaoTipoListSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_TIPO_REQUEST_SUCCESS, { data });
export const getAvaliacaoTipoListFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_TIPO_REQUEST_FAILURE, { err });

export const onImportarAvalicaoPerguntaEResposta = (data: any) => action(AvaliacaoTypes.AVALIACAO_IMPORTAR, data);
export const onImportarAvalicaoPerguntaERespostaSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_IMPORTAR_SUCCESS, { data });
export const onImportarAvalicaoPerguntaERespostaFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_IMPORTAR_FAILURE, { err });

export const onPersistAvaliacaoPergunta = (data: any) => action(AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_REQUEST, data);
export const onPersistAvaliacaoPerguntaSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_REQUEST_SUCCESS, { data });
export const onPersistAvaliacaoPerguntaFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_REQUEST_FAILURE, { err });

export const onPersistPerguntaResposta = (data: any) => action(AvaliacaoTypes.PERGUNTA_RESPOSTA_SAVE_REQUEST, data);
export const onPersistPerguntaRespostaSuccess = (data: any) => action(AvaliacaoTypes.PERGUNTA_RESPOSTA_SAVE_REQUEST_SUCCESS, { data });
export const onPersistPerguntaRespostaFailure = (err: any) => action(AvaliacaoTypes.PERGUNTA_RESPOSTA_SAVE_REQUEST_FAILURE, { err });

export const onEnviaNotificacaoAvaliacao = (data: any) => action(AvaliacaoTypes.AVALIACAO_PUSH_REQUEST, data);
export const onEnviaNotificacaoAvaliacaoSuccess = (data: any) => action(AvaliacaoTypes.AVALIACAO_PUSH_REQUEST_SUCCESS, { data });
export const onEnviaNotificacaoAvaliacaoFailure = (err: any) => action(AvaliacaoTypes.AVALIACAO_PUSH_REQUEST_FAILURE, { err });
