import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';

import * as UtilsActions from '../../store/ducks/utils/actions';
import { ApplicationState } from "../../store";

function ListaCurso({
    utils,
    getCursoList,
    cursos,
    onChangeCursos,
    error
}: any) {

    const [cursoList, setCursosList] = React.useState<any>([]);
    const [cursoSelecionados, setCursosSelecionados] = React.useState<any>([]);
    const [selectAll, setSelectAll] =  React.useState(false);
    const [lazyItems, setLazyItems] = React.useState<any>([]);
    const [lazyLoading, setLazyLoading] = React.useState(false);

    const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    React.useEffect(() => {
        getCursoList()
    }, []);

    React.useEffect(() => {
        if(utils.data.curso){
            setCursosList(utils.data.curso);
            if(utils.data.curso.length > 0){
                setSelectAll(cursos.length == utils.data.curso.length);
            }
        }
    }, [utils.data.curso]);

    React.useEffect(() => {
        if(cursos){
            setCursosSelecionados(cursos.map((item: any) => item.idCurso));
        }
    }, [cursos])

    const onLazyLoad = (event: any) => {
        setLazyLoading(true);

        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }

        loadLazyTimeout.current = setTimeout(() => {
            const { first, last } = event;
            const _lazyItems = [...lazyItems];

            for (let i = first; i < last; i++) {
                _lazyItems[i] = { label: `Item #${i}`, value: i };
            }

            setLazyItems(_lazyItems);
            setLazyLoading(false);
        }, 300);
    }

    return (
        <MultiSelect 
            style={{
                width: '100%'
            }}
            value={cursoSelecionados} 
            options={cursoList} 
            display="chip"
            onChange={(e) => {
                if(e.value){
                    setCursosSelecionados(e.value); 
                    setSelectAll(e.value.length === cursoList.length);
                    onChangeCursos(cursoList.filter((muni: any) => e.value.includes(muni.idCurso)));
                } else {
                    setCursosSelecionados([])
                    setSelectAll(false);
                    onChangeCursos([])
                }
            }} 
            selectAll={selectAll} 
            optionLabel="nmCurso"
            optionValue='idCurso'
            placeholder="Selecione os Cursos" 
            filter 
            showClear={true}
            className={error ? "p-multiselect-error" : ''}
            maxSelectedLabels={3} 
            selectedItemsLabel={`${cursoSelecionados ? cursoSelecionados.length : 0} cursos selecionados`}
            emptyFilterMessage='Não encontrado'
            filterPlaceholder='Busque por um curso'
            resetFilterOnHide={true}
            virtualScrollerOptions={{ lazy: true, onLazyLoad: onLazyLoad, itemSize: 43, showLoader: true, loading: lazyLoading, delay: 1, loadingTemplate: (options) => {
                return (
                    <div className="flex align-items-center p-2" style={{ height: '43px' }}>
                        <Skeleton width={options.even ? '70%' : '60%'} height="1.5rem" />
                    </div>
                )}
            }} 
        />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UtilsActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCurso);