import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";

import * as UsuarioActions from "../../store/ducks/usuario/actions";
import * as UtilsActions from "../../store/ducks/utils/actions";

import { ApplicationState } from "../../store";
import UsuarioMenu from "../../Components/UsuarioMenu";
import UsuarioTableComponent from "../../Components/TableUsuario/UsuarioTable";
import ModalImportUsuario from "../../Components/ModalImportUsuario";
import ModalInativaUsuario from "../../Components/ModalInativaUsuario";
import ModalCadastroUsuario from "../../Components/ModalCadastroUsuario";
import ModalResetSenha from "../../Components/ModalResetDeSenha";
import Loading from "../../Components/LoadingComponent";
import ModalImportUsuarioT5 from "../../Components/ModalImportUsuarioT5";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
  pagina: 0,
  tamanho: 10,
  ordem: "ASC",
  coluna: "nmPessoaFisica",
  txtBusca: "",
};

function Usuario({
  usuario,
  getUsuarioTable,
  onInativarUsuario,
  onAtivarUsuario,
  onInativarMassa,
  onImportarUsuario,
  onImportarUsuarioT5,
  onResetSenha,
  onResetSenhaMassa,
  getUsuarioData,
  clearUsuarioData,
  onSaveUsuario,
  onUpdateUsuario,
  getEstadosList,
  getPerfilList,
  getUnidadeNegocioList,
  utils,
  getMunicipioList,
  getAllClusterList,
  getAllSubclusterList,
  getAllGrupoList,
  getGrupoClusterSubcluster,
}: any) {
  const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
  const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
  const [modalImportOpen, setModalImportOpen] = useState(false);
  const [modalImportT5Open, setModalImportT5Open] = useState(false);
  const [modalInativacaoOpen, setModalInativacaoOpen] = useState(false);
  const [modalResetSenhaMassaOpen, setModalResetSenhaMassaOpen] =
    useState(false);
  const [body, setBody] = useState(_body);
  const [tableUsuario, setTableUsuario] = useState(null);
  const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
  const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
  const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

  useEffect(() => {
    if (usuario.error) {
      if (
        usuario.errorMessage === "Usuário com CPF já cadastrado no sistema!"
      ) {
        alert("Atenção!\n " + usuario.errorMessage);
      } else {
        alert(
          "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
            usuario.errorMessage
        );
      }
      handleCloseAndReload();
    }
  }, [usuario.error]);

  useEffect(() => {
    if (!usuario.loadingPersis) {
      handleCloseAndReload();
    }
  }, [usuario.loadingPersis]);

  useEffect(() => {
    if (!modalCadastroOpen) {
      getUsuarioTableData();
    }
  }, [body, modalCadastroOpen]);

  useEffect(() => {
    if (usuario.data.table != null) {
      setTableUsuario(usuario.data.table);
    }
  }, [usuario.data]);

  useEffect(() => {
    /* getAllGrupoList(); */
    getEstadosList();
    getPerfilList();
    getUnidadeNegocioList();
  }, []);

  useEffect(() => {
    if (!usuario.loadingInativacao) {
      getUsuarioTable(body);
    }
  }, [usuario.loadingInativacao]);

  const getUsuarioTableData = () => {
    getUsuarioTable(body);
  };

  const changeTable = (__body: any) => {
    setBody({ ...__body });
  };

  const onEditar = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione um usuário que gostaria de alterar");
    } else if (idsSelecionado.length > 1) {
      alert(
        "Selecione apenas um usuário para alteração!\nCaso queira fazer alteração para muitos Usuários utilize a planilha de importação!"
      );
    } else {
      getUsuarioData(idsSelecionado[0]);
      setModalCadastroOpen(true);
    }
  };

  const onInativar = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione os usuários que gostaria de inativar");
    } else {
      setAlertConfirmacaoOpen(true);
      setAlertConfirmacaoTexto(EntidadesAcoes.USUARIO_INATIVACAO);
      setAlertConfirmacaoAcao(() => () => {
        onInativarUsuario(idsSelecionado);
        getUsuarioTable(body);
        setAlertConfirmacaoOpen(false);
      })
    }
  };

  const onAtivar = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione os usuários que gostaria de ativar");
    } else {
      onAtivarUsuario(idsSelecionado);
    }
  };

  const envioImportacao = (form: FormData) => {
    onImportarUsuario(form);
  };

  const envioImportacaoT5 = (form: FormData) => {
    onImportarUsuarioT5(form);
  };

  const envioInativacaoMassa = (form: FormData) => {
    onInativarMassa(form);
  };

  const handleResetSenha = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione os usuários que gostaria de resetar a senha");
    } else {
      setAlertConfirmacaoOpen(true);
      setAlertConfirmacaoTexto(EntidadesAcoes.USUARIO_RESET_SENHA);
      setAlertConfirmacaoAcao(() => () => {
        onResetSenha(idsSelecionado);
        setAlertConfirmacaoOpen(false);
      })
      
    }
  };

  const handleResetSenhaMassa = (form: FormData) => {
    onResetSenhaMassa(form);
  };

  const handleChangeEstado = (idEstado: number) => {
    getMunicipioList(idEstado);
  };

  const handleChangeMunicipio = (idMunicipio: number) => {
    getGrupoClusterSubcluster(idMunicipio);
  };

  const handlePersist = (edicao: boolean, usuario: any) => {
    if (edicao) {
      onUpdateUsuario(usuario);
    } else {
      onSaveUsuario(usuario);
    }
  };

  const handleCloseAndReload = () => {
    clearUsuarioData();
    setModalCadastroOpen(false);
    setIdSelecionado([]);
  };

  function getGroupClusterSubclusterData() {
    getAllGrupoList();
    getAllClusterList();
    getAllSubclusterList();
  }

  function unmountAlertConfirmacao() {
    setAlertConfirmacaoTexto('');
    setAlertConfirmacaoAcao(() => () => {})
    setAlertConfirmacaoOpen(false);
  }

  return (
    <div className="pageBox">
      <Loading loading={usuario.loading || usuario.loadingPersis} />
      <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
      <ModalCadastroUsuario
        modalCadastroOpen={modalCadastroOpen}
        closeModal={() => handleCloseAndReload()}
        usuarioData={usuario.data.usuario}
        estados={utils.data.estados}
        changeEstado={handleChangeEstado}
        municipios={utils.data.municipios}
        changeMunicipio={handleChangeMunicipio}
        perfis={utils.data.perfil}
        unidadesNegocio={utils.data.unidadesNegocio}
        regional={utils.data.regional}
        persistUsuario={handlePersist}
        cluster={utils.data.cluster}
        subcluster={utils.data.subcluster}
        grupo={utils.data.grupo}
        getGroupClusterSubclusterData={getGroupClusterSubclusterData}
      />
      <ModalImportUsuario
        modalImportOpen={modalImportOpen}
        closeModal={() => setModalImportOpen(false)}
        handleEnvio={envioImportacao}
      />
      <ModalImportUsuarioT5
        modalImportOpen={modalImportT5Open}
        closeModal={() => setModalImportT5Open(false)}
        handleEnvio={envioImportacaoT5}
      />
      <ModalInativaUsuario
        modalInativacaoOpen={modalInativacaoOpen}
        closeModal={() => setModalInativacaoOpen(false)}
        handleEnvio={envioInativacaoMassa}
      />
      <ModalResetSenha
        modalResetSenhaMassaOpen={modalResetSenhaMassaOpen}
        closeModal={() => setModalResetSenhaMassaOpen(false)}
        handleEnvio={handleResetSenhaMassa}
      />
      <div className="menuLateral">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            width: "100%",
          }}
        >
          Ações
          <UsuarioMenu
            onCadastro={() => {
              clearUsuarioData();
              setModalCadastroOpen(true);
            }}
            onEditar={onEditar}
            onInativar={onInativar}
            onAtivar={onAtivar}
            onImportar={() => setModalImportOpen(true)}
            onImportarT5={() => setModalImportT5Open(true)}
            onInativarMassa={() => setModalInativacaoOpen(true)}
            onResetSenha={handleResetSenha}
            onResetSenhaMassa={() => setModalResetSenhaMassaOpen(true)}
          />
        </Typography>
      </div>
      <div className="table">
        <UsuarioTableComponent
          loading={usuario.loadingTable}
          data={tableUsuario}
          onChange={changeTable}
          selection={idsSelecionado}
          idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  usuario: state.usuario,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UsuarioActions, ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Usuario);
