import { action } from "typesafe-actions";
import { ComunicadoTypes } from "./types";

export const getComunicadoTable = (data: any) => action(ComunicadoTypes.COMUNICADO_TABLE_REQUEST, data);
export const getComunicadoTableSuccess = (data: any) => action(ComunicadoTypes.COMUNICADO_TABLE_REQUEST_SUCCESS, { data });
export const getComunicadoTableFailure = (err: any) => action(ComunicadoTypes.COMUNICADO_TABLE_REQUEST_FAILURE, { err });

export const onInativarComunicado = (data: any) => action(ComunicadoTypes.COMUNICADO_INATIVA_REQUEST, data);
export const onInativarComunicadoSuccess = (data: any) => action(ComunicadoTypes.COMUNICADO_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarComunicadoFailure = (err: any) => action(ComunicadoTypes.COMUNICADO_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarComunicado = (data: any) => action(ComunicadoTypes.COMUNICADO_ATIVA_REQUEST, data);
export const onAtivarComunicadoSuccess = (data: any) => action(ComunicadoTypes.COMUNICADO_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarComunicadoFailure = (err: any) => action(ComunicadoTypes.COMUNICADO_ATIVA_REQUEST_FAILURE, { err });

export const getComunicadoData = (data: any) => action(ComunicadoTypes.COMUNICADO_DATA_REQUEST, data);
export const getComunicadoDataSuccess = (data: any) => action(ComunicadoTypes.COMUNICADO_DATA_REQUEST_SUCCESS, { data });
export const getComunicadoDataFailure = (err: any) => action(ComunicadoTypes.COMUNICADO_DATA_REQUEST_FAILURE, { err });

export const onSaveComunicado = (data: any) => action(ComunicadoTypes.COMUNICADO_SAVE_REQUEST, data);
export const onSaveComunicadoSuccess = (data: any) => action(ComunicadoTypes.COMUNICADO_SAVE_REQUEST_SUCCESS, { data });
export const onSaveComunicadoFailure = (err: any) => action(ComunicadoTypes.COMUNICADO_SAVE_REQUEST_FAILURE, { err });

export const onUpdateComunicado = (data: any) => action(ComunicadoTypes.COMUNICADO_UPDATE_REQUEST, data);
export const onUpdateComunicadoSuccess = (data: any) => action(ComunicadoTypes.COMUNICADO_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateComunicadoFailure = (err: any) => action(ComunicadoTypes.COMUNICADO_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoComunicado = (data: any) => action(ComunicadoTypes.COMUNICADO_PUSH_REQUEST, data);
export const onNotificacaoComunicadoSuccess = (data: any) => action(ComunicadoTypes.COMUNICADO_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoComunicadoFailure = (err: any) => action(ComunicadoTypes.COMUNICADO_PUSH_REQUEST_FAILURE, { err });

export const clearComunicadoData = () => action(ComunicadoTypes.CLEAR_COMUNICADO_DATA);