import { Reducer } from "@reduxjs/toolkit";
import { CursoState, CursoTypes } from "./types";

const INITIAL_STATE: CursoState = {
    data: {
        table: null,
        curso: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<CursoState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CursoTypes.CURSO_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case CursoTypes.CURSO_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case CursoTypes.CURSO_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case CursoTypes.CURSO_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case CursoTypes.CURSO_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case CursoTypes.CURSO_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case CursoTypes.CURSO_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case CursoTypes.CURSO_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case CursoTypes.CURSO_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case CursoTypes.CURSO_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case CursoTypes.CURSO_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    curso: action.payload.data
                }
            };
        case CursoTypes.CURSO_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    curso: null
                }
            };


        case CursoTypes.CLEAR_CURSO_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    curso: null
                }
            };

        case CursoTypes.CURSO_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case CursoTypes.CURSO_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Curso realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    curso: action.payload.data
                }
            };
        case CursoTypes.CURSO_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case CursoTypes.CURSO_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case CursoTypes.CURSO_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Curso realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    curso: action.payload.data
                }
            };
        case CursoTypes.CURSO_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case CursoTypes.CURSO_PUSH_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case CursoTypes.CURSO_PUSH_REQUEST_SUCCESS:
            alert('As notificações entraram na fila para envio! Pode levar alguns minutos para que os usuários recebam.');
            return {
                ...state,
                error: false,
                loading: false,
            };
        case CursoTypes.CURSO_PUSH_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
            };

        default :
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
};

export default reducer;