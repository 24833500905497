import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getComunicadoDataFailure,
    getComunicadoDataSuccess,
    getComunicadoTableFailure,
    getComunicadoTableSuccess,
    onAtivarComunicadoFailure,
    onAtivarComunicadoSuccess,
    onInativarComunicadoFailure,
    onInativarComunicadoSuccess,
    onSaveComunicadoFailure, 
    onSaveComunicadoSuccess,
    onUpdateComunicadoFailure,
    onUpdateComunicadoSuccess,
    onNotificacaoComunicadoFailure,
    onNotificacaoComunicadoSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getComunicadoTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoComunicado/listAllCursoComunicadoTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getComunicadoTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getComunicadoTableFailure(error.message));
    }
};


function* onInativarComunicado(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoComunicado/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarComunicadoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarComunicadoFailure(error.message));
    }
};

function* onAtivarComunicado(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoComunicado/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarComunicadoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarComunicadoFailure(error.message));
    }
};

function* getComunicadoData(data: any): any {
    const idComunicado = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`cursoComunicado/findById?idCursoComunicado=${idComunicado}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getComunicadoDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getComunicadoDataFailure(error.message));
    }
};

function* onSaveComunicado(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('cursoComunicado/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveComunicadoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveComunicadoFailure(error.message));
    }
};

function* onUpdateComunicado(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoComunicado/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateComunicadoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateComunicadoFailure(error.message));
    }
};

function* onNotificacaoComunicado(data: any): any {
    const idComunicado = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`cursoComunicado/notificaComunicado?idCursoComunicado=${idComunicado}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoComunicadoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoComunicadoFailure(error.message));
    }
};

export {
    getComunicadoData,
    getComunicadoTable,
    onAtivarComunicado,
    onInativarComunicado,
    onSaveComunicado,
    onUpdateComunicado,
    onNotificacaoComunicado,
}
