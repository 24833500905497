import React, { useEffect,  useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'

import { StoryObj } from "../../store/ducks/storys/types";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaMunicipioComponent from "../ListaMunicipioComponent";
import ListaAvaliacaoComponent from "../ListaAvaliacaoComponent";
import ListaConteudoComponent from "../ListaConteudoComponent";
import TabPanel from "../TabPanel"; 
import { FormHelperText, InputLabel, List, ListItem, MenuItem, Select } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const defaultStory: StoryObj = {
    nmStory: '',
    dsStory: '',
    dtStoryInicio: null,
	dtStoryFim: null,
    imgStory: '',
    urlCompartilhamentoStory: '',
    storyMuncipios: [],
    storyPerfis: [],
    storyConteudos: []
};

const ModalCadastroStory = (props: any) => {

    const [isEdicao, setIsEdicao] = useState(false);
    const [story, setStory] = useState(defaultStory);
    const [capaStory, setCapaStory] = useState('');
    const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
    const [conteudosOrdenados, setConteudoOrdenados] = useState([]);
    const [tab, setTab] = useState(0);
    const [inativaGravar, setInativaGravar] = useState(true);
    const [imgStoryErro, setImgStoryErro] = useState(false);
    const [nmStoryErro, setNmStoryErro] = useState(false);
    const [dsStoryErro, setDsStoryErro] = useState(false);
    const [dtIniStoryErro, setDtIniStoryErro] = useState(false);
    const [dtFimStoryErro, setDtFimStoryErro] = useState(false);
    const [perfilErro, setPerfilErro] = useState(false);
    const [municipioErro, setMunicipioErro] = useState(false);
    const [conteudoErro, setConteudoErro] = useState(false);

    useEffect(() => {
        if(props.storyData){   
            handleRender(props.storyData);
        }
    }, [props.storyData]);

    useEffect(() => {
        if(!props.modalCadastroOpen){   
            handleClose();
        }
    }, [props.modalCadastroOpen]);

    useEffect(() => {
        if(!imgStoryErro && !nmStoryErro && !dsStoryErro && !dtIniStoryErro && !dtFimStoryErro && !perfilErro && !municipioErro && !conteudoErro
            && story.imgStory !== ''&& story.nmStory !== '' && story.dsStory !== '' && story.dtStoryInicio !== null && story.dtStoryFim !== null 
            && story.storyMuncipios.length !== 0 && story.storyPerfis.length !== 0 && story.storyConteudos.length !== 0) {
            setInativaGravar(false);
        } else {
            setInativaGravar(true);
        }
    }, [story, imgStoryErro, nmStoryErro, dsStoryErro, dtIniStoryErro, dtFimStoryErro, perfilErro, municipioErro, conteudoErro])


    const validaOrdenacao = (array: any ) => {
        let ordenado = array.map((item: any, idx: number) => {
            let obj = conteudosOrdenados.find((_item: any) => _item.idConteudo ==  item.idConteudo);
            if(obj != undefined ) {
                item = obj;
            } else {
                item.ordemConteudo = conteudosOrdenados.length + 1;
            }
            return item;
        });

        setConteudoOrdenados(ordenado);
        setStory({
            ...story,
            storyConteudos: ordenado,
        });
    }

    const handleRender = (storyData: any) => {
        if(storyData){
            setIsEdicao(true)
            setStory({
                ...storyData
            });
            setCapaStory('');
            setConteudoOrdenados(storyData.storyConteudos);
        }
    } 
    
    const handleClose = () => {
        setIsEdicao(false);
        setStory(defaultStory);
        setCapaStory('');
        setTab(0);
        setInativaGravar(true);
        setNmStoryErro(false);
        setDsStoryErro(false);
        setDtIniStoryErro(false);
        setDtFimStoryErro(false);
        setPerfilErro(false);
        setMunicipioErro(false);
        setConteudoErro(false);
        setImgStoryErro(false);

        props.closeModal();
    }

    const handlePersist = (e: any) => {
        e.preventDefault();

        const formStory = new FormData();

        if(story.storyConteudos && story.storyConteudos.length > 0){
            story.storyConteudos.forEach((conteudo: any, idx: number) => {
                if(conteudo.ordemConteudo == undefined || conteudo.ordemConteudo == 0){
                    conteudo.ordemConteudo = (idx +1);
                }
            })
        }

        formStory.append('story', JSON.stringify(story));

        if(capaStory != ''){
            formStory.append('fileImg', capaStory);
        }

        props.persist(isEdicao, formStory);
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if(res !== 'backdropClick') {
        handleClose()
      }
    }}
        >
            <Box sx={style} >
                <div className="modalHead">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isEdicao ? 'Editar Story' : 'Cadastro de novo Story'}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Fechar
                    </Button>
                </div>
                <Divider />
                <form className="modalMid-form" onSubmit={handlePersist}>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                {
                                    isEdicao && story.imgStory && capaStory == '' ? (
                                        <FormControl sx={{width: '100%'}} >
                                            <Typography id="modal-modal-title" variant="inherit" component="p">
                                                Capa
                                            </Typography>
                                            <div>
                                                <Link className="buttonArquivo" href={story.imgStory} underline="none" target="_blank" >
                                                    <Button  variant="contained" sx={{backgroundColor: '#eb4034'}} >Download</Button>
                                                </Link>
                                                <Button variant="text" color="error" onClick={() => {
                                                    setImgStoryErro(true);
                                                    setStory({
                                                        ...story,
                                                        imgStory: ''
                                                    })
                                                }}>Remover</Button>
                                            </div>
                                        </FormControl>
                                    ) : (
                                        <FormControl sx={{width: '100%'}} >
                                            <Typography id="modal-modal-title" variant="inherit" component="p">
                                                Capa
                                            </Typography>
                                            <Input type='file' error={imgStoryErro} onChange={(e: any) => {
                                                if(Array.from(e.target.files).length !== 0 ) {
                                                    if(validaNomeArquivo(e.target.files[0].name)) {
                                                        setImgStoryErro(Array.from(e.target.files).length === 0);
                                                        setStory({
                                                            ...story,
                                                            imgStory: e.target.files[0].name || ''
                                                        })
                                                        setCapaStory(e.target.files[0])
                                                    } else {
                                                        e.target.value = "";
                                                        setImgStoryErro(true);
                                                    }
                                                } else {
                                                    e.target.value = "";
                                                    setImgStoryErro(true);
                                                }
                                            }} />
                                            { imgStoryErro ? <FormHelperText error={imgStoryErro}>Campo Obrigatório.</FormHelperText> : null }
                                       </FormControl> 
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={5} >
                                <TextField
                                    required
                                    sx={{width: '100%'}}
                                    value={story.nmStory}
                                    label="Nome do Story"
                                    error={nmStoryErro}
                                    helperText={nmStoryErro ? "Campo Obrigatório." : ''}
                                    onChange={(e) => {
                                        setNmStoryErro(e.target.value === "");
                                        setStory({
                                            ...story,
                                            nmStory: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    required
                                    sx={{width: '100%'}}
                                    value={story.dsStory}
                                    label="Descrição do Story"
                                    error={dsStoryErro}
                                    helperText={dsStoryErro ? "Campo Obrigatório." : ''}
                                    onChange={(e) => {
                                        setDsStoryErro(e.target.value === "");
                                        setStory({
                                            ...story,
                                            dsStory: e.target.value
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={2.5}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                                    <DesktopDatePicker
                                        onError={(e) => {
                                            if(e){
                                                setDtIniStoryErro(true);
                                            }else {
                                                setDtIniStoryErro(false);
                                            }
                                        }}
                                        label="Data de Início"
                                        value={story.dtStoryInicio}
                                        minDate={dayjs('1900-01-01')}
                                        onChange={(newValue) => {
                                            setDtIniStoryErro(false);
                                            setStory({
                                                ...story,
                                                dtStoryInicio: newValue?.format()
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params} error={dtIniStoryErro} helperText={dtIniStoryErro ? "Campo Obrigatório." : ''} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={2.5}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                                    <DesktopDatePicker
                                        onError={(e) => {
                                            if(e){
                                                setDtFimStoryErro(true);
                                            }else {
                                                setDtFimStoryErro(false);
                                            }
                                        }}
                                        label="Data Final"
                                        value={story.dtStoryFim}
                                        minDate={dayjs('1900-01-01')}
                                        onChange={(newValue) => {
                                            setDtFimStoryErro(false);
                                            setStory({
                                                ...story,
                                                dtStoryFim: newValue?.format()
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params} error={dtFimStoryErro} helperText={dtFimStoryErro ? "Campo Obrigatório." : ''} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl sx={{width: '100%'}} >
                                    <ListaPerfilComponent
                                        error={perfilErro}
                                        perfis={story.storyPerfis}
                                        onChangePerfis={(value: any) => {
                                            setPerfilErro(value.length === 0)
                                            setStory({
                                                ...story,
                                                storyPerfis: value
                                            })
                                        }}
                                    />
                                    { perfilErro ? <FormHelperText error={perfilErro}>Campo Obrigatório.</FormHelperText> : null }
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl sx={{width: '100%'}} >
                                    <ListaMunicipioComponent 
                                        error={municipioErro}
                                        municipios={story.storyMuncipios}
                                        onChangeMunicipios={(value: any) => {
                                            setMunicipioErro(value.length === 0)
                                            setStory({
                                                ...story,
                                                storyMuncipios: value
                                            })
                                        }}
                                    />
                                    { municipioErro ? <FormHelperText error={municipioErro}>Campo Obrigatório.</FormHelperText> : null }
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl sx={{width: '100%'}} >
                                    <ListaConteudoComponent
                                        error={conteudoErro}
                                        conteudos={story.storyConteudos}
                                        multiple={true}
                                        onChangeConteudos={(value: any) => {
                                            setConteudoErro(value.length === 0)
                                            setStory({
                                                ...story,
                                                storyConteudos: value
                                            });
                                            validaOrdenacao(value);
                                        }}
                                    />
                                    { conteudoErro ? <FormHelperText error={conteudoErro}>Campo Obrigatório.</FormHelperText> : null }
                                </FormControl>
                            </Grid>

                            {
                                false ? (
                                    <Grid item xs={8} sx={{display: 'flex', alignItems: 'center'}}>
                                        <TextField
                                            disabled
                                            sx={{width: '80%'}}
                                            value={story.urlCompartilhamentoStory}
                                            label="Link de Compartilhamento"
                                        />
                                        <Tooltip title={copiado}>
                                            <IconButton  sx={{ fontSize: 25, marginLeft: '5px', storyr: 'pointer' }} onClick={() => { 
                                                navigator.clipboard.writeText(story.urlCompartilhamentoStory || '').then(() => {
                                                    setCopiado('Copiado!');
                                                }).catch(() => {
                                                    setCopiado('Não foi possível copiar o link de compartilhamento!');
                                                }).finally(() => {
                                                    setTimeout(() => {
                                                        setCopiado('Copiar link de compartilhamento');
                                                    }, 1500);
                                                });
                                            }} >
                                                <Icon >content_copy</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                ) : null
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <div style={{maxHeight: 400, overflow: 'auto', width: '100%'}}>
                            <List className="conteudoOrdemList" >
                                {
                                    story.storyConteudos && story.storyConteudos.length > 0 ? (
                                        story.storyConteudos.map((conteudo: any, idx: number) => (
                                            <ListItem key={`${conteudo.idConteudo}`}>
                                                <TextField
                                                    disabled
                                                    style={{width: 400}}
                                                    value={conteudo.nmConteudo}
                                                    label="Conteúdo"
                                                />
                                                <FormControl sx={{width: 200, marginLeft: '1em'}} >
                                                 <InputLabel id="demo-controlled-open-select-label">Ordem do Conteúdo</InputLabel>
                                                    <Select
                                                        value={conteudo.ordemConteudo && conteudo.ordemConteudo != 0 ? conteudo.ordemConteudo : (idx + 1)}
                                                        label="Ordem do Conteúdo"
                                                        onChange={(e) => {
                                                            let auxArr = story.storyConteudos;
                                                            auxArr[idx].ordemConteudo = parseInt(e.target.value.toString());
                                                            setStory({
                                                                ...story,
                                                                storyConteudos: auxArr
                                                            })
                                                        }}
                                                        >
                                                        {
                                                            story.storyConteudos.map((item: any, _idx: any) => (
                                                                <MenuItem key={`listItemOrdem-${idx}-${_idx}`} value={(_idx + 1)}>{(_idx + 1)}</MenuItem>
                                                            )) 
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </ListItem>
                                            ))
                                        ) : null
                                }
                            </List>
                        </div>
                    </TabPanel>
                    <Divider style={{marginTop: '1em'}} />
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
                            <Tab label="Informações" />
                            <Tab label="Ordenação de Conteúdos" disabled={!(story.storyConteudos && story.storyConteudos.length > 0)} />
                        </Tabs>
                    </Box>
                    <div className="cadastroFooter">
                        <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar' }</Button>
                        {
                            false ? 
                            <Button variant="contained" onClick={() => {
                                props.enviaNotificacao(story.idStory)
                            }}>Enviar Notificação</Button> : null
                        }
                    </div>
                </form>
            </Box>
            
        </Modal>
    )

};

export default ModalCadastroStory;