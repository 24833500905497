import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getTemaDataFailure,
    getTemaDataSuccess,
    getTemaExistenteFailure,
    getTemaExistenteSuccess,
    getTemaTableFailure,
    getTemaTableSuccess,
    onAtivarTemaFailure,
    onAtivarTemaSuccess,
    onInativarTemaFailure,
    onInativarTemaSuccess,
    onSaveTemaFailure, 
    onSaveTemaSuccess,
    onUpdateTemaFailure,
    onUpdateTemaSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getTemaTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('tema/listAllTemaTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getTemaTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getTemaTableFailure(error.message));
    }
};


function* onInativarTema(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('tema/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarTemaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarTemaFailure(error.message));
    }
};

function* onAtivarTema(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('tema/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarTemaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarTemaFailure(error.message));
    }
};

function* getTemaData(data: any): any {
    const idTema = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`tema/findById?idTema=${idTema}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getTemaDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getTemaDataFailure(error.message));
    }
};

function* onSaveTema(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('tema/save', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveTemaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveTemaFailure(error.message));
    }
};

function* onUpdateTema(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('tema/update', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateTemaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateTemaFailure(error.message));
    }
};

function* getTemaExistente(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('tema/findIdTemaExistente', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getTemaExistenteSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getTemaExistenteFailure(error.message));
    }
};

export {
    getTemaData,
    getTemaTable,
    onAtivarTema,
    onInativarTema,
    onSaveTema,
    onUpdateTema,
    getTemaExistente
}
