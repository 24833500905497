import { action } from "typesafe-actions";
import { TrilhaTypes } from "./types";

export const getTrilhaTable = (data: any) => action(TrilhaTypes.TRILHA_TABLE_REQUEST, data);
export const getTrilhaTableSuccess = (data: any) => action(TrilhaTypes.TRILHA_TABLE_REQUEST_SUCCESS, { data });
export const getTrilhaTableFailure = (err: any) => action(TrilhaTypes.TRILHA_TABLE_REQUEST_FAILURE, { err });

export const onInativarTrilha = (data: any) => action(TrilhaTypes.TRILHA_INATIVA_REQUEST, data);
export const onInativarTrilhaSuccess = (data: any) => action(TrilhaTypes.TRILHA_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarTrilhaFailure = (err: any) => action(TrilhaTypes.TRILHA_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarTrilha = (data: any) => action(TrilhaTypes.TRILHA_ATIVA_REQUEST, data);
export const onAtivarTrilhaSuccess = (data: any) => action(TrilhaTypes.TRILHA_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarTrilhaFailure = (err: any) => action(TrilhaTypes.TRILHA_ATIVA_REQUEST_FAILURE, { err });

export const getTrilhaData = (data: any) => action(TrilhaTypes.TRILHA_DATA_REQUEST, data);
export const getTrilhaDataSuccess = (data: any) => action(TrilhaTypes.TRILHA_DATA_REQUEST_SUCCESS, { data });
export const getTrilhaDataFailure = (err: any) => action(TrilhaTypes.TRILHA_DATA_REQUEST_FAILURE, { err });

export const onSaveTrilha = (data: any) => action(TrilhaTypes.TRILHA_SAVE_REQUEST, data);
export const onSaveTrilhaSuccess = (data: any) => action(TrilhaTypes.TRILHA_SAVE_REQUEST_SUCCESS, { data });
export const onSaveTrilhaFailure = (err: any) => action(TrilhaTypes.TRILHA_SAVE_REQUEST_FAILURE, { err });

export const onUpdateTrilha = (data: any) => action(TrilhaTypes.TRILHA_UPDATE_REQUEST, data);
export const onUpdateTrilhaSuccess = (data: any) => action(TrilhaTypes.TRILHA_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateTrilhaFailure = (err: any) => action(TrilhaTypes.TRILHA_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoTrilha = (data: any) => action(TrilhaTypes.TRILHA_PUSH_REQUEST, data);
export const onNotificacaoTrilhaSuccess = (data: any) => action(TrilhaTypes.TRILHA_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoTrilhaFailure = (err: any) => action(TrilhaTypes.TRILHA_PUSH_REQUEST_FAILURE, { err });

export const clearTrilhaData = () => action(TrilhaTypes.CLEAR_TRILHA_DATA);