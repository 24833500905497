import axios from 'axios';
import { store } from '../store';

const url = (process.env.REACT_APP_API_BASE_URL || window.location.origin) + '/api/';

const api = axios.create({
  baseURL: url,
  
});

// Esse bloco so deve ser utilizado em desenvolvimento local, não pode ser commitado na versão para HML ou PRD!!!
// api.interceptors.request.use(
//  async config => {
//    if (config.url?.includes('chatbot/')) {
//      config.baseURL = 'http://localhost:8090/api/';
//      return config;
//    }

//    return config;
//  },
//  error => {
//    return Promise.reject(error);
//  },
// );

api.interceptors.response.use((response: any) => {
  return response;
}, (error: any) => {
  const originalRequest = error.config;
  if (!error.response) {
      return Promise.reject('Network Error');
  } else if ((error.response.status === 401) && !originalRequest._retry) {
    originalRequest._retry = true;
    const chave = localStorage.getItem('chaveToken');
    const chaveToken = {
        refreshToken: chave
    };
    return axios.post(url + 'auth/refreshToken', chaveToken, {
      headers: {
          'Content-Type': 'application/json',
      },
    }).then( res => {
      localStorage.setItem('accessToken', res.data.accessToken);
      localStorage.setItem('chaveToken', res.data.chaveToken);
      api.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.accessToken;
      originalRequest.headers['Authorization'] = 'Bearer ' + res.data.accessToken;

      return axios(originalRequest);
    }).catch(err => {
      store.dispatch({type: 'FORCE_LOGOUT'});
    })        
  } else {
    return Promise.reject(error);
  }
})

export default api;
