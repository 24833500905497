import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getPerfilDataFailure,
    getPerfilDataSuccess,
    getPerfilTableFailure,
    getPerfilTableSuccess,
    onAtivarPerfilFailure,
    onAtivarPerfilSuccess,
    onInativarPerfilFailure,
    onInativarPerfilSuccess,
    onSavePerfilFailure, 
    onSavePerfilSuccess,
    onUpdatePerfilFailure,
    onUpdatePerfilSuccess,
    getPerfilExistenteFailure,
    getPerfilExistenteSuccess
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getPerfilTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('perfil/listAllPerfilTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getPerfilTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getPerfilTableFailure(error.message));
    }
};

function* onInativarPerfil(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('perfil/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarPerfilSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarPerfilFailure(error.message));
    }
};

function* onAtivarPerfil(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('perfil/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarPerfilSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarPerfilFailure(error.message));
    }
};

function* getPerfilData(data: any): any {
    const idPerfil = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`perfil/findById?idPerfil=${idPerfil}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getPerfilDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getPerfilDataFailure(error.message));
    }
};

function* onSavePerfil(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('perfil/save', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSavePerfilSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSavePerfilFailure(error.message));
    }
};

function* onUpdatePerfil(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('perfil/update', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdatePerfilSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdatePerfilFailure(error.message));
    }
};

function* getPerfilExistente(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('perfil/findIdPerfilExistente', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getPerfilExistenteSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getPerfilExistenteFailure(error.message));
    }
};

export {
    getPerfilData,
    getPerfilTable,
    onAtivarPerfil,
    onInativarPerfil,
    onSavePerfil,
    onUpdatePerfil,
    getPerfilExistente
}
