export enum UtilTypes {
  ESTADOS_LIST = "utils/ESTADOS_LIST",
  ESTADOS_LIST_SUCCESS = "utils/ESTADOS_LIST_SUCCESS",
  ESTADOS_LIST_FAILURE = "utils/ESTADOS_LIST_FAILURE",

  USUARIOS_LIST = "utils/USUARIOS_LIST",
  USUARIOS_LIST_SUCCESS = "utils/USUARIOS_LIST_SUCCESS",
  USUARIOS_LIST_FAILURE = "utils/USUARIOS_LIST_FAILURE",

  MUNICIPIOS_LIST = "utils/MUNICIPIOS_LIST",
  MUNICIPIOS_LIST_SUCCESS = "utils/MUNICIPIOS_LIST_SUCCESS",
  MUNICIPIOS_LIST_FAILURE = "utils/MUNICIPIOS_LIST_FAILURE",

  GESTORES_LIST = "utils/GESTORES_LIST",
  GESTORES_LIST_SUCCESS = "utils/GESTORES_LIST_SUCCESS",
  GESTORES_LIST_FAILURE = "utils/GESTORES_LIST_FAILURE",

  REGIONAL = "utils/REGIONAL",
  REGIONAL_SUCCESS = "utils/REGIONAL_SUCCESS",
  REGIONAL_FAILURE = "utils/REGIONAL_FAILURE",

  UNIDADE_NEGOCIO_LIST = "utils/UNIDADE_NEGOCIO_LIST",
  UNIDADE_NEGOCIO_LIST_SUCCESS = "utils/UNIDADE_NEGOCIO_LIST_SUCCESS",
  UNIDADE_NEGOCIO_LIST_FAILURE = "utils/UNIDADE_NEGOCIO_LIST_FAILURE",

  PERFIL_LIST = "utils/PERFIL_LIST",
  PERFIL_LIST_SUCCESS = "utils/PERFIL_LIST_SUCCESS",
  PERFIL_LIST_FAILURE = "utils/PERFIL_LIST_FAILURE",

  MUNICIPIO_ALL_LIST = "utils/MUNICIPIO_ALL_LIST",
  MUNICIPIO_ALL_LIST_SUCCESS = "utils/MUNICIPIO_ALL_LIST_SUCCESS",
  MUNICIPIO_ALL_LIST_FAILURE = "utils/MUNICIPIO_ALL_LIST_FAILURE",

  CLUSTER_LIST = "utils/CLUSTER_LIST",
  CLUSTER_LIST_SUCCESS = "utils/CLUSTER_LIST_SUCCESS",
  CLUSTER_LIST_FAILURE = "utils/CLUSTER_LIST_FAILURE",

  GRUPO_ALL_LIST = "utils/GRUPO_ALL_LIST",
  GRUPO_ALL_LIST_SUCCESS = "utils/GRUPO_ALL_LIST_SUCCESS",
  GRUPO_ALL_LIST_FAILURE = "utils/GRUPO_ALL_LIST_FAILURE",

  CLUSTER_ALL_LIST = "utils/CLUSTER_ALL_LIST",
  CLUSTER_ALL_LIST_SUCCESS = "utils/CLUSTER_ALL_LIST_SUCCESS",
  CLUSTER_ALL_LIST_FAILURE = "utils/CLUSTER_ALL_LIST_FAILURE",

  SUBCLUSTER_LIST = "utils/SUBCLUSTER_LIST",
  SUBCLUSTER_LIST_SUCCESS = "utils/SUBCLUSTER_LIST_SUCCESS",
  SUBCLUSTER_LIST_FAILURE = "utils/SUBCLUSTER_LIST_FAILURE",

  SUBCLUSTER_ALL_LIST = "utils/SUBCLUSTER_ALL_LIST",
  SUBCLUSTER_ALL_LIST_SUCCESS = "utils/SUBCLUSTER_ALL_LIST_SUCCESS",
  SUBCLUSTER_ALL_LIST_FAILURE = "utils/SUBCLUSTER_ALL_LIST_FAILURE",

  MENU_LIST = "utils/MENU_LIST",
  MENU_LIST_SUCCESS = "utils/MENU_LIST_SUCCESS",
  MENU_LIST_FAILURE = "utils/MENU_LIST_FAILURE",

  TEMA_LIST = "utils/TEMA_LIST",
  TEMA_LIST_SUCCESS = "utils/TEMA_LIST_SUCCESS",
  TEMA_LIST_FAILURE = "utils/TEMA_LIST_FAILURE",

  CONTEUDO_LIST = "utils/CONTEUDO_LIST",
  CONTEUDO_LIST_SUCCESS = "utils/CONTEUDO_LIST_SUCCESS",
  CONTEUDO_LIST_FAILURE = "utils/CONTEUDO_LIST_FAILURE",

  AVALIACAO_LIST = "utils/AVALIACAO_LIST",
  AVALIACAO_LIST_SUCCESS = "utils/AVALIACAO_LIST_SUCCESS",
  AVALIACAO_LIST_FAILURE = "utils/AVALIACAO_LIST_FAILURE",

  AVALIACAO_BY_PERFIL_ESTADO = "utils/AVALIACAO_BY_PERFIL_ESTADO",
  AVALIACAO_BY_PERFIL_ESTADO_SUCCESS = "utils/AVALIACAO_BY_PERFIL_ESTADO_SUCCESS",
  AVALIACAO_BY_PERFIL_ESTADO_FAILURE = "utils/AVALIACAO_BY_PERFIL_ESTADO_FAILURE",

  CURSO_LIST = "utils/CURSO_LIST",
  CURSO_LIST_SUCCESS = "utils/CURSO_LIST_SUCCESS",
  CURSO_LIST_FAILURE = "utils/CURSO_LIST_FAILURE",

  SEGURANCA_LIST = "utils/SEGURANCA_LIST",
  SEGURANCA_LIST_SUCCESS = "utils/SEGURANCA_LIST_SUCCESS",
  SEGURANCA_LIST_FAILURE = "utils/SEGURANCA_LIST_FAILURE",

  TRILHA_LIST = "utils/TRILHA_LIST",
  TRILHA_LIST_SUCCESS = "utils/TRILHA_LIST_SUCCESS",
  TRILHA_LIST_FAILURE = "utils/TRILHA_LIST_FAILURE",

  SET_LOADING = "utils/SET_LOADING",
  SET_LOADING_TRUE = "utils/SET_LOADING_TRUE",
  SET_LOADING_FALSE = "utils/SET_LOADING_FALSE",
  /*  */
  GRUPO_CLUSTER_SUBCLUSTER = "utils/GRUPO_CLUSTER_SUBCLUSTER",
  GRUPO_CLUSTER_SUBCLUSTER_SUCCESS = 'utils/GRUPO_CLUSTER_SUBCLUSTER_SUCCESS',
  GRUPO_CLUSTER_SUBCLUSTER_FAILURE = 'utils/GRUPO_CLUSTER_SUBCLUSTER_FAILURE',
  /*  */
}

export interface Utils {
  estados: any | null;
  municipios: any | null;
  usuarios: any | null;
  regional: any | null;
  unidadesNegocio: any | null;
  perfil: any | null;
  menu: any | null;
  tema: any | null;
  conteudo: any | null;
  avaliacao: any | null;
  curso: any | null;
  gestores: any | null;
  grupos: any | null;
  subclusters: any | null;
  clusters: any | null;
  trilhas: any | null;
}

export interface UtilsState {
  readonly data: Utils;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage: string | null;
}
