import React from 'react';
import { FormControlLabel, FormHelperText, FormLabel, InputLabel, List, ListItem, MenuItem, Radio, RadioGroup, Select, TextareaAutosize } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import 'dayjs/locale/pt-br';
import { useEffect, useState } from "react";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";
import { validaNomeArquivo } from "../../services/Uteis";
import { ChatBotNivelObj } from "../../store/ducks/chatbot/nivel/types";
import { ChatBotLinkAuxiliarDTO } from "../../store/ducks/chatbot/types";
import ListaConteudoComponent from "../ListaConteudoComponent";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const ModalCadastroNivel = (props: any) => {

    const [isEdicao, setIsEdicao] = useState(false);
    const [nivel, setNivel] = useState<ChatBotNivelObj>({ linksAuxiliares: [{}]});
    const [conteudoContexto, setConteudoContexto] = useState('');
    const [inativaGravar, setInativaGravar] = useState(true);
    const [tituloErro, setTituloErro] = useState(false);
    const [contextoErro, setContextoErro] = useState(false);
    const [chaveErro, setChaveErro] = useState(false);
    const [isConteudoInterno, setIsConteudoInterno] = useState(true);
    const [exibeFeedback, setExibeFeedback] = useState(false);

    useEffect(() => {
        if (nivel.linksAuxiliares && nivel.linksAuxiliares.length === 0) {
            handleAddLinkAuxiliar();
        }
    }, [nivel.linksAuxiliares]);

    useEffect(() => {
        if (props.nivelData) {
            handleRender(props.nivelData);
        }
    }, [props.nivelData]);

    useEffect(() => {
        if (!props.modalCadastroOpen) {
            handleClose();
        }
    }, [props.modalCadastroOpen]);

    useEffect(() => {
        setChaveErro(props.errorChave);
    }, [props.errorChave])

    useEffect(() => {
        if (!tituloErro && !contextoErro && !chaveErro && nivel.keyNivel != undefined) {
            setInativaGravar(false);
        } else {
            setInativaGravar(true);
        }
    }, [nivel, tituloErro, contextoErro, chaveErro])

    useEffect(() => {
        setNivel({
            ...nivel,
            categoria: props.categoriaPai,
            nivelPai: props.nivelPai
        })
    }, [props.nivelPai, props.categoriaPai])

    const handleRender = (nivelData: ChatBotNivelObj) => {
        if (nivelData) {
            setIsEdicao(true)
            setNivel({
                ...nivelData,
            });

            let obj = {};
            if(nivelData.categoria){
                obj = {
                    idCategoria: nivelData.categoria.idCategoria 
                };
            }
            if(nivelData.nivelPai){
                obj = {
                    idNivelPai: nivelData.nivelPai.idNivel
                };
            }

            props.getQtdChaves(obj);

            setConteudoContexto('');
            setIsConteudoInterno(nivelData.idConteudoInterno != null || nivelData.linkConteudoContexto == null);
            setExibeFeedback(nivelData.shouldShowFeedback || false);
        
        }
    }

    const handleClose = () => {
        setIsEdicao(false);
        setNivel({ linksAuxiliares: [] });
        setConteudoContexto('');
        setInativaGravar(true);
        setTituloErro(false);
        setContextoErro(false);
        setChaveErro(false);
        setIsConteudoInterno(true);
        setExibeFeedback(false);

        props.closeModal();
    }

    const handlePersist = (e: any) => {
        e.preventDefault();

        const formNivel = new FormData();

        if (nivel.linksAuxiliares?.length == 1) {
            if (!(nivel.linksAuxiliares[0].descricao && nivel.linksAuxiliares[0].link)) {
                nivel.linksAuxiliares = undefined;
            }
        }

        formNivel.append('nivel', JSON.stringify(nivel));

        if (conteudoContexto != '') {
            formNivel.append('arquivo', conteudoContexto);
        }

        props.persist(isEdicao, formNivel);
    }

    const handleAddLinkAuxiliar = () => {
        if (nivel.linksAuxiliares) {
            if (nivel.linksAuxiliares.length == 1 && !nivel.linksAuxiliares[0].descricao && !nivel.linksAuxiliares[0].link) {
                alert(
                    "Atenção\nVocê precisa preencher pelo menos um link auxiliar para adicionar outros!"
                );
                return;
            }

            setNivel({
                ...nivel,
                linksAuxiliares: [...nivel.linksAuxiliares, {}],
            });
        }
    };

    const handleExcluiLinkAuxiliar = (idx: any) => {
        const aux = nivel.linksAuxiliares;

        if (aux) {
            if (aux[idx].idLinkAuxiliar) {
                props.onDeleteLinkAuxiliar(aux[idx].idLinkAuxiliar);
            }
            if (aux.length == 1) {
                setNivel({
                    ...nivel,
                    linksAuxiliares: [],
                });
                return;
            }

            aux.splice(idx, 1);
            setNivel({
                ...nivel,
                linksAuxiliares: [...aux],
            });
        }
    };

    return (
        <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
            if (res !== 'backdropClick') {
                handleClose()
            }
        }}>
            <Box sx={style} >
                <div className="modalHead">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isEdicao ? 'Editar Nível' : 'Cadastrar Nível'}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Fechar
                    </Button>
                </div>
                <Divider />
                <form className="modalMid-form" onSubmit={handlePersist}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} >
                            <TextField
                                required
                                sx={{ width: '100%' }}
                                value={nivel.titulo ?? ""}
                                label="Titulo do Nível"
                                error={tituloErro}
                                helperText={tituloErro ? "Campo Obrigatório." : ''}
                                onChange={(e) => {
                                    setTituloErro(e.target.value === "");
                                    setNivel({
                                        ...nivel,
                                        titulo: e.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl sx={{ width: '100%' }} >
                                <TextareaAutosize
                                    style={{ width: '100%', }}
                                    value={nivel.contexto ?? ""}
                                    minRows={5}
                                    placeholder="Contexto do Nível"
                                    onChange={(e) => {
                                        setContextoErro(e.target.value === "");
                                        setNivel({
                                            ...nivel,
                                            contexto: e.target.value
                                        })
                                    }}
                                />
                                {contextoErro ? <FormHelperText error={contextoErro}>Campo Obrigatório.</FormHelperText> : null}
                            </FormControl>

                        </Grid>
                        {!props.isSubNivel ? 
                        <Grid item xs={4}>
                            <FormControl sx={{ width: '100%' }} >
                                <TextField
                                    sx={{ width: '100%' }}
                                    value={nivel.categoria?.titulo ?? ""}
                                    label="Categoria"
                                    disabled
                                />
                            </FormControl>
                        </Grid> :
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ width: '100%' }}
                                    value={nivel.nivelPai?.titulo ?? ""}
                                    label="Nível Pai"
                                    disabled
                                />
                            </Grid>}
                        <Grid item xs={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-controlled-open-select-label">
                                    Chave de seleção do Nível
                                </InputLabel>
                                <Select
                                    value={nivel.keyNivel ?? ""}
                                    label="Chave de seleção do Nível"
                                    onChange={(e) => {
                                        setChaveErro(e.target.value === "");
                                        if (e.target.value !== "") {
                                            let obj = {
                                                categoria: nivel.categoria,
                                                nivelPai: nivel.nivelPai,
                                                chave: e.target.value,
                                                idNivel: nivel.idNivel ?? null
                                            }
                                            props.onChangeChave(EntidadesAcoes.NIVEL, obj)
                                            setNivel({
                                                ...nivel,
                                                keyNivel: parseInt(e.target.value.toString())
                                            })
                                        }
                                    }}
                                >
                                    {[...Array(props.qtdChave)].map((item: any, _idx: any) => (
                                        <MenuItem
                                            key={`listChave-${_idx}`}
                                            value={_idx + 1}
                                        >
                                            {_idx + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {chaveErro ? <FormHelperText error={chaveErro}>Campo Obrigatório.</FormHelperText> : null}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} >
                            <FormControl>
                                <FormLabel>Exibir feedback do Conteúdo</FormLabel>
                                <RadioGroup
                                    row
                                    value={exibeFeedback}
                                    onChange={(e) => {
                                        setExibeFeedback(JSON.parse(e.target.value));
                                        setNivel({
                                            ...nivel,
                                            shouldShowFeedback: JSON.parse(e.target.value)
                                        })
                                    }}
                                    >
                                    <FormControlLabel value={true} control={<Radio />} label="Sim" />
                                    <FormControlLabel value={false} control={<Radio />} label="Não" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} >
                            <FormControl>
                                <FormLabel>Conteúdo de Contexto</FormLabel>
                                <RadioGroup
                                    row
                                    value={isConteudoInterno}
                                    onChange={(e) => {
                                        setIsConteudoInterno(JSON.parse(e.target.value));
                                    }}
                                    >
                                    <FormControlLabel value={true} control={<Radio />} label="Conteúdo Interno" />
                                    <FormControlLabel value={false} control={<Radio />} label="Upload de Conteúdo" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {
                            isConteudoInterno ? (
                                <Grid item xs={7}>
                                    <FormControl style={{ width: "100%", display: "flex", justifyContent: "center"}}>
                                        <Typography id="modal-modal-title" variant="inherit" component="p">
                                            Conteúdo Interno
                                        </Typography>
                                        <ListaConteudoComponent
                                            conteudos={{idConteudo: nivel.idConteudoInterno}}
                                            multiple={false}
                                            onChangeConteudos={(value: any) => {
                                                setNivel({
                                                    ...nivel, 
                                                    idConteudoInterno: value[0] ? value[0].idConteudo : null,
                                                    linkConteudoContexto: null
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={7} >
                                    {
                                        isEdicao && nivel.linkConteudoContexto && conteudoContexto == '' ? (
                                            <FormControl sx={{ width: '100%' }} >
                                                <Typography id="modal-modal-title" variant="inherit" component="p">
                                                    Conteúdo de contexto
                                                </Typography>
                                                <div>
                                                    <Link className="buttonArquivo" href={nivel.linkConteudoContexto} underline="none" target="_blank" >
                                                        <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                                                    </Link>
                                                    <Button variant="text" color="error" onClick={() => {
                                                        setNivel({
                                                            ...nivel,
                                                            linkConteudoContexto: null
                                                        })
                                                    }}>Remover</Button>
                                                </div>
                                            </FormControl>
                                        ) : (
                                            <FormControl sx={{ width: '100%' }} >
                                                <Typography id="modal-modal-title" variant="inherit" component="p">
                                                    Conteúdo de contexto
                                                </Typography>
                                                <Input type='file' onChange={(e: any) => {
                                                    if (Array.from(e.target.files).length !== 0) {
                                                        if (validaNomeArquivo(e.target.files[0].name)) {
                                                            setNivel({
                                                                ...nivel,
                                                                linkConteudoContexto: e.target.files[0].name || '',
                                                                idConteudoInterno: null
                                                            })
                                                            setConteudoContexto(e.target.files[0])
                                                        } else {
                                                            e.target.value = "";
                                                        }
                                                    } else {
                                                        e.target.value = "";
                                                    }
                                                }} />
                                            </FormControl>
                                        )
                                    }
                                </Grid>
                            )
                        }
                        <Grid item xs={12} >
                            <FormControl sx={{ width: '100%' }} >
                                <Typography id="modal-modal-title" variant="inherit" component="p">
                                    Link(s) auxiliar(es)
                                </Typography>
                                <div>
                                    <List className="list-link-aux">
                                        {
                                            nivel.linksAuxiliares ? nivel.linksAuxiliares.map((value: ChatBotLinkAuxiliarDTO, index: number) => (
                                                <ListItem key={`list-link-aux-${index}`}>
                                                    <TextField
                                                        sx={{ width: '40%', }}
                                                        value={value.descricao ?? ""}
                                                        label="Descrição Link Auxiliar"
                                                        onChange={(e) => {
                                                            if (nivel.linksAuxiliares) {
                                                                let auxArr = nivel.linksAuxiliares;
                                                                auxArr[index].descricao = e.target.value;
                                                                setNivel({
                                                                    ...nivel,
                                                                    linksAuxiliares: auxArr
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        sx={{ width: '40%', marginLeft: "1em" }}
                                                        value={value.link ?? ""}
                                                        label="Link Auxiliar"
                                                        onChange={(e) => {
                                                            if (nivel.linksAuxiliares) {
                                                                let auxArr = nivel.linksAuxiliares;
                                                                auxArr[index].link = e.target.value;
                                                                setNivel({
                                                                    ...nivel,
                                                                    linksAuxiliares: auxArr
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <FormControl sx={{ marginLeft: "1em" }}>
                                                        <div>
                                                            {!(
                                                                nivel.linksAuxiliares &&
                                                                nivel.linksAuxiliares.length == 1
                                                            ) || value.idLinkAuxiliar ? (
                                                                <IconButton
                                                                    color="secondary"
                                                                    onClick={() => handleExcluiLinkAuxiliar(index)}
                                                                    size="large"
                                                                >
                                                                    <Icon>clear</Icon>
                                                                </IconButton>
                                                            ) : null}
                                                            <IconButton
                                                                sx={{ marginLeft: "0.5em" }}
                                                                color="success"
                                                                onClick={handleAddLinkAuxiliar}
                                                                size="large"
                                                            >
                                                                <Icon>add</Icon>
                                                            </IconButton>
                                                        </div>
                                                    </FormControl>
                                                </ListItem>
                                            )) : null
                                        }
                                    </List>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: '1em' }} />

                    <div className="cadastroFooter">
                        <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
                    </div>
                </form>
            </Box>

        </Modal>
    )

};

export default ModalCadastroNivel;