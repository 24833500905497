import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getStoryDataFailure,
    getStoryDataSuccess,
    getStoryTableFailure,
    getStoryTableSuccess,
    onAtivarStoryFailure,
    onAtivarStorySuccess,
    onInativarStoryFailure,
    onInativarStorySuccess,
    onSaveStoryFailure, 
    onSaveStorySuccess,
    onUpdateStoryFailure,
    onUpdateStorySuccess,
    onNotificacaoStoryFailure,
    onNotificacaoStorySuccess,
} from "./actions";

function* getStoryTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('story/listAllStoryTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getStoryTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getStoryTableFailure(error.message));
    }
};


function* onInativarStory(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('story/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarStorySuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarStoryFailure(error.message));
    }
};

function* onAtivarStory(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('story/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarStorySuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarStoryFailure(error.message));
    }
};

function* getStoryData(data: any): any {
    const idStory = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`story/findById?idStory=${idStory}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getStoryDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getStoryDataFailure(error.message));
    }
};

function* onSaveStory(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('story/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveStorySuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveStoryFailure(error.message));
    }
};

function* onUpdateStory(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('story/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateStorySuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateStoryFailure(error.message));
    }
};

function* onNotificacaoStory(data: any): any {
    const idStory = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`story/notificaStory?idStory=${idStory}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoStorySuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoStoryFailure(error.message));
    }
};

export {
    getStoryData,
    getStoryTable,
    onAtivarStory,
    onInativarStory,
    onSaveStory,
    onUpdateStory,
    onNotificacaoStory,
}
