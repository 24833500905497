import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import "dayjs/locale/pt-br";

import { SubclusterObj } from "../../store/ducks/subcluster/types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultSubcluster: SubclusterObj = {
  nmSubcluster: "",
  dsSubcluster: "",
  idCluster: "",
  idSubcluster: "",
};

const ModalCadastroSubcluster = (props: any) => {
  const [isEdicao, setIsEdicao] = useState(false);
  const [subcluster, setSubcluster] = useState(defaultSubcluster);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [nmSubclusterError, setNmSubclusterError] = useState(false);
  const [dsSubclusterError, setDsSubclusterError] = useState(false);
  const [clusterError, setClusterError] = useState(false);

  useEffect(() => {
    if (props.subclustersData) {
      const subclusterObj = {
        idSubcluster: props.subclustersData.idSubcluster,
        nmSubcluster: props.subclustersData.nmSubcluster,
        dsSubcluster: props.subclustersData.dsSubcluster,
        //idCluster: props.subclustersData.cluster.idCluster,
      };
      handleRender(subclusterObj);
    }
  }, [props.subclustersData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (
      !clusterError &&
      !nmSubclusterError &&
      !dsSubclusterError &&
      !!subcluster.dsSubcluster &&
      !!subcluster.nmSubcluster
    ) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [subcluster, nmSubclusterError, dsSubclusterError, clusterError]);

  const handleRender = (subclustersData: any) => {
    if (subclustersData) {
      setIsEdicao(true);
      setSubcluster({
        ...subclustersData,
      });
    }
  };

  const handleClose = () => {
    setIsEdicao(false);
    setInativaGravar(false);
    setNmSubclusterError(false);
    setDsSubclusterError(false);
    setClusterError(false);
    setSubcluster(defaultSubcluster);

    props.closeModal();
  };

  const handlePersist = (e: any) => {
    e.preventDefault();
    const subclusterObj = {
      idSubcluster: subcluster.idSubcluster,
      nmSubcluster: subcluster.nmSubcluster,
      dsSubcluster: subcluster.dsSubcluster,
      /* cluster: {
        idCluster: subcluster.idCluster,
      }, */
    };
    props.persistSubcluster(isEdicao, subclusterObj);
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? "Editar Subcluster" : "Cadastro de novo Subcluster"}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={subcluster.nmSubcluster}
                label="Nome do Subcluster"
                error={nmSubclusterError}
                helperText={nmSubclusterError ? "Campo Obrigatório." : ""}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setNmSubclusterError(e.target.value === "");
                  setSubcluster({
                    ...subcluster,
                    nmSubcluster: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={subcluster.dsSubcluster}
                label="Descrição do Subcluster"
                error={dsSubclusterError}
                helperText={dsSubclusterError ? "Campo Obrigatório." : ""}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDsSubclusterError(e.target.value === "");
                  setSubcluster({
                    ...subcluster,
                    dsSubcluster: e.target.value,
                  });
                }}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Cluster
                </InputLabel>
                <Select
                  required
                  error={clusterError}
                  value={subcluster.idCluster}
                  label="Cluster"
                  defaultValue={""}
                  onChange={(e) => {
                    setClusterError(e.target.value === "");
                    setSubcluster({
                      ...subcluster,
                      idCluster: parseInt(e.target.value.toString()),
                    });
                  }}
                >
                  <MenuItem value="">Cluster...</MenuItem>

                  {props.listAllClusters
                    ? props.listAllClusters.map((item: any, idx: any) => (
                        <MenuItem
                          key={"clusTipo-" + idx}
                          value={item.idCluster}
                        >
                          {item.nmCluster}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {clusterError ? (
                  <FormHelperText error={clusterError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
          </Grid>
          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">
              {isEdicao ? "Gravar" : "Cadastrar"}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroSubcluster;
