import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

import * as JustificativaActions from "../../store/ducks/justificativa/actions";
import { ApplicationState } from "../../store";

function ListaTipoJustificativa({
  justificativa,
  getJustificativaTipoList,
  tiposJustificativa,
  onChangeTipoJustificativa,
  error,
}: any) {
  const [tipoJustificativasList, setTipoJustificativasList] =
    React.useState<any>([]);
  const [tiposJustificativaSelecionados, setTiposJustificativaSelecionados] =
    React.useState<any>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [lazyItems, setLazyItems] = React.useState<any>([]);
  const [lazyLoading, setLazyLoading] = React.useState(false);

  const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(
    null
  );

  React.useEffect(() => {
    getJustificativaTipoList();
  }, []);

  React.useEffect(() => {
    setTipoJustificativasList(justificativa.data.justificativaTipo);
    if (justificativa.data.justificativaTipo.length > 0) {
      setSelectAll(
        tiposJustificativa.length == justificativa.data.justificativaTipo.length
      );
    }
  }, [justificativa.data.justificativaTipo]);

  React.useEffect(() => {
    if (tiposJustificativa) {
      setTiposJustificativaSelecionados(
        tiposJustificativa.map((item: any) => item.idTipoJustificativa)
      );
    }
  }, [tiposJustificativa]);

  const onLazyLoad = (event: any) => {
    setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, 300);
  };

  return (
    <MultiSelect
      style={{
        width: "100%",
      }}
      value={tiposJustificativaSelecionados}
      options={tipoJustificativasList}
      display="chip"
      onChange={(e) => {
        if (e.value) {
          setTiposJustificativaSelecionados(e.value);
          setSelectAll(e.value.length === tipoJustificativasList.length);
          onChangeTipoJustificativa(
            tipoJustificativasList.filter((esta: any) =>
              e.value.includes(esta.idTipoJustificativa)
            )
          );
        } else {
          setTiposJustificativaSelecionados([]);
          setSelectAll(false);
          onChangeTipoJustificativa([]);
        }
      }}
      selectAll={selectAll}
      optionLabel="dsTipoJustificativa"
      optionValue="idTipoJustificativa"
      placeholder="Selecione os Tipos"
      filter
      showClear={true}
      className={error ? "p-multiselect-error" : ""}
      maxSelectedLabels={3}
      selectedItemsLabel={`${
        tiposJustificativaSelecionados
          ? tiposJustificativaSelecionados.length
          : 0
      } tipos selecionados`}
      emptyFilterMessage="Não encontrado"
      filterPlaceholder="Busque por um tipo"
      resetFilterOnHide={true}
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: onLazyLoad,
        itemSize: 43,
        showLoader: true,
        loading: lazyLoading,
        delay: 1,
        loadingTemplate: (options) => {
          return (
            <div
              className="flex align-items-center p-2"
              style={{ height: "43px" }}
            >
              <Skeleton width={options.even ? "70%" : "60%"} height="1.5rem" />
            </div>
          );
        },
      }}
    />
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  justificativa: state.justificativa,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...JustificativaActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaTipoJustificativa);
