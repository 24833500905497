import { ClusterObj } from "../cluster/types"
import { GrupoObj } from "../grupo/types"
import { SubclusterObj } from "../subcluster/types"

export enum MunicipioTypes {
    MUNICIPIO_TABLE_REQUEST = 'municipio/MUNICIPIO_TABLE_REQUEST',
    MUNICIPIO_TABLE_REQUEST_SUCCESS = 'municipio/MUNICIPIO_TABLE_RQUEST_SUCCESS',
    MUNICIPIO_TABLE_REQUEST_FAILURE = 'municipio/MUNICIPIO_TABLE_REQUEST_FAILURE',

    CLEAR_MUNICIPIO_DATA = 'municipio/CLEAR_MUNICIPIO_DATA',

    MUNICIPIO_DATA_REQUEST = 'municipio/MUNICIPIO_DATA_REQUEST',
    MUNICIPIO_DATA_REQUEST_SUCCESS = 'municipio/MUNICIPIO_DATA_REQUEST_SUCCESS',
    MUNICIPIO_DATA_REQUEST_FAILURE = 'municipio/MUNICIPIO_DATA_REQUEST_FAILURE',

    MUNICIPIO_SAVE_REQUEST = 'municipio/MUNICIPIO_SAVE_REQUEST',
    MUNICIPIO_SAVE_REQUEST_SUCCESS = 'municipio/MUNICIPIO_SAVE_REQUEST_SUCCESS',
    MUNICIPIO_SAVE_REQUEST_FAILURE = 'municipio/MUNICIPIO_SAVE_REQUEST_FAILURE',
}

export interface MunicipioObjTable {
    id?: number,
    idMunicipio?: string | number
    nmMunicipio?: string
    nmEstado?: string
    nmGrupo?: string
    nmCluster?: string
    nmSubCluster?: string
}


export interface MunicipioObj {
    idMunicipio?: string | number
    nmMunicipio?: string
    estado?: {
        idEstado: number
        nmEstado: number
        dsEstado: number
    }
    regional?: {
        idRegional: number
        nmRegional: number
        dsRegional: number
    }
    grupo?: GrupoObj
    cluster?: ClusterObj
    subcluster?: SubclusterObj
}


export interface MunicipioTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface MunicipioPage {
    readonly municipio: MunicipioObj | null,
    readonly table: MunicipioTable | null,
}

export interface MunicipioState {
    readonly data: MunicipioPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
}