import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getAvaliacaoDataFailure,
    getAvaliacaoDataSuccess,
    getAvaliacaoTableFailure,
    getAvaliacaoTableSuccess,
    getAvaliacaoTipoListFailure,
    getAvaliacaoTipoListSuccess,
    onAtivarAvaliacaoFailure,
    onAtivarAvaliacaoSuccess,
    onInativarAvaliacaoFailure,
    onInativarAvaliacaoSuccess,
    onSaveAvaliacaoFailure, 
    onSaveAvaliacaoSuccess,
    onUpdateAvaliacaoFailure,
    onUpdateAvaliacaoSuccess,
    onImportarAvalicaoPerguntaERespostaFailure,
    onImportarAvalicaoPerguntaERespostaSuccess,
    onPersistAvaliacaoPerguntaFailure,
    onPersistAvaliacaoPerguntaSuccess,
    onPersistPerguntaRespostaFailure,
    onPersistPerguntaRespostaSuccess,
    onEnviaNotificacaoAvaliacaoFailure,
    onEnviaNotificacaoAvaliacaoSuccess
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getAvaliacaoTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('avaliacao/listAllAvaliacaoTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        
        yield put(getAvaliacaoTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getAvaliacaoTableFailure(error.message));
    }
};

function* onInativarAvaliacao(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('avaliacao/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarAvaliacaoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarAvaliacaoFailure(error.message));
    }
};

function* onAtivarAvaliacao(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('avaliacao/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarAvaliacaoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarAvaliacaoFailure(error.message));
    }
};

function* getAvaliacaoData(data: any): any {
    const idAvaliacao = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`avaliacao/findById?idAvaliacao=${idAvaliacao}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getAvaliacaoDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getAvaliacaoDataFailure(error.message));
    }
};

function* onSaveAvaliacao(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('avaliacao/save', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveAvaliacaoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveAvaliacaoFailure(error.message));
    }
};

function* onUpdateAvaliacao(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('avaliacao/update', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
    }

    try {
        yield put(onUpdateAvaliacaoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateAvaliacaoFailure(error.message));
    }
};

function* onPersistAvaliacaoPergunta(data: any): any {
    const body = data.payload.body;
    const showAlert = data.payload.showAlert;
    const idAvaliacao = data.payload.idAvaliacao;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`avaliacao/persistAvaliacaoPergunta?idAvaliacao=${idAvaliacao}`, body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then( res => ({
            data: res.data,
            showAlert
        }))
    }

    try {
        yield put(onPersistAvaliacaoPerguntaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onPersistAvaliacaoPerguntaFailure(error.message));
    }
};

function* onPersistPerguntaResposta(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('avaliacao/persistAvaliacaoPerguntaResposta', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then( res => res.data)
    }

    try {
        yield put(onPersistPerguntaRespostaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onPersistPerguntaRespostaFailure(error.message));
    }
};

function* getAvaliacaoTipoList(): any {
    const token = localStorage.getItem('accessToken');

    const doRequest = () => {
        return api.get('avaliacao/listAvaliacaoTipo', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getAvaliacaoTipoListSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getAvaliacaoTipoListFailure(error.message));
    }
}

function* onImportarAvalicaoPerguntaEResposta(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('avaliacao/importarPerguntasERespostas', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
            responseType: 'blob'
        }).then( async (res) => {
            const blobTxt = await new Blob([res.data]).text();
            if(blobTxt == "Todas as perguntas e respostas foram importadas!" ){
                alert(blobTxt);
                return;
            }
            alert("Atenção\nA importação contem erros! Por Favor Verifique os dados no arquivo que foi retornado!");
            const url = window.URL.createObjectURL(new Blob([res.data],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',
            res.headers["content-disposition"].split("filename=")[1]);
            document.body.appendChild(link);
            link.click();
        })
        
    }

    try {

        yield put(onImportarAvalicaoPerguntaERespostaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onImportarAvalicaoPerguntaERespostaFailure(error.message));
    }
};

function* onEnviaNotificacaoAvaliacao(data: any): any {
    const idAvaliacao = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`avaliacao/notificaAvaliacao?idAvaliacao=${idAvaliacao}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onEnviaNotificacaoAvaliacaoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onEnviaNotificacaoAvaliacaoFailure(error.message));
    }
};


export {
    getAvaliacaoData,
    getAvaliacaoTable,
    onAtivarAvaliacao,
    onInativarAvaliacao,
    onSaveAvaliacao,
    onUpdateAvaliacao,
    getAvaliacaoTipoList,
    onImportarAvalicaoPerguntaEResposta,
    onPersistAvaliacaoPergunta,
    onPersistPerguntaResposta,
    onEnviaNotificacaoAvaliacao
}
