import { Reducer } from "@reduxjs/toolkit";
import { ClusterState, ClusterTypes } from "./types";

const INITIAL_STATE: ClusterState = {
    data: {
        table: null,
        cluster: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<ClusterState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ClusterTypes.CLUSTER_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case ClusterTypes.CLUSTER_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case ClusterTypes.CLUSTER_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };


        case ClusterTypes.CLUSTER_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case ClusterTypes.CLUSTER_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case ClusterTypes.CLUSTER_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };



        case ClusterTypes.CLUSTER_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ClusterTypes.CLUSTER_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    cluster: action.payload.data
                }
            };
        case ClusterTypes.CLUSTER_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    cluster: null
                }
            };


        case ClusterTypes.CLEAR_CLUSTER_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    cluster: null
                }
            };

        case ClusterTypes.CLUSTER_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case ClusterTypes.CLUSTER_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Cluster realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    cluster: action.payload.data
                }
            };
        case ClusterTypes.CLUSTER_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case ClusterTypes.CLUSTER_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case ClusterTypes.CLUSTER_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Cluster realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    cluster: action.payload.data
                }
            };
        case ClusterTypes.CLUSTER_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };


        default:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
};

export default reducer;