export enum PerfilTypes {
    PERFIL_TABLE_REQUEST = 'perfil/PERFIL_TABLE_REQUEST',
    PERFIL_TABLE_REQUEST_SUCCESS = 'perfil/PERFIL_TABLE_RQUEST_SUCCESS',
    PERFIL_TABLE_REQUEST_FAILURE = 'perfil/PERFIL_TABLE_REQUEST_FAILURE',

    PERFIL_ATIVA_REQUEST = 'perfil/PERFIL_ATIVA_REQUEST',
    PERFIL_ATIVA_REQUEST_SUCCESS = 'perfil/PERFIL_ATIVA_REQUEST_SUCCESS',
    PERFIL_ATIVA_REQUEST_FAILURE = 'perfil/PERFIL_ATIVA_REQUEST_FAILURE',

    PERFIL_INATIVA_REQUEST = 'perfil/PERFIL_INATIVA_REQUEST',
    PERFIL_INATIVA_REQUEST_SUCCESS = 'perfil/PERFIL_INATIVA_REQUEST_SUCCESS',
    PERFIL_INATIVA_REQUEST_FAILURE = 'perfil/PERFIL_INATIVA_REQUEST_FAILURE',
    
    CLEAR_PERFIL_DATA = 'perfil/CLEAR_PERFIL_DATA',
    
    PERFIL_DATA_REQUEST = 'perfil/PERFIL_DATA_REQUEST',
    PERFIL_DATA_REQUEST_SUCCESS = 'perfil/PERFIL_DATA_REQUEST_SUCCESS',
    PERFIL_DATA_REQUEST_FAILURE = 'perfil/PERFIL_DATA_REQUEST_FAILURE',

    PERFIL_SAVE_REQUEST = 'perfil/PERFIL_SAVE_REQUEST',
    PERFIL_SAVE_REQUEST_SUCCESS = 'perfil/PERFIL_SAVE_REQUEST_SUCCESS',
    PERFIL_SAVE_REQUEST_FAILURE = 'perfil/PERFIL_SAVE_REQUEST_FAILURE',

    PERFIL_UPDATE_REQUEST = 'perfil/PERFIL_UPDATE_REQUEST',
    PERFIL_UPDATE_REQUEST_SUCCESS = 'perfil/PERFIL_UPDATE_REQUEST_SUCCESS',
    PERFIL_UPDATE_REQUEST_FAILURE = 'perfil/PERFIL_UPDATE_REQUEST_FAILURE',

    PERFIL_EXISTENTE_REQUEST = 'perfil/PERFIL_EXISTENTE_REQUEST',
    PERFIL_EXISTENTE_REQUEST_SUCCESS = 'perfil/PERFIL_EXISTENTE_REQUEST_SUCCESS',
    PERFIL_EXISTENTE_REQUEST_FAILURE = 'perfil/PERFIL_EXISTENTE_REQUEST_FAILURE',
}

export interface PerfilObjTable {
    id?: number,
    idPerfil?: number,
    dsPerfil?: string,
    nmPerfil?: string,
}

export interface PerfilTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface PerfilPage {
    readonly perfil: any | null,
    readonly table: PerfilTable | null,
}

export interface PerfilState {
    readonly data: PerfilPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}