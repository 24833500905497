import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import * as PerfilAction from '../../store/ducks/perfil/actions';

import AcaoMenu from "../../Components/AcaoMenu";
import PerfilTableComponent from "../../Components/TablePerfil";
import { ApplicationState } from "../../store";
import ModalCadastroPerfil from "../../Components/ModalCadastroPerfil";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmPerfil",
    txtBusca: ""
};

function Perfil({
    perfil,
    getPerfilTable,
    getPerfilData,
    onInativarPerfil,
    onSavePerfil,
    onUpdatePerfil,
    clearPerfilData,
    getPerfilExistente
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(perfil.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + perfil.errorMessage);
        }
    }, [perfil.error]);
    
    useEffect(() => {
        if(!perfil.loadingPersis) {
            handleCloseAndReload()
        }
    }, [perfil.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getPerfilTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(perfil.data.table != null){
            setTable(perfil.data.table);
        }
    }, [perfil.data]);

    useEffect(() => {
        if(!perfil.loadingInativacao){
            getPerfilTable(body);
        }
    }, [perfil.loadingInativacao]);

    const getPerfilTableData = () => {
        getPerfilTable(body);
    }

    const handleCadastro = () => {
        clearPerfilData();
        setModalCadastroOpen(true);
        setIdSelecionado([]);
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione um perfil que gostaria de alterar');
        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas um perfil para alteração!');
        } else {
            getPerfilData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione os temas que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.PERFIL_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarPerfil(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersitPerfil = (edicao: boolean, perfil: any) => {
        if(edicao) {
            onUpdatePerfil(perfil);
        } else {
            onSavePerfil(perfil);
        }
    }

    const handleCloseAndReload = () => {
        clearPerfilData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={perfil.loading || perfil.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroPerfil 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload() } 
                perfilData={perfil.data.perfil}
                persistPerfil={handlePersitPerfil}
                buscaPerfilExistente={(per: any) => getPerfilExistente(per)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <PerfilTableComponent 
                    loading={perfil.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};


const mapStateToProps = (state: ApplicationState) => ({
    perfil: state.perfil,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...PerfilAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Perfil);