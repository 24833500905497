import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'
import { api } from "../../services";

import { SegurancaNoTrabalhoObj } from "../../store/ducks/segurancaNoTrabalho/types";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaMunicipioComponentTeste from "../ListaMunicipioComponentTeste";
import ListaTemaComponent from "../ListaTemaComponent";
import ListaAvaliacaoComponent from "../ListaAvaliacaoComponent";
import ListaConteudoComponent from "../ListaConteudoComponent";
import TabPanel from "../TabPanel";
import { FormHelperText, InputLabel, List, ListItem, MenuItem, Select } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const defaultSegurancaNoTrabalho: SegurancaNoTrabalhoObj = {
  nmSegurancaNoTrabalho: '',
  dsSegurancaNoTrabalho: '',
  dsTempoEstimado: '',
  imgSegurancaNoTrabalho: '',
  urlCompartilhamentoSegurancaNoTrabalho: '',
  icExibirCarrossel: 1,
  dtSegurancaNoTrabalhoInicio: null,
  dtSegurancaNoTrabalhoFim: null,
  tema: [],
  segurancaNoTrabalhoMuncipios: [],
  segurancaNoTrabalhoPerfis: [],
  segurancaNoTrabalhoAvaliacoes: [],
  segurancaNoTrabalhoConteudos: []
};

const ModalCadastroSegurancaNoTrabalho = (props: any) => {

  const [isEdicao, setIsEdicao] = useState(false);
  const [segurancaNoTrabalho, setSegurancaNoTrabalho] = useState(defaultSegurancaNoTrabalho);
  const [capaSegurancaNoTrabalho, setCapaSegurancaNoTrabalho] = useState('');
  const [municipios_, setMunicipios_] = useState([]);
  const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
  const [conteudosOrdenados, setConteudoOrdenados] = useState([]);
  const [tab, setTab] = useState(0);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [imgSegurancaNoTrabalhoErro, setImgSegurancaNoTrabalhoErro] = useState(false);
  const [nmSegurancaNoTrabalhoErro, setNmSegurancaNoTrabalhoErro] = useState(false);
  const [dsSegurancaNoTrabalhoErro, setDsSegurancaNoTrabalhoErro] = useState(false);
  const [dtIniSegurancaNoTrabalhoErro, setDtIniSegurancaNoTrabalhoErro] = useState(false);
  const [dtFimSegurancaNoTrabalhoErro, setDtFimSegurancaNoTrabalhoErro] = useState(false);
  const [dsTempoEstimadoErro, setDsTempoEstimadoErro] = useState(false);
  const [perfilErro, setPerfilErro] = useState(false);
  const [municipioErro, setMunicipioErro] = useState(false);
  const [temaErro, setTemaErro] = useState(false);
  const [conteudoErro, setConteudoErro] = useState(false);

  useEffect(() => {
    if (props.segurancaNoTrabalhoData) {
      handleMunicipioData();
      handleRender(props.segurancaNoTrabalhoData);
    }
  }, [props.segurancaNoTrabalhoData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (!imgSegurancaNoTrabalhoErro && !nmSegurancaNoTrabalhoErro && !dsSegurancaNoTrabalhoErro && !dtIniSegurancaNoTrabalhoErro && !dtFimSegurancaNoTrabalhoErro && !dsTempoEstimadoErro && !perfilErro && !municipioErro && !temaErro && !conteudoErro
      && segurancaNoTrabalho.imgSegurancaNoTrabalho !== '' && segurancaNoTrabalho.segurancaNoTrabalhoMuncipios.length !== 0 && segurancaNoTrabalho.nmSegurancaNoTrabalho !== '' && segurancaNoTrabalho.dsSegurancaNoTrabalho !== '' && segurancaNoTrabalho.dtSegurancaNoTrabalhoInicio !== null && segurancaNoTrabalho.dtSegurancaNoTrabalhoFim !== null
      && segurancaNoTrabalho.dsTempoEstimado !== '' && segurancaNoTrabalho.tema.length !== 0 && segurancaNoTrabalho.segurancaNoTrabalhoPerfis.length !== 0 && segurancaNoTrabalho.segurancaNoTrabalhoConteudos.length !== 0) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [segurancaNoTrabalho, imgSegurancaNoTrabalhoErro, nmSegurancaNoTrabalhoErro, dsSegurancaNoTrabalhoErro, dtIniSegurancaNoTrabalhoErro, dtFimSegurancaNoTrabalhoErro, dsTempoEstimadoErro, perfilErro, municipioErro, temaErro, conteudoErro])


  const validaOrdenacao = (array: any) => {
    let ordenado = array.map((item: any, idx: number) => {
      let obj = conteudosOrdenados.find((_item: any) => _item.idConteudo == item.idConteudo);
      if (obj != undefined) {
        item = obj;
      } else {
        item.ordemConteudo = conteudosOrdenados.length + 1;
      }
      return item;
    });

    setConteudoOrdenados(ordenado);
    setSegurancaNoTrabalho({
      ...segurancaNoTrabalho,
      segurancaNoTrabalhoConteudos: ordenado,
    });
  }

  const handleRender = (segurancaNoTrabalhoData: any) => {
    if (segurancaNoTrabalhoData) {
      delete segurancaNoTrabalhoData.tema.dsTema;
      setIsEdicao(true)
      setSegurancaNoTrabalho({
        ...segurancaNoTrabalhoData
      });
      setCapaSegurancaNoTrabalho('');
      setConteudoOrdenados(segurancaNoTrabalhoData.segurancaNoTrabalhoConteudos);
    }
  }

  const handleClose = () => {
    setIsEdicao(false);
    setSegurancaNoTrabalho(defaultSegurancaNoTrabalho);
    setCapaSegurancaNoTrabalho('');
    setMunicipios_([]);
    setTab(0);
    setInativaGravar(true);
    setNmSegurancaNoTrabalhoErro(false);
    setDsSegurancaNoTrabalhoErro(false);
    setDtIniSegurancaNoTrabalhoErro(false);
    setDtFimSegurancaNoTrabalhoErro(false);
    setDsTempoEstimadoErro(false);
    setPerfilErro(false);
    setMunicipioErro(false);
    setTemaErro(false);
    setConteudoErro(false);
    setImgSegurancaNoTrabalhoErro(false);

    props.closeModal();
  }

  const handleMunicipioData = async () => {
    if(props.id != undefined){
      setMunicipios_(await getSegurancaNoTrabalhoDataMunicipioLimited(props.id));
    }
  }

  async function getSegurancaNoTrabalhoDataMunicipioTotal(data: any) {
    const idSegurancaNoTrabalho = data;
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`segurancaNoTrabalho/findMunicipioById?idSegurancaNoTrabalho=${idSegurancaNoTrabalho}`, {headers: {'Authorization': `Bearer ${token}`}})
        return response;
    } catch (error) {
        console.log(error);
    }
  }
    async function getSegurancaNoTrabalhoDataMunicipioLimited(data: any) {
      const idSegurancaNoTrabalho = data;
      const token = localStorage.getItem('accessToken');
      try {
          const { data: response } = await api.get(`segurancaNoTrabalho/findMunicipioByIdLimit?idSegurancaNoTrabalho=${idSegurancaNoTrabalho}`, {headers: {'Authorization': `Bearer ${token}`}})
          return response;
      } catch (error) {
          console.log(error);
      }
  };

  const handlePersist = async (e: any) => {
    e.preventDefault();
    if(segurancaNoTrabalho.segurancaNoTrabalhoMuncipios.length === 351 && isEdicao){
      segurancaNoTrabalho.segurancaNoTrabalhoMuncipios = await getSegurancaNoTrabalhoDataMunicipioTotal(props.id);
    }
    const formSegurancaNoTrabalho = new FormData();

    if (segurancaNoTrabalho.segurancaNoTrabalhoConteudos && segurancaNoTrabalho.segurancaNoTrabalhoConteudos.length > 0) {
      segurancaNoTrabalho.segurancaNoTrabalhoConteudos.forEach((conteudo: any, idx: number) => {
        if (conteudo.ordemConteudo == undefined || conteudo.ordemConteudo == 0) {
          conteudo.ordemConteudo = (idx + 1);
        }
      })
    }

    formSegurancaNoTrabalho.append('segurancaNoTrabalho', JSON.stringify(segurancaNoTrabalho));

    if (capaSegurancaNoTrabalho != '') {
      formSegurancaNoTrabalho.append('fileImg', capaSegurancaNoTrabalho);
    }

    props.persist(isEdicao, formSegurancaNoTrabalho);
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if (res !== 'backdropClick') {
        handleClose()
      }
    }}
    >
      <Box sx={style} >
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? 'Editar ' : 'Cadastro de nova Segurança do Trabalho'}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <TabPanel value={tab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6} >
                {
                  isEdicao && segurancaNoTrabalho.imgSegurancaNoTrabalho && capaSegurancaNoTrabalho == '' ? (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <div>
                        <Link className="buttonArquivo" href={segurancaNoTrabalho.imgSegurancaNoTrabalho} underline="none" target="_blank" >
                          <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                        </Link>
                        <Button variant="text" color="error" onClick={() => {
                          setImgSegurancaNoTrabalhoErro(true);
                          setSegurancaNoTrabalho({
                            ...segurancaNoTrabalho,
                            imgSegurancaNoTrabalho: ''
                          })
                        }}>Remover</Button>
                      </div>
                    </FormControl>
                  ) : (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <Input type='file' error={imgSegurancaNoTrabalhoErro} onChange={(e: any) => {
                        if (Array.from(e.target.files).length !== 0) {
                          if (validaNomeArquivo(e.target.files[0].name)) {
                            setImgSegurancaNoTrabalhoErro(Array.from(e.target.files).length === 0);
                            setSegurancaNoTrabalho({
                              ...segurancaNoTrabalho,
                              imgSegurancaNoTrabalho: e.target.files[0].name || ''
                            })
                            setCapaSegurancaNoTrabalho(e.target.files[0])
                          } else {
                            e.target.value = "";
                            setImgSegurancaNoTrabalhoErro(true);
                          }
                        } else {
                          e.target.value = "";
                          setImgSegurancaNoTrabalhoErro(true);
                        }
                      }} />
                      {imgSegurancaNoTrabalhoErro ? <FormHelperText error={imgSegurancaNoTrabalhoErro}>Campo Obrigatório.</FormHelperText> : null}
                    </FormControl>
                  )
                }
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Ocultar no carrossel"
                  checked={segurancaNoTrabalho.icExibirCarrossel === 0}
                  style={{ marginInline: 0 }}
                  onChange={() => {
                    setSegurancaNoTrabalho(state => ({
                      ...state,
                      icExibirCarrossel: state.icExibirCarrossel === 0 ? 1 : 0
                    }))
                  }} />
              </Grid>
              <Grid item xs={5} >
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={segurancaNoTrabalho.nmSegurancaNoTrabalho}
                  label="Nome da Segurança do Trabalho"
                  error={nmSegurancaNoTrabalhoErro}
                  helperText={nmSegurancaNoTrabalhoErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setNmSegurancaNoTrabalhoErro(e.target.value === "");
                    setSegurancaNoTrabalho({
                      ...segurancaNoTrabalho,
                      nmSegurancaNoTrabalho: e.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={segurancaNoTrabalho.dsSegurancaNoTrabalho}
                  label="Descrição da Segurança do Trabalho"
                  error={dsSegurancaNoTrabalhoErro}
                  helperText={dsSegurancaNoTrabalhoErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDsSegurancaNoTrabalhoErro(e.target.value === "");
                    setSegurancaNoTrabalho({
                      ...segurancaNoTrabalho,
                      dsSegurancaNoTrabalho: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtIniSegurancaNoTrabalhoErro(true);
                      } else {
                        setDtIniSegurancaNoTrabalhoErro(false);
                      }
                    }}
                    label="Data de Início"
                    value={segurancaNoTrabalho.dtSegurancaNoTrabalhoInicio}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtIniSegurancaNoTrabalhoErro(false);
                      setSegurancaNoTrabalho({
                        ...segurancaNoTrabalho,
                        dtSegurancaNoTrabalhoInicio: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtIniSegurancaNoTrabalhoErro} helperText={dtIniSegurancaNoTrabalhoErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtFimSegurancaNoTrabalhoErro(true);
                      } else {
                        setDtFimSegurancaNoTrabalhoErro(false);
                      }
                    }}
                    label="Data Final"
                    value={segurancaNoTrabalho.dtSegurancaNoTrabalhoFim}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtFimSegurancaNoTrabalhoErro(false);
                      setSegurancaNoTrabalho({
                        ...segurancaNoTrabalho,
                        dtSegurancaNoTrabalhoFim: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtFimSegurancaNoTrabalhoErro} helperText={dtFimSegurancaNoTrabalhoErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={segurancaNoTrabalho.dsTempoEstimado}
                  label="Duração em Minutos"
                  error={dsTempoEstimadoErro}
                  helperText={dsTempoEstimadoErro ? "Campo Obrigatório." : ''}
                  onChange={(e) => {
                    setDsTempoEstimadoErro(e.target.value === "");
                    setSegurancaNoTrabalho({
                      ...segurancaNoTrabalho,
                      dsTempoEstimado: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaTemaComponent
                    error={temaErro}
                    temas={segurancaNoTrabalho.tema}
                    multiple={false}
                    onChangeTemas={(value: any) => {
                      setTemaErro(value.length === 0)
                      setSegurancaNoTrabalho({
                        ...segurancaNoTrabalho,
                        tema: value[0] ? value[0] : {}
                      })
                    }}
                  />
                  {temaErro ? <FormHelperText error={temaErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>


              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaPerfilComponent
                    error={perfilErro}
                    perfis={segurancaNoTrabalho.segurancaNoTrabalhoPerfis}
                    onChangePerfis={(value: any) => {
                      setPerfilErro(value.length === 0)
                      setSegurancaNoTrabalho({
                        ...segurancaNoTrabalho,
                        segurancaNoTrabalhoPerfis: value
                      })
                    }}
                  />
                  {perfilErro ? <FormHelperText error={perfilErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaMunicipioComponentTeste
                    error={municipioErro}
                    municipios={municipios_}
                    callbackList={getSegurancaNoTrabalhoDataMunicipioTotal}
                    id={props.id}
                    onChangeMunicipios={(value: any) => {
                      setMunicipioErro(value.length === 0)
                      setSegurancaNoTrabalho({
                        ...segurancaNoTrabalho,
                        segurancaNoTrabalhoMuncipios: value
                      })
                    }}
                  />
                  {municipioErro ? <FormHelperText error={municipioErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaConteudoComponent
                    error={conteudoErro}
                    conteudos={segurancaNoTrabalho.segurancaNoTrabalhoConteudos}
                    multiple={true}
                    onChangeConteudos={(value: any) => {
                      setConteudoErro(value.length === 0)
                      setSegurancaNoTrabalho({
                        ...segurancaNoTrabalho,
                        segurancaNoTrabalhoConteudos: value
                      });
                      validaOrdenacao(value);
                    }}
                  />
                  {conteudoErro ? <FormHelperText error={conteudoErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <ListaAvaliacaoComponent
                  avaliacoes={segurancaNoTrabalho.segurancaNoTrabalhoAvaliacoes}
                  onChangeAvaliacoes={(value: any) => setSegurancaNoTrabalho({
                    ...segurancaNoTrabalho,
                    segurancaNoTrabalhoAvaliacoes: value
                  })}
                />
              </Grid>

              {
                segurancaNoTrabalho.urlCompartilhamentoSegurancaNoTrabalho ? (
                  <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      disabled
                      sx={{ width: '80%' }}
                      value={segurancaNoTrabalho.urlCompartilhamentoSegurancaNoTrabalho}
                      label="Link de Compartilhamento"
                    />
                    <Tooltip title={copiado}>
                      <IconButton sx={{ fontSize: 25, marginLeft: '5px', segurancaNoTrabalhor: 'pointer' }} onClick={() => {
                        navigator.clipboard.writeText(segurancaNoTrabalho.urlCompartilhamentoSegurancaNoTrabalho || '').then(() => {
                          setCopiado('Copiado!');
                        }).catch(() => {
                          setCopiado('Não foi possível copiar o link de compartilhamento!');
                        }).finally(() => {
                          setTimeout(() => {
                            setCopiado('Copiar link de compartilhamento');
                          }, 1500);
                        });
                      }} >
                        <Icon >content_copy</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null
              }
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div style={{ maxHeight: 400, overflow: 'auto', width: '100%' }}>
              <List className="conteudoOrdemList" >
                {
                  segurancaNoTrabalho.segurancaNoTrabalhoConteudos && segurancaNoTrabalho.segurancaNoTrabalhoConteudos.length > 0 ? (
                    segurancaNoTrabalho.segurancaNoTrabalhoConteudos.map((conteudo: any, idx: number) => (
                      <ListItem key={`${conteudo.idConteudo}`}>
                        <TextField
                          disabled
                          style={{ width: 400 }}
                          value={conteudo.nmConteudo}
                          label="Conteúdo"
                        />
                        <FormControl sx={{ width: 200, marginLeft: '1em' }} >
                          <InputLabel id="demo-controlled-open-select-label">Ordem do Conteúdo</InputLabel>
                          <Select
                            value={conteudo.ordemConteudo && conteudo.ordemConteudo != 0 ? conteudo.ordemConteudo : (idx + 1)}
                            label="Ordem do Conteúdo"
                            onChange={(e) => {
                              let auxArr = segurancaNoTrabalho.segurancaNoTrabalhoConteudos;
                              auxArr[idx].ordemConteudo = parseInt(e.target.value.toString());
                              setSegurancaNoTrabalho({
                                ...segurancaNoTrabalho,
                                segurancaNoTrabalhoConteudos: auxArr
                              })
                            }}
                          >
                            {
                              segurancaNoTrabalho.segurancaNoTrabalhoConteudos.map((item: any, _idx: any) => (
                                <MenuItem key={`listItemOrdem-${idx}-${_idx}`} value={(_idx + 1)}>{(_idx + 1)}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </ListItem>
                    ))
                  ) : null
                }
              </List>
            </div>
          </TabPanel>
          <Divider style={{ marginTop: '1em' }} />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
              <Tab label="Informações" />
              <Tab label="Ordenação de Conteúdos" disabled={!(segurancaNoTrabalho.segurancaNoTrabalhoConteudos && segurancaNoTrabalho.segurancaNoTrabalhoConteudos.length > 0)} />
            </Tabs>
          </Box>
          <div className="cadastroFooter">
            <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
            {
              isEdicao ?
                <Button variant="contained" onClick={() => {
                  props.enviaNotificacao(segurancaNoTrabalho.idSegurancaNoTrabalho)
                }}>Enviar Notificação</Button> : null
            }
          </div>
        </form>
      </Box>

    </Modal>
  )

};

export default ModalCadastroSegurancaNoTrabalho;