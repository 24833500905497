import { Reducer } from "@reduxjs/toolkit";
import { AvaliacaoState, AvaliacaoTypes } from "./types";

const INITIAL_STATE: AvaliacaoState = {
    data: {
        table: null,
        avaliacao: null,
        avaliacaoTipo: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    importSuccess: false,
    loadingInativacao: false,
};

const reducer: Reducer<AvaliacaoState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AvaliacaoTypes.AVALIACAO_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case AvaliacaoTypes.AVALIACAO_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case AvaliacaoTypes.AVALIACAO_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case AvaliacaoTypes.AVALIACAO_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case AvaliacaoTypes.AVALIACAO_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case AvaliacaoTypes.AVALIACAO_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case AvaliacaoTypes.AVALIACAO_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case AvaliacaoTypes.AVALIACAO_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case AvaliacaoTypes.AVALIACAO_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case AvaliacaoTypes.AVALIACAO_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case AvaliacaoTypes.AVALIACAO_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    avaliacao: action.payload.data
                }
            };
        case AvaliacaoTypes.AVALIACAO_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    avaliacao: null
                }
            };

        case AvaliacaoTypes.CLEAR_AVALIACAO_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    avaliacao: null
                }
            };

        case AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingPersis: false,
            };
        case AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_IMAGE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

            case AvaliacaoTypes.AVALIACAO_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case AvaliacaoTypes.AVALIACAO_SAVE_REQUEST_SUCCESS:
            alert('Avaliação cadastrada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    avaliacao: action.payload.data
                }
            };
        case AvaliacaoTypes.AVALIACAO_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case AvaliacaoTypes.AVALIACAO_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case AvaliacaoTypes.AVALIACAO_UPDATE_REQUEST_SUCCESS:
            alert('Avaliação alterada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    avaliacao: action.payload.data
                }
            };
        case AvaliacaoTypes.AVALIACAO_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case AvaliacaoTypes.AVALIACAO_TIPO_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case AvaliacaoTypes.AVALIACAO_TIPO_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    avaliacaoTipo: action.payload.data
                }
            };
        case AvaliacaoTypes.AVALIACAO_TIPO_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    avaliacaoTipo: null
                }
            };

        case AvaliacaoTypes.AVALIACAO_IMPORTAR:
            return {
                ...state,
                error: false,
                loading: true,
                importSuccess: false,
            };
        case AvaliacaoTypes.AVALIACAO_IMPORTAR_SUCCESS:
            return {
                ...state,
                error: false,
                importSuccess: true,
            };

        case AvaliacaoTypes.AVALIACAO_IMPORTAR_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                importSuccess: false,
                errorMessage: action.payload.err,
            };

        case AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_REQUEST_SUCCESS:
            if (action.payload.data.showAlert) {
                alert('Perguntas cadastradas com sucesso!');
            }
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    avaliacao: action.payload.data.data
                }
            };
        case AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case AvaliacaoTypes.PERGUNTA_RESPOSTA_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case AvaliacaoTypes.PERGUNTA_RESPOSTA_SAVE_REQUEST_SUCCESS:
            alert('Respostas cadastradas com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                }
            };
        case AvaliacaoTypes.PERGUNTA_RESPOSTA_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };


        case AvaliacaoTypes.AVALIACAO_PUSH_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case AvaliacaoTypes.AVALIACAO_PUSH_REQUEST_SUCCESS:
            alert('As notificações entraram na fila para envio! Pode levar alguns minutos para que os usuários recebam.');
            return {
                ...state,
                error: false,
                loading: false,
            };
        case AvaliacaoTypes.AVALIACAO_PUSH_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
            };

        default :
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
};

export default reducer;