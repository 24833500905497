import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";

import * as JustificativaAction from "../../store/ducks/justificativa/actions";
import * as UtilsActions from "../../store/ducks/utils/actions";

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";
import Loading from "../../Components/LoadingComponent";
import JustificativaTableComponent from "../../Components/TableJustificativa";
import ModalCadastroJustificativa from "../../Components/ModalCadastroJustificativa";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
  pagina: 0,
  tamanho: 10,
  ordem: "ASC",
  coluna: "tipoJustificativa.idTipoJustificativa",
  txtBusca: "",
};

function Justificativa({
  justificativa,
  getJustificativaTable,
  getJustificativaData,
  onSaveJustificativa,
  onUpdateJustificativa,
  onInativarJustificativa,
  clearJustificativaData,
  onEnviaNotificacaoJustificativa,
  utils,
  getMunicipioList,
  getEstadosList,
  getPerfilList,
  getJustificativaTipoList,
  getUsuariosList,
  setLoading,
  auth,
}: any) {
  const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
  const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
  const [body, setBody] = useState(_body);
  const [table, setTable] = useState(null);
  const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
  const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
  const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

  useEffect(() => {
    if (justificativa.error) {
      alert(
        "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
          justificativa.errorMessage
      );
    }
  }, [justificativa.error]);

  useEffect(() => {
    if (!modalCadastroOpen) {
      getJustificativaTableData();
    }
  }, [body, modalCadastroOpen]);

  useEffect(() => {
    if (justificativa.data.table != null) {
      setTable(justificativa.data.table);
    }
  }, [justificativa.data]);

  useEffect(() => {
    if (!justificativa.loadingInativacao) {
      const tableBody = {
        ...body,
        idGestor: auth.data.idUsuario,
      };
      getJustificativaTable(tableBody);
    }
  }, [justificativa.loadingInativacao]);

  useEffect(() => {
    if (!justificativa.loadingPersis) {
      handleCloseAndReload();
    }
  }, [justificativa.loadingPersis]);

  useEffect(() => {
    getJustificativaTipoList();
    getEstadosList();
    getPerfilList();
  }, []);

  const getJustificativaTableData = () => {
    const tableBody = {
      ...body,
      idGestor: auth.data.idUsuario,
    };
    getJustificativaTable(tableBody);
  };

  const handleCadastro = () => {
    setModalCadastroOpen(true);
  };

  const handleChangeEstado = (idEstado: number) => {
    getMunicipioList(idEstado);
  };

  const handleChangeMunicipio = (idMunicipio: number) => {
    getUsuariosList(idMunicipio);
  };

  const handleEdicao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione uma justificativa que gostaria de alterar");
    } else if (idsSelecionado.length > 1) {
      alert("Selecione apenas uma justificativa para alteração!");
    } else {
      getJustificativaData(idsSelecionado[0]);
      setModalCadastroOpen(true);
    }
  };

  const handleInativacao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione as justificativas que gostaria de inativar");
    } else {
      setAlertConfirmacaoOpen(true);
      setAlertConfirmacaoTexto(EntidadesAcoes.JUSTIFICATIVA_EXCLUSAO);
      setAlertConfirmacaoAcao(() => () => {
        onInativarJustificativa(idsSelecionado);
        setAlertConfirmacaoOpen(false);
      })
      
    }
  };

  function unmountAlertConfirmacao() {
    setAlertConfirmacaoTexto('');
    setAlertConfirmacaoAcao(() => () => {})
    setAlertConfirmacaoOpen(false);
  }

  const changeTable = (__body: any) => {
    setBody({ ...__body });
  };

  const handlePersitJustificativa = (edicao: boolean, justificativa: any) => {
    if (edicao) {
      onUpdateJustificativa(justificativa);
    } else {
      onSaveJustificativa(justificativa);
    }
  };

  const handleCloseAndReload = () => {
    clearJustificativaData();
    setModalCadastroOpen(false);
    setIdSelecionado([]);
  };

  return (
    <div className="pageBox">
      <Loading loading={justificativa.loading || justificativa.loadingPersis} />
      <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
      <ModalCadastroJustificativa
        modalCadastroOpen={modalCadastroOpen}
        closeModal={() => handleCloseAndReload()}
        justificativaData={justificativa.data.justificativa}
        persistJustificativa={handlePersitJustificativa}
        enviaNotificacao={(idJustificativa: any) =>
          onEnviaNotificacaoJustificativa(idJustificativa)
        }
        perfis={utils.data.perfil}
        estados={utils.data.estados}
        changeEstado={handleChangeEstado}
        municipios={utils.data.municipios}
        changeMunicipio={handleChangeMunicipio}
        usuarios={utils.data.usuarios}
        listJustificativaTipo={justificativa.data.justificativaTipo}
        setLoading={setLoading}
        {...auth.data}
      />
      <div className="menuLateral">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            width: "100%",
          }}
        >
          Ações
          <AcaoMenu
            onCadastro={handleCadastro}
            onEditar={handleEdicao}
            onInativar={handleInativacao}
          />
        </Typography>
      </div>
      <div className="table">
        <JustificativaTableComponent
          loading={justificativa.loadingTable}
          data={table}
          body={body}
          onChange={changeTable}
          selection={idsSelecionado}
          idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}
          select
          selectData={justificativa.data.justificativaTipo}
          selectPlaceholder="Tipo de Justificativa"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  justificativa: state.justificativa,
  utils: state.utils,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...JustificativaAction, ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Justificativa);
