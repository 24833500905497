import React, { useEffect, useState } from "react";
import { GridColDef } from '@mui/x-data-grid';
import { UsuarioTable, UsuarioTableObj } from "../../store/ducks/usuario/types";
import GenericTable from "../GenericTable/GenericTable";
import Icon from '@mui/material/Icon';
import { mascaraCpf, verificarCPF } from "../../services/Uteis";

const usu: UsuarioTableObj[] = [];
const col: GridColDef[] = [];

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmPessoaFisica",
    txtBusca: ""
};

const UsuarioTableComponent = (props: any) => {
    
    const [txtBusca, setTxtBusca] = useState('');
    const [columns, setColumns] = useState(col);
    const [listUsuario, setListUsuario] = useState(usu);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numberElements, setNumberElements] = useState(0);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        generateColumnsTable();
    }, []);

    useEffect(() => {
        if(props.data){
            tranformObjectTable(props.data)
        }
    }, [props.data]);

    const generateColumnsTable = () => {
        const _columns: GridColDef[] = [
            { field: 'status', headerName: 'Ativo/Inativo', width: 100, renderCell: ({row}) => {
                return <Icon sx={{color: row.status == 1 ? '#0f0' :'#f00'}}>person</Icon>
            } },
            { field: 'idUsuario', headerName: 'ID do Usuário', width: 120 },
            { field: 'nmUsuario', headerName: 'Nome do Usuário', width: 400 },
            { field: 'nmPerfil', headerName: 'Perfil', width: 200 },
            { field: 'cpf', headerName: 'CPF', width: 130},
            { field: 'emaiUsuario', headerName: 'E-Mail', width: 400},
        ];

        setColumns(_columns);
    }

    const tranformObjectTable = (obj: UsuarioTable) => {
        setSize(obj.size);
        setPage(obj.number);
        setTotalElements(obj.totalElements);
        setTotalPages(obj.totalPages);
        setNumberElements(obj.numberOfElements);
        setOffset(obj.pageable.offset)

        let list: UsuarioTableObj[] = obj.content.map((item: any) => {
            return {
                id: item[0],
                idUsuario: item[0],
                status: item[1],
                nmUsuario: item[2],
                cpf: mascaraCpf(item[3]),
                nmPerfil: item[4],
                emaiUsuario: item[5]
            }
        });

        setListUsuario(list);
    }

    const onPageChange = (pageIdx: any) => {
        _body.pagina = pageIdx - 1;
        setPage(pageIdx - 1)
        props.onChange(_body);
    }

    const onSizeChange = (_size: any) => {
        _body.tamanho = _size;
        setSize(_size);
        props.onChange(_body);
    }

    const onChangeBusca = (txt: string) => {
        _body.txtBusca = verificarCPF(txt) ? txt.replaceAll('-', '').replaceAll('.', '') : txt;
        setTxtBusca(txt);
        if(txt.length >= 3){
            props.onChange(_body);
        } else {
            _body.txtBusca = '';
            props.onChange(_body);
        }
    }

    const onSortChange = (sort: any) => {
        if(sort != undefined) {
            let colunaSort = sort.field && 
            sort.field == "idUsuario" ? "usuario"
            : sort.field == "nmUsuario" ? 'nmPessoaFisica' 
            : sort.field == "cpf" ? 'cpf'
            : sort.field == "emaiUsuario" ? 'emailPessoaFisica'
            : sort.field == "status" ? 'idDatabaseStatus'
            : sort.field == "nmPerfil" ? 'usuario.perfil.nmPerfil' : 'nmPessoaFisica' ;

            _body.coluna = colunaSort;
            _body.ordem = sort.sort.toUpperCase();
        } else {
            _body.coluna = 'nmPessoaFisica';
            _body.ordem = 'ASC';
        }
        props.onChange(_body);

    }

    return (
        <GenericTable 
            loading={props.loading}
            titleTable='Usuários Cadastrados'
            rows={listUsuario} 
            columns={columns} 
            page={page} 
            totalPages={totalPages} 
            onPageChange={onPageChange}
            offset={offset}
            numberElements={numberElements} 
            totalElements={totalElements}
            size={size}
            sizeChange={onSizeChange}
            txtBusca={txtBusca}
            onChangePesquisa={onChangeBusca}
            selection={props.selection}
            idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
            sortChange={onSortChange}
        />
    )
};

export default UsuarioTableComponent;