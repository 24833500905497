import { action } from "typesafe-actions";
import { UrlExternaTypes } from "./types";

export const getUrlExternaTable = (data: any) => action(UrlExternaTypes.URL_EXTERNA_TABLE_REQUEST, data);
export const getUrlExternaTableSuccess = (data: any) => action(UrlExternaTypes.URL_EXTERNA_TABLE_REQUEST_SUCCESS, { data });
export const getUrlExternaTableFailure = (err: any) => action(UrlExternaTypes.URL_EXTERNA_TABLE_REQUEST_FAILURE, { err });

export const onInativarUrlExterna = (data: any) => action(UrlExternaTypes.URL_EXTERNA_INATIVA_REQUEST, data);
export const onInativarUrlExternaSuccess = (data: any) => action(UrlExternaTypes.URL_EXTERNA_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarUrlExternaFailure = (err: any) => action(UrlExternaTypes.URL_EXTERNA_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarUrlExterna = (data: any) => action(UrlExternaTypes.URL_EXTERNA_ATIVA_REQUEST, data);
export const onAtivarUrlExternaSuccess = (data: any) => action(UrlExternaTypes.URL_EXTERNA_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarUrlExternaFailure = (err: any) => action(UrlExternaTypes.URL_EXTERNA_ATIVA_REQUEST_FAILURE, { err });

export const clearUrlExternaData = () => action(UrlExternaTypes.CLEAR_URL_EXTERNA_DATA);

export const getUrlExternaData = (data: any) => action(UrlExternaTypes.URL_EXTERNA_DATA_REQUEST, data);
export const getUrlExternaDataSuccess = (data: any) => action(UrlExternaTypes.URL_EXTERNA_DATA_REQUEST_SUCCESS, { data });
export const getUrlExternaDataFailure = (err: any) => action(UrlExternaTypes.URL_EXTERNA_DATA_REQUEST_FAILURE, { err });

export const onSaveUrlExterna = (data: any) => action(UrlExternaTypes.URL_EXTERNA_SAVE_REQUEST, data);
export const onSaveUrlExternaSuccess = (data: any) => action(UrlExternaTypes.URL_EXTERNA_SAVE_REQUEST_SUCCESS, { data });
export const onSaveUrlExternaFailure = (err: any) => action(UrlExternaTypes.URL_EXTERNA_SAVE_REQUEST_FAILURE, { err });

export const onUpdateUrlExterna = (data: any) => action(UrlExternaTypes.URL_EXTERNA_UPDATE_REQUEST, data);
export const onUpdateUrlExternaSuccess = (data: any) => action(UrlExternaTypes.URL_EXTERNA_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateUrlExternaFailure = (err: any) => action(UrlExternaTypes.URL_EXTERNA_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoUrlExterna = (data: any) => action(UrlExternaTypes.URL_EXTERNA_PUSH_REQUEST, data);
export const onNotificacaoUrlExternaSuccess = (data: any) => action(UrlExternaTypes.URL_EXTERNA_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoUrlExternaFailure = (err: any) => action(UrlExternaTypes.URL_EXTERNA_PUSH_REQUEST_FAILURE, { err });
