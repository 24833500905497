import { Reducer } from "@reduxjs/toolkit";
import { PerfilState, PerfilTypes } from "./types";

const INITIAL_STATE: PerfilState = {
    data: {
        table: null,
        perfil: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<PerfilState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PerfilTypes.PERFIL_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case PerfilTypes.PERFIL_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case PerfilTypes.PERFIL_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case PerfilTypes.PERFIL_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case PerfilTypes.PERFIL_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loadingInativacao: false,
                loading: false,
            };
        case PerfilTypes.PERFIL_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case PerfilTypes.PERFIL_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case PerfilTypes.PERFIL_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case PerfilTypes.PERFIL_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case PerfilTypes.PERFIL_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case PerfilTypes.PERFIL_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    perfil: action.payload.data
                }
            };
        case PerfilTypes.PERFIL_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    perfil: null
                }
            };

        case PerfilTypes.CLEAR_PERFIL_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    perfil: null
                }
            };

        case PerfilTypes.PERFIL_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case PerfilTypes.PERFIL_SAVE_REQUEST_SUCCESS:
            alert('Público Alvo cadastrado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    perfil: action.payload.data
                }
            };
        case PerfilTypes.PERFIL_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case PerfilTypes.PERFIL_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case PerfilTypes.PERFIL_UPDATE_REQUEST_SUCCESS:
            alert('Público Alvo editado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    perfil: action.payload.data
                }
            };
        case PerfilTypes.PERFIL_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };


        case PerfilTypes.PERFIL_EXISTENTE_REQUEST:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
        case PerfilTypes.PERFIL_EXISTENTE_REQUEST_SUCCESS:
            if(action.payload.data !== ''){
                alert('Atenção\nJá existe um Público Alvo com esses dados cadastrado na base.\nAo continuar você seguirá com a edição do público alvo já existente!');
            }
            
            return {
                ...state,
                data: {
                    ...state.data,
                    perfil: action.payload.data !== '' ? {
                        idPerfil: action.payload.data 
                    } : null
                }
            };
        case PerfilTypes.PERFIL_EXISTENTE_REQUEST_FAILURE:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };

        default :
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
};

export default reducer;