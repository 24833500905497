import { action } from "typesafe-actions";
import { PerfilTypes } from "./types";

export const getPerfilTable = (data: any) => action(PerfilTypes.PERFIL_TABLE_REQUEST, data);
export const getPerfilTableSuccess = (data: any) => action(PerfilTypes.PERFIL_TABLE_REQUEST_SUCCESS, { data });
export const getPerfilTableFailure = (err: any) => action(PerfilTypes.PERFIL_TABLE_REQUEST_FAILURE, { err });

export const onInativarPerfil = (data: any) => action(PerfilTypes.PERFIL_INATIVA_REQUEST, data);
export const onInativarPerfilSuccess = (data: any) => action(PerfilTypes.PERFIL_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarPerfilFailure = (err: any) => action(PerfilTypes.PERFIL_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarPerfil = (data: any) => action(PerfilTypes.PERFIL_ATIVA_REQUEST, data);
export const onAtivarPerfilSuccess = (data: any) => action(PerfilTypes.PERFIL_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarPerfilFailure = (err: any) => action(PerfilTypes.PERFIL_ATIVA_REQUEST_FAILURE, { err });

export const clearPerfilData = () => action(PerfilTypes.CLEAR_PERFIL_DATA);

export const getPerfilData = (data: any) => action(PerfilTypes.PERFIL_DATA_REQUEST, data);
export const getPerfilDataSuccess = (data: any) => action(PerfilTypes.PERFIL_DATA_REQUEST_SUCCESS, { data });
export const getPerfilDataFailure = (err: any) => action(PerfilTypes.PERFIL_DATA_REQUEST_FAILURE, { err });

export const onSavePerfil = (data: any) => action(PerfilTypes.PERFIL_SAVE_REQUEST, data);
export const onSavePerfilSuccess = (data: any) => action(PerfilTypes.PERFIL_SAVE_REQUEST_SUCCESS, { data });
export const onSavePerfilFailure = (err: any) => action(PerfilTypes.PERFIL_SAVE_REQUEST_FAILURE, { err });

export const onUpdatePerfil = (data: any) => action(PerfilTypes.PERFIL_UPDATE_REQUEST, data);
export const onUpdatePerfilSuccess = (data: any) => action(PerfilTypes.PERFIL_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdatePerfilFailure = (err: any) => action(PerfilTypes.PERFIL_UPDATE_REQUEST_FAILURE, { err });

export const getPerfilExistente = (data: any) => action(PerfilTypes.PERFIL_EXISTENTE_REQUEST, data);
export const getPerfilExistenteSuccess = (data: any) => action(PerfilTypes.PERFIL_EXISTENTE_REQUEST_SUCCESS, { data });
export const getPerfilExistenteFailure = (err: any) => action(PerfilTypes.PERFIL_EXISTENTE_REQUEST_FAILURE, { err });
