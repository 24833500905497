import { action } from "typesafe-actions";
import { T1Types } from "./types";

export const getT1Table = (data: any) => action(T1Types.T1_TABLE_REQUEST, data);
export const getT1TableSuccess = (data: any) => action(T1Types.T1_TABLE_REQUEST_SUCCESS, { data });
export const getT1TableFailure = (err: any) => action(T1Types.T1_TABLE_REQUEST_FAILURE, { err });

export const onInativarT1 = (data: any) => action(T1Types.T1_INATIVA_REQUEST, data);
export const onInativarT1Success = (data: any) => action(T1Types.T1_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarT1Failure = (err: any) => action(T1Types.T1_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarT1 = (data: any) => action(T1Types.T1_ATIVA_REQUEST, data);
export const onAtivarT1Success = (data: any) => action(T1Types.T1_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarT1Failure = (err: any) => action(T1Types.T1_ATIVA_REQUEST_FAILURE, { err });

export const getT1Data = (data: any) => action(T1Types.T1_DATA_REQUEST, data);
export const getT1DataSuccess = (data: any) => action(T1Types.T1_DATA_REQUEST_SUCCESS, { data });
export const getT1DataFailure = (err: any) => action(T1Types.T1_DATA_REQUEST_FAILURE, { err });

export const onSaveT1 = (data: any) => action(T1Types.T1_SAVE_REQUEST, data);
export const onSaveT1Success = (data: any) => action(T1Types.T1_SAVE_REQUEST_SUCCESS, { data });
export const onSaveT1Failure = (err: any) => action(T1Types.T1_SAVE_REQUEST_FAILURE, { err });

export const onUpdateT1 = (data: any) => action(T1Types.T1_UPDATE_REQUEST, data);
export const onUpdateT1Success = (data: any) => action(T1Types.T1_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateT1Failure = (err: any) => action(T1Types.T1_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoT1 = (data: any) => action(T1Types.T1_PUSH_REQUEST, data);
export const onNotificacaoT1Success = (data: any) => action(T1Types.T1_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoT1Failure = (err: any) => action(T1Types.T1_PUSH_REQUEST_FAILURE, { err });

export const clearT1Data = () => action(T1Types.CLEAR_T1_DATA);