import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";

import * as ClusterAction from "../../store/ducks/cluster/actions";
import * as UtilsAction from "../../store/ducks/utils/actions";

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";
import Loading from "../../Components/LoadingComponent";
import ModalCadastroCluster from "../../Components/ModalCadastroCluster";
import ClusterTableComponent from "../../Components/TableCluster";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
  pagina: 0,
  tamanho: 10,
  ordem: "ASC",
  coluna: "nmCluster",
  txtBusca: "",
};

function Cluster({
  cluster,
  getClusterTable,
  getClusterData,
  onSaveCluster,
  onUpdateCluster,
  onInativarCluster,
  clearClusterData,
  utils,
  getAllGrupoList,
}: any) {
  const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
  const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
  const [body, setBody] = useState(_body);
  const [table, setTable] = useState(null);
  const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
  const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
  const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

  useEffect(() => {
    if (cluster.error) {
      alert(
        "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
          cluster.errorMessage
      );
    }
  }, [cluster.error]);

  useEffect(() => {
    if (!modalCadastroOpen) {
      getClusterTableData();
    }
  }, [body, modalCadastroOpen]);

  useEffect(() => {
    if (cluster.data.table != null) {
      setTable(cluster.data.table);
    }
  }, [cluster.data]);

  useEffect(() => {
    if (!cluster.loadingInativacao) {
      getClusterTable(body);
    }
  }, [cluster.loadingInativacao]);

  useEffect(() => {
    if (!cluster.loadingPersis) {
      handleCloseAndReload();
    }
  }, [cluster.loadingPersis]);

  useEffect(() => {
    getAllGrupoList();
  }, []);

  const getClusterTableData = () => {
    getClusterTable(body);
  };

  const handleCadastro = () => {
    clearClusterData();
    setModalCadastroOpen(true);
  };

  const handleEdicao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione um Cluster que gostaria de alterar");
    } else if (idsSelecionado.length > 1) {
      alert("Selecione apenas um Cluster para alteração!");
    } else {
      getClusterData(idsSelecionado[0]);
      setModalCadastroOpen(true);
    }
  };

  const changeTable = (__body: any) => {
    setBody({ ...__body });
  };

  const handlePersistCluster = (edicao: boolean, cluster: any) => {
    if (edicao) {
      onUpdateCluster(cluster);
    } else {
      onSaveCluster(cluster);
    }
  };

  const handleCloseAndReload = () => {
    clearClusterData();
    setModalCadastroOpen(false);
    setIdSelecionado([]);
  };

  const handleInativacao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione os Clusters que gostaria de inativar");
    } else {
      setAlertConfirmacaoOpen(true);
      setAlertConfirmacaoTexto(EntidadesAcoes.CLUSTER_EXCLUSAO);
      setAlertConfirmacaoAcao(() => () => {
        onInativarCluster(idsSelecionado);
        setAlertConfirmacaoOpen(false);
      })
    }
  };

  function unmountAlertConfirmacao() {
    setAlertConfirmacaoTexto('');
    setAlertConfirmacaoAcao(() => () => {})
    setAlertConfirmacaoOpen(false);
  }

  return (
    <div className="pageBox">
      <Loading loading={cluster.loading || cluster.loadingPersis} />
      <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
      <ModalCadastroCluster
        modalCadastroOpen={modalCadastroOpen}
        closeModal={() => handleCloseAndReload()}
        clusterData={cluster.data.cluster}
        persistCluster={handlePersistCluster}
        getClusterData={(idCluster: any) => getClusterData(idCluster)}
        listAllGrupos={utils.data.grupos}
      />
      <div className="menuLateral">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            width: "100%",
          }}
        >
          Ações
          <AcaoMenu
            onCadastro={handleCadastro}
            onEditar={handleEdicao}
            onInativar={handleInativacao}
          />
        </Typography>
      </div>
      <div className="table">
        <ClusterTableComponent
          loading={cluster.loadingTable}
          data={table}
          body={body}
          onChange={changeTable}
          selection={idsSelecionado}
          idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  cluster: state.cluster,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...ClusterAction, ...UtilsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cluster);
