import { action } from "typesafe-actions";
import { GrupoTypes as GrupoTypes } from "./types";

export const getGrupoTable = (data: any) => action(GrupoTypes.GRUPO_TABLE_REQUEST, data);
export const getGrupoTableSuccess = (data: any) => action(GrupoTypes.GRUPO_TABLE_REQUEST_SUCCESS, { data });
export const getGrupoTableFailure = (err: any) => action(GrupoTypes.GRUPO_TABLE_REQUEST_FAILURE, { err });

export const onInativarGrupo = (data: any) => action(GrupoTypes.GRUPO_INATIVA_REQUEST, data);
export const onInativarGrupoSuccess = (data: any) => action(GrupoTypes.GRUPO_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarGrupoFailure = (err: any) => action(GrupoTypes.GRUPO_INATIVA_REQUEST_FAILURE, { err });

export const getGrupoData = (data: any) => action(GrupoTypes.GRUPO_DATA_REQUEST, data);
export const getGrupoDataSuccess = (data: any) => action(GrupoTypes.GRUPO_DATA_REQUEST_SUCCESS, { data });
export const getGrupoDataFailure = (err: any) => action(GrupoTypes.GRUPO_DATA_REQUEST_FAILURE, { err });

export const onSaveGrupo = (data: any) => action(GrupoTypes.GRUPO_SAVE_REQUEST, data);
export const onSaveGrupoSuccess = (data: any) => action(GrupoTypes.GRUPO_SAVE_REQUEST_SUCCESS, { data });
export const onSaveGrupoFailure = (err: any) => action(GrupoTypes.GRUPO_SAVE_REQUEST_FAILURE, { err });

export const onUpdateGrupo = (data: any) => action(GrupoTypes.GRUPO_UPDATE_REQUEST, data);
export const onUpdateGrupoSuccess = (data: any) => action(GrupoTypes.GRUPO_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateGrupoFailure = (err: any) => action(GrupoTypes.GRUPO_UPDATE_REQUEST_FAILURE, { err });

export const clearGrupoData = () => action(GrupoTypes.CLEAR_GRUPO_DATA);