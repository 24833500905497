import React, { useEffect,  useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'dayjs/locale/pt-br'

import TabPanel from "../TabPanel"; 
import { FormHelperText, InputLabel, List, ListItem, MenuItem, Select, TextareaAutosize } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";
import { ChatBotSaudacaoObj } from "../../store/ducks/chatbot/saudacao/types";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const ModalCadastroSaudacao = (props: any) => {

    const [isEdicao, setIsEdicao] = useState(false);
    const [saudacao, setSaudacao] = useState<ChatBotSaudacaoObj>({});
    const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
    const [inativaGravar, setInativaGravar] = useState(true);
    const [tituloErro, setTituloErro] = useState(false);
    const [contextoErro, setContextoErro] = useState(false);
    const [chaveErro, setChaveErro] = useState(false);

    useEffect(() => {
        if(props.saudacaoData){   
            handleRender(props.saudacaoData);
        }
    }, [props.saudacaoData]);

    useEffect(() => {
        if(!props.modalCadastroOpen){   
            handleClose();
        }
    }, [props.modalCadastroOpen]);

    useEffect(() => {
        setChaveErro(props.errorChave);
    }, [props.errorChave])

    useEffect(() => {
        if(!tituloErro && !contextoErro && !chaveErro) {
            setInativaGravar(false);
        } else {
            setInativaGravar(true);
        }
    }, [saudacao, tituloErro, contextoErro, chaveErro])

    const handleRender = (saudacaoData: any) => {
        if(saudacaoData){
            setIsEdicao(true)
            setSaudacao({
                ...saudacaoData,
            });
        }
    } 
    
    const handleClose = () => {
        setIsEdicao(false);
        setSaudacao({});
        setInativaGravar(true);
        setTituloErro(false);
        setContextoErro(false);

        props.closeModal();
    }

    const handlePersist = (e: any) => {
        e.preventDefault();

        props.persist(isEdicao, saudacao);
    }

    return (
        <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
            if(res !== 'backdropClick') {
                handleClose()
            }
            }}>
            <Box sx={style} >
                <div className="modalHead">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isEdicao ? 'Editar Saudação' : 'Cadastrar Saudação'}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Fechar
                    </Button>
                </div>
                <Divider />
                <form className="modalMid-form" onSubmit={handlePersist}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} >
                                <TextField
                                    required
                                    sx={{width: '100%'}}
                                    value={saudacao.titulo ?? ""}
                                    label="Titulo da Saudação"
                                    error={tituloErro}
                                    helperText={tituloErro ? "Campo Obrigatório." : ''}
                                    onChange={(e) => {
                                        setTituloErro(e.target.value === "");
                                        setSaudacao({
                                            ...saudacao,
                                            titulo: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: '100%' }} >
                                    <TextareaAutosize 
                                        style={{width: '100%', }}
                                        value={saudacao.descricao ?? ""}
                                        minRows={6}
                                        placeholder="Descrição da Saudação"
                                        onChange={(e) => {
                                            setContextoErro(e.target.value === "");
                                            setSaudacao({
                                                ...saudacao,
                                                descricao: e.target.value
                                            })
                                        }}
                                    />
                                    {contextoErro ? <FormHelperText error={contextoErro}>Campo Obrigatório.</FormHelperText> : null}
                                </FormControl>
                                
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>Saudação Ativa</FormLabel>
                                    <RadioGroup
                                        row
                                        value={saudacao.ativo ?? false}
                                        onChange={(e) => {
                                            setSaudacao({
                                                ...saudacao,
                                                ativo: JSON.parse(e.target.value)
                                            })
                                        }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Sim" />
                                        <FormControlLabel value={false} control={<Radio />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    <Divider style={{marginTop: '1em'}} />

                    <div className="cadastroFooter">
                        <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar' }</Button>
                    </div>
                </form>
            </Box>
            
        </Modal>
    )

};

export default ModalCadastroSaudacao;