import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import {
  getMunicipioDataFailure,
  getMunicipioDataSuccess,
  getMunicipioTableFailure,
  getMunicipioTableSuccess,
  onSaveMunicipioFailure,
  onSaveMunicipioSuccess,
} from "./actions";

function* getMunicipioTable(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("municipio/listAllMunicipioTable", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getMunicipioTableSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getMunicipioTableFailure(error.message));
  }
}

function* getMunicipioData(data: any): any {
  const idMunicipio = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`municipio/findById?idMunicipio=${idMunicipio}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getMunicipioDataSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getMunicipioDataFailure(error.message));
  }
}

function* onSaveMunicipio(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("municipio/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onSaveMunicipioSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onSaveMunicipioFailure(error.message));
  }
}

export {
  getMunicipioData,
  getMunicipioTable,
  onSaveMunicipio,
};
