import { action } from "typesafe-actions";
import { StoryTypes } from "./types";

export const getStoryTable = (data: any) => action(StoryTypes.STORY_TABLE_REQUEST, data);
export const getStoryTableSuccess = (data: any) => action(StoryTypes.STORY_TABLE_REQUEST_SUCCESS, { data });
export const getStoryTableFailure = (err: any) => action(StoryTypes.STORY_TABLE_REQUEST_FAILURE, { err });

export const onInativarStory = (data: any) => action(StoryTypes.STORY_INATIVA_REQUEST, data);
export const onInativarStorySuccess = (data: any) => action(StoryTypes.STORY_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarStoryFailure = (err: any) => action(StoryTypes.STORY_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarStory = (data: any) => action(StoryTypes.STORY_ATIVA_REQUEST, data);
export const onAtivarStorySuccess = (data: any) => action(StoryTypes.STORY_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarStoryFailure = (err: any) => action(StoryTypes.STORY_ATIVA_REQUEST_FAILURE, { err });

export const getStoryData = (data: any) => action(StoryTypes.STORY_DATA_REQUEST, data);
export const getStoryDataSuccess = (data: any) => action(StoryTypes.STORY_DATA_REQUEST_SUCCESS, { data });
export const getStoryDataFailure = (err: any) => action(StoryTypes.STORY_DATA_REQUEST_FAILURE, { err });

export const onSaveStory = (data: any) => action(StoryTypes.STORY_SAVE_REQUEST, data);
export const onSaveStorySuccess = (data: any) => action(StoryTypes.STORY_SAVE_REQUEST_SUCCESS, { data });
export const onSaveStoryFailure = (err: any) => action(StoryTypes.STORY_SAVE_REQUEST_FAILURE, { err });

export const onUpdateStory = (data: any) => action(StoryTypes.STORY_UPDATE_REQUEST, data);
export const onUpdateStorySuccess = (data: any) => action(StoryTypes.STORY_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateStoryFailure = (err: any) => action(StoryTypes.STORY_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoStory = (data: any) => action(StoryTypes.STORY_PUSH_REQUEST, data);
export const onNotificacaoStorySuccess = (data: any) => action(StoryTypes.STORY_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoStoryFailure = (err: any) => action(StoryTypes.STORY_PUSH_REQUEST_FAILURE, { err });

export const clearStoryData = () => action(StoryTypes.CLEAR_STORY_DATA);