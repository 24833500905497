import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import Input from "@mui/material/Input";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/pt-br";

import {
  AvaliacaoObj,
  AvaliacaoPerguntaObj,
  AvaliacaoPerguntaRespostaObj,
} from "../../store/ducks/avaliacao/types";
import TabPanel from "../TabPanel";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaMunicipioComponentTeste from "../ListaMunicipioComponentTeste";
import { api } from "../../services";
import { validaNomeArquivo } from "../../services/Uteis";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultAvaliacao: AvaliacaoObj = {
  nmAvaliacao: "",
  dsAvaliacao: "",
  dtAvaliacaoInicio: null,
  dtAvaliacaoFim: null,
  icAleatorio: 0,
  icLivre: 0,
  icExibirAvaliacao: 0,
  avaliacaoTipo: null,
  avaliacaoMuncipios: [],
  avaliacaoPerfis: [],
  avaliacaoPerguntas: [],
};

const ModalCadastroAvaliacao = (props: any) => {
  const [isEdicao, setIsEdicao] = useState(false);
  const [avaliacao, setAvaliacao] = useState(defaultAvaliacao);
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [municipios_, setMunicipios_] = useState([]);
  const [file, setFile] = useState("");
  const [tab, setTab] = useState(0);
  const [tabResposta, setTabResposta] = useState(0);
  const [disableRespostas, setDisableRespostas] = useState(false);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [nmAvaliacaoErro, setNmAvaliacaoErro] = useState(false);
  const [dsAvaliacaoErro, setDsAvaliacaoErro] = useState(false);
  const [dtIniAvaliacaoErro, setDtIniAvaliacaoErro] = useState(false);
  const [dtFimAvaliacaoErro, setDtFimAvaliacaoErro] = useState(false);
  const [tpAvaliacaoErro, setTpAvaliacaoErro] = useState(false);
  const [tpMidiaError, setTpMidiaError] = useState(false);
  const [perfilErro, setPerfilErro] = useState(false);
  const [municipioErro, setMunicipioErro] = useState(false);

  useEffect(() => {
    if (props.avaliacaoData) {
      handleMunicipioData();
      handleRender(props.avaliacaoData);
    }
  }, [props.avaliacaoData]);

  useEffect(() => {
    if (
      avaliacao.avaliacaoPerguntas &&
      avaliacao.avaliacaoPerguntas.length == 0
    ) {
      handleAddPergunta();
    } else if (avaliacao.avaliacaoPerguntas) {
      const item = avaliacao.avaliacaoPerguntas.find(
        ({ idAvaliacaoPergunta }: AvaliacaoPerguntaObj) =>
          idAvaliacaoPergunta != undefined
      );
      if (item) {
        setDisableRespostas(true);
      } else {
        setDisableRespostas(false);
      }
      avaliacao.avaliacaoPerguntas.forEach(
        (_item: AvaliacaoPerguntaObj, idx: number) => {
          if (
            _item.avaliacaoPerguntaRespostas &&
            _item.avaliacaoPerguntaRespostas.length == 0
          ) {
            handleAddResposta(idx);
          }
        }
      );
    }
  }, [avaliacao.avaliacaoPerguntas]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (
      !nmAvaliacaoErro &&
      !dsAvaliacaoErro &&
      !dtIniAvaliacaoErro &&
      !dtFimAvaliacaoErro &&
      !tpAvaliacaoErro &&
      !perfilErro &&
      !municipioErro &&
      avaliacao.avaliacaoPerfis.length !== 0 &&
      avaliacao.avaliacaoMuncipios.length !== 0 &&
      titulo !== "" &&
      descricao !== "" &&
      avaliacao.dtAvaliacaoInicio !== null &&
      avaliacao.dtAvaliacaoFim !== null &&
      avaliacao.avaliacaoTipo !== null &&
      avaliacao.avaliacaoTipo.idAvaliacaoTipo !== 0 &&
      avaliacao.avaliacaoMuncipios.length !== 0 &&
      avaliacao.avaliacaoPerfis.length !== 0
    ) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [
    avaliacao,
    nmAvaliacaoErro,
    dsAvaliacaoErro,
    dtIniAvaliacaoErro,
    dtFimAvaliacaoErro,
    tpAvaliacaoErro,
    perfilErro,
    municipioErro,
  ]);

  const handleRender = (avaliacaoData: any) => {
    if (avaliacaoData) {
      setIsEdicao(true);
      setTitulo(String(avaliacaoData.nmAvaliacao));
      setDescricao(String(avaliacaoData.dsAvaliacao));
      setAvaliacao({
        ...avaliacaoData,
      });
    }
  };

  const handleClose = () => {
    setIsEdicao(false);
    setAvaliacao(defaultAvaliacao);
    setTab(0);
    setTitulo("");
    setDescricao("");
    setMunicipios_([]);
    setDisableRespostas(false);
    setTabResposta(0);
    setFile("");
    setInativaGravar(true);
    setNmAvaliacaoErro(false);
    setDsAvaliacaoErro(false);
    setDtIniAvaliacaoErro(false);
    setDtFimAvaliacaoErro(false);
    setTpAvaliacaoErro(false);

    props.closeModal();
  };


  const handleMunicipioData = async () => {
    if(props.id != undefined){
      setMunicipios_(await getAvaliacaoDataMunicipioLimited(props.id) );
    }
  }

  async function getAvaliacaoDataMunicipioTotal(data: any) {
    const idAvaliacao = data;
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`avaliacao/findMunicipioById?idAvaliacao=${idAvaliacao}`, {headers: {'Authorization': `Bearer ${token}`}})
        return response;
    } catch (error) {
        console.log(error);
    }
  }
    async function getAvaliacaoDataMunicipioLimited(data: any) {
      const idAvaliacao = data;
      const token = localStorage.getItem('accessToken');
      try {
          const { data: response } = await api.get(`avaliacao/findMunicipioByIdLimit?idAvaliacao=${idAvaliacao}`, {headers: {'Authorization': `Bearer ${token}`}})
          return response;
      } catch (error) {
          console.log(error);
      }
  };

  const handlePersistAvaliacao = async (e: any) => {
    e.preventDefault();
    avaliacao.nmAvaliacao = titulo;
    avaliacao.dsAvaliacao = descricao;
    if(avaliacao.avaliacaoMuncipios.length === 351 && isEdicao){
      avaliacao.avaliacaoMuncipios = await getAvaliacaoDataMunicipioTotal(props.id);
    }
   
    props.persistAvaliacao(isEdicao, avaliacao);
    setTab(tab + 1);
  };

  const handleEnvioImportacaoPergunta = () => {
    if (file == null || file === "") {
      alert(
        "Atenção\nÉ necessário selecionar um arquivo antes de realizar a importação!"
      );
      return;
    }
    let form = new FormData();
    form.append("file", file);
    form.append("avaliacao", JSON.stringify(avaliacao));

    props.handleImportacao(form);
  };

  const handleEnvioPerguntas = async (showModal: boolean) => {
    const perguntas: AvaliacaoPerguntaObj[] = [];

    if (avaliacao.avaliacaoPerguntas) {
      avaliacao.avaliacaoPerguntas.forEach(
        (pergunta: AvaliacaoPerguntaObj, idx: any) => {
          if (pergunta.nmAvaliacaoPergunta != "") {
            perguntas.push(pergunta);
            perguntas[perguntas.length - 1].nrOrdem =
              perguntas[perguntas.length - 1].nrOrdem == 0
                ? perguntas.length
                : perguntas[perguntas.length - 1].nrOrdem;
          }
        }
      );
    }

    const payload = {
      body: perguntas,
      idAvaliacao: avaliacao.idAvaliacao,
      showModal,
    };

    props.persistPerguntas(payload);
    setTab(tab + 1);
  };

  const handleSaveImageOnS3 = async (file: File) => {
    const form = new FormData();
    form.append("file", file);
    const token = localStorage.getItem("accessToken");

    let payload = {
      loading: true,
      err: null,
    };
    props.setIsLoading(payload);
    try {
      const response = await api.post(
        `avaliacao/upload-midia?idAvaliacao=${avaliacao.idAvaliacao}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      payload.loading = false;
      props.setIsLoading(payload);
      return response.data;
    } catch (error: any) {
      payload.loading = false;
      payload.err = error.message;
      props.setIsLoading(payload);
      return "";
    }
  };

  const handlePersistResposta = () => {
    if (avaliacao.avaliacaoPerguntas) {
      const arrRespostas: AvaliacaoPerguntaRespostaObj[] = [];

      avaliacao.avaliacaoPerguntas.forEach(
        (pergunta: AvaliacaoPerguntaObj, idx: any) => {
          if (pergunta.avaliacaoPerguntaRespostas) {
            pergunta.avaliacaoPerguntaRespostas.forEach(
              (resposta: AvaliacaoPerguntaRespostaObj, _idx: any) => {
                const respostaObj: AvaliacaoPerguntaRespostaObj = {
                  ...resposta,
                  avaliacaoPergunta: {
                    idAvaliacaoPergunta: pergunta.idAvaliacaoPergunta,
                  },
                };
                if (respostaObj.dsAvaliacaoPerguntaResposta != "") {
                  respostaObj.nrOrdem =
                    respostaObj.nrOrdem == 0 ? _idx + 1 : respostaObj.nrOrdem;
                  arrRespostas.push(respostaObj);
                }
              }
            );
          }
        }
      );

      if (arrRespostas.length == 0) {
        alert(
          "Atenção!\nPreencha pelo menos uma reposta por pergunta para continuar!"
        );
        return;
      }

      handleEnvioPerguntas(false);
      props.persisteResposta(arrRespostas);
      handleClose();
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleChangeTabResposta = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabResposta(newValue);
  };

  const handleAddPergunta = () => {
    if (avaliacao.avaliacaoPerguntas) {
      if (
        avaliacao.avaliacaoPerguntas.length == 1 &&
        avaliacao.avaliacaoPerguntas[0].nmAvaliacaoPergunta == ""
      ) {
        alert(
          "Atenção\nVocê precisa preencher pelo menos uma pergunta para adicionar outras!"
        );
        return;
      }

      const defaultPergunta: AvaliacaoPerguntaObj = {
        icPerguntaDissertativa: 0,
        nmAvaliacaoPergunta: "",
        nrOrdem: 0,
        pathMidia: "",
      };

      setAvaliacao({
        ...avaliacao,
        avaliacaoPerguntas: [...avaliacao.avaliacaoPerguntas, defaultPergunta],
      });
    }
  };

  const handleExcluiPergunta = (idx: any) => {
    const aux = avaliacao.avaliacaoPerguntas;

    if (aux) {
      if (aux.length == 1) {
        setAvaliacao({
          ...avaliacao,
          avaliacaoPerguntas: [],
        });

        return;
      }

      aux.splice(idx, 1);
      setAvaliacao({
        ...avaliacao,
        avaliacaoPerguntas: [...aux],
      });
    }
  };

  const handleAddResposta = (idxPergunta: any) => {
    if (avaliacao.avaliacaoPerguntas) {
      const aux =
        avaliacao.avaliacaoPerguntas[idxPergunta].avaliacaoPerguntaRespostas;
      if (aux) {
        if (aux.length == 1 && aux[0].dsAvaliacaoPerguntaResposta == "") {
          alert(
            "Atenção\nVocê precisa preencher pelo menos uma resposta para adicionar outras!"
          );
          return;
        }

        const defaultResposta: AvaliacaoPerguntaRespostaObj = {
          icCorreta: 0,
          dsAvaliacaoPerguntaResposta: "",
          nrOrdem: 0,
        };

        const _aux = avaliacao.avaliacaoPerguntas;
        _aux[idxPergunta].avaliacaoPerguntaRespostas = [
          ...aux,
          defaultResposta,
        ];

        setAvaliacao({
          ...avaliacao,
          avaliacaoPerguntas: _aux,
        });
      }
    }
  };

  const handleExcluiResposta = (idxPergunta: any, idxResposta: any) => {
    if (avaliacao.avaliacaoPerguntas) {
      const aux =
        avaliacao.avaliacaoPerguntas[idxPergunta].avaliacaoPerguntaRespostas;

      if (aux) {
        if (aux.length == 1) {
          const _aux = avaliacao.avaliacaoPerguntas;
          _aux[idxPergunta].avaliacaoPerguntaRespostas = [];

          setAvaliacao({
            ...avaliacao,
            avaliacaoPerguntas: _aux,
          });

          handleAddResposta(idxPergunta);

          return;
        }

        aux.splice(idxResposta, 1);

        const _aux = avaliacao.avaliacaoPerguntas;
        _aux[idxPergunta].avaliacaoPerguntaRespostas = aux;
        setAvaliacao({
          ...avaliacao,
          avaliacaoPerguntas: [..._aux],
        });
      }
    }
  };

  const validaBotaoAddResposta = (idx: number) => {
    if (avaliacao.avaliacaoPerguntas) {
      const arr = avaliacao.avaliacaoPerguntas[idx].avaliacaoPerguntaRespostas;
      if (arr) {
        if (arr.length == 1) {
          if (arr[0].dsAvaliacaoPerguntaResposta == "") {
            return false;
          }
        }
      }
    }
    return true;
  };

  async function downloadMedia(mediaUrl: string, fileName: string) {
    try {
      const response = await fetch(mediaUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      let payload = {
        loading: false,
        err: error.message,
      };
      props.setIsLoading(payload);
      console.error("Failed to download media:", error);
    }
  }

  const getFileNameFromUrl = (url: string): string => {
    const path = url ? new URL(url).pathname : "";
    const fileName = path.split("/").pop();
    return fileName || "";
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? "Editar Avaliação" : "Cadastro de nova Avaliação"}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <TabPanel value={tab} index={0}>
          <form className="modalMid-form" onSubmit={handlePersistAvaliacao}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  sx={{ width: "100%" }}
                  value={titulo}
                  label="Nome da Avaliação"
                  error={nmAvaliacaoErro}
                  helperText={nmAvaliacaoErro ? "Campo Obrigatório." : ""}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setNmAvaliacaoErro(e.target.value === "");
                    setTitulo(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  sx={{ width: "100%" }}
                  value={descricao}
                  label="Descrição da Avaliação"
                  error={dsAvaliacaoErro}
                  helperText={dsAvaliacaoErro ? "Campo Obrigatório." : ""}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDsAvaliacaoErro(e.target.value === "");
                    setDescricao(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtIniAvaliacaoErro(true);
                      } else {
                        setDtIniAvaliacaoErro(false);
                      }
                    }}
                    label="Data de Início"
                    value={avaliacao.dtAvaliacaoInicio}
                    minDate={dayjs("1900-01-01")}
                    onChange={(newValue) => {
                      setDtIniAvaliacaoErro(false);
                      setAvaliacao({
                        ...avaliacao,
                        dtAvaliacaoInicio: newValue?.format(),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={dtIniAvaliacaoErro}
                        helperText={
                          dtIniAvaliacaoErro ? "Campo Obrigatório." : ""
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtFimAvaliacaoErro(true);
                      } else {
                        setDtFimAvaliacaoErro(false);
                      }
                    }}
                    label="Data Final"
                    value={avaliacao.dtAvaliacaoFim}
                    minDate={dayjs("1900-01-01")}
                    onChange={(newValue) => {
                      setDtFimAvaliacaoErro(false);
                      setAvaliacao({
                        ...avaliacao,
                        dtAvaliacaoFim: newValue?.format(),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={dtFimAvaliacaoErro}
                        helperText={
                          dtFimAvaliacaoErro ? "Campo Obrigatório." : ""
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">
                    Tipo da Avaliação
                  </InputLabel>
                  <Select
                    required
                    value={
                      avaliacao.avaliacaoTipo != null
                        ? avaliacao.avaliacaoTipo.idAvaliacaoTipo
                        : 0
                    }
                    label="Tipo da Avaliação"
                    defaultValue={0}
                    error={tpAvaliacaoErro}
                    onChange={(e) => {
                      setTpAvaliacaoErro(e.target.value === 0);
                      setAvaliacao({
                        ...avaliacao,
                        avaliacaoTipo: {
                          idAvaliacaoTipo: parseInt(e.target.value.toString()),
                        },
                      });
                    }}
                  >
                    <MenuItem value={0}>Escolha...</MenuItem>
                    {props.listAvaliacaoTipo
                      ? props.listAvaliacaoTipo.map((item: any, idx: any) => (
                          <MenuItem
                            key={"contTipo-" + idx}
                            value={item.idAvaliacaoTipo}
                          >
                            {item.nmAvaliacaoTipo}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {tpAvaliacaoErro ? (
                    <FormHelperText error={tpAvaliacaoErro}>
                      Campo Obrigatório.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: "100%" }}>
                  <ListaPerfilComponent
                    error={perfilErro}
                    perfis={avaliacao.avaliacaoPerfis}
                    onChangePerfis={(value: any) => {
                      setPerfilErro(value.length === 0);
                      setAvaliacao({
                        ...avaliacao,
                        avaliacaoPerfis: value,
                      });
                    }}
                  />
                  {perfilErro ? (
                    <FormHelperText error={perfilErro}>
                      Campo Obrigatório.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: "100%" }}>
                  <ListaMunicipioComponentTeste
                    error={municipioErro}
                    municipios={municipios_}
                    callbackList={getAvaliacaoDataMunicipioTotal}
                    id={props.id}
                    onChangeMunicipios={(value: any) => {
                      setMunicipioErro(value.length === 0);
                      setAvaliacao({
                        ...avaliacao,
                        avaliacaoMuncipios: value,
                      });
                    }}
                  />
                  {municipioErro ? (
                    <FormHelperText error={municipioErro}>
                      Campo Obrigatório.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl>
                  <FormLabel>Publicar no App</FormLabel>
                  <RadioGroup
                    row
                    value={avaliacao.icLivre}
                    onChange={(e) => {
                      setAvaliacao({
                        ...avaliacao,
                        icLivre: parseInt(e.target.value),
                      });
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="Perguntas aleatórias"
                  checked={avaliacao.icAleatorio === 1}
                  style={{ marginInline: 0 }}
                  onChange={() => {
                    setAvaliacao((state) => ({
                      ...state,
                      icAleatorio: state.icAleatorio === 1 ? 0 : 1,
                    }));
                  }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="Exibir após fim  da vigência"
                  checked={avaliacao.icExibirAvaliacao === 1}
                  style={{ marginInline: 0 }}
                  onChange={() => {
                    setAvaliacao((state) => ({
                      ...state,
                      icExibirAvaliacao: state.icExibirAvaliacao === 1 ? 0 : 1,
                    }));
                  }}
                />
              </Grid>
            </Grid>
            <div className="cadastroFooter" style={{ display: "flex" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={inativaGravar}
              >
                {isEdicao
                  ? "Salvar e Alterar Perguntas"
                  : "Salvar e Cadastrar Perguntas"}
              </Button>
              {isEdicao ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    props.enviaNotificacao(avaliacao.idAvaliacao);
                  }}
                >
                  Enviar Notificação
                </Button>
              ) : null}
              {(isEdicao || avaliacao.idAvaliacao) && (
                <Button
                  variant="contained"
                  type="submit"
                  disabled
                  style={{
                    marginLeft: "auto",
                    userSelect: "text",
                    color: "#d32f2f",
                  }}
                >
                  <b>ID:</b>
                  <strong>{" " + avaliacao.idAvaliacao}</strong>
                </Button>
              )}
            </div>
          </form>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div style={{ maxHeight: 600, overflow: "auto", width: "100%" }}>
            <Typography id="modal-modal-title">
              Importar Perguntas e Respostas
            </Typography>
            <div className="avaliacaoImport">
              <div>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, marginBottom: "5px" }}
                >
                  Modelo de importação:
                </Typography>
                <Link
                  href="https://files-conectado.connectapps.com.br/modelo_importacao_avaliacao.xlsx"
                  underline="none"
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#eb4034" }}
                  >
                    Download
                  </Button>
                </Link>
              </div>
              <div>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, marginBottom: "5px" }}
                >
                  Arquivo:
                </Typography>
                <Input
                  type="file"
                  onChange={(e: any) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  onClick={handleEnvioImportacaoPergunta}
                >
                  Importar
                </Button>
              </div>
            </div>
            <Divider style={{ marginTop: "1em", marginBottom: "1em" }} />
            <div>
              <Typography id="modal-modal-title">
                Cadastrar Perguntas
              </Typography>
              <List className="avaliacaoPerguntaList">
                {avaliacao.avaliacaoPerguntas &&
                avaliacao.avaliacaoPerguntas.length > 0
                  ? avaliacao.avaliacaoPerguntas.map(
                      (
                        avaliacaoPergunta: AvaliacaoPerguntaObj,
                        idx: number
                      ) => (
                        <ListItem key={`avaliacao-pergunta-${idx}`}>
                          {isEdicao && !!avaliacaoPergunta.pathMidia ? (
                            <FormControl sx={{ minWidth: "90px" }}>
                              <Typography
                                id="modal-modal-title"
                                variant="inherit"
                                component="p"
                              >
                                Arquivo
                              </Typography>
                              <div style={{ width: "100%" }}>
                                <Link
                                  sx={{ width: "100%" }}
                                  className="buttonArquivo"
                                  component="button"
                                  onClick={() =>
                                    downloadMedia(
                                      avaliacaoPergunta.pathMidia!,
                                      getFileNameFromUrl(
                                        avaliacaoPergunta.pathMidia!
                                      )
                                    )
                                  }
                                  underline="none"
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      backgroundColor: "#eb4034",
                                      width: "100%",
                                    }}
                                  >
                                    Download
                                  </Button>
                                </Link>
                                <Button
                                  sx={{ width: "100%" }}
                                  variant="text"
                                  color="error"
                                  onClick={() => {
                                    let auxArr = avaliacao.avaliacaoPerguntas;
                                    if (auxArr) {
                                      auxArr[idx].pathMidia = "";
                                      setAvaliacao({
                                        ...avaliacao,
                                        avaliacaoPerguntas: auxArr,
                                      });
                                    }
                                    setTpMidiaError(true);
                                  }}
                                >
                                  Remover
                                </Button>
                              </div>
                            </FormControl>
                          ) : (
                            <FormControl sx={{ minWidth: "90px" }}>
                              <Typography
                                id="modal-modal-title"
                                variant="inherit"
                                component="p"
                              >
                                Midia
                              </Typography>
                              <Input
                                type="file"
                                inputProps={{
                                  accept: "image/*,video/*,.pdf,audio/*,.gif",
                                }}
                                placeholder="Mídia"
                                title="Mídia"
                                error={tpMidiaError}
                                sx={{
                                  alignSelf: "flex-end",
                                }}
                                onChange={async (e: any) => {
                                  let auxArr = avaliacao.avaliacaoPerguntas;
                                  if (Array.from(e.target.files).length !== 0) {
                                    setTpMidiaError(
                                      Array.from(e.target.files).length === 0
                                    );
                                    if (
                                      validaNomeArquivo(e.target.files[0].name)
                                    ) {
                                      const pathMidia =
                                        await handleSaveImageOnS3(
                                          e.target.files[0]!
                                        );

                                      auxArr![idx].pathMidia = pathMidia;
                                      setAvaliacao({
                                        ...avaliacao,
                                        avaliacaoPerguntas: auxArr,
                                      });
                                    } else {
                                      e.target.value = "";
                                      setTpMidiaError(true);
                                    }
                                  } else {
                                    e.target.value = "";
                                    setTpMidiaError(true);
                                  }
                                }}
                              />
                            </FormControl>
                          )}
                          <TextField
                            required
                            style={{ width: 400, marginLeft: "1em" }}
                            value={avaliacaoPergunta.nmAvaliacaoPergunta}
                            label="Pergunta"
                            onChange={(e) => {
                              let auxArr = avaliacao.avaliacaoPerguntas;
                              if (auxArr) {
                                auxArr[idx].nmAvaliacaoPergunta =
                                  e.target.value;
                                setAvaliacao({
                                  ...avaliacao,
                                  avaliacaoPerguntas: auxArr,
                                });
                              }
                            }}
                          />
                          <FormControl sx={{ width: 200, marginLeft: "1em" }}>
                            <InputLabel id="demo-controlled-open-select-label">
                              Ordem da Pergunta
                            </InputLabel>
                            <Select
                              value={
                                avaliacaoPergunta.nrOrdem != 0
                                  ? avaliacaoPergunta.nrOrdem
                                  : idx + 1
                              }
                              label="Ordem da Pergunta"
                              onChange={(e) => {
                                let auxArr = avaliacao.avaliacaoPerguntas;
                                if (auxArr) {
                                  auxArr[idx].nrOrdem = parseInt(
                                    e.target.value.toString()
                                  );
                                  setAvaliacao({
                                    ...avaliacao,
                                    avaliacaoPerguntas: auxArr,
                                  });
                                }
                              }}
                            >
                              {avaliacao.avaliacaoPerguntas
                                ? avaliacao.avaliacaoPerguntas.map(
                                    (item: any, _idx: any) => (
                                      <MenuItem
                                        key={`listPerguntaOrdem-${idx}-${_idx}`}
                                        value={_idx + 1}
                                      >
                                        {_idx + 1}
                                      </MenuItem>
                                    )
                                  )
                                : null}
                            </Select>
                          </FormControl>
                          <FormControlLabel
                            sx={{ marginLeft: "1em" }}
                            control={
                              <Checkbox
                                checked={
                                  avaliacaoPergunta.icPerguntaDissertativa == 1
                                }
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  let auxArr = avaliacao.avaliacaoPerguntas;
                                  if (auxArr) {
                                    auxArr[idx].icPerguntaDissertativa = e
                                      .target.checked
                                      ? 1
                                      : 0;
                                    setAvaliacao({
                                      ...avaliacao,
                                      avaliacaoPerguntas: auxArr,
                                    });
                                  }
                                }}
                              />
                            }
                            label="Dissertativa"
                          />
                          <FormControl sx={{ marginLeft: "1em" }}>
                            <div>
                              {!(
                                avaliacao.avaliacaoPerguntas &&
                                avaliacao.avaliacaoPerguntas.length == 1 &&
                                avaliacao.avaliacaoPerguntas[0]
                                  .nmAvaliacaoPergunta == ""
                              ) || avaliacaoPergunta.idAvaliacaoPergunta ? (
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleExcluiPergunta(idx)}
                                  size="large"
                                >
                                  <Icon>clear</Icon>
                                </IconButton>
                              ) : null}
                              <IconButton
                                sx={{ marginLeft: "0.5em" }}
                                color="success"
                                onClick={handleAddPergunta}
                                size="large"
                              >
                                <Icon>add</Icon>
                              </IconButton>
                            </div>
                          </FormControl>
                        </ListItem>
                      )
                    )
                  : null}
              </List>
              <div
                className="cadastroFooter"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  disabled={
                    avaliacao.avaliacaoPerguntas &&
                    avaliacao.avaliacaoPerguntas.length == 1 &&
                    avaliacao.avaliacaoPerguntas[0].nmAvaliacaoPergunta == ""
                  }
                  variant="contained"
                  onClick={() => handleEnvioPerguntas(true)}
                >
                  {isEdicao
                    ? "Salvar e Alterar Respostas"
                    : "Salvar e Cadastrar Respostas"}
                </Button>
                {(isEdicao || avaliacao.idAvaliacao) && (
                  <Button
                    variant="contained"
                    type="submit"
                    disabled
                    style={{
                      marginLeft: "auto",
                      userSelect: "text",
                      color: "#d32f2f",
                    }}
                  >
                    <b>ID:</b>
                    <strong>{" " + avaliacao.idAvaliacao}</strong>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Box>
            <Tabs
              value={tabResposta}
              onChange={handleChangeTabResposta}
              textColor="secondary"
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {disableRespostas &&
              avaliacao.avaliacaoPerguntas &&
              avaliacao.avaliacaoPerguntas.length > 0
                ? avaliacao.avaliacaoPerguntas.map(
                    (avaliacaoPergunta: AvaliacaoPerguntaObj, idx: number) =>
                      avaliacaoPergunta.icPerguntaDissertativa == 0 ? (
                        <Tab
                          wrapped
                          className="tabComponent"
                          key={`tab-resposta-${avaliacaoPergunta.idAvaliacaoPergunta}`}
                          label={`${avaliacaoPergunta.nrOrdem} - ${avaliacaoPergunta.nmAvaliacaoPergunta}`}
                          value={idx}
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            display: "inline-block",
                            width: "300px",
                            "-ms-flex-direction": "unset",
                            flexDirection: "unset",
                          }}
                        />
                      ) : null
                  )
                : null}
            </Tabs>
            <div style={{ maxHeight: 600, overflow: "auto", width: "100%" }}>
              {disableRespostas &&
              avaliacao.avaliacaoPerguntas &&
              avaliacao.avaliacaoPerguntas.length > 0
                ? avaliacao.avaliacaoPerguntas.map(
                    (avaliacaoPergunta: AvaliacaoPerguntaObj, idx: number) =>
                      avaliacaoPergunta.icPerguntaDissertativa == 0 ? (
                        <TabPanel
                          key={`tab-panel-resposta-${avaliacaoPergunta.idAvaliacaoPergunta}`}
                          value={tabResposta}
                          index={idx}
                        >
                          <List className="avaliacaoPerguntaList">
                            {avaliacaoPergunta.avaliacaoPerguntaRespostas &&
                            avaliacaoPergunta.avaliacaoPerguntaRespostas
                              .length > 0
                              ? avaliacaoPergunta.avaliacaoPerguntaRespostas.map(
                                  (
                                    perguntaResposta: AvaliacaoPerguntaRespostaObj,
                                    _idx: number
                                  ) => (
                                    <ListItem
                                      key={`avaliacao-pergunta-resposta-${_idx}`}
                                    >
                                      <TextField
                                        required
                                        style={{ width: 400 }}
                                        value={
                                          perguntaResposta.dsAvaliacaoPerguntaResposta
                                        }
                                        label="Resposta"
                                        onChange={(e) => {
                                          if (avaliacao.avaliacaoPerguntas) {
                                            let auxArr =
                                              avaliacao.avaliacaoPerguntas[idx]
                                                .avaliacaoPerguntaRespostas;
                                            if (auxArr) {
                                              auxArr[
                                                _idx
                                              ].dsAvaliacaoPerguntaResposta =
                                                e.target.value;

                                              const _aux =
                                                avaliacao.avaliacaoPerguntas;
                                              _aux[
                                                idx
                                              ].avaliacaoPerguntaRespostas =
                                                auxArr;

                                              setAvaliacao({
                                                ...avaliacao,
                                                avaliacaoPerguntas: _aux,
                                              });
                                            }
                                          }
                                        }}
                                      />
                                      <FormControl
                                        sx={{ width: 200, marginLeft: "1em" }}
                                      >
                                        <InputLabel id="demo-controlled-open-select-label">
                                          Ordem da Resposta
                                        </InputLabel>
                                        <Select
                                          value={
                                            perguntaResposta.nrOrdem != 0
                                              ? perguntaResposta.nrOrdem
                                              : _idx + 1
                                          }
                                          label="Ordem da Resposta"
                                          onChange={(e) => {
                                            if (avaliacao.avaliacaoPerguntas) {
                                              let auxArr =
                                                avaliacao.avaliacaoPerguntas[
                                                  idx
                                                ].avaliacaoPerguntaRespostas;
                                              if (auxArr) {
                                                auxArr[_idx].nrOrdem = parseInt(
                                                  e.target.value.toString()
                                                );

                                                const _aux =
                                                  avaliacao.avaliacaoPerguntas;
                                                _aux[
                                                  idx
                                                ].avaliacaoPerguntaRespostas =
                                                  auxArr;

                                                setAvaliacao({
                                                  ...avaliacao,
                                                  avaliacaoPerguntas: _aux,
                                                });
                                              }
                                            }
                                          }}
                                        >
                                          {avaliacaoPergunta &&
                                          avaliacaoPergunta.avaliacaoPerguntaRespostas
                                            ? avaliacaoPergunta.avaliacaoPerguntaRespostas.map(
                                                (item: any, __idx: any) => (
                                                  <MenuItem
                                                    key={`listRespostaOrdem-${_idx}-${__idx}`}
                                                    value={__idx + 1}
                                                  >
                                                    {__idx + 1}
                                                  </MenuItem>
                                                )
                                              )
                                            : null}
                                        </Select>
                                      </FormControl>
                                      <FormControlLabel
                                        sx={{ marginLeft: "1em" }}
                                        control={
                                          <Checkbox
                                            checked={
                                              perguntaResposta.icCorreta == 1
                                            }
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                              if (
                                                avaliacao.avaliacaoPerguntas
                                              ) {
                                                let auxArr =
                                                  avaliacao.avaliacaoPerguntas[
                                                    idx
                                                  ].avaliacaoPerguntaRespostas;
                                                if (auxArr) {
                                                  auxArr[_idx].icCorreta = e
                                                    .target.checked
                                                    ? 1
                                                    : 0;

                                                  const _aux =
                                                    avaliacao.avaliacaoPerguntas;
                                                  _aux[
                                                    idx
                                                  ].avaliacaoPerguntaRespostas =
                                                    auxArr;

                                                  setAvaliacao({
                                                    ...avaliacao,
                                                    avaliacaoPerguntas: _aux,
                                                  });
                                                }
                                              }
                                            }}
                                          />
                                        }
                                        label="Correta"
                                      />
                                      <FormControl sx={{ marginLeft: "1em" }}>
                                        <div>
                                          {validaBotaoAddResposta(idx) ||
                                          perguntaResposta.idAvaliacaoPerguntaResposta ? (
                                            <IconButton
                                              color="secondary"
                                              onClick={() =>
                                                handleExcluiResposta(idx, _idx)
                                              }
                                              size="large"
                                            >
                                              <Icon>clear</Icon>
                                            </IconButton>
                                          ) : null}
                                          <IconButton
                                            sx={{ marginLeft: "0.5em" }}
                                            color="success"
                                            onClick={() =>
                                              handleAddResposta(idx)
                                            }
                                            size="large"
                                          >
                                            <Icon>add</Icon>
                                          </IconButton>
                                        </div>
                                      </FormControl>
                                    </ListItem>
                                  )
                                )
                              : null}
                          </List>
                        </TabPanel>
                      ) : null
                  )
                : null}
            </div>
            <div
              className="cadastroFooter"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                disabled={
                  avaliacao.avaliacaoPerguntas &&
                  avaliacao.avaliacaoPerguntas.length == 1 &&
                  avaliacao.avaliacaoPerguntas[0].nmAvaliacaoPergunta == ""
                }
                variant="contained"
                onClick={handlePersistResposta}
              >
                Salvar Respostas
              </Button>
              {tab === 2 && (
                <TextField
                  style={{ flex: 1 }}
                  label="Explicação da resposta"
                  value={
                    avaliacao.avaliacaoPerguntas![tabResposta]
                      .feedbackPergunta ?? ""
                  }
                  onChange={(self) => {
                    setAvaliacao((state) => ({
                      ...state,
                      avaliacaoPerguntas: state.avaliacaoPerguntas?.map(
                        (pergunta, index) => {
                          if (index === tabResposta) {
                            return {
                              ...pergunta,
                              feedbackPergunta: self.target.value,
                            };
                          }
                          return pergunta;
                        }
                      ),
                    }));
                  }}
                />
              )}
              {(isEdicao || avaliacao.idAvaliacao) && (
                <Button
                  variant="contained"
                  type="submit"
                  disabled
                  style={{
                    marginLeft: "auto",
                    userSelect: "text",
                    color: "#d32f2f",
                  }}
                >
                  <b>ID:</b>
                  <strong>{" " + avaliacao.idAvaliacao}</strong>
                </Button>
              )}
            </div>
          </Box>
        </TabPanel>
        <Divider style={{ marginTop: "1em" }} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab className="tabComponent" label="Informações" />
            <Tab
              className="tabComponent"
              label="Perguntas"
              disabled={!avaliacao.idAvaliacao}
            />
            <Tab
              className="tabComponent"
              label="Respostas"
              disabled={!disableRespostas}
            />
          </Tabs>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalCadastroAvaliacao;
