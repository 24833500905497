import React, { useEffect,  useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import 'dayjs/locale/pt-br'

import { FormControlLabel, FormHelperText, FormLabel, InputLabel, List, ListItem, MenuItem, Radio, RadioGroup, Select, TextareaAutosize } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";
import { ChatBotCategoriaObj } from "../../store/ducks/chatbot/categoria/types";
import { ChatBotLinkAuxiliarDTO } from "../../store/ducks/chatbot/types";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";
import ListaConteudoComponent from "../ListaConteudoComponent";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const ModalCadastroCategoria = (props: any) => {

    const [isEdicao, setIsEdicao] = useState(false);
    const [categoria, setCategoria] = useState<ChatBotCategoriaObj>({linksAuxiliares: [{}]});
    const [conteudoContexto, setConteudoContexto] = useState('');
    const [inativaGravar, setInativaGravar] = useState(true);
    const [tituloErro, setTituloErro] = useState(false);
    const [contextoErro, setContextoErro] = useState(false);
    const [chaveErro, setChaveErro] = useState(false);
    const [isConteudoInterno, setIsConteudoInterno] = useState(true);

    useEffect(() => {
        if(categoria.linksAuxiliares && categoria.linksAuxiliares.length === 0){
            handleAddLinkAuxiliar();
        }
    }, [categoria.linksAuxiliares]);

    useEffect(() => {
        if(props.categoriaData){   
            handleRender(props.categoriaData);
        }
    }, [props.categoriaData]);

    useEffect(() => {
        if(!props.modalCadastroOpen){   
            handleClose();
        }
    }, [props.modalCadastroOpen]);

    useEffect(() => {
        setChaveErro(props.errorChave);
    }, [props.errorChave])

    useEffect(() => {
        if(!tituloErro && !contextoErro && !chaveErro && categoria.keyCategoria != undefined) {
            setInativaGravar(false);
        } else {
            setInativaGravar(true);
        }
    }, [categoria, tituloErro, contextoErro, chaveErro])

    const handleRender = (categoriaData: any) => {
        if(categoriaData){
            setIsEdicao(true)
            setCategoria({
                ...categoriaData,
            });
            setConteudoContexto('');
            setIsConteudoInterno(categoriaData.idConteudoInterno != null || categoriaData.linkConteudoContexto == null);
        }
    } 
    
    const handleClose = () => {
        setIsEdicao(false);
        setCategoria({linksAuxiliares: []});
        setConteudoContexto('');
        setInativaGravar(true);
        setTituloErro(false);
        setContextoErro(false);
        setIsConteudoInterno(true);

        props.closeModal();
    }

    const handlePersist = (e: any) => {
        e.preventDefault();

        const formCategoria = new FormData();

        if(categoria.linksAuxiliares?.length == 1){
            if(!(categoria.linksAuxiliares[0].descricao && categoria.linksAuxiliares[0].link)){
                categoria.linksAuxiliares = undefined;
            }
        }

        formCategoria.append('categoria', JSON.stringify(categoria));

        if(conteudoContexto != ''){
            formCategoria.append('arquivo', conteudoContexto);
        }

        props.persist(isEdicao, formCategoria);
    }

    const handleAddLinkAuxiliar = () => {
        if (categoria.linksAuxiliares) {
            if (categoria.linksAuxiliares.length == 1 && !categoria.linksAuxiliares[0].descricao && !categoria.linksAuxiliares[0].link) {
                alert(
                    "Atenção\nVocê precisa preencher pelo menos um link auxiliar para adicionar outros!"
                );
                return;
            }

            setCategoria({
                ...categoria,
                linksAuxiliares: [...categoria.linksAuxiliares, {}],
            });
        }
    };

    const handleExcluiLinkAuxiliar = (idx: any) => {
        const aux = categoria.linksAuxiliares;
    
        if (aux) {
            if(aux[idx].idLinkAuxiliar){
                props.onDeleteLinkAuxiliar(aux[idx].idLinkAuxiliar);
            }
            if (aux.length == 1) {
                setCategoria({
                    ...categoria,
                    linksAuxiliares: [],
                });

                return;
            }

            aux.splice(idx, 1);
            setCategoria({
                ...categoria,
                linksAuxiliares: [...aux],
            });
        }
    };

    return (
        <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
            if(res !== 'backdropClick') {
                handleClose()
            }
            }}>
            <Box sx={style} >
                <div className="modalHead">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isEdicao ? 'Editar Categoria' : 'Cadastrar Categoria'}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Fechar
                    </Button>
                </div>
                <Divider />
                <form className="modalMid-form" onSubmit={handlePersist}>
                        <Grid container spacing={2}>
                        <Grid item xs={4} >
                                <TextField
                                    required
                                    sx={{width: '100%'}}
                                    value={categoria.titulo ?? ""}
                                    label="Titulo da Categoria"
                                    error={tituloErro}
                                    helperText={tituloErro ? "Campo Obrigatório." : ''}
                                    onChange={(e) => {
                                        setTituloErro(e.target.value === "");
                                        setCategoria({
                                            ...categoria,
                                            titulo: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: '100%' }} >
                                    <TextareaAutosize 
                                        style={{width: '100%', }}
                                        value={categoria.contexto ?? ""}
                                        minRows={5}
                                        placeholder="Contexto da Categoria"
                                        onChange={(e) => {
                                            setContextoErro(e.target.value === "");
                                            setCategoria({
                                                ...categoria,
                                                contexto: e.target.value
                                            })
                                        }}
                                    />
                                    {contextoErro ? <FormHelperText error={contextoErro}>Campo Obrigatório.</FormHelperText> : null}
                                </FormControl>
                                
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-controlled-open-select-label">
                                        Chave de seleção da Categoria
                                    </InputLabel>
                                    <Select
                                        value={categoria.keyCategoria ?? ""}
                                        label="Chave de seleção da Categoria"
                                        onChange={(e) => {
                                            setChaveErro(e.target.value === "");
                                            if(e.target.value !== "") {
                                                props.onChangeChave(EntidadesAcoes.CATEGORIA, {
                                                    chave: e.target.value,
                                                    idCategoria: categoria.idCategoria ?? null
                                                })
                                                setCategoria({ 
                                                    ...categoria,
                                                    keyCategoria: parseInt(e.target.value.toString())
                                                })
                                            }
                                        }}
                                    >
                                    {[...Array(props.qtdChave)].map((item: any, _idx: any) => (
                                        <MenuItem
                                            key={`listChave-${_idx}`}
                                            value={_idx + 1}
                                        >
                                            {_idx + 1}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    {chaveErro ? <FormHelperText error={chaveErro}>Campo Obrigatório.</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} >
                                <FormControl>
                                    <FormLabel>Conteúdo de Contexto</FormLabel>
                                    <RadioGroup
                                        row
                                        value={isConteudoInterno}
                                        onChange={(e) => {
                                            setIsConteudoInterno(JSON.parse(e.target.value));
                                        }}
                                        >
                                        <FormControlLabel value={true} control={<Radio />} label="Conteúdo Interno" />
                                        <FormControlLabel value={false} control={<Radio />} label="Upload de Conteúdo" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {
                                isConteudoInterno ? (
                                    <Grid item xs={5}>
                                        <FormControl style={{ width: "100%", display: "flex", justifyContent: "center"}}>
                                            <Typography id="modal-modal-title" variant="inherit" component="p">
                                                Conteúdo Interno
                                            </Typography>
                                            <ListaConteudoComponent
                                                conteudos={{idConteudo: categoria.idConteudoInterno}}
                                                multiple={false}
                                                onChangeConteudos={(value: any) => {
                                                    setCategoria({
                                                        ...categoria, 
                                                        idConteudoInterno: value[0] ? value[0].idConteudo : null,
                                                        linkConteudoContexto: null
                                                    })
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <Grid item xs={5} >
                                        {
                                            isEdicao && categoria.linkConteudoContexto && conteudoContexto == '' ? (
                                                <FormControl sx={{width: '100%'}} >
                                                    <Typography id="modal-modal-title" variant="inherit" component="p">
                                                        Conteúdo
                                                    </Typography>
                                                    <div>
                                                        <Link className="buttonArquivo" href={categoria.linkConteudoContexto} underline="none" target="_blank" >
                                                            <Button  variant="contained" sx={{backgroundColor: '#eb4034'}} >Download</Button>
                                                        </Link>
                                                        <Button variant="text" color="error" onClick={() => {
                                                            setCategoria({
                                                                ...categoria,
                                                                linkConteudoContexto: null
                                                            })
                                                        }}>Remover</Button>
                                                    </div>
                                                </FormControl>
                                            ) : (
                                                <FormControl sx={{width: '100%'}} >
                                                    <Typography id="modal-modal-title" variant="inherit" component="p">
                                                        Upload de Conteúdo
                                                    </Typography>
                                                    <Input type='file' onChange={(e: any) => {
                                                        if(Array.from(e.target.files).length !== 0 ) {
                                                            if(validaNomeArquivo(e.target.files[0].name)) {
                                                                setCategoria({
                                                                    ...categoria,
                                                                    linkConteudoContexto: e.target.files[0].name || '',
                                                                    idConteudoInterno: null
                                                                })
                                                                setConteudoContexto(e.target.files[0])
                                                            } else {
                                                                e.target.value = "";
                                                            }
                                                        } else {
                                                            e.target.value = "";
                                                        }
                                                    }} />
                                            </FormControl> 
                                            )
                                        }
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} >
                                <FormControl sx={{width: '100%'}} >
                                    <Typography id="modal-modal-title" variant="inherit" component="p">
                                        Link(s) auxiliar(es)
                                    </Typography>
                                    <div>
                                        <List className="list-link-aux">
                                            {
                                                categoria.linksAuxiliares ? categoria.linksAuxiliares.map((value: ChatBotLinkAuxiliarDTO, index: number) => (
                                                    <ListItem  key={`list-link-aux-${index}`}>
                                                        <TextField
                                                            sx={{width: '40%', }}
                                                            value={value.descricao ?? ""}
                                                            label="Descrição Link Auxiliar"
                                                            onChange={(e) => {
                                                                if(categoria.linksAuxiliares) {
                                                                    let auxArr = categoria.linksAuxiliares;
                                                                    auxArr[index].descricao = e.target.value;
                                                                    setCategoria({
                                                                        ...categoria,
                                                                        linksAuxiliares: auxArr
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                        <TextField
                                                            sx={{width: '40%', marginLeft: "1em"}}
                                                            value={value.link ?? ""}
                                                            label="Link Auxiliar"
                                                            onChange={(e) => {
                                                                if(categoria.linksAuxiliares) {
                                                                    let auxArr = categoria.linksAuxiliares;
                                                                    auxArr[index].link = e.target.value;
                                                                    setCategoria({
                                                                        ...categoria,
                                                                        linksAuxiliares: auxArr
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                        <FormControl sx={{ marginLeft: "1em" }}>
                                                            <div>
                                                                {!(
                                                                    categoria.linksAuxiliares &&
                                                                    categoria.linksAuxiliares.length == 1
                                                                ) || value.idLinkAuxiliar ? (
                                                                    <IconButton
                                                                        color="secondary"
                                                                        onClick={() => handleExcluiLinkAuxiliar(index)}
                                                                        size="large"
                                                                    >
                                                                        <Icon>clear</Icon>
                                                                    </IconButton>
                                                                ) : null}
                                                                <IconButton
                                                                    sx={{ marginLeft: "0.5em" }}
                                                                    color="success"
                                                                    onClick={handleAddLinkAuxiliar}
                                                                    size="large"
                                                                >
                                                                    <Icon>add</Icon>
                                                                </IconButton>
                                                            </div>
                                                        </FormControl>
                                                    </ListItem>
                                                )) : null
                                            }
                                        </List>
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                    <Divider style={{marginTop: '1em'}} />

                    <div className="cadastroFooter">
                        <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar' }</Button>
                    </div>
                </form>
            </Box>
            
        </Modal>
    )

};

export default ModalCadastroCategoria;