import { Dayjs } from "dayjs"

export enum T1Types {
    T1_TABLE_REQUEST = 't1/T1_TABLE_REQUEST',
    T1_TABLE_REQUEST_SUCCESS = 't1/T1_TABLE_RQUEST_SUCCESS',
    T1_TABLE_REQUEST_FAILURE = 't1/T1_TABLE_REQUEST_FAILURE',

    T1_ATIVA_REQUEST = 't1/T1_ATIVA_REQUEST',
    T1_ATIVA_REQUEST_SUCCESS = 't1/T1_ATIVA_REQUEST_SUCCESS',
    T1_ATIVA_REQUEST_FAILURE = 't1/T1_ATIVA_REQUEST_FAILURE',

    T1_INATIVA_REQUEST = 't1/T1_INATIVA_REQUEST',
    T1_INATIVA_REQUEST_SUCCESS = 't1/T1_INATIVA_REQUEST_SUCCESS',
    T1_INATIVA_REQUEST_FAILURE = 't1/T1_INATIVA_REQUEST_FAILURE',
    
    CLEAR_T1_DATA = 't1/CLEAR_T1_DATA',

    T1_DATA_REQUEST = 't1/T1_DATA_REQUEST',
    T1_DATA_REQUEST_SUCCESS = 't1/T1_DATA_REQUEST_SUCCESS',
    T1_DATA_REQUEST_FAILURE = 't1/T1_DATA_REQUEST_FAILURE',

    T1_SAVE_REQUEST = 't1/T1_SAVE_REQUEST',
    T1_SAVE_REQUEST_SUCCESS = 't1/T1_SAVE_REQUEST_SUCCESS',
    T1_SAVE_REQUEST_FAILURE = 't1/T1_SAVE_REQUEST_FAILURE',

    T1_UPDATE_REQUEST = 't1/T1_UPDATE_REQUEST',
    T1_UPDATE_REQUEST_SUCCESS = 't1/T1_UPDATE_REQUEST_SUCCESS',
    T1_UPDATE_REQUEST_FAILURE = 't1/T1_UPDATE_REQUEST_FAILURE',

    T1_PUSH_REQUEST = 't1/T1_PUSH_REQUEST',
    T1_PUSH_REQUEST_SUCCESS = 't1/T1_PUSH_REQUEST_SUCCESS',
    T1_PUSH_REQUEST_FAILURE = 't1/T1_PUSH_REQUEST_FAILURE',
}

export interface T1ObjTable {
    id?: number,
    idCursoT1?: number,
    nmCursoT1?: string,
    dsCursoT1?: string,
    dsTempoEstimado?: string
    nmTema?: string,
    qtdConteudos?: number
}


export interface T1Obj {
    idCursoT1?: number,
    nmCursoT1?: string,
    dsCursoT1?: string,
    dtCursoT1Inicio?: null | Dayjs | string,
	dtCursoT1Fim?: null | Dayjs | string,
    dsTempoEstimado?: string,
    tema?: any | null,
    imgCursoT1?: string | null,
    urlCompartilhamentoCursoT1?: string,
	idDatabaseStatus?: number | null,
    cursoT1Muncipios?: any | null,
    cursoT1Perfis?: any | null,
    cursoT1Conteudos?: any | null,
    cursoT1Avaliacoes?: any | null,
}


export interface T1Table {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface T1Page {
    readonly t1: T1Obj | null,
    readonly table: T1Table | null,
}

export interface T1State {
    readonly data: T1Page,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}