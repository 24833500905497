import { ChatBotCategoriaList } from "../categoria/types"
import { ChatBotLinkAuxiliarDTO } from "../types"

export enum ChatBotNivelTypes {
    CHATBOT_NIVEL_TABLE_REQ = 'chatbot/nivel/CHATBOT_NIVEL_TABLE_REQ',
    CHATBOT_NIVEL_TABLE_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_TABLE_REQ_SUCCESS',
    CHATBOT_NIVEL_TABLE_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_TABLE_REQ_FAILURE',

    CHATBOT_NIVEL_REQ = 'chatbot/nivel/CHATBOT_NIVEL_REQ',
    CHATBOT_NIVEL_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_REQ_SUCCESS',
    CHATBOT_NIVEL_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_REQ_FAILURE',

    CHATBOT_NIVEL_SAVE_REQ = 'chatbot/nivel/CHATBOT_NIVEL_SAVE_REQ',
    CHATBOT_NIVEL_SAVE_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_SAVE_REQ_SUCCESS',
    CHATBOT_NIVEL_SAVE_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_SAVE_REQ_FAILURE',

    CHATBOT_NIVEL_UPDATE_REQ = 'chatbot/nivel/CHATBOT_NIVEL_UPDATE_REQ',
    CHATBOT_NIVEL_UPDATE_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_UPDATE_REQ_SUCCESS',
    CHATBOT_NIVEL_UPDATE_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_UPDATE_REQ_FAILURE',

    CHATBOT_NIVEL_DELETE_REQ = 'chatbot/nivel/CHATBOT_NIVEL_DELETE_REQ',
    CHATBOT_NIVEL_DELETE_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_DELETE_REQ_SUCCESS',
    CHATBOT_NIVEL_DELETE_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_DELETE_REQ_FAILURE',

    CHATBOT_NIVEL_KEY_REQ = 'chatbot/nivel/CHATBOT_NIVEL_KEY_REQ',
    CHATBOT_NIVEL_KEY_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_KEY_REQ_SUCCESS',
    CHATBOT_NIVEL_KEY_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_KEY_REQ_FAILURE',

    CHATBOT_NIVEL_LIST_REQ = 'chatbot/nivel/CHATBOT_NIVEL_LIST_REQ',
    CHATBOT_NIVEL_LIST_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_LIST_REQ_SUCCESS',
    CHATBOT_NIVEL_LIST_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_LIST_REQ_FAILURE',

    CHATBOT_NIVEL_QTD_CHAVES_REQ = 'chatbot/nivel/CHATBOT_NIVEL_QTD_CHAVES_REQ',
    CHATBOT_NIVEL_QTD_CHAVES_REQ_SUCCESS = 'chatbot/nivel/CHATBOT_NIVEL_QTD_CHAVES_REQ_SUCCESS',
    CHATBOT_NIVEL_QTD_CHAVES_REQ_FAILURE = 'chatbot/nivel/CHATBOT_NIVEL_QTD_CHAVES_REQ_FAILURE',

    CLEAR_CHATBOT_NIVEL = 'chatbot/nivel/CLEAR_CHATBOT_NIVEL',
}

export interface ReqParamsChaveEmUso {
    chave: number,
    idCategoria?: number,
    idNivelPai?:  number,
    idNivel?: number,
}

export interface ChatBotNivelList {
    idNivel: number,
    titulo: string,
}

export interface ChatBotNivelObjTable {
    id: number,
    idNivel: number,
    titulo: string,
    contexto: string,
    key: number,
    subRows: Array<ChatBotNivelObjTable>
}

export interface ChatBotNivelTable {
    [x: string]: any
    content: Array<ChatBotNivelObjTable>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface ChatBotNivelPage {
    readonly nivel: ChatBotNivelObj | null,
    readonly table: ChatBotNivelTable | null
    readonly lista: Array<ChatBotNivelList>,
    readonly qtdChave: number
}

export interface ChatBotNivelObj {
    idNivel?: number,
    titulo?: string,
    contexto?: string,
    nivelPai?: ChatBotNivelList | null,
    categoria?: ChatBotCategoriaList | null,
    keyNivel?: number,
    linkConteudoContexto?: string | null,
    idConteudoInterno?: number | null,
    linksAuxiliares?: Array<ChatBotLinkAuxiliarDTO>,
    shouldShowFeedback?: boolean | null
}

export interface ChatBotNivelState {
    readonly data: ChatBotNivelPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly errorChave: boolean,
}