import { Reducer } from "@reduxjs/toolkit";
import { ConteudoState, ConteudoTypes } from "./types";

const INITIAL_STATE: ConteudoState = {
    data: {
        table: null,
        conteudo: null,
        conteudoTipo: []
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<ConteudoState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ConteudoTypes.CONTEUDO_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case ConteudoTypes.CONTEUDO_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case ConteudoTypes.CONTEUDO_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case ConteudoTypes.CONTEUDO_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case ConteudoTypes.CONTEUDO_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case ConteudoTypes.CONTEUDO_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case ConteudoTypes.CONTEUDO_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case ConteudoTypes.CONTEUDO_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case ConteudoTypes.CONTEUDO_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case ConteudoTypes.CONTEUDO_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ConteudoTypes.CONTEUDO_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    conteudo: action.payload.data
                }
            };
        case ConteudoTypes.CONTEUDO_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    conteudo: null
                }
            };


        case ConteudoTypes.CLEAR_CONTEUDO_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    conteudo: null
                }
            };

        case ConteudoTypes.CONTEUDO_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case ConteudoTypes.CONTEUDO_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Conteudo realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    conteudo: action.payload.data
                }
            };
        case ConteudoTypes.CONTEUDO_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case ConteudoTypes.CONTEUDO_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case ConteudoTypes.CONTEUDO_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Conteudo realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    conteudo: action.payload.data
                }
            };
        case ConteudoTypes.CONTEUDO_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case ConteudoTypes.CONTEUDO_PUSH_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ConteudoTypes.CONTEUDO_PUSH_REQUEST_SUCCESS:
            alert('As notificações entraram na fila para envio! Pode levar alguns minutos para que os usuários recebam.');
            return {
                ...state,
                error: false,
                loading: false,
            };
        case ConteudoTypes.CONTEUDO_PUSH_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
            };


        case ConteudoTypes.CONTEUDO_TIPO_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ConteudoTypes.CONTEUDO_TIPO_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    conteudoTipo: action.payload.data
                }
            };
        case ConteudoTypes.CONTEUDO_TIPO_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    conteudoTipo: null
                }
            };

        default :
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
};

export default reducer;