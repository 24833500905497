import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getCursoDataFailure,
    getCursoDataSuccess,
    getCursoTableFailure,
    getCursoTableSuccess,
    onAtivarCursoFailure,
    onAtivarCursoSuccess,
    onInativarCursoFailure,
    onInativarCursoSuccess,
    onSaveCursoFailure, 
    onSaveCursoSuccess,
    onUpdateCursoFailure,
    onUpdateCursoSuccess,
    onNotificacaoCursoFailure,
    onNotificacaoCursoSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getCursoTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('curso/listAllCursoTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getCursoTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getCursoTableFailure(error.message));
    }
};


function* onInativarCurso(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('curso/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarCursoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarCursoFailure(error.message));
    }
};

function* onAtivarCurso(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('curso/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarCursoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarCursoFailure(error.message));
    }
};

function* getCursoData(data: any): any {
    const idCurso = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`curso/findById?idCurso=${idCurso}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getCursoDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getCursoDataFailure(error.message));
    }
};

function* onSaveCurso(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('curso/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveCursoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveCursoFailure(error.message));
    }
};

function* onUpdateCurso(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('curso/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateCursoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateCursoFailure(error.message));
    }
};

function* onNotificacaoCurso(data: any): any {
    const idCurso = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`curso/notificaCurso?idCurso=${idCurso}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoCursoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoCursoFailure(error.message));
    }
};

export {
    getCursoData,
    getCursoTable,
    onAtivarCurso,
    onInativarCurso,
    onSaveCurso,
    onUpdateCurso,
    onNotificacaoCurso,
}
