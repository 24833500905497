import { action } from "typesafe-actions";
import { ChatBotNivelTypes } from "./types";

export const getChatBotNivelTable = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_TABLE_REQ, data);
export const getChatBotNivelTableSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_TABLE_REQ_SUCCESS, { data });
export const getChatBotNivelTableFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_TABLE_REQ_FAILURE, { err });

export const getChatBotNivel = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_REQ, data);
export const getChatBotNivelSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_REQ_SUCCESS, { data });
export const getChatBotNivelFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_REQ_FAILURE, { err });

export const getChatBotNivelSave = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_SAVE_REQ, data);
export const getChatBotNivelSaveSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_SAVE_REQ_SUCCESS, { data });
export const getChatBotNivelSaveFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_SAVE_REQ_FAILURE, { err });

export const getChatBotNivelUpdate = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_UPDATE_REQ, data);
export const getChatBotNivelUpdateSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_UPDATE_REQ_SUCCESS, { data });
export const getChatBotNivelUpdateFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_UPDATE_REQ_FAILURE, { err });

export const getChatBotNivelDelete = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_DELETE_REQ, data);
export const getChatBotNivelDeleteSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_DELETE_REQ_SUCCESS, { data });
export const getChatBotNivelDeleteFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_DELETE_REQ_FAILURE, { err });

export const getChatBotNivelKeyEmUso = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_KEY_REQ, data);
export const getChatBotNivelKeyEmUsoSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_KEY_REQ_SUCCESS, { data });
export const getChatBotNivelKeyEmUsoFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_KEY_REQ_FAILURE, { err });

export const getChatBotNivelList = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_LIST_REQ, data);
export const getChatBotNivelListSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_LIST_REQ_SUCCESS, { data });
export const getChatBotNivelListFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_LIST_REQ_FAILURE, { err });

export const getChatBotNivelQtdChaves = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_QTD_CHAVES_REQ, data);
export const getChatBotNivelQtdChavesSuccess = (data: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_QTD_CHAVES_REQ_SUCCESS, { data });
export const getChatBotNivelQtdChavesFailure = (err: any) => action(ChatBotNivelTypes.CHATBOT_NIVEL_QTD_CHAVES_REQ_FAILURE, { err });

export const clearChatBotNivel = () => action(ChatBotNivelTypes.CLEAR_CHATBOT_NIVEL);