import { FormHelperText, Icon, IconButton, InputLabel, List, ListItem, MenuItem, Select, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import React, { useEffect, useState } from "react";
import { api } from "../../services";

import { validaNomeArquivo } from "../../services/Uteis";
import { ComunicadoObj } from "../../store/ducks/comunicado/types";
import ListaAvaliacaoComponent from "../ListaAvaliacaoComponent";
import ListaConteudoComponent from "../ListaConteudoComponent";
import ListaMunicipioComponentTeste from "../ListaMunicipioComponentTeste";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaTemaComponent from "../ListaTemaComponent";
import TabPanel from "../TabPanel";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const defaultComunicado: ComunicadoObj = {
  nmCursoComunicado: '',
  dsCursoComunicado: '',
  dtCursoComunicadoInicio: null,
  dtCursoComunicadoFim: null,
  dsTempoEstimado: '',
  imgCursoComunicado: '',
  urlCompartilhamentoCursoComunicado: '',
  tema: [],
  cursoComunicadoMuncipios: [],
  cursoComunicadoPerfis: [],
  cursoComunicadoAvaliacoes: [],
  cursoComunicadoConteudos: []
};

const ModalCadastroComunicado = (props: any) => {

  const [isEdicao, setIsEdicao] = useState(false);
  const [comunicado, setComunicado] = useState(defaultComunicado);
  const [capaComunicado, setCapaComunicado] = useState('');
  const [municipios_, setMunicipios_] = useState([]);
  const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
  const [conteudosOrdenados, setConteudoOrdenados] = useState([]);
  const [tab, setTab] = useState(0);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [imgComunicadoErro, setImgComunicadoErro] = useState(false);
  const [nmComunicadoErro, setNmComunicadoErro] = useState(false);
  const [dsComunicadoErro, setDsComunicadoErro] = useState(false);
  const [dtIniComunicadoErro, setDtIniComunicadoErro] = useState(false);
  const [dtFimComunicadoErro, setDtFimComunicadoErro] = useState(false);
  const [dsTempoEstimadoErro, setDsTempoEstimadoErro] = useState(false);
  const [perfilErro, setPerfilErro] = useState(false);
  const [municipioErro, setMunicipioErro] = useState(false);
  const [temaErro, setTemaErro] = useState(false);
  const [conteudoErro, setConteudoErro] = useState(false);

  useEffect(() => {
    if (props.comunicadoData) {
      handleMunicipioData();
      handleRender(props.comunicadoData);
    }
  }, [props.comunicadoData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (!imgComunicadoErro && !nmComunicadoErro && !dsComunicadoErro && !dtIniComunicadoErro && !dtFimComunicadoErro && !dsTempoEstimadoErro && !perfilErro && !municipioErro && !temaErro && !conteudoErro
      && comunicado.imgCursoComunicado !== '' && comunicado.nmCursoComunicado !== '' && comunicado.dsCursoComunicado !== '' && comunicado.dtCursoComunicadoInicio !== null && comunicado.dtCursoComunicadoFim !== null
      && comunicado.dsTempoEstimado !== '' && comunicado.tema.length !== 0 && comunicado.cursoComunicadoMuncipios.length !== 0 && comunicado.cursoComunicadoPerfis.length !== 0 && comunicado.cursoComunicadoConteudos.length !== 0) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [comunicado, imgComunicadoErro, nmComunicadoErro, dsComunicadoErro, dtIniComunicadoErro, dtFimComunicadoErro, dsTempoEstimadoErro, perfilErro, municipioErro, temaErro, conteudoErro])

  const validaOrdenacao = (array: any) => {
    let ordenado = array.map((item: any, idx: number) => {
      let obj = conteudosOrdenados.find((_item: any) => _item.idConteudo == item.idConteudo);
      if (obj != undefined) {
        item = obj;
      } else {
        item.ordemConteudo = conteudosOrdenados.length + 1;
      }
      return item;
    });

    setConteudoOrdenados(ordenado);
    setComunicado({
      ...comunicado,
      cursoComunicadoConteudos: ordenado,
    });
  }

  const handleRender = (comunicadoData: any) => {
    if (comunicadoData) {
      delete comunicadoData.tema.dsTema;
      setIsEdicao(true)
      setComunicado({
        ...comunicadoData
      });
      setCapaComunicado('');
    }
  }

  const handleClose = () => {
    setIsEdicao(false);
    setComunicado(defaultComunicado);
    setCapaComunicado('');
    setMunicipios_([]);
    setTab(0);
    setInativaGravar(true);
    setNmComunicadoErro(false);
    setDsComunicadoErro(false);
    setDtIniComunicadoErro(false);
    setDtFimComunicadoErro(false);
    setDsTempoEstimadoErro(false);
    setPerfilErro(false);
    setMunicipioErro(false);
    setTemaErro(false);
    setConteudoErro(false);
    setImgComunicadoErro(false);

    props.closeModal();
  }

  const handleMunicipioData = async () => {
    if(props.id != undefined){
      setMunicipios_(await getComunicadoDataMunicipioLimited(props.id) );
    }
  }

  async function getComunicadoDataMunicipioTotal(data: any) {
    const idCursoComunicado = data;
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`cursoComunicado/findMunicipioById?idCursoComunicado=${idCursoComunicado}`, {headers: {'Authorization': `Bearer ${token}`}})
        return response;
    } catch (error) {
        console.log(error);
    }
  }
    async function getComunicadoDataMunicipioLimited(data: any) {
      const idCursoComunicado = data;
      const token = localStorage.getItem('accessToken');
      try {
          const { data: response } = await api.get(`cursoComunicado/findMunicipioByIdLimit?idCursoComunicado=${idCursoComunicado}`, {headers: {'Authorization': `Bearer ${token}`}})
          return response;
      } catch (error) {
          console.log(error);
      }
  };

  const handlePersist = async (e: any) => {
    e.preventDefault();
    if(comunicado.cursoComunicadoMuncipios.length === 351 && isEdicao){
      comunicado.cursoComunicadoMuncipios = await getComunicadoDataMunicipioTotal(props.id);
    }
    const formComunicado = new FormData();

    if (comunicado.cursoComunicadoConteudos && comunicado.cursoComunicadoConteudos.length > 0) {
      comunicado.cursoComunicadoConteudos.forEach((conteudo: any, idx: number) => {
        if (conteudo.ordemConteudo == undefined || conteudo.ordemConteudo == 0) {
          conteudo.ordemConteudo = (idx + 1);
        }
      })
    }

    formComunicado.append('cursoComunicado', JSON.stringify(comunicado));

    if (capaComunicado != '') {
      formComunicado.append('fileImg', capaComunicado);
    }


    props.persist(isEdicao, formComunicado);
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if (res !== 'backdropClick') {
        handleClose()
      }
    }}
    >
      <Box sx={style} >
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? 'Editar Comunicado' : 'Cadastro de novo Comunicado'}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <TabPanel value={tab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6} >
                {
                  isEdicao && comunicado.imgCursoComunicado && capaComunicado == '' ? (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <div>
                        <Link className="buttonArquivo" href={comunicado.imgCursoComunicado} underline="none" target="_blank" >
                          <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                        </Link>
                        <Button variant="text" color="error" onClick={() => {
                          setImgComunicadoErro(true);
                          setComunicado({
                            ...comunicado,
                            imgCursoComunicado: ''
                          })
                        }}>Remover</Button>
                      </div>
                    </FormControl>
                  ) : (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <Input type='file' error={imgComunicadoErro} onChange={(e: any) => {
                        if (Array.from(e.target.files).length !== 0) {
                          if (validaNomeArquivo(e.target.files[0].name)) {
                            setImgComunicadoErro(Array.from(e.target.files).length === 0);
                            setComunicado({
                              ...comunicado,
                              imgCursoComunicado: e.target.files[0].name || ''
                            })
                            setCapaComunicado(e.target.files[0])
                          } else {
                            e.target.value = "";
                            setImgComunicadoErro(true);
                          }
                        } else {
                          e.target.value = "";
                          setImgComunicadoErro(true);
                        }
                      }} />
                      {imgComunicadoErro ? <FormHelperText error={imgComunicadoErro}>Campo Obrigatório.</FormHelperText> : null}
                    </FormControl>
                  )
                }
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={5} >
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={comunicado.nmCursoComunicado}
                  label="Nome do Comunicado"
                  error={nmComunicadoErro}
                  helperText={nmComunicadoErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setNmComunicadoErro(e.target.value === "");
                    setComunicado({
                      ...comunicado,
                      nmCursoComunicado: e.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={comunicado.dsCursoComunicado}
                  label="Descrição do Comunicado"
                  error={dsComunicadoErro}
                  helperText={dsComunicadoErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDsComunicadoErro(e.target.value === "");
                    setComunicado({
                      ...comunicado,
                      dsCursoComunicado: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtIniComunicadoErro(true);
                      } else {
                        setDtIniComunicadoErro(false);
                      }
                    }}
                    label="Data de Início"
                    value={comunicado.dtCursoComunicadoInicio}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtIniComunicadoErro(false);
                      setComunicado({
                        ...comunicado,
                        dtCursoComunicadoInicio: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtIniComunicadoErro} helperText={dtIniComunicadoErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtFimComunicadoErro(true);
                      } else {
                        setDtFimComunicadoErro(false);
                      }
                    }}
                    label="Data Final"
                    value={comunicado.dtCursoComunicadoFim}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtFimComunicadoErro(false);
                      setComunicado({
                        ...comunicado,
                        dtCursoComunicadoFim: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtFimComunicadoErro} helperText={dtFimComunicadoErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={comunicado.dsTempoEstimado}
                  label="Duração em Minutos"
                  error={dsTempoEstimadoErro}
                  helperText={dsTempoEstimadoErro ? "Campo Obrigatório." : ''}
                  onChange={(e) => {
                    setDsTempoEstimadoErro(e.target.value === "");
                    setComunicado({
                      ...comunicado,
                      dsTempoEstimado: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaTemaComponent
                    error={temaErro}
                    temas={comunicado.tema}
                    multiple={false}
                    onChangeTemas={(value: any) => {
                      setTemaErro(value.length === 0)
                      setComunicado({
                        ...comunicado,
                        tema: value[0] ? value[0] : {}
                      })
                    }}
                  />
                  {temaErro ? <FormHelperText error={temaErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>


              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaPerfilComponent
                    error={perfilErro}
                    perfis={comunicado.cursoComunicadoPerfis}
                    onChangePerfis={(value: any) => {
                      setPerfilErro(value.length === 0)
                      setComunicado({
                        ...comunicado,
                        cursoComunicadoPerfis: value
                      })
                    }}
                  />
                  {perfilErro ? <FormHelperText error={perfilErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaMunicipioComponentTeste
                    error={municipioErro}
                    municipios={municipios_}
                    callbackList={getComunicadoDataMunicipioTotal}
                    id={props.id}
                    onChangeMunicipios={(value: any) => {
                      setMunicipioErro(value.length === 0)
                      setComunicado({
                        ...comunicado,
                        cursoComunicadoMuncipios: value
                      })
                    }}
                  />
                  {municipioErro ? <FormHelperText error={municipioErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaConteudoComponent
                    error={conteudoErro}
                    conteudos={comunicado.cursoComunicadoConteudos}
                    multiple={true}
                    onChangeConteudos={(value: any) => {
                      setConteudoErro(value.length === 0)
                      setComunicado({
                        ...comunicado,
                        cursoComunicadoConteudos: value
                      });
                      validaOrdenacao(value);
                    }}
                  />
                  {conteudoErro ? <FormHelperText error={conteudoErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <ListaAvaliacaoComponent
                  error={false}
                  avaliacoes={comunicado.cursoComunicadoAvaliacoes}
                  onChangeAvaliacoes={(value: any) => setComunicado({
                    ...comunicado,
                    cursoComunicadoAvaliacoes: value
                  })}
                />
              </Grid>

              {
                comunicado.urlCompartilhamentoCursoComunicado ? (
                  <Grid item xs={8} sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        disabled
                        sx={{width: '80%'}}
                        value={comunicado.urlCompartilhamentoCursoComunicado}
                        label="Link de Compartilhamento"
                    />
                    <Tooltip title={copiado}>
                      <IconButton  sx={{ fontSize: 25, marginLeft: '5px', comunicador: 'pointer' }} onClick={() => {
                          navigator.clipboard.writeText(comunicado.urlCompartilhamentoCursoComunicado || '').then(() => {
                              setCopiado('Copiado!');
                          }).catch(() => {
                              setCopiado('Não foi possível copiar o link de compartilhamento!');
                          }).finally(() => {
                              setTimeout(() => {
                                  setCopiado('Copiar link de compartilhamento');
                              }, 1500);
                          });
                      }} >
                          <Icon >content_copy</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null
              }
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div style={{ maxHeight: 400, overflow: 'auto', width: '100%' }}>
              <List className="conteudoOrdemList" >
                {
                  comunicado.cursoComunicadoConteudos && comunicado.cursoComunicadoConteudos.length > 0 ? (
                    comunicado.cursoComunicadoConteudos.map((conteudo: any, idx: number) => (
                      <ListItem key={`${conteudo.idConteudo}`}>
                        <TextField
                          disabled
                          style={{ width: 400 }}
                          value={conteudo.nmConteudo}
                          label="Conteúdo"
                        />
                        <FormControl sx={{ width: 200, marginLeft: '1em' }} >
                          <InputLabel id="demo-controlled-open-select-label">Ordem do Conteúdo</InputLabel>
                          <Select
                            value={conteudo.ordemConteudo != 0 ? conteudo.ordemConteudo : (idx + 1)}
                            label="Ordem do Conteúdo"
                            onChange={(e) => {
                              let auxArr = comunicado.cursoComunicadoConteudos;
                              auxArr[idx].ordemConteudo = parseInt(e.target.value.toString());
                              setComunicado({
                                ...comunicado,
                                cursoComunicadoConteudos: auxArr
                              })
                            }}
                          >
                            {
                              comunicado.cursoComunicadoConteudos.map((item: any, _idx: any) => (
                                <MenuItem key={`listItemOrdem-${idx}-${_idx}`} value={(_idx + 1)}>{(_idx + 1)}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </ListItem>
                    ))
                  ) : null
                }
              </List>
            </div>
          </TabPanel>
          <Divider style={{ marginTop: '1em' }} />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
              <Tab label="Informações" />
              <Tab label="Ordenação de Conteúdos" disabled={!(comunicado.cursoComunicadoConteudos && comunicado.cursoComunicadoConteudos.length > 0)} />
            </Tabs>
          </Box>
          <div className="cadastroFooter">
            <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
            {
              isEdicao ?
                <Button variant="contained" onClick={() => {
                  props.enviaNotificacao(comunicado.idCursoComunicado)
                }}>Enviar Notificação</Button> : null
            }
          </div>
        </form>
      </Box>

    </Modal>
  )

};

export default ModalCadastroComunicado;