import { Dayjs } from "dayjs"

export enum JustificativaTypes {
    JUSTIFICATIVA_TABLE_REQUEST = 'justificativa/JUSTIFICATIVA_TABLE_REQUEST',
    JUSTIFICATIVA_TABLE_REQUEST_SUCCESS = 'justificativa/JUSTIFICATIVA_TABLE_RQUEST_SUCCESS',
    JUSTIFICATIVA_TABLE_REQUEST_FAILURE = 'justificativa/JUSTIFICATIVA_TABLE_REQUEST_FAILURE',
    
    CLEAR_JUSTIFICATIVA_DATA = 'justificativa/JUSTIFICATIVANTEUDO_DATA',

    JUSTIFICATIVA_INATIVA_REQUEST = 'justificativa/JUSTIFICATIVA_INATIVA_REQUEST',
    JUSTIFICATIVA_INATIVA_REQUEST_SUCCESS = 'justificativa/JUSTIFICATIVA_INATIVA_REQUEST_SUCCESS',
    JUSTIFICATIVA_INATIVA_REQUEST_FAILURE = 'justificativa/JUSTIFICATIVA_INATIVA_REQUEST_FAILURE',

    JUSTIFICATIVA_DATA_REQUEST = 'justificativa/JUSTIFICATIVA_DATA_REQUEST',
    JUSTIFICATIVA_DATA_REQUEST_SUCCESS = 'justificativa/JUSTIFICATIVA_DATA_REQUEST_SUCCESS',
    JUSTIFICATIVA_DATA_REQUEST_FAILURE = 'justificativa/JUSTIFICATIVA_DATA_REQUEST_FAILURE',

    JUSTIFICATIVA_SAVE_REQUEST = 'justificativa/JUSTIFICATIVA_SAVE_REQUEST',
    JUSTIFICATIVA_SAVE_REQUEST_SUCCESS = 'justificativa/JUSTIFICATIVA_SAVE_REQUEST_SUCCESS',
    JUSTIFICATIVA_SAVE_REQUEST_FAILURE = 'justificativa/JUSTIFICATIVA_SAVE_REQUEST_FAILURE',

    JUSTIFICATIVA_UPDATE_REQUEST = 'justificativa/JUSTIFICATIVA_UPDATE_REQUEST',
    JUSTIFICATIVA_UPDATE_REQUEST_SUCCESS = 'justificativa/JUSTIFICATIVA_UPDATE_REQUEST_SUCCESS',
    JUSTIFICATIVA_UPDATE_REQUEST_FAILURE = 'justificativa/JUSTIFICATIVA_UPDATE_REQUEST_FAILURE',

    JUSTIFICATIVA_TIPO_REQUEST = 'justificativa/JUSTIFICATIVA_TIPO_REQUEST',
    JUSTIFICATIVA_TIPO_REQUEST_SUCCESS = 'justificativa/JUSTIFICATIVA_TIPO_REQUEST_SUCCESS',
    JUSTIFICATIVA_TIPO_REQUEST_FAILURE = 'justificativa/JUSTIFICATIVA_TIPO_REQUEST_FAILURE',
}

export interface JustificativaObj {
    idJustificativa?: string | number,
    idPerfil?: string | number,
    idEstado?: string | number,
    idMunicipio?: string | number,
    idUsuario?: string | number,
    idAvaliacao?: string | number,
    usuario?: any | null,
    tipoJustificativa?: any | null,
    dtDesligamento?: null | Dayjs | string,
	dtRetorno?: null | Dayjs | string,
    nvCargo?: string
}

export interface JustificativaObjTable {
    id?: number,
    idJustificativa?: number,
    nmJustificativaTipo?: string
}

export interface JustificativaTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface JustificativaPage {
    readonly justificativa: JustificativaObj | null,
    readonly justificativaTipo: any | null,
    readonly table: JustificativaTable | null,
}

export interface JustificativaState {
    readonly data: JustificativaPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}