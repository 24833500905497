import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getDestaqueTableSuccess,
    getDestaqueTableFailure,
    getDestaqueDataFailure,
    getDestaqueDataSuccess,
    onAtivarDestaqueFailure,
    onAtivarDestaqueSuccess,
    onInativarDestaqueFailure,
    onInativarDestaqueSuccess,
    onSaveDestaqueFailure,
    onSaveDestaqueSuccess,
    onUpdateDestaqueFailure,
    onUpdateDestaqueSuccess
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getDestaqueTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/destaque/listAllConteudoDestaqueTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getDestaqueTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getDestaqueTableFailure(error.message));
    }
};


function* onInativarDestaque(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/destaque/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarDestaqueSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarDestaqueFailure(error.message));
    }
};

function* onAtivarDestaque(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/destaque/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarDestaqueSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarDestaqueFailure(error.message));
    }
};

function* getDestaqueData(data: any): any {
    const idDestaque = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`conteudo/destaque/findById?idConteudoDestaque=${idDestaque}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getDestaqueDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getDestaqueDataFailure(error.message));
    }
};

function* onSaveDestaque(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('conteudo/destaque/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveDestaqueSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveDestaqueFailure(error.message));
    }
};

function* onUpdateDestaque(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/destaque/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateDestaqueSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateDestaqueFailure(error.message));
    }
};

export {
    getDestaqueData,
    getDestaqueTable,
    onAtivarDestaque,
    onInativarDestaque,
    onSaveDestaque,
    onUpdateDestaque,
}
