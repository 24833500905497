import { action } from 'typesafe-actions';
import { AuthTypes } from './types';

export const doLoginApp = (data: any) => action(AuthTypes.AUTH_LOGIN, { data });
export const loginRequest = (data: any) => action(AuthTypes.AUTH_LOGIN_REQUEST, data);
export const loginRequestSuccess = (data: any) => action(AuthTypes.AUTH_LOGIN_REQUEST_SUCCESS, { data });
export const loginRequestFailure = (err: any) => action(AuthTypes.AUTH_LOGIN_REQUEST_FAILURE, { err });
export const doLogoutApp = (data: any) => action(AuthTypes.AUTH_LOGOUT, data);
export const doLogoutAppSuccess = (data: any) => action(AuthTypes.AUTH_LOGOUT_SUCCESS, { data });
export const doLogoutAppFailure = (err: any) => action(AuthTypes.AUTH_LOGOUT_FAILURE, { err });
export const refreshToken = () => action(AuthTypes.REFRESH_TOKEN);
export const refreshTokenSuccess = (data: any) => action(AuthTypes.REFRESH_TOKEN_SUCCESS, { data });
export const refreshTokenFailure = (err: any) => action(AuthTypes.REFRESH_TOKEN_FAILURE, { err });