import { Dayjs } from "dayjs";

export enum ClusterTypes {
  CLUSTER_TABLE_REQUEST = "cluster/CLUSTER_TABLE_REQUEST",
  CLUSTER_TABLE_REQUEST_SUCCESS = "cluster/CLUSTER_TABLE_RQUEST_SUCCESS",
  CLUSTER_TABLE_REQUEST_FAILURE = "cluster/CLUSTER_TABLE_REQUEST_FAILURE",

  CLUSTER_INATIVA_REQUEST = "cluster/CLUSTER_INATIVA_REQUEST",
  CLUSTER_INATIVA_REQUEST_SUCCESS = "cluster/CLUSTER_INATIVA_REQUEST_SUCCESS",
  CLUSTER_INATIVA_REQUEST_FAILURE = "cluster/CLUSTER_INATIVA_REQUEST_FAILURE",

  CLEAR_CLUSTER_DATA = "cluster/CLEAR_CLUSTER_DATA",

  CLUSTER_DATA_REQUEST = "cluster/CLUSTER_DATA_REQUEST",
  CLUSTER_DATA_REQUEST_SUCCESS = "cluster/CLUSTER_DATA_REQUEST_SUCCESS",
  CLUSTER_DATA_REQUEST_FAILURE = "cluster/CLUSTER_DATA_REQUEST_FAILURE",

  CLUSTER_SAVE_REQUEST = "cluster/CLUSTER_SAVE_REQUEST",
  CLUSTER_SAVE_REQUEST_SUCCESS = "cluster/CLUSTER_SAVE_REQUEST_SUCCESS",
  CLUSTER_SAVE_REQUEST_FAILURE = "cluster/CLUSTER_SAVE_REQUEST_FAILURE",

  CLUSTER_UPDATE_REQUEST = "cluster/CLUSTER_UPDATE_REQUEST",
  CLUSTER_UPDATE_REQUEST_SUCCESS = "cluster/CLUSTER_UPDATE_REQUEST_SUCCESS",
  CLUSTER_UPDATE_REQUEST_FAILURE = "cluster/CLUSTER_UPDATE_REQUEST_FAILURE",
}

export interface ClusterObjTable {
  id?: number;
  idCluster?: string | number;
  nmCluster?: string;
  dsCluster?: string;
}

export interface ClusterObj {
  idCluster?: string | number;
  nmCluster?: string;
  dsCluster?: string;
  idGrupo?: string | number;
}

export interface ClusterTable {
  [x: string]: any;
  content: Array<Object>;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  numberOfElements: number;
}

export interface ClusterPage {
  readonly cluster: ClusterObj | null;
  readonly table: ClusterTable | null;
}

export interface ClusterState {
  readonly data: ClusterPage;
  readonly loading: boolean;
  readonly loadingTable: boolean;
  readonly loadingPersis: boolean;
  readonly loadingInativacao: boolean;
  readonly error: boolean;
  readonly errorMessage: string | null;
}
