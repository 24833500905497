import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import {
  getSubclusterDataFailure,
  getSubclusterDataSuccess,
  getSubclusterTableFailure,
  getSubclusterTableSuccess,
  onInativarSubclusterFailure,
  onInativarSubclusterSuccess,
  onSaveSubclusterFailure,
  onSaveSubclusterSuccess,
  onUpdateSubclusterFailure,
  onUpdateSubclusterSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getSubclusterTable(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("subcluster/listAllSubClusterTable", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getSubclusterTableSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getSubclusterTableFailure(error.message));
  }
}

function* onInativarSubcluster(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("subcluster/desativarSubcluster", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onInativarSubclusterSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onInativarSubclusterFailure(error.message));
  }
}

function* getSubclusterData(data: any): any {
  const idSubcluster = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`subcluster/${idSubcluster}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getSubclusterDataSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getSubclusterDataFailure(error.message));
  }
}

function* onSaveSubcluster(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("subcluster/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onSaveSubclusterSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onSaveSubclusterFailure(error.message));
  }
}

function* onUpdateSubcluster(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("subcluster/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onUpdateSubclusterSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onUpdateSubclusterFailure(error.message));
  }
}

export {
  getSubclusterData,
  onInativarSubcluster,
  getSubclusterTable,
  onSaveSubcluster,
  onUpdateSubcluster,
};
