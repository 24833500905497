import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";

import * as UrlExternaAction from '../../store/ducks/pesquisaExt/actions'
import PesquisaExtTableComponent from "../../Components/TablePesquisaExt";
import ModalCadastroPesquisaExt from "../../Components/ModalCadastroPesquisaExt";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmUrlExterna",
    txtBusca: ""
};

function PesquisaExterna({
    urlExterna,
    getUrlExternaTable,
    onInativarUrlExterna,
    getUrlExternaData,
    onSaveUrlExterna,
    onUpdateUrlExterna,
    onNotificacaoUrlExterna,
    clearUrlExternaData
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(urlExterna.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + urlExterna.errorMessage);
        }
    }, [urlExterna.error]);

    useEffect(() => {
        if(!urlExterna.loadingPersis) {
            handleCloseAndReload()
        }
    }, [urlExterna.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getUrlExternaTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(urlExterna.data.table != null){
            setTable(urlExterna.data.table);
        }

    }, [urlExterna.data]);

    useEffect(() => {
        if(!urlExterna.loadingInativacao){
            getUrlExternaTable(body);
        }
    }, [urlExterna.loadingInativacao]);

    const getUrlExternaTableData = () => {
        getUrlExternaTable(body);
    }

    const handleCadastro = () => {
        clearUrlExternaData();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione uma pesquisa externa que gostaria de alterar');
        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas uma pesquisa externa para alteração!');
        } else {
            getUrlExternaData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione as pesquisas externa que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.PESQUISA_EXT_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarUrlExterna(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersistUrlExt = (edicao: boolean, urlExterna: any) => {
        if(edicao) {
            onUpdateUrlExterna(urlExterna);
        } else {
            onSaveUrlExterna(urlExterna);
        }
    }

    const handleCloseAndReload = () => {
        clearUrlExternaData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={urlExterna.loading || urlExterna.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroPesquisaExt 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()} 
                urlExtData={urlExterna.data.urlExterna}
                persist={handlePersistUrlExt}
                enviaNotificacao={(idUrlExterna: any) => onNotificacaoUrlExterna(idUrlExterna)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <PesquisaExtTableComponent 
                    loading={urlExterna.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};

const mapStateToProps = (state: ApplicationState) => ({
    urlExterna: state.urlExterna,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UrlExternaAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PesquisaExterna);
