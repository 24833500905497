import { Store, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import {
  persistStore,
  persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from "./ducks/rootReducer";
import rootSaga from "./ducks/rootSaga";

import { AuthState } from "./ducks/auth/types";
import { UsuarioState } from "./ducks/usuario/types";
import { UtilsState } from "./ducks/utils/types";
import { PerfilState } from "./ducks/perfil/types";
import { TemaState } from "./ducks/temas/types";
import { UrlExternaState } from "./ducks/pesquisaExt/types";
import { ConteudoState } from "./ducks/conteudo/types";
import { CursoState } from "./ducks/curso/types";
import { SegurancaNoTrabalhoState } from "./ducks/segurancaNoTrabalho/types";
import { DestaqueState } from "./ducks/destaque/types";
import { ComunicadoState } from "./ducks/comunicado/types";
import { T1State } from "./ducks/t1/types";
import { RelatorioState } from "./ducks/relatorio/types";
import { TrilhaState } from "./ducks/trilha/types";
import { AvaliacaoState } from "./ducks/avaliacao/types";
import { JustificativaState } from "./ducks/justificativa/types";
import { ClusterState } from "./ducks/cluster/types";
import { SubclusterState } from "./ducks/subcluster/types";
import { GrupoState } from "./ducks/grupo/types";
import { StoryState } from "./ducks/storys/types";
import { MunicipioState } from "./ducks/municipios/types";
import { ChatBotCategoriaState } from "./ducks/chatbot/categoria/types";
import { ChatBotNivelState } from "./ducks/chatbot/nivel/types";
import { ChatBotState } from "./ducks/chatbot/types";
import { ChatBotSaudacaoState } from "./ducks/chatbot/saudacao/types";

export interface ApplicationState {
  auth: AuthState,
  utils: UtilsState,
  usuario: UsuarioState,
  perfil: PerfilState,
  tema: TemaState,
  urlExterna: UrlExternaState,
  conteudo: ConteudoState,
  curso: CursoState,
  segurancaNoTrabalho: SegurancaNoTrabalhoState,
  destaque: DestaqueState,
  comunicado: ComunicadoState,
  t1: T1State,
  relatorio: RelatorioState,
  trilha: TrilhaState,
  avaliacao: AvaliacaoState,
  justificativa: JustificativaState,
  cluster: ClusterState,
  subcluster: SubclusterState,
  grupo: GrupoState
  story: StoryState,
  municipio: MunicipioState,
  categoria: ChatBotCategoriaState,
  nivel: ChatBotNivelState,
  chatbot: ChatBotState,
  saudacao: ChatBotSaudacaoState
};

const persistConfig = { key: 'root', version: 1, storage }
const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = configureStore({
  reducer: persistedReducer,
  middleware: (gDM) => gDM({
    serializableCheck: false,
    immutableCheck: false
  }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export {
  store,
  persistor
}