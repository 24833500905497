import { Dayjs } from "dayjs"

export enum SegurancaNoTrabalhoTypes {
  SEGURANCA_TABLE_REQUEST = 'segurancaNoTrabalho/SEGURANCA_TABLE_REQUEST',
  SEGURANCA_TABLE_REQUEST_SUCCESS = 'segurancaNoTrabalho/SEGURANCA_TABLE_RQUEST_SUCCESS',
  SEGURANCA_TABLE_REQUEST_FAILURE = 'segurancaNoTrabalho/SEGURANCA_TABLE_REQUEST_FAILURE',

  SEGURANCA_ATIVA_REQUEST = 'segurancaNoTrabalho/SEGURANCA_ATIVA_REQUEST',
  SEGURANCA_ATIVA_REQUEST_SUCCESS = 'segurancaNoTrabalho/SEGURANCA_ATIVA_REQUEST_SUCCESS',
  SEGURANCA_ATIVA_REQUEST_FAILURE = 'segurancaNoTrabalho/SEGURANCA_ATIVA_REQUEST_FAILURE',

  SEGURANCA_INATIVA_REQUEST = 'segurancaNoTrabalho/SEGURANCA_INATIVA_REQUEST',
  SEGURANCA_INATIVA_REQUEST_SUCCESS = 'segurancaNoTrabalho/SEGURANCA_INATIVA_REQUEST_SUCCESS',
  SEGURANCA_INATIVA_REQUEST_FAILURE = 'segurancaNoTrabalho/SEGURANCA_INATIVA_REQUEST_FAILURE',

  CLEAR_SEGURANCA_DATA = 'segurancaNoTrabalho/CLEAR_SEGURANCA_DATA',

  SEGURANCA_DATA_REQUEST = 'segurancaNoTrabalho/SEGURANCA_DATA_REQUEST',
  SEGURANCA_DATA_REQUEST_SUCCESS = 'segurancaNoTrabalho/SEGURANCA_DATA_REQUEST_SUCCESS',
  SEGURANCA_DATA_REQUEST_FAILURE = 'segurancaNoTrabalho/SEGURANCA_DATA_REQUEST_FAILURE',

  SEGURANCA_SAVE_REQUEST = 'segurancaNoTrabalho/SEGURANCA_SAVE_REQUEST',
  SEGURANCA_SAVE_REQUEST_SUCCESS = 'segurancaNoTrabalho/SEGURANCA_SAVE_REQUEST_SUCCESS',
  SEGURANCA_SAVE_REQUEST_FAILURE = 'segurancaNoTrabalho/SEGURANCA_SAVE_REQUEST_FAILURE',

  SEGURANCA_UPDATE_REQUEST = 'segurancaNoTrabalho/SEGURANCA_UPDATE_REQUEST',
  SEGURANCA_UPDATE_REQUEST_SUCCESS = 'segurancaNoTrabalho/SEGURANCA_UPDATE_REQUEST_SUCCESS',
  SEGURANCA_UPDATE_REQUEST_FAILURE = 'segurancaNoTrabalho/SEGURANCA_UPDATE_REQUEST_FAILURE',

  SEGURANCA_PUSH_REQUEST = 'segurancaNoTrabalho/SEGURANCA_PUSH_REQUEST',
  SEGURANCA_PUSH_REQUEST_SUCCESS = 'segurancaNoTrabalho/SEGURANCA_PUSH_REQUEST_SUCCESS',
  SEGURANCA_PUSH_REQUEST_FAILURE = 'segurancaNoTrabalho/SEGURANCA_PUSH_REQUEST_FAILURE',
}

export interface SegurancaNoTrabalhoObjTable {
  id?: number,
  idSegurancaNoTrabalho?: number,
  nmSegurancaNoTrabalho?: string,
  dsSegurancaNoTrabalho?: string,
  dsTempoEstimado?: string
  nmTema?: string,
  qtdConteudos?: number
}


export interface SegurancaNoTrabalhoObj {
  idSegurancaNoTrabalho?: number,
  nmSegurancaNoTrabalho?: string,
  dsSegurancaNoTrabalho?: string,
  dtSegurancaNoTrabalhoInicio?: null | Dayjs | string,
  dtSegurancaNoTrabalhoFim?: null | Dayjs | string,
  dsTempoEstimado?: string,
  tema?: any | null,
  imgSegurancaNoTrabalho?: string | null,
  urlCompartilhamentoSegurancaNoTrabalho?: string,
  idDatabaseStatus?: number | null,
  segurancaNoTrabalhoMuncipios?: any | null,
  segurancaNoTrabalhoPerfis?: any | null,
  segurancaNoTrabalhoConteudos?: any | null,
  segurancaNoTrabalhoAvaliacoes?: any | null,
  icExibirCarrossel?: number,
}


export interface SegurancaNoTrabalhoTable {
  [x: string]: any
  content: Array<Object>,
  totalPages: number,
  totalElements: number,
  size: number,
  number: number,
  numberOfElements: number
}

export interface SegurancaNoTrabalhoPage {
  readonly segurancaNoTrabalho: SegurancaNoTrabalhoObj | null,
  readonly table: SegurancaNoTrabalhoTable | null,
}

export interface SegurancaNoTrabalhoState {
  readonly data: SegurancaNoTrabalhoPage,
  readonly loading: boolean,
  readonly loadingTable: boolean,
  readonly loadingPersis: boolean,
  readonly error: boolean,
  readonly errorMessage: string | null,
  readonly loadingInativacao: boolean,
}