import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import "dayjs/locale/pt-br";

import { GrupoObj } from "../../store/ducks/grupo/types";
import ListaClusterComponent from "../ListaClusterComponent";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultGrupo: GrupoObj = {
  idGrupo: "",
  nmGrupo: "",
  dsGrupo: "",
};

const ModalCadastroGrupo = (props: any) => {
  const [isEdicao, setIsEdicao] = useState(false);
  const [grupo, setGrupo] = useState(defaultGrupo);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [nmGrupoError, setNmGrupoError] = useState(false);
  const [dsGrupoError, setDsGrupoError] = useState(false);

  useEffect(() => {
    if (props.gruposData) {
      handleRender(props.gruposData);
    }
  }, [props.gruposData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (!nmGrupoError && !dsGrupoError && !!grupo.nmGrupo && !!grupo.dsGrupo) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [grupo, nmGrupoError, dsGrupoError]);

  const handleRender = (gruposData: any) => {
    if (gruposData) {
      setIsEdicao(true);
      setGrupo({
        ...gruposData,
      });
    }
  };

  const handleClose = () => {
    setIsEdicao(false);
    setInativaGravar(false);
    setNmGrupoError(false);
    setDsGrupoError(false);
    setGrupo(defaultGrupo);

    props.closeModal();
  };

  const handlePersist = (e: any) => {
    e.preventDefault();
    props.persistGrupo(isEdicao, grupo);
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? "Editar Grupo" : "Cadastro de novo Grupo"}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={grupo.nmGrupo}
                label="Nome do Grupo"
                error={nmGrupoError}
                helperText={nmGrupoError ? "Campo Obrigatório." : ""}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setNmGrupoError(e.target.value === "");
                  setGrupo({
                    ...grupo,
                    nmGrupo: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={grupo.dsGrupo}
                label="Descrição do Grupo"
                error={dsGrupoError}
                helperText={dsGrupoError ? "Campo Obrigatório." : ""}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDsGrupoError(e.target.value === "");
                  setGrupo({
                    ...grupo,
                    dsGrupo: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>

          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">
              {isEdicao ? "Gravar" : "Cadastrar"}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroGrupo;
