import { Reducer } from "@reduxjs/toolkit";
import { StoryState, StoryTypes } from "./types";

const INITIAL_STATE: StoryState = {
    data: {
        table: null,
        story: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<StoryState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case StoryTypes.STORY_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case StoryTypes.STORY_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case StoryTypes.STORY_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case StoryTypes.STORY_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case StoryTypes.STORY_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case StoryTypes.STORY_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case StoryTypes.STORY_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case StoryTypes.STORY_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case StoryTypes.STORY_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case StoryTypes.STORY_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case StoryTypes.STORY_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    story: action.payload.data
                }
            };
        case StoryTypes.STORY_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    story: null
                }
            };


        case StoryTypes.CLEAR_STORY_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    story: null
                }
            };

        case StoryTypes.STORY_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case StoryTypes.STORY_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Story realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    story: action.payload.data
                }
            };
        case StoryTypes.STORY_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case StoryTypes.STORY_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case StoryTypes.STORY_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Story realizada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    story: action.payload.data
                }
            };
        case StoryTypes.STORY_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case StoryTypes.STORY_PUSH_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case StoryTypes.STORY_PUSH_REQUEST_SUCCESS:
            alert('As notificações entraram na fila para envio! Pode levar alguns minutos para que os usuários recebam.');
            return {
                ...state,
                error: false,
                loading: false,
            };
        case StoryTypes.STORY_PUSH_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
            };

        default :
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
};

export default reducer;