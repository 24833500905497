import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FormLabel } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'

import { CursoObj } from "../../store/ducks/curso/types";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaMunicipioComponentTeste from "../ListaMunicipioComponentTeste";
import ListaTemaComponent from "../ListaTemaComponent";
import ListaAvaliacaoComponent from "../ListaAvaliacaoComponent";
import ListaConteudoComponent from "../ListaConteudoComponent";
import TabPanel from "../TabPanel";
import { FormHelperText, InputLabel, List, ListItem, MenuItem, Select } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";
import { api } from "../../services";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const defaultCurso: CursoObj = {
  nmCurso: '',
  dsCurso: '',
  dsTempoEstimado: '',
  imgCurso: '',
  urlCompartilhamentoCurso: '',
  icExibirCarrossel: 1,
  dtCursoInicio: null,
  dtCursoFim: null,
  tema: [],
  cursoMuncipios: [],
  cursoPerfis: [],
  cursoAvaliacoes: [],
  cursoConteudos: []
};

const ModalCadastroCurso = (props: any) => {

  const [isEdicao, setIsEdicao] = useState(false);
  const [curso, setCurso] = useState(defaultCurso);
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [municipios_, setMunicipios_] = useState([]);
  const [capaCurso, setCapaCurso] = useState('');
  const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
  const [conteudosOrdenados, setConteudoOrdenados] = useState([]);
  const [tab, setTab] = useState(0);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [imgCursoErro, setImgCursoErro] = useState(false);
  const [nmCursoErro, setNmCursoErro] = useState(false);
  const [dsCursoErro, setDsCursoErro] = useState(false);
  const [dtIniCursoErro, setDtIniCursoErro] = useState(false);
  const [dtFimCursoErro, setDtFimCursoErro] = useState(false);
  const [dsTempoEstimadoErro, setDsTempoEstimadoErro] = useState(false);
  const [perfilErro, setPerfilErro] = useState(false);
  const [municipioErro, setMunicipioErro] = useState(false);
  const [temaErro, setTemaErro] = useState(false);
  const [conteudoErro, setConteudoErro] = useState(false);

  useEffect(() => {
    if (props.cursoData) {
      handleMunicipioData();
      handleRender(props.cursoData);
    }
  }, [props.cursoData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if ( checkErrors()
      && checkCampos()) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [curso, municipios_, imgCursoErro, nmCursoErro, dsCursoErro, dtIniCursoErro, dtFimCursoErro, dsTempoEstimadoErro, perfilErro, municipioErro, temaErro, conteudoErro])


  const checkErrors = () => {
    if (!imgCursoErro && !nmCursoErro && !dsCursoErro && !dtIniCursoErro && !dtFimCursoErro && !dsTempoEstimadoErro && !perfilErro && !municipioErro && !temaErro && !conteudoErro) {
      return true
    } else {
      return false
    }
  }

  const checkCampos = () => {
    if (curso.imgCurso !== '' && titulo !== '' && descricao !== '' && curso.cursoMuncipios.length !== 0 && curso.dtCursoInicio !== null && curso.dtCursoFim !== null
    && curso.dsTempoEstimado !== '' && Object.keys(curso.tema).length !== 0 && curso.cursoPerfis.length !== 0 && curso.cursoConteudos.length !== 0) {
      return true
    } else {
      return false
    }
  }


  const validaOrdenacao = (array: any) => {
    let ordenado = array.map((item: any, idx: number) => {
      let obj = conteudosOrdenados.find((_item: any) => _item.idConteudo == item.idConteudo);
      if (obj != undefined) {
        item = obj;
      } else {
        item.ordemConteudo = conteudosOrdenados.length + 1;
      }
      return item;
    });

    setConteudoOrdenados(ordenado);
    setCurso({
      ...curso,
      cursoConteudos: ordenado,
    });
  }

  const handleMunicipioData = async () => {
    if(props.id != undefined){
      setMunicipios_(await getCursoDataMunicipioLimited(props.id) );
    }

  }

  async function getCursoDataMunicipioTotal(data: any) {
    const idCurso = data;
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`curso/findMunicipioById?idCurso=${idCurso}`, {headers: {'Authorization': `Bearer ${token}`}})
        return response;
    } catch (error) {
        console.log(error);
    }
  }
    async function getCursoDataMunicipioLimited(data: any) {
      const idCurso = data;
      const token = localStorage.getItem('accessToken');
      try {
          const { data: response } = await api.get(`curso/findMunicipioByIdLimit?idCurso=${idCurso}`, {headers: {'Authorization': `Bearer ${token}`}})
          return response;
      } catch (error) {
          console.log(error);
      }
  };

  const handleRender = (cursoData: any) => {
    if (cursoData) {
      delete cursoData.tema.dsTema;
      setIsEdicao(true)
      setCurso({
        ...cursoData
      });
      setTitulo(String(cursoData.nmCurso));
      setDescricao(String(cursoData.dsCurso));
      setCapaCurso('');
      setConteudoOrdenados(cursoData.cursoConteudos);
    }
  }

  const handleClose = () => {
    setIsEdicao(false);
    setCurso(defaultCurso);
    setCapaCurso('');
    setTitulo("");
    setDescricao("");
    setMunicipios_([]);
    setTab(0);
    setInativaGravar(true);
    setNmCursoErro(false);
    setDsCursoErro(false);
    setDtIniCursoErro(false);
    setDtFimCursoErro(false);
    setDsTempoEstimadoErro(false);
    setPerfilErro(false);
    setMunicipioErro(false);
    setTemaErro(false);
    setConteudoErro(false);
    setImgCursoErro(false);

    props.closeModal();
  }

  const handlePersist = async (e: any) => {
    e.preventDefault();

    curso.nmCurso = titulo;
    curso.dsCurso = descricao;
    if(curso.cursoMuncipios.length === 351 && isEdicao){
      curso.cursoMuncipios = await getCursoDataMunicipioTotal(props.id);
    }

    
    const formCurso = new FormData();

    if (curso.cursoConteudos && curso.cursoConteudos.length > 0) {
      curso.cursoConteudos.forEach((conteudo: any, idx: number) => {
        if (conteudo.ordemConteudo == undefined || conteudo.ordemConteudo == 0) {
          conteudo.ordemConteudo = (idx + 1);
        }
      })
    }

    formCurso.append('curso', JSON.stringify(curso));

    if (capaCurso != '') {
      formCurso.append('fileImg', capaCurso);
    }

    props.persist(isEdicao, formCurso);
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if (res !== 'backdropClick') {
        handleClose()
      }
    }}
    >
      <Box sx={style} >
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? 'Editar Curso' : 'Cadastro de novo Curso'}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <TabPanel value={tab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6} >
                {
                  isEdicao && curso.imgCurso && capaCurso == '' ? (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <div>
                        <Link className="buttonArquivo" href={curso.imgCurso} underline="none" target="_blank" >
                          <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                        </Link>
                        <Button variant="text" color="error" onClick={() => {
                          setImgCursoErro(true);
                          setCurso({
                            ...curso,
                            imgCurso: ''
                          })
                        }}>Remover</Button>
                      </div>
                    </FormControl>
                  ) : (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <Input type='file' error={imgCursoErro} onChange={(e: any) => {
                        if (Array.from(e.target.files).length !== 0) {
                          if (validaNomeArquivo(e.target.files[0].name)) {
                            setImgCursoErro(Array.from(e.target.files).length === 0);
                            setCurso({
                              ...curso,
                              imgCurso: e.target.files[0].name || ''
                            })
                            setCapaCurso(e.target.files[0])
                          } else {
                            e.target.value = "";
                            setImgCursoErro(true);
                          }
                        } else {
                          e.target.value = "";
                          setImgCursoErro(true);
                        }
                      }} />
                      {imgCursoErro ? <FormHelperText error={imgCursoErro}>Campo Obrigatório.</FormHelperText> : null}
                    </FormControl>
                  )
                }
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Ocultar no carrossel"
                  checked={curso.icExibirCarrossel === 0}
                  style={{ marginInline: 0 }}
                  onChange={() => {
                    setCurso(state => ({
                      ...state,
                      icExibirCarrossel: state.icExibirCarrossel === 0 ? 1 : 0
                    }))
                  }} />
              </Grid>
              <Grid item xs={5} >
              <TextField
                  required
                  sx={{ width: '100%' }}
                  value={titulo}
                  label="Nome do Curso"
                  error={nmCursoErro}
                  helperText={nmCursoErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setNmCursoErro(e.target.value === "");
                    setTitulo(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={descricao}
                  label="Descrição do Curso"
                  error={dsCursoErro}
                  helperText={dsCursoErro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDsCursoErro(e.target.value === "");
                    setDescricao(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtIniCursoErro(true);
                      } else {
                        setDtIniCursoErro(false);
                      }
                    }}
                    label="Data de Início"
                    value={curso.dtCursoInicio}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtIniCursoErro(false);
                      setCurso({
                        ...curso,
                        dtCursoInicio: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtIniCursoErro} helperText={dtIniCursoErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtFimCursoErro(true);
                      } else {
                        setDtFimCursoErro(false);
                      }
                    }}
                    label="Data Final"
                    value={curso.dtCursoFim}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtFimCursoErro(false);
                      setCurso({
                        ...curso,
                        dtCursoFim: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtFimCursoErro} helperText={dtFimCursoErro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={curso.dsTempoEstimado}
                  label="Duração em Minutos"
                  error={dsTempoEstimadoErro}
                  helperText={dsTempoEstimadoErro ? "Campo Obrigatório." : ''}
                  onChange={(e) => {
                    setDsTempoEstimadoErro(e.target.value === "");
                    setCurso({
                      ...curso,
                      dsTempoEstimado: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaTemaComponent
                    error={temaErro}
                    temas={curso.tema}
                    multiple={false}
                    onChangeTemas={(value: any) => {
                      setTemaErro(value.length === 0)
                      setCurso({
                        ...curso,
                        tema: value[0] ? value[0] : {}
                      })
                    }}
                  />
                  {temaErro ? <FormHelperText error={temaErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>


              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaPerfilComponent
                    error={perfilErro}
                    perfis={curso.cursoPerfis}
                    onChangePerfis={(value: any) => {
                      setPerfilErro(value.length === 0)
                      setCurso({
                        ...curso,
                        cursoPerfis: value
                      })
                    }}
                  />
                  {perfilErro ? <FormHelperText error={perfilErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaMunicipioComponentTeste
                    error={municipioErro}
                    municipios={municipios_}
                    callbackList={getCursoDataMunicipioTotal}
                    id={props.id}
                    onChangeMunicipios={(value: any) => {
                      setMunicipioErro(value.length === 0)
                      setCurso({
                        ...curso,
                        cursoMuncipios: value
                      })
                    }}
                  />
                  {municipioErro ? <FormHelperText error={municipioErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaConteudoComponent
                    error={conteudoErro}
                    conteudos={curso.cursoConteudos}
                    multiple={true}
                    onChangeConteudos={(value: any) => {
                      setConteudoErro(value.length === 0)
                      setCurso({
                        ...curso,
                        cursoConteudos: value
                      });
                      validaOrdenacao(value);
                    }}
                  />
                  {conteudoErro ? <FormHelperText error={conteudoErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <ListaAvaliacaoComponent
                  avaliacoes={curso.cursoAvaliacoes}
                  onChangeAvaliacoes={(value: any) => setCurso({
                    ...curso,
                    cursoAvaliacoes: value
                  })}
                />
              </Grid>

              {
                curso.urlCompartilhamentoCurso ? (
                  <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      disabled
                      sx={{ width: '80%' }}
                      value={curso.urlCompartilhamentoCurso}
                      label="Link de Compartilhamento"
                    />
                    <Tooltip title={copiado}>
                      <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                        navigator.clipboard.writeText(curso.urlCompartilhamentoCurso || '').then(() => {
                          setCopiado('Copiado!');
                        }).catch(() => {
                          setCopiado('Não foi possível copiar o link de compartilhamento!');
                        }).finally(() => {
                          setTimeout(() => {
                            setCopiado('Copiar link de compartilhamento');
                          }, 1500);
                        });
                      }} >
                        <Icon >content_copy</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null
              }
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div style={{ maxHeight: 400, overflow: 'auto', width: '100%' }}>
              <List className="conteudoOrdemList" >
                {
                  curso.cursoConteudos && curso.cursoConteudos.length > 0 ? (
                    curso.cursoConteudos.map((conteudo: any, idx: number) => (
                      <ListItem key={`${conteudo.idConteudo}`}>
                        <TextField
                          disabled
                          style={{ width: 400 }}
                          value={conteudo.nmConteudo}
                          label="Conteúdo"
                        />
                        <FormControl sx={{ width: 200, marginLeft: '1em' }} >
                          <InputLabel id="demo-controlled-open-select-label">Ordem do Conteúdo</InputLabel>
                          <Select
                            value={conteudo.ordemConteudo && conteudo.ordemConteudo != 0 ? conteudo.ordemConteudo : (idx + 1)}
                            label="Ordem do Conteúdo"
                            onChange={(e) => {
                              let auxArr = curso.cursoConteudos;
                              auxArr[idx].ordemConteudo = parseInt(e.target.value.toString());
                              setCurso({
                                ...curso,
                                cursoConteudos: auxArr
                              })
                            }}
                          >
                            {
                              curso.cursoConteudos.map((item: any, _idx: any) => (
                                <MenuItem key={`listItemOrdem-${idx}-${_idx}`} value={(_idx + 1)}>{(_idx + 1)}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </ListItem>
                    ))
                  ) : null
                }
              </List>
            </div>
          </TabPanel>
          <Divider style={{ marginTop: '1em' }} />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
              <Tab label="Informações" />
              <Tab label="Ordenação de Conteúdos" disabled={!(curso.cursoConteudos && curso.cursoConteudos.length > 0)} />
            </Tabs>
          </Box>
          <div className="cadastroFooter">
            <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
            {
              isEdicao ?
                <Button variant="contained" onClick={() => {
                  props.enviaNotificacao(curso.idCurso)
                }}>Enviar Notificação</Button> : null
            }
          </div>
        </form>
      </Box>

    </Modal>
  )

};

export default ModalCadastroCurso;