import React, { useEffect, useState } from "react";
import { GridColDef } from '@mui/x-data-grid';
import GenericTable from "../GenericTable/GenericTable";
import { ChatBotNivelObjTable, ChatBotNivelTable } from "../../store/ducks/chatbot/nivel/types";

const ChatbotNivelTableComponent = (props: any) => {

    const [txtBusca, setTxtBusca] = useState('');
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [listChatbotNivelTable, setListChatbotNivelTable] = useState<ChatBotNivelObjTable[]>([]);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numberElements, setNumberElements] = useState(0);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        generateColumnsTable();
    }, []);

    useEffect(() => {
        if(props.data){
            tranformObjectTable(props.data)
        }
    }, [props.data]);

    const generateColumnsTable = () => {
        const _columns: GridColDef[] = [
            { field: 'titulo', headerName: 'Titulo', width: 200 },
            { field: 'contexto', headerName: 'Contexto', width: 350 },
            { field: 'keyNivel', headerName: 'Chave ', width: 100 },
            { field: 'categoriaRelacionada', headerName: 'Categoria Associada ', width: 200 },
            { field: 'nivelRelacionado', headerName: 'Nível Associado ', width: 200 },
        ];

        setColumns(_columns);
    };

    const tranformObjectTable = (obj: ChatBotNivelTable) => {
        setSize(obj.size);
        setPage(obj.number);
        setTotalElements(obj.totalElements);
        setTotalPages(obj.totalPages);
        setNumberElements(obj.numberOfElements);
        setOffset(obj.pageable.offset)

        obj.content.forEach(item => {
            item.id =item.idNivel;
        })

        setListChatbotNivelTable(obj.content);
    };
    
    const onPageChange = (pageIdx: any) => {
        props.body.page = pageIdx - 1;
        setPage(pageIdx - 1)
        props.onChange(props.body);
    }

    const onSizeChange = (_size: any) => {
        props.body.size = _size;
        setSize(_size);
        props.onChange(props.body);
    }

    const onChangeBusca = (txt: string) => {
        props.body.txtBusca = txt;
        setTxtBusca(txt);
        if(txt.length >= 3){
            props.onChange(props.body);
        } else {
            props.body.txtBusca = '';
            props.onChange(props.body);
        }
    }

    const onSortChange = (sort: any) => {
        props.body.sort = sort.field + ',' + sort.sort;
        props.onChange(props.body);
    }

    return (
        <GenericTable 
            loading={props.loading}
            titleTable='Níveis Cadastrados'
            rows={listChatbotNivelTable} 
            columns={columns} 
            page={page} 
            totalPages={totalPages} 
            onPageChange={onPageChange}
            offset={offset}
            numberElements={numberElements} 
            totalElements={totalElements}
            size={size}
            sizeChange={onSizeChange}
            txtBusca={txtBusca}
            onChangePesquisa={onChangeBusca}
            selection={props.selection}
            idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
            sortChange={onSortChange}
        />
    )
};

export default ChatbotNivelTableComponent;