import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';

import * as UtilsActions from '../../store/ducks/utils/actions';
import { ApplicationState } from "../../store";

function ListaMunicipio({
    utils,
    getAllMunicipioList,
    municipios,
    onChangeMunicipios,
    error
}: any) {

    
    const [municipiosList, setMunicipiosList] = React.useState<any>([]);
    const [municipiosSelecionados, setMunicipiosSelecionados] = React.useState<any>([]);
    const [selectAll, setSelectAll] =  React.useState(false);
    const [lazyItems, setLazyItems] = React.useState<any>([]);
    const [lazyLoading, setLazyLoading] = React.useState(false);
    const [filtro , setFiltro] = React.useState("");


    const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    React.useEffect(() => {
        getAllMunicipioList() 
    }, []);

    React.useEffect(() => {
        setMunicipiosList(utils.data.municipios);
        if(utils.data.municipios.length > 0){
            setSelectAll(municipios.length === utils.data.municipios.length);
        }
        
    }, [utils.data.municipios]);

    React.useEffect(() => {
        if(municipios){
            let count = 0
            setMunicipiosSelecionados(municipios.map(
                (item: any) => 
                    item.idMunicipio
                ));
            
        }
    }, [municipios]);

    const onLazyLoad = (event: any) => {
        setLazyLoading(true);

        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }

        loadLazyTimeout.current = setTimeout(() => {
            const { first, last } = event;
            console.log(event);
            const _lazyItems = [...lazyItems];
            _lazyItems[first] = { label: `Item #${first}`, value: first };
            _lazyItems[last] = { label: `Item #${last}`, value: last };
            setLazyItems(_lazyItems);
            setLazyLoading(false);
        }, 300);
    }

    
    

    const comparaLista = (listaVisual: Array<any>, listaSelecao: Array<any>) => {
        let validade = false;
        if (listaVisual[listaVisual.length - 1] != listaSelecao[listaSelecao.length - 1] && listaVisual[0] === listaSelecao[0]){
            return true;
        } 
        for (let i = 0; i < listaVisual.length; i++) {
            for (let j = 0; j < listaSelecao.length; j++){
                if(listaVisual[i] === listaSelecao[j]){
                    validade = true;
                }else{
                    validade = false;
                }
            }
        } 
        if (listaSelecao.length === 0){
            validade = true;
        }
        return validade;
    }

    const separaValorLista = (lista: Array<any>) => {
        let retorno = new Array();
        for (let i = 0; i < lista.length; i++){
            retorno.push(lista[i].idMunicipio);
        }
        return retorno;
    }
  

    return (
        <MultiSelect 
            style={{
                width: '100%'
            }}
            value={municipiosSelecionados} 
            options={municipiosList} 
            display="chip"
            onChange={(e) => {
                if(e.value){
                    if(e.value.length === municipiosList.length){
                        setSelectAll(e.value.length === municipiosList.length);
                        setMunicipiosSelecionados(e.value); 
                        onChangeMunicipios(municipiosList.filter((muni: any) => e.value.includes(muni.idMunicipio)));
                    } else if(!comparaLista(municipiosSelecionados, e.value)){
                        setSelectAll(e.value.length === municipiosList.length);
                        let _municipios = [...municipiosSelecionados, ...e.value.filter((item: any) => !municipiosSelecionados.includes(item))]
                        setMunicipiosSelecionados(_municipios); 
                        onChangeMunicipios(municipiosList.filter((muni: any) => _municipios.includes(muni.idMunicipio)));
                    }else{
                        if(e.value.length === 0){
                            setSelectAll(e.value.length === municipiosList.length);
                            let listaPesquisaMunicipio = [...municipiosList.filter((item: any) => item.nmMunicipio.includes(filtro))];
                            listaPesquisaMunicipio = separaValorLista(listaPesquisaMunicipio);
                            let _municipios = [...municipiosSelecionados.filter((item: any) => !listaPesquisaMunicipio.includes(item))]
                            setMunicipiosSelecionados(_municipios); 
                            onChangeMunicipios(municipiosList.filter((muni: any) => _municipios.includes(muni.idMunicipio)));
                        }else{
                            setSelectAll(e.value.length === municipiosList.length);
                            setMunicipiosSelecionados(e.value); 
                            onChangeMunicipios(municipiosList.filter((muni: any) => e.value.includes(muni.idMunicipio)));
                    }}               
                } else {
                    setMunicipiosSelecionados([])
                    setSelectAll(false);
                    onChangeMunicipios([])
                }
            }} 
            onFilter={(e) => {setFiltro(e.filter)}}
            selectAll={selectAll} 
            optionLabel="nmMunicipio"
            optionValue='idMunicipio'
            placeholder="Selecione os Municípios" 
            filter 
            showClear={true}
            className={error ? "p-multiselect-error" : ''}
            maxSelectedLabels={3} 
            selectedItemsLabel={`${municipiosSelecionados ? municipiosSelecionados.length : 0} municípios selecionados`}
            emptyFilterMessage='Não encontrado'
            filterPlaceholder='Busque por um município'
            resetFilterOnHide={true}
            virtualScrollerOptions={{ lazy: true, onLazyLoad: onLazyLoad, itemSize: 43, showLoader: true, loading: lazyLoading, delay: 1, loadingTemplate: (options) => {
                
                return (
                    <div className="flex align-items-center p-2" style={{ height: '43px' }}>
                        <Skeleton width={options.even ? '70%' : '60%'} height="1.5rem" />
                    </div>
                )
                }
            }} 
        />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UtilsActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaMunicipio);