import { Dayjs } from "dayjs"

export enum CursoTypes {
  CURSO_TABLE_REQUEST = 'curso/CURSO_TABLE_REQUEST',
  CURSO_TABLE_REQUEST_SUCCESS = 'curso/CURSO_TABLE_RQUEST_SUCCESS',
  CURSO_TABLE_REQUEST_FAILURE = 'curso/CURSO_TABLE_REQUEST_FAILURE',

  CURSO_ATIVA_REQUEST = 'curso/CURSO_ATIVA_REQUEST',
  CURSO_ATIVA_REQUEST_SUCCESS = 'curso/CURSO_ATIVA_REQUEST_SUCCESS',
  CURSO_ATIVA_REQUEST_FAILURE = 'curso/CURSO_ATIVA_REQUEST_FAILURE',

  CURSO_INATIVA_REQUEST = 'curso/CURSO_INATIVA_REQUEST',
  CURSO_INATIVA_REQUEST_SUCCESS = 'curso/CURSO_INATIVA_REQUEST_SUCCESS',
  CURSO_INATIVA_REQUEST_FAILURE = 'curso/CURSO_INATIVA_REQUEST_FAILURE',

  CLEAR_CURSO_DATA = 'curso/CLEAR_CURSO_DATA',

  CURSO_DATA_REQUEST = 'curso/CURSO_DATA_REQUEST',
  CURSO_DATA_REQUEST_SUCCESS = 'curso/CURSO_DATA_REQUEST_SUCCESS',
  CURSO_DATA_REQUEST_FAILURE = 'curso/CURSO_DATA_REQUEST_FAILURE',

  CURSO_SAVE_REQUEST = 'curso/CURSO_SAVE_REQUEST',
  CURSO_SAVE_REQUEST_SUCCESS = 'curso/CURSO_SAVE_REQUEST_SUCCESS',
  CURSO_SAVE_REQUEST_FAILURE = 'curso/CURSO_SAVE_REQUEST_FAILURE',

  CURSO_UPDATE_REQUEST = 'curso/CURSO_UPDATE_REQUEST',
  CURSO_UPDATE_REQUEST_SUCCESS = 'curso/CURSO_UPDATE_REQUEST_SUCCESS',
  CURSO_UPDATE_REQUEST_FAILURE = 'curso/CURSO_UPDATE_REQUEST_FAILURE',

  CURSO_PUSH_REQUEST = 'curso/CURSO_PUSH_REQUEST',
  CURSO_PUSH_REQUEST_SUCCESS = 'curso/CURSO_PUSH_REQUEST_SUCCESS',
  CURSO_PUSH_REQUEST_FAILURE = 'curso/CURSO_PUSH_REQUEST_FAILURE',
}

export interface CursoObjTable {
  id?: number,
  idCurso?: number,
  nmCurso?: string,
  dsCurso?: string,
  dsTempoEstimado?: string
  nmTema?: string,
  qtdConteudos?: number
}

export interface CursoObj {
  idCurso?: number,
  nmCurso?: string,
  dsCurso?: string,
  dtCursoInicio?: null | Dayjs | string,
  dtCursoFim?: null | Dayjs | string,
  dsTempoEstimado?: string,
  tema?: any | null,
  imgCurso?: string | null,
  urlCompartilhamentoCurso?: string,
  idDatabaseStatus?: number | null,
  cursoMuncipios?: any | null,
  cursoPerfis?: any | null,
  cursoConteudos?: any | null,
  cursoAvaliacoes?: any | null,
  icExibirCarrossel?: number,
}

export interface SegurancaNoTrabalhoObjTable {
  id?: number,
  idSegurancaNoTrabalho?: number,
  nmSegurancaNoTrabalho?: string,
  dsSegurancaNoTrabalho?: string,
  dsTempoEstimado?: string
  nmTema?: string,
  qtdConteudos?: number
}

export interface SegurancaNoTrabalhoObj {
  idSegurancaNoTrabalho?: number,
  nmSegurancaNoTrabalho?: string,
  dsSegurancaNoTrabalho?: string,
  dtSegurancaNoTrabalhoInicio?: null | Dayjs | string,
  dtSegurancaNoTrabalhoFim?: null | Dayjs | string,
  dsTempoEstimado?: string,
  tema?: any | null,
  imgSegurancaNoTrabalho?: string | null,
  urlCompartilhamentoSegurancaNoTrabalho?: string,
  idDatabaseStatus?: number | null,
  segurancaNoTrabalhoMuncipios?: any | null,
  segurancaNoTrabalhoPerfis?: any | null,
  segurancaNoTrabalhoConteudos?: any | null,
  segurancaNoTrabalhoAvaliacoes?: any | null,
  icExibirCarrossel?: number,
}


export interface CursoTable {
  [x: string]: any
  content: Array<Object>,
  totalPages: number,
  totalElements: number,
  size: number,
  number: number,
  numberOfElements: number
}

export interface CursoPage {
  readonly curso: CursoObj | null,
  readonly table: CursoTable | null,
}

export interface CursoState {
  readonly data: CursoPage,
  readonly loading: boolean,
  readonly loadingTable: boolean,
  readonly loadingPersis: boolean,
  readonly error: boolean,
  readonly errorMessage: string | null,
  readonly loadingInativacao: boolean,
}