import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

import * as UtilsActions from "../../store/ducks/utils/actions";
import { ApplicationState } from "../../store";

function ListaEstado({
  utils,
  getEstadosList,
  estados,
  onChangeEstados,
  error,
}: any) {
  const [estadosList, setEstadosList] = React.useState<any>([]);
  const [estadosSelecionados, setEstadosSelecionados] = React.useState<any>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [lazyItems, setLazyItems] = React.useState<any>([]);
  const [lazyLoading, setLazyLoading] = React.useState(false);

  const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(
    null
  );

  React.useEffect(() => {
    getEstadosList();
  }, []);

  React.useEffect(() => {
    setEstadosList(utils.data.estados);
    if (utils.data.estados.length > 0) {
      setSelectAll(estados.length == utils.data.estados.length);
    }
  }, [utils.data.estados]);

  React.useEffect(() => {
    if (estados) {
      setEstadosSelecionados(estados.map((item: any) => item.idEstado));
    }
  }, [estados]);

  const onLazyLoad = (event: any) => {
    setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, 300);
  };

  return (
    <MultiSelect
      style={{
        width: "100%",
      }}
      value={estadosSelecionados}
      options={estadosList}
      display="chip"
      onChange={(e) => {
        if (e.value) {
          setEstadosSelecionados(e.value);
          setSelectAll(e.value.length === estadosList.length);
          onChangeEstados(
            estadosList.filter((esta: any) => e.value.includes(esta.idEstado))
          );
        } else {
          setEstadosSelecionados([]);
          setSelectAll(false);
          onChangeEstados([]);
        }
      }}
      selectAll={selectAll}
      optionLabel="nmEstado"
      optionValue="idEstado"
      placeholder="Selecione os Estados"
      filter
      showClear={true}
      className={error ? "p-multiselect-error" : ""}
      maxSelectedLabels={3}
      selectedItemsLabel={`${
        estadosSelecionados ? estadosSelecionados.length : 0
      } estados selecionados`}
      emptyFilterMessage="Não encontrado"
      filterPlaceholder="Busque por um estado"
      resetFilterOnHide={true}
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: onLazyLoad,
        itemSize: 43,
        showLoader: true,
        loading: lazyLoading,
        delay: 1,
        loadingTemplate: (options) => {
          return (
            <div
              className="flex align-items-center p-2"
              style={{ height: "43px" }}
            >
              <Skeleton width={options.even ? "70%" : "60%"} height="1.5rem" />
            </div>
          );
        },
      }}
    />
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaEstado);
