import { Reducer } from "@reduxjs/toolkit";
import { UtilsState, UtilTypes } from "./types";

const INITIAL_STATE: UtilsState = {
  data: {
    menu: null,
    perfil: [],
    estados: [],
    municipios: [],
    usuarios: [],
    regional: null,
    unidadesNegocio: null,
    tema: [],
    avaliacao: [],
    conteudo: [],
    curso: [],
    gestores: [],
    grupos: null,
    subclusters: null,
    clusters: null,
    trilhas: []
  },
  loading: false,
  error: false,
  errorMessage: null,
};

const reducer: Reducer<UtilsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UtilTypes.ESTADOS_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

      case UtilTypes.GRUPO_ALL_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.GRUPO_ALL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          grupos: action.payload.data,
        },
      };

    case UtilTypes.GRUPO_ALL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          grupos: [],
        },
      };

      case UtilTypes.SUBCLUSTER_ALL_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

      case UtilTypes.SUBCLUSTER_ALL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          subclusters: action.payload.data,
        },
      };

      case UtilTypes.SUBCLUSTER_ALL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          subclusters: [],
        },
      };

      case UtilTypes.SUBCLUSTER_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.SUBCLUSTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          subclusters: action.payload.data,
        },
      };

      case UtilTypes.SUBCLUSTER_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          errorMessage: action.payload.err,
          data: {
            ...state.data,
            subclusters: null,
          },
        };
  
      case UtilTypes.CLUSTER_ALL_LIST:
        return {
          ...state,
          loading: true,
          error: false,
        };

        case UtilTypes.CLUSTER_ALL_LIST_SUCCESS:
          return {
            ...state,
            loading: false,
            error: false,
            data: {
              ...state.data,
              clusters: action.payload.data,
            },
          };

          case UtilTypes.CLUSTER_ALL_LIST_FAILURE:
            return {
              ...state,
              loading: false,
              error: true,
              errorMessage: action.payload.err,
              data: {
                ...state.data,
                clusters: [],
              },
            };

        case UtilTypes.CLUSTER_LIST:
          return {
            ...state,
            loading: true,
            error: false,
          };
    
        case UtilTypes.CLUSTER_LIST_SUCCESS:
          return {
            ...state,
            loading: false,
            error: false,
            data: {
              ...state.data,
              clusters: action.payload.data,
            },
          };

          case UtilTypes.CLUSTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          clusters: null,
        },
      };

      case UtilTypes.REGIONAL:
      return {
        ...state,
        loading: true,
        error: false,
      };

      case UtilTypes.REGIONAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          regional: action.payload.data,
        },
      };

      case UtilTypes.REGIONAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          regional: null,
        },
      };

    case UtilTypes.ESTADOS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          estados: action.payload.data,
        },
      };

    case UtilTypes.ESTADOS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          estados: [],
        },
      };

    case UtilTypes.GESTORES_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.GESTORES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          gestores: action.payload.data,
        },
      };

    case UtilTypes.GESTORES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          gestores: null,
        },
      };

    case UtilTypes.MUNICIPIOS_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.MUNICIPIOS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          municipios: action.payload.data,
        },
      };

    case UtilTypes.MUNICIPIOS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          municipios: null,
        },
      };

    case UtilTypes.USUARIOS_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.USUARIOS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          usuarios: action.payload.data,
        },
      };

    case UtilTypes.USUARIOS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          usuarios: null,
        },
      };

    case UtilTypes.MUNICIPIO_ALL_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.MUNICIPIO_ALL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          municipios: action.payload.data,
        },
      };

    case UtilTypes.MUNICIPIO_ALL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          municipios: [],
        },
      };

    case UtilTypes.REGIONAL:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.REGIONAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          regional: action.payload.data,
        },
      };

    case UtilTypes.REGIONAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          regional: null,
        },
      };


      case UtilTypes.GRUPO_CLUSTER_SUBCLUSTER:
            return {
                ...state,
                loading: true,
                error: false
            };
    
        case UtilTypes.GRUPO_CLUSTER_SUBCLUSTER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                data: {
                    ...state.data,

                    regional: {idRegional: action.payload.data.regional.idRegional,
                        nmRegional: action.payload.data.regional.nmRegional,
                        dsRegional: action.payload.data.regional.dsRegional,},

                    grupo: {idGrupo: action.payload.data.grupo.idGrupo,
                        nmGrupo: action.payload.data.grupo.nmGrupo,
                        dsGrupo: action.payload.data.grupo.dsGrupo,},


                        cluster: {idCluster: action.payload.data.cluster.idCluster,
                            nmCluster: action.payload.data.cluster.nmCluster,
                            dsCluster: action.payload.data.cluster.dsCluster,},

                    subcluster: {idSubcluster: action.payload.data.subcluster.idSubcluster,
                                nmSubcluster: action.payload.data.subcluster.nmSubcluster,
                                dsSubcluster: action.payload.data.subcluster.dsSubcluster,}
                }
            };

        case UtilTypes.GRUPO_CLUSTER_SUBCLUSTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    cluster: null,

                    grupo: null,

                    subcluster: null
                }
            };

    case UtilTypes.MENU_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          menu: action.payload.data,
        },
      };

    case UtilTypes.MENU_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          menu: null,
        },
      };

    case UtilTypes.PERFIL_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.PERFIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          perfil: action.payload.data,
        },
      };

    case UtilTypes.PERFIL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          perfil: [],
        },
      };

    case UtilTypes.UNIDADE_NEGOCIO_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.UNIDADE_NEGOCIO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          unidadesNegocio: action.payload.data,
        },
      };

    case UtilTypes.UNIDADE_NEGOCIO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          unidadesNegocio: null,
        },
      };

    case UtilTypes.TEMA_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.TEMA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          tema: action.payload.data,
        },
      };

    case UtilTypes.TEMA_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          tema: [],
        },
      };

    case UtilTypes.CONTEUDO_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.CONTEUDO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          conteudo: action.payload.data,
        },
      };

    case UtilTypes.CONTEUDO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          conteudo: [],
        },
      };

    case UtilTypes.AVALIACAO_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.AVALIACAO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          avaliacao: action.payload.data,
        },
      };

    case UtilTypes.AVALIACAO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          avaliacao: [],
        },
      };

    case UtilTypes.AVALIACAO_BY_PERFIL_ESTADO:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.AVALIACAO_BY_PERFIL_ESTADO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          avaliacao: action.payload.data,
        },
      };

    case UtilTypes.AVALIACAO_BY_PERFIL_ESTADO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          avaliacao: [],
        },
      };

    case UtilTypes.CURSO_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.CURSO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          curso: action.payload.data,
        },
      };

    case UtilTypes.CURSO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          curso: [],
        },
      };

      case UtilTypes.SEGURANCA_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.SEGURANCA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          curso: action.payload.data,
        },
      };

    case UtilTypes.SEGURANCA_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          curso: [],
        },
      };

    case UtilTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.SET_LOADING_FALSE:
      if (action?.payload?.err) {
        alert("Erro!\n" + action.payload.err);
        return {
          ...state,
          loading: false,
          error: true,
          errorMessage: action.payload.err,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
      };

    case UtilTypes.TRILHA_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case UtilTypes.TRILHA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          trilhas: action.payload.data,
        },
      };

    case UtilTypes.TRILHA_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          trilhas: [],
        },
      };

    default:
      return {
        ...state,
        error: false,
        loading: false,
        errorMessage: null,
      };
  }
};

export default reducer;
