import { action } from "typesafe-actions";
import { MunicipioTypes as MunicipioTypes } from "./types";

export const getMunicipioTable = (data: any) => action(MunicipioTypes.MUNICIPIO_TABLE_REQUEST, data);
export const getMunicipioTableSuccess = (data: any) => action(MunicipioTypes.MUNICIPIO_TABLE_REQUEST_SUCCESS, { data });
export const getMunicipioTableFailure = (err: any) => action(MunicipioTypes.MUNICIPIO_TABLE_REQUEST_FAILURE, { err });

export const getMunicipioData = (data: any) => action(MunicipioTypes.MUNICIPIO_DATA_REQUEST, data);
export const getMunicipioDataSuccess = (data: any) => action(MunicipioTypes.MUNICIPIO_DATA_REQUEST_SUCCESS, { data });
export const getMunicipioDataFailure = (err: any) => action(MunicipioTypes.MUNICIPIO_DATA_REQUEST_FAILURE, { err });

export const onSaveMunicipio = (data: any) => action(MunicipioTypes.MUNICIPIO_SAVE_REQUEST, data);
export const onSaveMunicipioSuccess = (data: any) => action(MunicipioTypes.MUNICIPIO_SAVE_REQUEST_SUCCESS, { data });
export const onSaveMunicipioFailure = (err: any) => action(MunicipioTypes.MUNICIPIO_SAVE_REQUEST_FAILURE, { err });

export const clearMunicipioData = () => action(MunicipioTypes.CLEAR_MUNICIPIO_DATA);