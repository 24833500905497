import { Reducer } from "@reduxjs/toolkit";
import { TemaState, TemaTypes } from "./types";

const INITIAL_STATE: TemaState = {
    data: {
        table: null,
        tema: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingPersis: false,
    loadingTable: false,
    loadingInativacao: false,
};

const reducer: Reducer<TemaState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TemaTypes.TEMA_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case TemaTypes.TEMA_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case TemaTypes.TEMA_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case TemaTypes.TEMA_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case TemaTypes.TEMA_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case TemaTypes.TEMA_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case TemaTypes.TEMA_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case TemaTypes.TEMA_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case TemaTypes.TEMA_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case TemaTypes.TEMA_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case TemaTypes.TEMA_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    tema: action.payload.data
                }
            };
        case TemaTypes.TEMA_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    tema: null
                }
            };

        case TemaTypes.CLEAR_TEMA_DATA:
                return {
                    ...state,
                    error: false,
                    loading: false,
                    data: {
                        ...state.data,
                        tema: null
                    }
                };

        case TemaTypes.TEMA_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case TemaTypes.TEMA_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Tema realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    tema: action.payload.data
                }
            };
        case TemaTypes.TEMA_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case TemaTypes.TEMA_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case TemaTypes.TEMA_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Tema realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    tema: action.payload.data
                }
            };
        case TemaTypes.TEMA_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };


        case TemaTypes.TEMA_EXISTENTE_REQUEST:
            return {
            ...state,
            error: false,
            loading: false,
            errorMessage: null
        };
        case TemaTypes.TEMA_EXISTENTE_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    tema: action.payload.data !== '' ? {
                        idTema: action.payload.data 
                    } : null
                }
            };
        case TemaTypes.TEMA_EXISTENTE_REQUEST_FAILURE:
                    return {
            ...state,
            error: false,
            loading: false,
            errorMessage: null
        };

        default :
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
};

export default reducer;