import React from "react";
import { useEffect, useMemo, useState } from "react";
import { type MRT_ColumnDef } from "material-react-table";
import Highlighter from "react-highlight-words";

import { ChatBotCategoriaObjTable, ChatBotCategoriaTable } from "../../store/ducks/chatbot/categoria/types";
import GenericTableTree from "../GenericTableTree/GenericTableTree";

import './style.css'

const ChatbotCategoriaTableTreeComponent = (props: any) => {

    const [txtBusca, setTxtBusca] = useState('');
    const [listChatbotCategoriaTable, setListChatbotCategoriaTable] = useState<ChatBotCategoriaObjTable[]>([]);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numberElements, setNumberElements] = useState(0);
    const [offset, setOffset] = useState(0);
    const [wordHighlights, setWordsHighlights] = useState<Array<string>>([])

    useEffect(() => {
        if (props.data) {
            tranformObjectTable(props.data)
        }
    }, [props.data]);

    useEffect(() => {
        let arrText = txtBusca.split(" ");
        setWordsHighlights(arrText);
    }, [txtBusca])

    const columns = useMemo<MRT_ColumnDef[]>(() => [
        {
            accessorKey: 'titulo',
            header: 'Titulo',
            size: 300,
            Cell: ({ cell }) => (
                <Highlighter
                    highlightClassName="highlight"
                    searchWords={wordHighlights}
                    autoEscape={true}
                    textToHighlight={cell.getValue<string>()}
                />
            ),
        },
        {
            accessorKey: 'contexto',
            header: 'Contexto',
            size: 600,
            Cell: ({ cell }) => (
                <Highlighter
                    highlightClassName="highlight"
                    searchWords={wordHighlights}
                    autoEscape={true}
                    textToHighlight={cell.getValue<string>()}
                />
            ),
        },

        {
            accessorKey: 'key',
            header: 'Chave',
            size: 200,
        }
    ],
        [wordHighlights],
    );

    const tranformObjectTable = (obj: ChatBotCategoriaTable) => {
        setSize(obj.size);
        setPage(obj.number);
        setTotalElements(obj.totalElements);
        setTotalPages(obj.totalPages);
        setNumberElements(obj.numberOfElements);
        setOffset(obj.pageable.offset)

        obj.content.forEach(item => {
            item.id = item.idCategoria;
        })

        setListChatbotCategoriaTable(obj.content);
    };

    const onPageChange = (pageIdx: any) => {
        props.body.page = pageIdx - 1;
        setPage(pageIdx - 1)
        props.onChange(props.body);
    }

    const onSizeChange = (_size: any) => {
        props.body.size = _size;
        setSize(_size);
        props.onChange(props.body);
    }

    const onChangeBusca = (txt: string) => {
        props.body.txtBusca = txt;
        setTxtBusca(txt);
        if (txt.length >= 3) {
            props.onChange(props.body);
        } else {
            props.body.txtBusca = '';
            props.onChange(props.body);
        }
    }

    const onSortChange = (sort: any) => {
        let field = sort.id == 'key' ? 'keyCategoria' : sort.id;
        let _sort = sort.desc ? 'desc' : 'asc';

        props.body.sort = field + ',' + _sort
        props.onChange(props.body);
    }

    return (
        <GenericTableTree
            titleTable="Categorias e Níveis"
            rows={listChatbotCategoriaTable}
            columns={columns}
            page={page}
            totalPages={totalPages}
            offset={offset}
            numberElements={numberElements}
            totalElements={totalElements}
            size={size}
            txtBusca={txtBusca}
            onPageChange={onPageChange}
            onChangePesquisa={onChangeBusca}
            sizeChange={onSizeChange}
            sortChange={onSortChange}
            onCriarCategoria={props.onCriarCategoria}
            onEditar={props.onEditar}
            onExcluir={props.onExcluir}
            onAddNivel={props.onAddNivel}
            loading={props.loading}
        />

    )
};

export default ChatbotCategoriaTableTreeComponent;