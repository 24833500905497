import { Reducer } from "@reduxjs/toolkit";
import { DestaqueState, DestaqueTypes } from "./types";

const INITIAL_STATE: DestaqueState = {
    data: {
        table: null,
        destaque: null,
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};


const reducer: Reducer<DestaqueState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DestaqueTypes.DESTAQUE_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case DestaqueTypes.DESTAQUE_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case DestaqueTypes.DESTAQUE_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };
        case DestaqueTypes.DESTAQUE_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case DestaqueTypes.DESTAQUE_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case DestaqueTypes.DESTAQUE_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };
        case DestaqueTypes.DESTAQUE_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case DestaqueTypes.DESTAQUE_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case DestaqueTypes.DESTAQUE_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };
        case DestaqueTypes.DESTAQUE_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case DestaqueTypes.DESTAQUE_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    destaque: action.payload.data
                }
            };
        case DestaqueTypes.DESTAQUE_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    destaque: null
                }
            };
        case DestaqueTypes.CLEAR_DESTAQUE_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    destaque: null
                }
            };
        case DestaqueTypes.DESTAQUE_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case DestaqueTypes.DESTAQUE_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Destaque realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    destaque: action.payload.data
                }
            };
        case DestaqueTypes.DESTAQUE_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };
        case DestaqueTypes.DESTAQUE_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case DestaqueTypes.DESTAQUE_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Destaque realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    destaque: action.payload.data
                }
            };
        case DestaqueTypes.DESTAQUE_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        default :
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
};

export default reducer;