import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import {
  getClusterDataFailure,
  getClusterDataSuccess,
  getClusterTableFailure,
  getClusterTableSuccess,
  onInativarClusterFailure,
  onInativarClusterSuccess,
  onSaveClusterFailure,
  onSaveClusterSuccess,
  onUpdateClusterFailure,
  onUpdateClusterSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getClusterTable(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("cluster/listAllClusterTable", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getClusterTableSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getClusterTableFailure(error.message));
  }
}

function* onInativarCluster(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("cluster/desativarCluster", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onInativarClusterSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onInativarClusterFailure(error.message));
  }
}

function* getClusterData(data: any): any {
  const idCluster = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`cluster/${idCluster}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getClusterDataSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getClusterDataFailure(error.message));
  }
}

function* onSaveCluster(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("cluster/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onSaveClusterSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onSaveClusterFailure(error.message));
  }
}

function* onUpdateCluster(data: any): any {
  const body = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post("cluster/save", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(onUpdateClusterSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(onUpdateClusterFailure(error.message));
  }
}

export {
  getClusterData,
  onInativarCluster,
  getClusterTable,
  onSaveCluster,
  onUpdateCluster,
};
