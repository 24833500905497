import { Dayjs } from "dayjs"

export enum DestaqueTypes {
    DESTAQUE_TABLE_REQUEST = 'conteudo/DESTAQUE_TABLE_REQUEST',
    DESTAQUE_TABLE_REQUEST_SUCCESS = 'conteudo/DESTAQUE_TABLE_RQUEST_SUCCESS',
    DESTAQUE_TABLE_REQUEST_FAILURE = 'conteudo/DESTAQUE_TABLE_REQUEST_FAILURE',

    DESTAQUE_ATIVA_REQUEST = 'conteudo/DESTAQUE_ATIVA_REQUEST',
    DESTAQUE_ATIVA_REQUEST_SUCCESS = 'conteudo/DESTAQUE_ATIVA_REQUEST_SUCCESS',
    DESTAQUE_ATIVA_REQUEST_FAILURE = 'conteudo/DESTAQUE_ATIVA_REQUEST_FAILURE',

    DESTAQUE_INATIVA_REQUEST = 'conteudo/DESTAQUE_INATIVA_REQUEST',
    DESTAQUE_INATIVA_REQUEST_SUCCESS = 'conteudo/DESTAQUE_INATIVA_REQUEST_SUCCESS',
    DESTAQUE_INATIVA_REQUEST_FAILURE = 'conteudo/DESTAQUE_INATIVA_REQUEST_FAILURE',

    CLEAR_DESTAQUE_DATA = 'conteudo/CLEAR_DESTAQUE_DATA',

    DESTAQUE_DATA_REQUEST = 'conteudo/DESTAQUE_DATA_REQUEST',
    DESTAQUE_DATA_REQUEST_SUCCESS = 'conteudo/DESTAQUE_DATA_REQUEST_SUCCESS',
    DESTAQUE_DATA_REQUEST_FAILURE = 'conteudo/DESTAQUE_DATA_REQUEST_FAILURE',

    DESTAQUE_SAVE_REQUEST = 'conteudo/DESTAQUE_SAVE_REQUEST',
    DESTAQUE_SAVE_REQUEST_SUCCESS = 'conteudo/DESTAQUE_SAVE_REQUEST_SUCCESS',
    DESTAQUE_SAVE_REQUEST_FAILURE = 'conteudo/DESTAQUE_SAVE_REQUEST_FAILURE',

    DESTAQUE_UPDATE_REQUEST = 'conteudo/DESTAQUE_UPDATE_REQUEST',
    DESTAQUE_UPDATE_REQUEST_SUCCESS = 'conteudo/DESTAQUE_UPDATE_REQUEST_SUCCESS',
    DESTAQUE_UPDATE_REQUEST_FAILURE = 'conteudo/DESTAQUE_UPDATE_REQUEST_FAILURE',
}

export interface DestaqueObjTable {
    id?: number,
    idConteudoDestaque?: number,
    dsDestaque?: string,
    urlCapaDestaque?: string,
    dtDestaqueInicio?: null | Dayjs | string,
	dtDestaqueFim?: null | Dayjs | string,
    nmConteudo?: string,
}

export interface DestaqueObj {
    idConteudoDestaque?: number,
    dsDestaque?: string,
    urlCapaDestaque?: string | null,
    dtDestaqueInicio?: null | Dayjs | string,
	dtDestaqueFim?: null | Dayjs | string,
    icFixoPrimeiro?: number,
    conteudo?: any,
    icDestaqueVisualizado?: number
    icDestaqueInicial?: number
}

export interface DestaqueTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface DestaquePage {
    readonly destaque: DestaqueObj | null,
    readonly table: DestaqueTable | null,
}

export interface DestaqueState {
    readonly data: DestaquePage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly loadingInativacao: boolean,
}