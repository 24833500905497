import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';

import * as UtilsActions from '../../store/ducks/utils/actions';
import { ApplicationState } from "../../store";

function ListaSubcluster({
    multiple = true,
    utils,
    getAllSubclusterList,
    subclusters,
    onChangeSubclusters,
    error
}: any) {

    const [subclustersList, setSubclustersList] = React.useState<any>([]);
    const [subclustersSelecionados, setSubclustersSelecionados] = React.useState<any>([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const [lazyItems, setLazyItems] = React.useState<any>([]);
    const [lazyLoading, setLazyLoading] = React.useState(false);

    const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    React.useEffect(() => {
        getAllSubclusterList()
    }, []);

    React.useEffect(() => {
        setSubclustersList(utils.data.subclusters);
        if (utils.data.subclusters && utils.data.subclusters.length > 0) {
            if(multiple){
                setSelectAll(subclusters.length == utils.data.subclusters.length);
            }
        }

    }, [utils.data.subclusters]);

    React.useEffect(() => {
        if (subclusters) {
            setSubclustersSelecionados(multiple ? subclusters.map((item: any) => item.idSubcluster)
            : subclusters.idSubcluster ? [subclusters.idSubcluster] : []);
        }
    }, [subclusters]);

    const onLazyLoad = (event: any) => {
        setLazyLoading(true);

        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }

        loadLazyTimeout.current = setTimeout(() => {
            const { first, last } = event;
            const _lazyItems = [...lazyItems];

            for (let i = first; i < last; i++) {
                _lazyItems[i] = { label: `Item #${i}`, value: i };
            }

            setLazyItems(_lazyItems);
            setLazyLoading(false);
        }, 300);
    }

    return (
        <MultiSelect
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }}
            {...(!multiple && { selectionLimit: 1 })}
            showSelectAll={multiple}
            value={subclustersSelecionados}
            options={subclustersList}
            display="chip"
            onChange={(e) => {
                if (e.value) {
                    setSubclustersSelecionados(e.value);
                    setSelectAll(e.value.length === subclustersList.length);
                    onChangeSubclusters(subclustersList.filter((subclu: any) => e.value.includes(subclu.idSubcluster)));
                } else {
                    setSubclustersSelecionados([])
                    setSelectAll(false);
                    onChangeSubclusters([])
                }
            }}
            selectAll={selectAll}
            optionLabel="nmSubcluster"
            optionValue='idSubcluster'
            placeholder={
                multiple ? "Selecione os Subclusters" : "Selecione um Subcluster"
            }
            filter
            showClear={true}
            className={error ? "p-multiselect-error" : ''}
            maxSelectedLabels={3}
            selectedItemsLabel={`${subclustersSelecionados ? subclustersSelecionados.length : 0} subclusters selecionados`}
            emptyFilterMessage='Não encontrado'
            filterPlaceholder={
                multiple ? "Busque por subclusters" : "Busque por um subcluster"
            }
            resetFilterOnHide={true}
            virtualScrollerOptions={{
                lazy: true, onLazyLoad: onLazyLoad, itemSize: 43, showLoader: true, loading: lazyLoading, delay: 1, loadingTemplate: (options) => {
                    return (
                        <div className="flex align-items-center p-2" style={{ height: '43px' }}>
                            <Skeleton width={options.even ? '70%' : '60%'} height="1.5rem" />
                        </div>
                    )
                }
            }}
        />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    utils: state.utils
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaSubcluster);