import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

import * as UtilsActions from "../../store/ducks/utils/actions";
import { ApplicationState } from "../../store";

function ListaAvaliacao({
  multiple = true,
  utils,
  getAvaliacaoList,
  avaliacoes,
  onChangeAvaliacoes,
  error,
}: any) {
  const [avaliacaoList, setAvaliacaosList] = React.useState<any>([]);
  const [avaliacaoSelecionados, setAvaliacaosSelecionados] =
    React.useState<any>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [lazyItems, setLazyItems] = React.useState<any>([]);
  const [lazyLoading, setLazyLoading] = React.useState(false);

  const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(
    null
  );

  React.useEffect(() => {
    getAvaliacaoList();
  }, []);

  React.useEffect(() => {
    if (utils.data.avaliacao) {
      setAvaliacaosList(utils.data.avaliacao);
      if (utils.data.avaliacao.length > 0) {
        setSelectAll(avaliacoes.length == utils.data.avaliacao.length);
      }
    }
  }, [utils.data.avaliacao]);

  React.useEffect(() => {
    if (avaliacoes) {
      setAvaliacaosSelecionados(
        avaliacoes.map((item: any) => item.idAvaliacao)
      );
    }
  }, [avaliacoes]);

  const onLazyLoad = (event: any) => {
    setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, 300);
  };

  return (
    <MultiSelect
      style={{
        width: "100%",
        height: "100%",
      }}
      value={avaliacaoSelecionados}
      options={avaliacaoList}
      display="chip"
      onChange={(e) => {
        if (e.value) {
          setAvaliacaosSelecionados(e.value);
          setSelectAll(e.value.length === avaliacaoList.length);
          onChangeAvaliacoes(
            avaliacaoList.filter((muni: any) =>
              e.value.includes(muni.idAvaliacao)
            )
          );
        } else {
          setAvaliacaosSelecionados([]);
          setSelectAll(false);
          onChangeAvaliacoes([]);
        }
      }}
      {...(!multiple && { selectionLimit: 1 })}
      selectAll={selectAll}
      optionLabel="nmAvaliacao"
      optionValue="idAvaliacao"
      placeholder={
        multiple ? "Selecione as Avaliações" : "Selecione uma Avaliação"
      }
      filter
      showClear={true}
      className={error ? "p-multiselect-error" : ""}
      maxSelectedLabels={3}
      selectedItemsLabel={`${
        avaliacaoSelecionados ? avaliacaoSelecionados.length : 0
      } Avaliação(ões) selecionada(s)`}
      emptyFilterMessage="Não encontrado"
      filterPlaceholder={
        multiple ? "Busque por avaliações" : "Busque por uma avaliação"
      }
      resetFilterOnHide={true}
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: onLazyLoad,
        itemSize: 43,
        showLoader: true,
        loading: lazyLoading,
        delay: 1,
        loadingTemplate: (options) => {
          return (
            <div
              className="flex align-items-center p-2"
              style={{ height: "43px" }}
            >
              <Skeleton width={options.even ? "70%" : "60%"} height="1.5rem" />
            </div>
          );
        },
      }}
    />
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaAvaliacao);
