import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";
import * as GrupoAction from "../../store/ducks/grupo/actions";

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";
import Loading from "../../Components/LoadingComponent";
import ModalCadastroGrupo from "../../Components/ModalCadastroGrupo";
import GrupoTableComponent from "../../Components/TableGrupo";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
  pagina: 0,
  tamanho: 10,
  ordem: "ASC",
  coluna: "nmGrupo",
  txtBusca: "",
};

function Grupo({
  grupo,
  getGrupoTable,
  getGrupoData,
  onSaveGrupo,
  onUpdateGrupo,
  onInativarGrupo,
  clearGrupoData,
}: any) {
  const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
  const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
  const [body, setBody] = useState(_body);
  const [table, setTable] = useState(null);
  const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
  const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
  const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

  useEffect(() => {
    if (grupo.error) {
      alert(
        "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
          grupo.errorMessage
      );
    }
  }, [grupo.error]);

  useEffect(() => {
    if (!modalCadastroOpen) {
      getGrupoTableData();
    }
  }, [body, modalCadastroOpen]);

  useEffect(() => {
    if (grupo.data.table != null) {
      setTable(grupo.data.table);
    }
  }, [grupo.data]);

  useEffect(() => {
    if (!grupo.loadingInativacao) {
      getGrupoTable(body);
    }
  }, [grupo.loadingInativacao]);

  useEffect(() => {
    if (!grupo.loadingPersis) {
      handleCloseAndReload();
    }
  }, [grupo.loadingPersis]);

  const getGrupoTableData = () => {
    getGrupoTable(body);
  };

  const handleCadastro = () => {
    clearGrupoData();
    setModalCadastroOpen(true);
  };

  const handleEdicao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione um Grupo que gostaria de alterar");
    } else if (idsSelecionado.length > 1) {
      alert("Selecione apenas um Grupo para alteração!");
    } else {
      getGrupoData(idsSelecionado[0]);
      setModalCadastroOpen(true);
    }
  };

  const changeTable = (__body: any) => {
    setBody({ ...__body });
  };

  const handlePersistGrupo = (edicao: boolean, grupo: any) => {
    if (edicao) {
      onUpdateGrupo(grupo);
    } else {
      onSaveGrupo(grupo);
    }
  };

  const handleCloseAndReload = () => {
    clearGrupoData();
    setModalCadastroOpen(false);
    setIdSelecionado([]);
  };

  const handleInativacao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione os Grupos que gostaria de inativar");
    } else {
      setAlertConfirmacaoOpen(true);
      setAlertConfirmacaoTexto(EntidadesAcoes.GRUPO_EXCLUSAO);
      setAlertConfirmacaoAcao(() => () => {
        onInativarGrupo(idsSelecionado);
        setAlertConfirmacaoOpen(false);
      })
      
    }
  };
  function unmountAlertConfirmacao() {
    setAlertConfirmacaoTexto('');
    setAlertConfirmacaoAcao(() => () => {})
    setAlertConfirmacaoOpen(false);
  }

  return (
    <div className="pageBox">
      <Loading loading={grupo.loading || grupo.loadingPersis} />
      <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
      <ModalCadastroGrupo
        modalCadastroOpen={modalCadastroOpen}
        closeModal={() => handleCloseAndReload()}
        gruposData={grupo.data.grupo}
        persistGrupo={handlePersistGrupo}
        getGrupoData={(idGrupo: any) => getGrupoData(idGrupo)}
      />
      <div className="menuLateral">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            width: "100%",
          }}
        >
          Ações
          <AcaoMenu
            onCadastro={handleCadastro}
            onEditar={handleEdicao}
            onInativar={handleInativacao}
          />
        </Typography>
      </div>
      <div className="table">
        <GrupoTableComponent
          loading={grupo.loadingTable}
          data={table}
          body={body}
          onChange={changeTable}
          selection={idsSelecionado}
          idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  grupo: state.grupo,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...GrupoAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Grupo);
