import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import GenericTable from "../GenericTable/GenericTable";
import { MunicipioObjTable, MunicipioTable } from "../../store/ducks/municipios/types";

const grupo: MunicipioObjTable[] = [];
const col: GridColDef[] = [];

const MunicipioTableComponent = (props: any) => {
  const [txtBusca, setTxtBusca] = useState("");
  const [columns, setColumns] = useState(col);
  const [listMunicipios, setListMunicipios] = useState(grupo);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [numberElements, setNumberElements] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    generateColumnsTable();
  }, []);

  useEffect(() => {
    if (props.data) {
      tranformObjectTable(props.data);
    }
  }, [props.data]);

  const generateColumnsTable = () => {
    const _columns: GridColDef[] = [
      { field: "nmMunicipio", headerName: "Município", width: 300 },
      { field: "nmEstado", headerName: "Estado", width: 100 },
      { field: "nmGrupo", headerName: "Grupo", width: 200 },
      { field: "nmCluster", headerName: "Cluster", width: 200 },
      { field: "nmSubCluster", headerName: "SubCLuster", width: 200 },
    ];

    setColumns(_columns);
  };

  const tranformObjectTable = (obj: MunicipioTable) => {
    setSize(obj.size);
    setPage(obj.number);
    setTotalElements(obj.totalElements);
    setTotalPages(obj.totalPages);
    setNumberElements(obj.numberOfElements);
    setOffset(obj.pageable.offset);

    let list: MunicipioObjTable[] = obj.content.map((content: any) => {
      const item = content;
      return {
        id: item[0],
        idMunicipio: item[0],
        nmMunicipio: item[1],
        nmEstado: item[2],
        nmGrupo: item[3],
        nmCluster: item[4],
        nmSubCluster: item[5],
      };
    });

    setListMunicipios(list);
  };

  const onPageChange = (pageIdx: any) => {
    props.body.pagina = pageIdx - 1;
    setPage(pageIdx - 1);
    props.onChange(props.body);
  };

  const onSizeChange = (_size: any) => {
    props.body.tamanho = _size;
    setSize(_size);
    props.onChange(props.body);
  };

  const onChangeBusca = (txt: string) => {
    props.body.txtBusca = txt;
    setTxtBusca(txt);
    if (txt.length >= 3) {
      props.onChange(props.body);
    } else {
      props.body.txtBusca = "";
      props.onChange(props.body);
    }
  };

  const onSortChange = (sort: any) => {
    if (sort != undefined) {
      let colunaSort =
        sort.field && sort.field == "nmMunicipio" ? "nmMunicipio" 
          : sort.field == "nmEstado" ? "estado.nmEstado" 
          : sort.field == "nmGrupo" ? "grupo.nmGrupo"
          : sort.field == "nmCluster" ? "cluster.nmCluster"
          : sort.field == "nmSubCluster" ? "subcluster.nmSubCluster" : "nmMunicipio";

      props.body.coluna = colunaSort;
      props.body.ordem = sort.sort.toUpperCase();
    } else {
      props.body.coluna = "nmMunicipio";
      props.body.ordem = "ASC";
    }
    props.onChange(props.body);
  };

  return (
    <GenericTable
      loading={props.loading}
      titleTable="Municípios Cadastrados"
      rows={listMunicipios}
      columns={columns}
      page={page}
      totalPages={totalPages}
      onPageChange={onPageChange}
      offset={offset}
      numberElements={numberElements}
      totalElements={totalElements}
      size={size}
      sizeChange={onSizeChange}
      txtBusca={txtBusca}
      onChangePesquisa={onChangeBusca}
      selection={props.selection}
      idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
      sortChange={onSortChange}
    />
  );
};

export default MunicipioTableComponent;
