import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";

import * as TrilhaActions from '../../store/ducks/trilha/actions'
import TrilhaTableComponent from "../../Components/TableTrilha";
import ModalCadastroTrilha from "../../Components/ModalCadastroTrilha";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmTrilha",
    txtBusca: ""
};

function Trilha({
    trilha,
    utils,
    getTrilhaTable,
    onInativarTrilha,
    getTrilhaData,
    onSaveTrilha,
    onUpdateTrilha,
    onNotificacaoTrilha,
    clearTrilhaData
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(trilha.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + trilha.errorMessage);
        }
    }, [trilha.error]);

    useEffect(() => {
        if(!trilha.loadingPersis) {
            handleCloseAndReload()
        }
    }, [trilha.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getTrilhaTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(trilha.data.table != null){
            setTable(trilha.data.table);
        }

    }, [trilha.data]);

    useEffect(() => {
        if(!trilha.loadingInativacao){
            getTrilhaTable(body);
        }
    }, [trilha.loadingInativacao]);

    const getTrilhaTableData = () => {
        getTrilhaTable(body);
    }

    const handleCadastro = () => {
        clearTrilhaData();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione uma trilha que gostaria de alterar');

        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas uma trilha para alteração!');

        } else {
            getTrilhaData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione as trilhas que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.TRILHA_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarTrilha(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersistTrilha = (edicao: boolean, trilha: FormData) => {
        if(edicao) {
            onUpdateTrilha(trilha);
        } else {
            onSaveTrilha(trilha);
        }
    }

    const handleCloseAndReload = () => {
        clearTrilhaData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={trilha.loading || trilha.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroTrilha 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()}
                trilhaData={trilha.data.trilha} 
                persist={handlePersistTrilha}
                enviaNotificacao={(idTrilha: any) => onNotificacaoTrilha(idTrilha)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <TrilhaTableComponent
                    loading={trilha.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};

const mapStateToProps = (state: ApplicationState) => ({
    trilha: state.trilha,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...TrilhaActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Trilha);
