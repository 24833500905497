import React, { useState } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

export default function ModalResetDeSenha(props: any) {
  const [file, setFile] = useState('');

  const handleEnvioReset = () => {
    let form = new FormData();
    form.append('file', file);

    props.handleEnvio(form);
  }

  return (
    <Modal
      open={props.modalResetSenhaMassaOpen}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div >
        <Box sx={style}>
          <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reset de Senha
            </Typography>
            <Button variant="outlined" color="error" onClick={props.closeModal}>
              Fechar
            </Button>
          </div>
          <Divider />
          <div className="modalMid">
            <div>
              <Typography id="modal-modal-description" sx={{ mt: 2, marginBottom: '5px' }}>
                Modelo de reset:
              </Typography>
              <Link href="https://files-conectado.connectapps.com.br/modelo_reset_de_senha_usuario_conectado.xlsx" download='modelo_reset_senha_massa_conectado.xlsx' underline="none">
                <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
              </Link>
            </div>
            <div>
              <Typography id="modal-modal-description" sx={{ mt: 2, marginBottom: '5px' }}>
                Arquivo:
              </Typography>
              <Input type='file' onChange={(e: any) => {
                setFile(e.target.files[0])
              }} />
            </div>
          </div>
          <Divider />
          <div className="modalFooter">
            <Button variant="contained" onClick={handleEnvioReset}>Importar</Button>
          </div>
        </ Box>
      </div>
    </Modal>
  )
} 