import { Reducer } from "@reduxjs/toolkit";
import { T1State, T1Types } from "./types";

const INITIAL_STATE: T1State = {
    data: {
        table: null,
        t1: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<T1State> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case T1Types.T1_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case T1Types.T1_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case T1Types.T1_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case T1Types.T1_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case T1Types.T1_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case T1Types.T1_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case T1Types.T1_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case T1Types.T1_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case T1Types.T1_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case T1Types.T1_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case T1Types.T1_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    t1: action.payload.data
                }
            };
        case T1Types.T1_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    t1: null
                }
            };


        case T1Types.CLEAR_T1_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    t1: null
                }
            };

        case T1Types.T1_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case T1Types.T1_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Treinamento de 1 Ponto realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    t1: action.payload.data
                }
            };
        case T1Types.T1_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case T1Types.T1_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case T1Types.T1_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Treinamento de 1 Ponto realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    t1: action.payload.data
                }
            };
        case T1Types.T1_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case T1Types.T1_PUSH_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case T1Types.T1_PUSH_REQUEST_SUCCESS:
            alert('As notificações entraram na fila para envio! Pode levar alguns minutos para que os usuários recebam.');
            return {
                ...state,
                error: false,
                loading: false,
            };
        case T1Types.T1_PUSH_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
            };

        default :
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
};

export default reducer;