import { action } from "typesafe-actions";
import { ChatBotSaudacaoTypes } from "./types";

export const getChatBotSaudacaoTable = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_TABLE_REQ, data);
export const getChatBotSaudacaoTableSuccess = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_TABLE_REQ_SUCCESS, { data });
export const getChatBotSaudacaoTableFailure = (err: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_TABLE_REQ_FAILURE, { err });

export const getChatBotSaudacao = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_REQ, data);
export const getChatBotSaudacaoSuccess = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_REQ_SUCCESS, { data });
export const getChatBotSaudacaoFailure = (err: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_REQ_FAILURE, { err });

export const postChatBotSaudacaoSave = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_SAVE_REQ, data);
export const postChatBotSaudacaoSaveSuccess = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_SAVE_REQ_SUCCESS, { data });
export const postChatBotSaudacaoSaveFailure = (err: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_SAVE_REQ_FAILURE, { err });

export const putChatBotSaudacaoUpdate = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_UPDATE_REQ, data);
export const putChatBotSaudacaoUpdateSuccess = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_UPDATE_REQ_SUCCESS, { data });
export const putChatBotSaudacaoUpdateFailure = (err: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_UPDATE_REQ_FAILURE, { err });

export const deleteChatBotSaudacao = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_DELETE_REQ, data);
export const deleteChatBotSaudacaoSuccess = (data: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_DELETE_REQ_SUCCESS, { data });
export const deleteChatBotSaudacaoFailure = (err: any) => action(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_DELETE_REQ_FAILURE, { err });

export const clearChatBotSaudacao = () => action(ChatBotSaudacaoTypes.CLEAR_CHATBOT_SAUDACAO);