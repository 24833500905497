import React, { useEffect, useState } from "react";
import { GridColDef } from '@mui/x-data-grid';
import GenericTable from "../GenericTable/GenericTable";
import { SegurancaNoTrabalhoObjTable, SegurancaNoTrabalhoTable } from "../../store/ducks/segurancaNoTrabalho/types";

const con: SegurancaNoTrabalhoObjTable[] = [];
const col: GridColDef[] = [];

const SegurancaNoTrabalhoTableComponent = (props: any) => {

    const [txtBusca, setTxtBusca] = useState('');
    const [columns, setColumns] = useState(col);
    const [listSegurancaNoTrabalho, setListSegurancaNoTrabalho] = useState(con);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [numberElements, setNumberElements] = useState(0);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        generateColumnsTable();
    }, []);

    useEffect(() => {
        if(props.data){
            tranformObjectTable(props.data)
        }
    }, [props.data]);

    const generateColumnsTable = () => {
        const _columns: GridColDef[] = [
            { field: 'nmSegurancaNoTrabalho', headerName: 'Nome', width: 300 },
            { field: 'dsSegurancaNoTrabalho', headerName: 'Descrição', width: 300 },
            { field: 'dsTempoEstimado', headerName: 'Duração', width: 70 },
            { field: 'nmTema', headerName: 'Tema', width: 120 },
            { field: 'qtdConteudos', headerName: 'Conteúdos', width: 90 },
        ];

        setColumns(_columns);
    };

    const tranformObjectTable = (obj: SegurancaNoTrabalhoTable) => {
        setSize(obj.size);
        setPage(obj.number);
        setTotalElements(obj.totalElements);
        setTotalPages(obj.totalPages);
        setNumberElements(obj.numberOfElements);
        setOffset(obj.pageable.offset)

        let list: SegurancaNoTrabalhoObjTable[] = obj.content.map((item: any) => {
            return {
                id: item[0],
                idSegurancaNoTrabalho: item[0],
                nmSegurancaNoTrabalho: item[1],
                dsSegurancaNoTrabalho: item[2],
                dsTempoEstimado: item[3],
                nmTema: item[4],
                qtdConteudos: item[5],
            }
        });

        setListSegurancaNoTrabalho(list);
    };
    
    const onPageChange = (pageIdx: any) => {
        props.body.pagina = pageIdx - 1;
        setPage(pageIdx - 1)
        props.onChange(props.body);
    }

    const onSizeChange = (_size: any) => {
        props.body.tamanho = _size;
        setSize(_size);
        props.onChange(props.body);
    }

    const onChangeBusca = (txt: string) => {
        props.body.txtBusca = txt;
        setTxtBusca(txt);
        if(txt.length >= 3){
            props.onChange(props.body);
        } else {
            props.body.txtBusca = '';
            props.onChange(props.body);
        }
    }

    const onSortChange = (sort: any) => {
        if(sort != undefined) {
            let colunaSort = sort.field && 
            sort.field == "nmSegurancaNoTrabalho" ? 'nmSegurancaNoTrabalho' 
            : sort.field == "dsSegurancaNoTrabalho" ? 'dsSegurancaNoTrabalho'
            : sort.field == "dsTempoEstimado" ? 'dsTempoEstimado'
            : sort.field == "nmTema" ? 'ct.nmTema' 
            : sort.field == "qtdConteudos" ? 'qtdConteudos' : 'nmSegurancaNoTrabalho' ;

            props.body.coluna = colunaSort;
            props.body.ordem = sort.sort.toUpperCase();
        } else {
            props.body.coluna = 'nmSegurancaNoTrabalho';
            props.body.ordem = 'ASC';
        }
        props.onChange(props.body);

    }

    return (
        <GenericTable 
            loading={props.loading}
            titleTable='Segurança do Trabalho Cadastradas'
            rows={listSegurancaNoTrabalho} 
            columns={columns} 
            page={page} 
            totalPages={totalPages} 
            onPageChange={onPageChange}
            offset={offset}
            numberElements={numberElements} 
            totalElements={totalElements}
            size={size}
            sizeChange={onSizeChange}
            txtBusca={txtBusca}
            onChangePesquisa={onChangeBusca}
            selection={props.selection}
            idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
            sortChange={onSortChange}
        />
    )
};

export default SegurancaNoTrabalhoTableComponent;