import { api } from "../../../services";
import { call, put } from 'redux-saga/effects';

import {
    loginRequestSuccess,
    loginRequestFailure,
    doLogoutAppFailure, 
    doLogoutAppSuccess,
    refreshTokenFailure,
    refreshTokenSuccess
} from './actions';


function* loginRequest(action: any):any{
    const doLogin = () => {
        return api.post('auth/login', action.payload )
        .then(res => res.data)
    }

    try {
        yield put(loginRequestSuccess(yield call(doLogin)));
    } catch (err: any) {
        yield put(loginRequestFailure(err));
    }
}

function* doLogoutApp(action: any): any {
    const token = action.payload;

    const doLogout = () => {
        return api.post('auth/logout', {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then( res => res.data)
    }

    try {
        yield put(doLogoutAppSuccess(yield call(doLogout)));
    } catch (error: any) {
        yield put(doLogoutAppFailure(error.message));
    }
}

function* refreshToken(): any {

    const doRefreshToken = () => {
        const chave = localStorage.getItem('chaveToken');
        const chaveToken = {
            refreshToken: chave
        };
    
        return api.post('auth/refreshtoken', chaveToken, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then( res => res.data);
    }

    try {
        yield put(refreshTokenSuccess(yield call(doRefreshToken)));
    } catch (error: any) {
        yield put(refreshTokenFailure(error.message));
    }
}

export {
    loginRequest,
    doLogoutApp,
    refreshToken
}