import { action } from "typesafe-actions";
import { CursoTypes } from "./types";

export const getCursoTable = (data: any) => action(CursoTypes.CURSO_TABLE_REQUEST, data);
export const getCursoTableSuccess = (data: any) => action(CursoTypes.CURSO_TABLE_REQUEST_SUCCESS, { data });
export const getCursoTableFailure = (err: any) => action(CursoTypes.CURSO_TABLE_REQUEST_FAILURE, { err });

export const onInativarCurso = (data: any) => action(CursoTypes.CURSO_INATIVA_REQUEST, data);
export const onInativarCursoSuccess = (data: any) => action(CursoTypes.CURSO_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarCursoFailure = (err: any) => action(CursoTypes.CURSO_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarCurso = (data: any) => action(CursoTypes.CURSO_ATIVA_REQUEST, data);
export const onAtivarCursoSuccess = (data: any) => action(CursoTypes.CURSO_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarCursoFailure = (err: any) => action(CursoTypes.CURSO_ATIVA_REQUEST_FAILURE, { err });

export const getCursoData = (data: any) => action(CursoTypes.CURSO_DATA_REQUEST, data);
export const getCursoDataSuccess = (data: any) => action(CursoTypes.CURSO_DATA_REQUEST_SUCCESS, { data });
export const getCursoDataFailure = (err: any) => action(CursoTypes.CURSO_DATA_REQUEST_FAILURE, { err });

export const onSaveCurso = (data: any) => action(CursoTypes.CURSO_SAVE_REQUEST, data);
export const onSaveCursoSuccess = (data: any) => action(CursoTypes.CURSO_SAVE_REQUEST_SUCCESS, { data });
export const onSaveCursoFailure = (err: any) => action(CursoTypes.CURSO_SAVE_REQUEST_FAILURE, { err });

export const onUpdateCurso = (data: any) => action(CursoTypes.CURSO_UPDATE_REQUEST, data);
export const onUpdateCursoSuccess = (data: any) => action(CursoTypes.CURSO_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateCursoFailure = (err: any) => action(CursoTypes.CURSO_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoCurso = (data: any) => action(CursoTypes.CURSO_PUSH_REQUEST, data);
export const onNotificacaoCursoSuccess = (data: any) => action(CursoTypes.CURSO_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoCursoFailure = (err: any) => action(CursoTypes.CURSO_PUSH_REQUEST_FAILURE, { err });

export const clearCursoData = () => action(CursoTypes.CLEAR_CURSO_DATA);