import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import {
  getAllClusterListFailure,
  getAllClusterListSuccess,
  getAllGrupoListFailure,
  getAllGrupoListSuccess,
  getAllMunicipioListFailure,
  getAllMunicipioListSuccess,
  getAllSubclusterListFailure,
  getAllSubclusterListSuccess,
  getAvaliacaoListFailure,
  getAvaliacaoListSuccess,
  getAvaliacoesByPerfilAndEstadoFailure,
  getAvaliacoesByPerfilAndEstadoSuccess,
  getClusterListFailure,
  getClusterListSuccess,
  getConteudoListFailure,
  getConteudoListSuccess,
  getCursoListFailure,
  getCursoListSuccess,
  getSegurancaNoTrabalhoListFailure,
  getSegurancaNoTrabalhoListSuccess,
  getEstadosListFailure,
  getEstadosListSuccess,
  getGestoresListFailure,
  getGestoresListSuccess,
  getMenuInfoFailure,
  getMenuInfoSuccess,
  getMunicipioListFailure,
  getMunicipioListSuccess,
  getPerfilListFailure,
  getPerfilListSuccess,
  getRegionalFailure,
  getRegionalSuccess,
  getSubclusterListFailure,
  getSubclusterListSuccess,
  getTemaListFailure,
  getTemaListSuccess,
  getUnidadeNegocioListFailure,
  getUnidadeNegocioListSuccess,
  getUsuariosListFailure,
  getUsuariosListSuccess,
  setLoadingFalse,
  setLoadingTrue,
  getGrupoClusterSubclusterFailure,
  getGrupoClusterSubclusterSuccess,
  getTrilhaListFailure,
  getTrilhaListSuccess
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getAllMunicipioList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("util/municipios-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getAllMunicipioListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getAllMunicipioListFailure(error.message));
  }
}

function* getAllClusterList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("cluster", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getAllClusterListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getAllClusterListFailure(error.message));
  }
}

function* getAllGrupoList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`grupo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getAllGrupoListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getAllGrupoListFailure(error.message));
  }
}

function* getClusterList(data: any): any {
  const idGrupo = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`cluster/grupo/${idGrupo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getClusterListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getClusterListFailure(error.message));
  }
}

function* getAllSubclusterList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("subcluster", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getAllSubclusterListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getAllSubclusterListFailure(error.message));
  }
}

function* getSubclusterList(data: any): any {
  const idCluster = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`subcluster/cluster/${idCluster}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getSubclusterListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getSubclusterListFailure(error.message));
  }
}

function* getEstadosList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("util/estados", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getEstadosListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getEstadosListFailure(error.message));
  }
}

function* getGestoresList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .post(
        "usuario/listAllGestor",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data);
  };

  try {
    yield put(getGestoresListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getGestoresListFailure(error.message));
  }
}

function* getMunicipioList(data: any): any {
  const idEstado = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`util/municipios?idEstado=${idEstado}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getMunicipioListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getMunicipioListFailure(error.message));
  }
}

function* getUsuariosList(data: any): any {
  const idMunicipio = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`usuario/findByMunicipio?idMunicipio=${idMunicipio}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getUsuariosListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getUsuariosListFailure(error.message));
  }
}

function* getRegional(data: any): any {
  const idMunicipio = data.payload;
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get(`util/regional?idMunicipio=${idMunicipio}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getRegionalSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getRegionalFailure(error.message));
  }
}

function* getPerfilList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("perfil/listPerfilCombo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getPerfilListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getPerfilListFailure(error.message));
  }
}

function* getTemaList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("tema/listTemaCombo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getTemaListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getTemaListFailure(error.message));
  }
}

function* getUnidadeNegocioList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("util/unidadeNegocio-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getUnidadeNegocioListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getUnidadeNegocioListFailure(error.message));
  }
}

function* getMenuInfo(data: any): any {
  const token = localStorage.getItem("accessToken");
  const idUsuario = data.payload;

  const doRequest = () => {
    return api
      .get(`util/menu?idUsuario=${idUsuario}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data
      });
  };

  try {
    yield put(getMenuInfoSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getMenuInfoFailure(error.message));
  }
}

function* getConteudoList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("conteudo/listConteudoToCombo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getConteudoListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getConteudoListFailure(error.message));
  }
}

function* getAvaliacaoList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("avaliacao/listAllAvaliacaoCombo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getAvaliacaoListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getAvaliacaoListFailure(error.message));
  }
}

function* getCursoList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("curso/listAllCursoToCombo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getCursoListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getCursoListFailure(error.message));
  }
}

function* getSegurancaNoTrabalhoList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("curso/listAllSegurancaNoTrabalhoToCombo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getSegurancaNoTrabalhoListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getSegurancaNoTrabalhoListFailure(error.message));
  }
}

function* getAvaliacoesByPerfilAndEstado(data: any): any {
  const token = localStorage.getItem("accessToken");
  const body = data.payload;

  const doRequest = () => {
    return api
      .post("avaliacao/listAllAvaliacaoPerfisEstados", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getAvaliacoesByPerfilAndEstadoSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getAvaliacoesByPerfilAndEstadoFailure(error.message));
  }
}

function* setLoading(data: any): any {
  const { loading, err } = data.payload;
  if (loading) {
    yield put(setLoadingTrue());
  } else {
    yield put(setLoadingFalse(err));
  }
}


/*  */

function* getGrupoClusterSubcluster(data: any): any {
  const idMunicipio = data.payload;
  const token = localStorage.getItem('accessToken');

  const doRequest = () => {
      return api.get(`municipio/findById?idMunicipio=${idMunicipio}`, {
          headers: {
              'Authorization': `Bearer ${token}`
          },
          
      }).then( res => {
          return res.data
          }
      )
  }
  try {
      yield put(getGrupoClusterSubclusterSuccess(yield call(doRequest)));
  } catch (error: any) {
      yield put(getGrupoClusterSubclusterFailure(error.message));
  }
}

function* getTrilhaList(): any {
  const token = localStorage.getItem("accessToken");

  const doRequest = () => {
    return api
      .get("trilha/listAllTrilhaToCombo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  try {
    yield put(getTrilhaListSuccess(yield call(doRequest)));
  } catch (error: any) {
    yield put(getTrilhaListFailure(error.message));
  }
}

export {
  getAllMunicipioList,
  getAllSubclusterList,
  getSubclusterList,
  getAllClusterList,
  getClusterList,
  getEstadosList,
  getMunicipioList,
  getRegional,
  getPerfilList,
  getUnidadeNegocioList,
  getAvaliacoesByPerfilAndEstado,
  getMenuInfo,
  getTemaList,
  getConteudoList,
  getAvaliacaoList,
  getCursoList,
  getSegurancaNoTrabalhoList,
  setLoading,
  getGestoresList,
  getAllGrupoList,
  getUsuariosList,
  getGrupoClusterSubcluster,
  getTrilhaList
};
