import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getT1DataFailure,
    getT1DataSuccess,
    getT1TableFailure,
    getT1TableSuccess,
    onAtivarT1Failure,
    onAtivarT1Success,
    onInativarT1Failure,
    onInativarT1Success,
    onSaveT1Failure, 
    onSaveT1Success,
    onUpdateT1Failure,
    onUpdateT1Success,
    onNotificacaoT1Failure,
    onNotificacaoT1Success,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getT1Table(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoT1/listAllCursoT1Table', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getT1TableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getT1TableFailure(error.message));
    }
};


function* onInativarT1(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoT1/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarT1Success(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarT1Failure(error.message));
    }
};

function* onAtivarT1(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoT1/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarT1Success(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarT1Failure(error.message));
    }
};

function* getT1Data(data: any): any {
    const idT1 = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`cursoT1/findById?idCursoT1=${idT1}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getT1DataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getT1DataFailure(error.message));
    }
};

function* onSaveT1(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('cursoT1/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveT1Success(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveT1Failure(error.message));
    }
};

function* onUpdateT1(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('cursoT1/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateT1Success(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateT1Failure(error.message));
    }
};

function* onNotificacaoT1(data: any): any {
    const idT1 = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`cursoT1/notificaT1?idCursoT1=${idT1}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoT1Success(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoT1Failure(error.message));
    }
};

export {
    getT1Data,
    getT1Table,
    onAtivarT1,
    onInativarT1,
    onSaveT1,
    onUpdateT1,
    onNotificacaoT1,
}
