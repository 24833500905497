import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

import * as UtilsActions from "../../store/ducks/utils/actions";
import { ApplicationState } from "../../store";

function ListaPerfil({
  multiple = true,
  utils,
  getPerfilList,
  perfis,
  onChangePerfis,
  justificativaCombo = false,
  error,
}: any) {
  const [listPerfis, setListPerfis] = React.useState<any>([]);
  const [perfisSelecionados, setPerfisSelecionados] = React.useState<any>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [lazyItems, setLazyItems] = React.useState<any>([]);
  const [lazyLoading, setLazyLoading] = React.useState(false);

  const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(
    null
  );

  React.useEffect(() => {
    getPerfilList();
  }, []);


  React.useEffect(() => {
    if (utils.data.perfil) {
      if(justificativaCombo) {
        let _perfis = utils.data.perfil.filter( (perfil: any) => (perfil.idPerfil != 1 && perfil.idPerfil != 30));
        setListPerfis(_perfis);
      } else {
        setListPerfis(utils.data.perfil);
      }
      if (multiple && utils.data.perfil.length > 0) {
        setSelectAll(perfis.length == utils.data.perfil.length);
      }
    }
  }, [utils.data.perfil]);

  React.useEffect(() => {
    if (perfis) {
      setPerfisSelecionados(perfis.map((item: any) => item.idPerfil));
    }
  }, [perfis]);

  const onLazyLoad = (event: any) => {
    setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, 300);
  };

  return (
    <MultiSelect
      style={{
        width: "100%",
        height: "100%",
      }}
      value={perfisSelecionados}
      options={listPerfis}
      {...(!multiple && { selectionLimit: 1 })}
      display="chip"
      onChange={(e) => {
        if (e.value) {
          setPerfisSelecionados(e.value);
          setSelectAll(e.value.length === listPerfis.length);
          onChangePerfis(
            listPerfis.filter((muni: any) => e.value.includes(muni.idPerfil))
          );
        } else {
          setPerfisSelecionados([]);
          setSelectAll(false);
          onChangePerfis([]);
        }
      }}
      selectAll={selectAll}
      optionLabel="nmPerfil"
      optionValue="idPerfil"
      placeholder={multiple ? "Selecione os Perfis" : "Selecione o Perfil"}
      filter
      showClear={true}
      className={error ? "p-multiselect-error" : ""}
      maxSelectedLabels={3}
      selectedItemsLabel={`${
        perfisSelecionados ? perfisSelecionados.length : 0
      } perfil(s) selecionado(s)`}
      emptyFilterMessage="Não encontrado"
      filterPlaceholder="Busque por um perfil"
      resetFilterOnHide={true}
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: onLazyLoad,
        itemSize: 43,
        showLoader: true,
        loading: lazyLoading,
        delay: 1,
        loadingTemplate: (options) => {
          return (
            <div
              className="flex align-items-center p-2"
              style={{ height: "43px" }}
            >
              <Skeleton width={options.even ? "70%" : "60%"} height="1.5rem" />
            </div>
          );
        },
      }}
    />
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaPerfil);
