import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertaConfirmaAcao({openDialog, texto, execAcao, cancelaAcao}: any) {
  return (
    <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Atenção, deseja confirmar esta ação?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {texto}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button color='error' onClick={() => cancelaAcao()}>Cancelar</Button>
            <Button onClick={() => execAcao()} autoFocus>
                Confirmar
            </Button>
        </DialogActions>
    </Dialog>
  );
}