import React from "react";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as ChatBotActions from '../../../store/ducks/chatbot/action';
import * as CategoriaActions from '../../../store/ducks/chatbot/categoria/actions';
import * as NivelActions from '../../../store/ducks/chatbot/nivel/actions';

import AlertaConfirmaAcao from "../../../Components/AlertaConfirmaAcao";
import Loading from "../../../Components/LoadingComponent";
import ModalCadastroCategoria from "../../../Components/ModalCadastroChatbotCategoria";
import ModalCadastroNivel from "../../../Components/ModalCadastroChatbotNivel";
import ChatbotCategoriaTableTreeComponent from "../../../Components/TableTreeChatbotCategoria";
import { EntidadesAcoes } from "../../../enums/EntidadesAcoesConfirmacao";
import { ApplicationState } from "../../../store";
import { ChatBotCategoriaTable } from "../../../store/ducks/chatbot/categoria/types";
import { ReqParamsChaveEmUso } from "../../../store/ducks/chatbot/nivel/types";

function ChatBotCategoria({
    chatbot,
    categoria,
    nivel,
    getChatBotCategoriaTable,
    getChatBotCategoria,
    getChatBotCategoriaDelete,
    getChatBotCategoriaSave,
    getChatBotCategoriaUpdate,
    clearChatBotCategoria,
    getChatBotCategoriaKeyEmUso,
    deleteChatBotLinkAuxiliar,
    getChatBotNivel,
    getChatBotNivelSave,
    getChatBotNivelUpdate,
    getChatBotNivelDelete,
    getChatBotNivelQtdChaves,
    clearChatBotNivel,
    getChatBotNivelKeyEmUso
}: any) {

    const [modalCadastroCategoriaOpen, setModalCadastroCategoriaOpen] = useState(false);
    const [modalCadastroNivelOpen, setModalCadastroNivelOpen] = useState(false);
    const [body, setBody] = useState({});
    const [table, setTable] = useState<ChatBotCategoriaTable>();
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => { });
    const [isSubNivel, setIsSubNivel] = useState(false);
    const [categoriaPai, setCategoriaPai] = useState<any>(null);
    const [nivelPai, setNivelPai] = useState<any>(null);

    useEffect(() => {
        if (categoria.error || chatbot.error || nivel.error) {
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + (categoria.errorMessage ?? chatbot.errorMessage ?? nivel.errorMessage));
        }
    }, [categoria.error, chatbot.error, nivel.error]);

    useEffect(() => {
        if (!categoria.loadingPersis || !nivel.loadingPersis) {
            handleCloseAndReload();
        }
    }, [categoria.loadingPersis, nivel.loadingPersis]);

    useEffect(() => {
        if (!modalCadastroCategoriaOpen) {
            getChatBotCategoriaTableData();
        }
    }, [body, modalCadastroCategoriaOpen]);

    useEffect(() => {
        if (categoria.data.table != null) {
            setTable(categoria.data.table);
        }
    }, [categoria.data]);

    useEffect(() => {
        if (!categoria.loadingInativacao) {
            getChatBotCategoriaTable(body);
        }
    }, [categoria.loadingInativacao]);

    const getChatBotCategoriaTableData = () => {
        getChatBotCategoriaTable(body);
    }

    const handleCadastroCategoria = () => {
        clearChatBotCategoria();
        setModalCadastroCategoriaOpen(true)
    }

    const handleCadastroNivel = (entidade: EntidadesAcoes, id: number, titulo: string) => {
        clearChatBotNivel();
        let obj = {};

        if(entidade == EntidadesAcoes.NIVEL){
            setNivelPai(null);
            setIsSubNivel(false);
            obj = {
                idCategoria: id
            };
            getChatBotNivelQtdChaves(obj);
            setCategoriaPai({
                titulo,
                idCategoria: id
            })
        } else if (entidade == EntidadesAcoes.SUBNIVEL) {
            setCategoriaPai(null);
            setIsSubNivel(true);
            obj = {
                idNivelPai: id
            };
            getChatBotNivelQtdChaves(obj);
            setNivelPai({
                titulo,
                idNivel: id
            })
        }
        setModalCadastroNivelOpen(true);
    }

    const handleEdicao = (entidade: EntidadesAcoes, id: number) => {
        if(entidade && id) {
            if(entidade == EntidadesAcoes.CATEGORIA){
                getChatBotCategoria(id);
                setModalCadastroCategoriaOpen(true);
            } else if (entidade == EntidadesAcoes.NIVEL) {
                getChatBotNivel(id);
                setIsSubNivel(false);
                setModalCadastroNivelOpen(true);
            } else {
                getChatBotNivel(id);
                setIsSubNivel(true);
                setModalCadastroNivelOpen(true);
            }
        }

    }

    const handleInativacao = (entidade: EntidadesAcoes, id: number, titulo: string) => {
        if (entidade && id && titulo) {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(`${entidade} ${titulo}!`);
            setAlertConfirmacaoAcao(() => () => {
                if(entidade == EntidadesAcoes.CATEGORIA) {
                    getChatBotCategoriaDelete([id]);
                } else {
                    getChatBotNivelDelete([id]);    
                }
                setAlertConfirmacaoOpen(false);
                handleCloseAndReload();
            });
        }
    }

    const changeTable = (__body: any) => {
        setBody({ ...__body });
    }

    const handlePersitChatBotCategoria = (edicao: boolean, categoria: any) => {
        if (edicao) {
            getChatBotCategoriaUpdate(categoria);
        } else {
            getChatBotCategoriaSave(categoria);
        }
    }

    const handlePersitChatBotNivel = (edicao: boolean, nivel: any) => {
        if(edicao) {
            getChatBotNivelUpdate(nivel);
        } else {
            getChatBotNivelSave(nivel);
        }

    }

    const handleCloseAndReload = () => {
        clearChatBotCategoria();
        clearChatBotNivel();
        setModalCadastroCategoriaOpen(false);
        setModalCadastroNivelOpen(false);
        setIsSubNivel(false);
        getChatBotCategoriaTableData();
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => { })
        setAlertConfirmacaoOpen(false);

    }

    const handleChangeChave = (entidade: EntidadesAcoes, chave: any) => {
        if(entidade == EntidadesAcoes.CATEGORIA) {
            getChatBotCategoriaKeyEmUso(chave);
        } else {
            let reqParam: ReqParamsChaveEmUso = {
                chave: chave.chave,
            };
            if(chave.categoria && chave.categoria.idCategoria){
                reqParam.idCategoria = chave.categoria.idCategoria;
            } 
            if(chave.nivelPai && chave.nivelPai.idNivel) {
                reqParam.idNivelPai = chave.nivelPai.idNivel;
            }
            if(chave.idNivel) {
                reqParam.idNivel = chave.idNivel;
            }
    
            getChatBotNivelKeyEmUso(reqParam);
        }
    }

    return (
        <div className="pageBox">
            <Loading loading={categoria.loading || nivel.loading || categoria.loadingPersis || nivel.loadingPersis || categoria.loadingInativacao || nivel.loadingInativacao} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroCategoria
                modalCadastroOpen={modalCadastroCategoriaOpen}
                closeModal={() => handleCloseAndReload()}
                categoriaData={categoria.data.categoria}
                persist={handlePersitChatBotCategoria}
                qtdChave={table ? table.totalElements + 1 : 1}
                onChangeChave={handleChangeChave}
                errorChave={categoria.errorChave}
                onDeleteLinkAuxiliar={(idNivel: number) => {
                    setAlertConfirmacaoOpen(true);
                    setAlertConfirmacaoTexto(EntidadesAcoes.CHATBOT_LINK_AUXILIAR_EXCLUSAO);
                    setAlertConfirmacaoAcao(() => () => {
                        deleteChatBotLinkAuxiliar(idNivel);
                        setAlertConfirmacaoOpen(false);
                    })
                }}
            />
            <ModalCadastroNivel 
                isSubNivel={isSubNivel}
                categoriaPai={categoriaPai}
                nivelPai={nivelPai}
                modalCadastroOpen={modalCadastroNivelOpen} 
                closeModal={() => handleCloseAndReload()}
                nivelData={nivel.data.nivel} 
                persist={handlePersitChatBotNivel}
                qtdChave={nivel.data.qtdChave ? nivel.data.qtdChave + 1 : 1}
                onChangeChave={handleChangeChave}
                errorChave={nivel.errorChave}
                getQtdChaves={(id:number) => getChatBotNivelQtdChaves(id)}
                onDeleteLinkAuxiliar={(idNivel: number) => {
                    setAlertConfirmacaoOpen(true);
                    setAlertConfirmacaoTexto(EntidadesAcoes.CHATBOT_LINK_AUXILIAR_EXCLUSAO);
                    setAlertConfirmacaoAcao(() => () => {
                        deleteChatBotLinkAuxiliar(idNivel);
                        setAlertConfirmacaoOpen(false);
                    })
                }}
            />
            <div className="table">
                <ChatbotCategoriaTableTreeComponent
                    loading={categoria.loadingTable}
                    data={table}
                    body={body}
                    onChange={changeTable}
                    onCriarCategoria={handleCadastroCategoria}
                    onEditar={handleEdicao}
                    onExcluir={(entidade: EntidadesAcoes, id: number, titulo: string) => handleInativacao(entidade, id, titulo)}
                    onAddNivel={handleCadastroNivel}
                />
            </div>
        </div>
    )
};


const mapStateToProps = (state: ApplicationState) => ({
    categoria: state.categoria,
    nivel: state.nivel,
    chatbot: state.chatbot,
    utils: state.utils
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ ...CategoriaActions, ...ChatBotActions, ...NivelActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotCategoria);