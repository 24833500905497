import { Reducer } from "@reduxjs/toolkit";
import { JustificativaState, JustificativaTypes } from "./types";

const INITIAL_STATE: JustificativaState = {
  data: {
    table: null,
    justificativa: null,
    justificativaTipo: [],
  },
  error: false,
  errorMessage: null,
  loading: false,
  loadingTable: false,
  loadingPersis: false,
  loadingInativacao: false,
};

const reducer: Reducer<JustificativaState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case JustificativaTypes.JUSTIFICATIVA_TABLE_REQUEST:
      return {
        ...state,
        error: false,
        loadingTable: true,
      };
    case JustificativaTypes.JUSTIFICATIVA_TABLE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
        loadingTable: false,
        data: {
          ...state.data,
          table: action.payload.data,
        },
      };
    case JustificativaTypes.JUSTIFICATIVA_TABLE_REQUEST_FAILURE:
      return {
        ...state,
        error: true,
        loadingTable: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          table: null,
        },
      };

    case JustificativaTypes.JUSTIFICATIVA_DATA_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case JustificativaTypes.JUSTIFICATIVA_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          justificativa: action.payload.data,
        },
      };
    case JustificativaTypes.JUSTIFICATIVA_DATA_REQUEST_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          justificativa: null,
        },
      };

    case JustificativaTypes.CLEAR_JUSTIFICATIVA_DATA:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          justificativa: null,
        },
      };

    case JustificativaTypes.JUSTIFICATIVA_SAVE_REQUEST:
      return {
        ...state,
        error: false,
        loadingPersis: true,
      };
    case JustificativaTypes.JUSTIFICATIVA_SAVE_REQUEST_SUCCESS:
      alert("Cadastro de Justificativa realizado com sucesso!");
      return {
        ...state,
        error: false,
        loadingPersis: false,
        data: {
          ...state.data,
          justificativa: action.payload.data,
        },
      };
    case JustificativaTypes.JUSTIFICATIVA_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        error: true,
        loadingPersis: false,
        errorMessage: action.payload.err,
      };

    case JustificativaTypes.JUSTIFICATIVA_INATIVA_REQUEST:
      return {
        ...state,
        error: false,
        loadingPersis: true,
        loadingInativacao: true,
      };

    case JustificativaTypes.JUSTIFICATIVA_INATIVA_REQUEST_SUCCESS:
      alert("Ação realizada com sucesso!");
      return {
        ...state,
        error: false,
        loadingPersis: false,
        loadingInativacao: false,
      };

    case JustificativaTypes.JUSTIFICATIVA_INATIVA_REQUEST_FAILURE:
      return {
        ...state,
        error: true,
        loadingPersis: false,
        loadingInativacao: false,
        errorMessage: action.payload.err,
      };

    case JustificativaTypes.JUSTIFICATIVA_UPDATE_REQUEST:
      return {
        ...state,
        error: false,
        loadingPersis: true,
      };
    case JustificativaTypes.JUSTIFICATIVA_UPDATE_REQUEST_SUCCESS:
      alert("Alteração de Justificativa realizada com sucesso!");
      return {
        ...state,
        error: false,
        loadingPersis: false,
        data: {
          ...state.data,
          justificativa: action.payload.data,
        },
      };
    case JustificativaTypes.JUSTIFICATIVA_UPDATE_REQUEST_FAILURE:
      return {
        ...state,
        error: true,
        loadingPersis: false,
        errorMessage: action.payload.err,
      };

    case JustificativaTypes.JUSTIFICATIVA_TIPO_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case JustificativaTypes.JUSTIFICATIVA_TIPO_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          justificativaTipo: action.payload.data,
        },
      };
    case JustificativaTypes.JUSTIFICATIVA_TIPO_REQUEST_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          justificativaTipo: [],
        },
      };

    default:
      return {
        ...state,
        error: false,
        loading: false,
        errorMessage: null,
      };
  }
};

export default reducer;
