import { action } from "typesafe-actions";
import { DestaqueTypes } from "./types";

export const getDestaqueTable = (data: any) => action(DestaqueTypes.DESTAQUE_TABLE_REQUEST, data);
export const getDestaqueTableSuccess = (data: any) => action(DestaqueTypes.DESTAQUE_TABLE_REQUEST_SUCCESS, { data });
export const getDestaqueTableFailure = (err: any) => action(DestaqueTypes.DESTAQUE_TABLE_REQUEST_FAILURE, { err });

export const onInativarDestaque = (data: any) => action(DestaqueTypes.DESTAQUE_INATIVA_REQUEST, data);
export const onInativarDestaqueSuccess = (data: any) => action(DestaqueTypes.DESTAQUE_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarDestaqueFailure = (err: any) => action(DestaqueTypes.DESTAQUE_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarDestaque = (data: any) => action(DestaqueTypes.DESTAQUE_ATIVA_REQUEST, data);
export const onAtivarDestaqueSuccess = (data: any) => action(DestaqueTypes.DESTAQUE_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarDestaqueFailure = (err: any) => action(DestaqueTypes.DESTAQUE_ATIVA_REQUEST_FAILURE, { err });

export const getDestaqueData = (data: any) => action(DestaqueTypes.DESTAQUE_DATA_REQUEST, data);
export const getDestaqueDataSuccess = (data: any) => action(DestaqueTypes.DESTAQUE_DATA_REQUEST_SUCCESS, { data });
export const getDestaqueDataFailure = (err: any) => action(DestaqueTypes.DESTAQUE_DATA_REQUEST_FAILURE, { err });

export const onSaveDestaque = (data: any) => action(DestaqueTypes.DESTAQUE_SAVE_REQUEST, data);
export const onSaveDestaqueSuccess = (data: any) => action(DestaqueTypes.DESTAQUE_SAVE_REQUEST_SUCCESS, { data });
export const onSaveDestaqueFailure = (err: any) => action(DestaqueTypes.DESTAQUE_SAVE_REQUEST_FAILURE, { err });

export const onUpdateDestaque = (data: any) => action(DestaqueTypes.DESTAQUE_UPDATE_REQUEST, data);
export const onUpdateDestaqueSuccess = (data: any) => action(DestaqueTypes.DESTAQUE_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateDestaqueFailure = (err: any) => action(DestaqueTypes.DESTAQUE_UPDATE_REQUEST_FAILURE, { err });

export const clearDestaqueData = () => action(DestaqueTypes.CLEAR_DESTAQUE_DATA);