import { action } from "typesafe-actions";
import { TemaTypes } from "./types";

export const getTemaTable = (data: any) => action(TemaTypes.TEMA_TABLE_REQUEST, data);
export const getTemaTableSuccess = (data: any) => action(TemaTypes.TEMA_TABLE_REQUEST_SUCCESS, { data });
export const getTemaTableFailure = (err: any) => action(TemaTypes.TEMA_TABLE_REQUEST_FAILURE, { err });

export const onInativarTema = (data: any) => action(TemaTypes.TEMA_INATIVA_REQUEST, data);
export const onInativarTemaSuccess = (data: any) => action(TemaTypes.TEMA_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarTemaFailure = (err: any) => action(TemaTypes.TEMA_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarTema = (data: any) => action(TemaTypes.TEMA_ATIVA_REQUEST, data);
export const onAtivarTemaSuccess = (data: any) => action(TemaTypes.TEMA_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarTemaFailure = (err: any) => action(TemaTypes.TEMA_ATIVA_REQUEST_FAILURE, { err });

export const clearTemaData = () => action(TemaTypes.CLEAR_TEMA_DATA);

export const getTemaData = (data: any) => action(TemaTypes.TEMA_DATA_REQUEST, data);
export const getTemaDataSuccess = (data: any) => action(TemaTypes.TEMA_DATA_REQUEST_SUCCESS, { data });
export const getTemaDataFailure = (err: any) => action(TemaTypes.TEMA_DATA_REQUEST_FAILURE, { err });

export const onSaveTema = (data: any) => action(TemaTypes.TEMA_SAVE_REQUEST, data);
export const onSaveTemaSuccess = (data: any) => action(TemaTypes.TEMA_SAVE_REQUEST_SUCCESS, { data });
export const onSaveTemaFailure = (err: any) => action(TemaTypes.TEMA_SAVE_REQUEST_FAILURE, { err });

export const onUpdateTema = (data: any) => action(TemaTypes.TEMA_UPDATE_REQUEST, data);
export const onUpdateTemaSuccess = (data: any) => action(TemaTypes.TEMA_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateTemaFailure = (err: any) => action(TemaTypes.TEMA_UPDATE_REQUEST_FAILURE, { err });

export const getTemaExistente = (data: any) => action(TemaTypes.TEMA_EXISTENTE_REQUEST, data);
export const getTemaExistenteSuccess = (data: any) => action(TemaTypes.TEMA_EXISTENTE_REQUEST_SUCCESS, { data });
export const getTemaExistenteFailure = (err: any) => action(TemaTypes.TEMA_EXISTENTE_REQUEST_FAILURE, { err });