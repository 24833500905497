export enum ChatBotTypes {
    CHATBOT_DELETE_LINK_AUX_REQ = 'chatbot/CHATBOT_DELETE_LINK_AUX_REQ',
    CHATBOT_DELETE_LINK_AUX_REQ_SUCCESS = 'chatbot/CHATBOT_DELETE_LINK_AUX_REQ_SUCCESS',
    CHATBOT_DELETE_LINK_AUX_REQ_FAILURE = 'chatbot/CHATBOT_DELETE_LINK_AUX_REQ_FAILURE',
}
export interface ChatBotLinkAuxiliarDTO {
    idLinkAuxiliar?: number,
    descricao?: string,
    link?: string
}
export interface ChatBotState {
    readonly error: boolean,
    readonly errorMessage: string | null,
}