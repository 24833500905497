import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";

import * as StoryActions from '../../store/ducks/storys/actions'
import StoryTableComponent from "../../Components/TableStory";
import ModalCadastroStory from "../../Components/ModalCadastroStory";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmStory",
    txtBusca: ""
};

function Story({
    story,
    utils,
    getStoryTable,
    onInativarStory,
    getStoryData,
    onSaveStory,
    onUpdateStory,
    onNotificacaoStory,
    clearStoryData
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(story.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + story.errorMessage);
        }
    }, [story.error]);

    useEffect(() => {
        if(!story.loadingPersis) {
            handleCloseAndReload()
        }
    }, [story.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getStoryTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(story.data.table != null){
            setTable(story.data.table);
        }

    }, [story.data]);

    useEffect(() => {
        if(!story.loadingInativacao){
            getStoryTable(body);
        }
    }, [story.loadingInativacao]);

    const getStoryTableData = () => {
        getStoryTable(body);
    }

    const handleCadastro = () => {
        clearStoryData();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione um story que gostaria de alterar');

        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas um story para alteração!');

        } else {
            getStoryData(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione os stories que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.STORY_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarStory(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersistStory = (edicao: boolean, story: FormData) => {
        if(edicao) {
            onUpdateStory(story);
        } else {
            onSaveStory(story);
        }

    }

    const handleCloseAndReload = () => {
        clearStoryData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }

    return (
        <div className="pageBox">
            <Loading loading={story.loading || story.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroStory 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()}
                storyData={story.data.story} 
                persist={handlePersistStory}
                enviaNotificacao={(idStory: any) =>  onNotificacaoStory(idStory)}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <StoryTableComponent
                    loading={story.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};

const mapStateToProps = (state: ApplicationState) => ({
    story: state.story,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...StoryActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Story);
