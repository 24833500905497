import { Dayjs } from "dayjs"

export enum GrupoTypes {
    GRUPO_TABLE_REQUEST = 'grupo/GRUPO_TABLE_REQUEST',
    GRUPO_TABLE_REQUEST_SUCCESS = 'grupo/GRUPO_TABLE_RQUEST_SUCCESS',
    GRUPO_TABLE_REQUEST_FAILURE = 'grupo/GRUPO_TABLE_REQUEST_FAILURE',

    GRUPO_INATIVA_REQUEST = 'grupo/GRUPO_INATIVA_REQUEST',
    GRUPO_INATIVA_REQUEST_SUCCESS = 'grupo/GRUPO_INATIVA_REQUEST_SUCCESS',
    GRUPO_INATIVA_REQUEST_FAILURE = 'grupo/GRUPO_INATIVA_REQUEST_FAILURE',

    CLEAR_GRUPO_DATA = 'grupo/CLEAR_GRUPO_DATA',

    GRUPO_DATA_REQUEST = 'grupo/GRUPO_DATA_REQUEST',
    GRUPO_DATA_REQUEST_SUCCESS = 'grupo/GRUPO_DATA_REQUEST_SUCCESS',
    GRUPO_DATA_REQUEST_FAILURE = 'grupo/GRUPO_DATA_REQUEST_FAILURE',

    GRUPO_SAVE_REQUEST = 'grupo/GRUPO_SAVE_REQUEST',
    GRUPO_SAVE_REQUEST_SUCCESS = 'grupo/GRUPO_SAVE_REQUEST_SUCCESS',
    GRUPO_SAVE_REQUEST_FAILURE = 'grupo/GRUPO_SAVE_REQUEST_FAILURE',

    GRUPO_UPDATE_REQUEST = 'grupo/GRUPO_UPDATE_REQUEST',
    GRUPO_UPDATE_REQUEST_SUCCESS = 'grupo/GRUPO_UPDATE_REQUEST_SUCCESS',
    GRUPO_UPDATE_REQUEST_FAILURE = 'grupo/GRUPO_UPDATE_REQUEST_FAILURE',
}

export interface GrupoObjTable {
    id?: number,
    idGrupo?: string | number
    nmGrupo?: string
    dsGrupo?: string
}


export interface GrupoObj {
    idGrupo?: string | number
    nmGrupo?: string
    dsGrupo?: string
}


export interface GrupoTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface GrupoPage {
    readonly grupo: GrupoObj | null,
    readonly table: GrupoTable | null,
}

export interface GrupoState {
    readonly data: GrupoPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
}