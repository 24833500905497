import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import "dayjs/locale/pt-br";

import { ClusterObj } from "../../store/ducks/cluster/types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultCluster: ClusterObj = {
  idCluster: "",
  nmCluster: "",
  dsCluster: "",
  idGrupo: "",
};

const ModalCadastroCluster = (props: any) => {
  const [isEdicao, setIsEdicao] = useState(false);
  const [cluster, setCluster] = useState(defaultCluster);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [nmClusterError, setNmClusterError] = useState(false);
  const [dsClusterError, setDsClusterError] = useState(false);
  const [grupoError, setGrupoError] = useState(false);

  useEffect(() => {
    if (props.clusterData) {
      const clusterObj = {
        idCluster: props.clusterData.idCluster,
        nmCluster: props.clusterData.nmCluster,
        dsCluster: props.clusterData.dsCluster,
        //idGrupo: props.clusterData.grupo.idGrupo,
      };
      handleRender(clusterObj);
    }
  }, [props.clusterData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (
      !grupoError &&
      !dsClusterError &&
      !nmClusterError &&
      !!cluster.nmCluster &&
      !!cluster.dsCluster
    ) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [cluster, grupoError, nmClusterError, dsClusterError]);

  const handleRender = (clusterData: any) => {
    if (clusterData) {
      setIsEdicao(true);
      setCluster({
        ...clusterData,
      });
    }
  };

  const handleClose = () => {
    setIsEdicao(false);
    setInativaGravar(false);
    setDsClusterError(false);
    setGrupoError(false);
    setNmClusterError(false);
    setCluster(defaultCluster);

    props.closeModal();
  };

  const handlePersist = (e: any) => {
    e.preventDefault();
    const clusterObj = {
      idCluster: cluster.idCluster,
      nmCluster: cluster.nmCluster,
      dsCluster: cluster.dsCluster,
    };
    props.persistCluster(isEdicao, clusterObj);
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? "Editar Cluster" : "Cadastro de novo Cluster"}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={cluster.nmCluster}
                label="Nome do Cluster"
                error={nmClusterError}
                helperText={nmClusterError ? "Campo Obrigatório." : ""}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setNmClusterError(e.target.value === "");
                  setCluster({
                    ...cluster,
                    nmCluster: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={cluster.dsCluster}
                label="Descrição do Cluster"
                error={dsClusterError}
                helperText={dsClusterError ? "Campo Obrigatório." : ""}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDsClusterError(e.target.value === "");
                  setCluster({
                    ...cluster,
                    dsCluster: e.target.value,
                  });
                }}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">
                  Grupo
                </InputLabel>
                <Select
                  required
                  error={grupoError}
                  value={cluster.idGrupo}
                  label="Grupo"
                  defaultValue={""}
                  onChange={(e) => {
                    setGrupoError(e.target.value === "");
                    setCluster({
                      ...cluster,
                      idGrupo: parseInt(e.target.value.toString()),
                    });
                  }}
                >
                  <MenuItem value="">Grupo...</MenuItem>

                  {props.listAllGrupos
                    ? props.listAllGrupos.map((item: any, idx: any) => (
                        <MenuItem key={"grupTipo-" + idx} value={item.idGrupo}>
                          {item.nmGrupo}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {grupoError ? (
                  <FormHelperText error={grupoError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
          </Grid>

          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">
              {isEdicao ? "Gravar" : "Cadastrar"}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroCluster;
