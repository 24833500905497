import { action } from "typesafe-actions";
import { UtilTypes } from "./types";

export const getEstadosList = () => action(UtilTypes.ESTADOS_LIST);
export const getEstadosListSuccess = (data: any) =>
  action(UtilTypes.ESTADOS_LIST_SUCCESS, { data });
export const getEstadosListFailure = (err: any) =>
  action(UtilTypes.ESTADOS_LIST_FAILURE, { err });

export const getGestoresList = () => action(UtilTypes.GESTORES_LIST);
export const getGestoresListSuccess = (data: any) =>
  action(UtilTypes.GESTORES_LIST_SUCCESS, { data });
export const getGestoresListFailure = (err: any) =>
  action(UtilTypes.GESTORES_LIST_FAILURE, { err });

export const getMunicipioList = (data: any) =>
  action(UtilTypes.MUNICIPIOS_LIST, data);
export const getMunicipioListSuccess = (data: any) =>
  action(UtilTypes.MUNICIPIOS_LIST_SUCCESS, { data });
export const getMunicipioListFailure = (err: any) =>
  action(UtilTypes.MUNICIPIOS_LIST_FAILURE, { err });

export const getUsuariosList = (data: any) =>
  action(UtilTypes.USUARIOS_LIST, data);
export const getUsuariosListSuccess = (data: any) =>
  action(UtilTypes.USUARIOS_LIST_SUCCESS, { data });
export const getUsuariosListFailure = (err: any) =>
  action(UtilTypes.USUARIOS_LIST_FAILURE, { err });

export const getRegional = (data: any) => action(UtilTypes.REGIONAL, data);
export const getRegionalSuccess = (data: any) =>
  action(UtilTypes.REGIONAL_SUCCESS, { data });
export const getRegionalFailure = (err: any) =>
  action(UtilTypes.REGIONAL_FAILURE, { err });

export const getPerfilList = () => action(UtilTypes.PERFIL_LIST);
export const getPerfilListSuccess = (data: any) =>
  action(UtilTypes.PERFIL_LIST_SUCCESS, { data });
export const getPerfilListFailure = (err: any) =>
  action(UtilTypes.PERFIL_LIST_FAILURE, { err });

export const getTemaList = () => action(UtilTypes.TEMA_LIST);
export const getTemaListSuccess = (data: any) =>
  action(UtilTypes.TEMA_LIST_SUCCESS, { data });
export const getTemaListFailure = (err: any) =>
  action(UtilTypes.TEMA_LIST_FAILURE, { err });

export const getUnidadeNegocioList = () =>
  action(UtilTypes.UNIDADE_NEGOCIO_LIST);
export const getUnidadeNegocioListSuccess = (data: any) =>
  action(UtilTypes.UNIDADE_NEGOCIO_LIST_SUCCESS, { data });
export const getUnidadeNegocioListFailure = (err: any) =>
  action(UtilTypes.UNIDADE_NEGOCIO_LIST_FAILURE, { err });

export const getAllMunicipioList = () => action(UtilTypes.MUNICIPIO_ALL_LIST);
export const getAllMunicipioListSuccess = (data: any) =>
  action(UtilTypes.MUNICIPIO_ALL_LIST_SUCCESS, { data });
export const getAllMunicipioListFailure = (err: any) =>
  action(UtilTypes.MUNICIPIO_ALL_LIST_FAILURE, { err });

export const getAllClusterList = () => action(UtilTypes.CLUSTER_ALL_LIST);
export const getAllClusterListSuccess = (data: any) =>
  action(UtilTypes.CLUSTER_ALL_LIST_SUCCESS, { data });
export const getAllClusterListFailure = (err: any) =>
  action(UtilTypes.CLUSTER_ALL_LIST_FAILURE, { err });

export const getAllGrupoList = () => action(UtilTypes.GRUPO_ALL_LIST);
export const getAllGrupoListSuccess = (data: any) =>
  action(UtilTypes.GRUPO_ALL_LIST_SUCCESS, { data });
export const getAllGrupoListFailure = (err: any) =>
  action(UtilTypes.GRUPO_ALL_LIST_FAILURE, { err });

export const getClusterList = (data: any) =>
  action(UtilTypes.CLUSTER_LIST, data);
export const getClusterListSuccess = (data: any) =>
  action(UtilTypes.CLUSTER_LIST_SUCCESS, { data });
export const getClusterListFailure = (err: any) =>
  action(UtilTypes.CLUSTER_LIST_FAILURE, { err });

export const getAllSubclusterList = () => action(UtilTypes.SUBCLUSTER_ALL_LIST);
export const getAllSubclusterListSuccess = (data: any) =>
  action(UtilTypes.SUBCLUSTER_ALL_LIST_SUCCESS, { data });
export const getAllSubclusterListFailure = (err: any) =>
  action(UtilTypes.SUBCLUSTER_ALL_LIST_FAILURE, { err });

export const getSubclusterList = (data: any) =>
  action(UtilTypes.SUBCLUSTER_LIST, data);
export const getSubclusterListSuccess = (data: any) =>
  action(UtilTypes.SUBCLUSTER_LIST_SUCCESS, { data });
export const getSubclusterListFailure = (err: any) =>
  action(UtilTypes.SUBCLUSTER_LIST_FAILURE, { err });

export const getMenuInfo = (data: any) => action(UtilTypes.MENU_LIST, data);
export const getMenuInfoSuccess = (data: any) =>
  action(UtilTypes.MENU_LIST_SUCCESS, { data });
export const getMenuInfoFailure = (err: any) =>
  action(UtilTypes.MENU_LIST_FAILURE, { err });

export const getAvaliacaoList = () => action(UtilTypes.AVALIACAO_LIST);
export const getAvaliacaoListSuccess = (data: any) =>
  action(UtilTypes.AVALIACAO_LIST_SUCCESS, { data });
export const getAvaliacaoListFailure = (err: any) =>
  action(UtilTypes.AVALIACAO_LIST_FAILURE, { err });

export const getAvaliacoesByPerfilAndEstado = (data: any) =>
  action(UtilTypes.AVALIACAO_BY_PERFIL_ESTADO, data);
export const getAvaliacoesByPerfilAndEstadoSuccess = (data: any) =>
  action(UtilTypes.AVALIACAO_BY_PERFIL_ESTADO_SUCCESS, { data });
export const getAvaliacoesByPerfilAndEstadoFailure = (err: any) =>
  action(UtilTypes.AVALIACAO_BY_PERFIL_ESTADO_FAILURE, { err });

export const getConteudoList = () => action(UtilTypes.CONTEUDO_LIST);
export const getConteudoListSuccess = (data: any) =>
  action(UtilTypes.CONTEUDO_LIST_SUCCESS, { data });
export const getConteudoListFailure = (err: any) =>
  action(UtilTypes.CONTEUDO_LIST_FAILURE, { err });

export const getCursoList = () => action(UtilTypes.CURSO_LIST);
export const getCursoListSuccess = (data: any) =>
  action(UtilTypes.CURSO_LIST_SUCCESS, { data });
export const getCursoListFailure = (err: any) =>
  action(UtilTypes.CURSO_LIST_FAILURE, { err });

export const getSegurancaNoTrabalhoList = () => action(UtilTypes.SEGURANCA_LIST);
export const getSegurancaNoTrabalhoListSuccess = (data: any) =>
  action(UtilTypes.SEGURANCA_LIST_SUCCESS, { data });
export const getSegurancaNoTrabalhoListFailure = (err: any) =>
  action(UtilTypes.SEGURANCA_LIST_FAILURE, { err });

export const getTrilhaList = () => action(UtilTypes.TRILHA_LIST);
export const getTrilhaListSuccess = (data: any) =>
  action(UtilTypes.TRILHA_LIST_SUCCESS, { data });
export const getTrilhaListFailure = (err: any) =>
  action(UtilTypes.TRILHA_LIST_FAILURE, { err });

export const setLoading = (data: any) => action(UtilTypes.SET_LOADING, data);
export const setLoadingTrue = () => action(UtilTypes.SET_LOADING_TRUE);
export const setLoadingFalse = (err: any) =>
  action(UtilTypes.SET_LOADING_FALSE, { err });


  /*  */

export const getGrupoClusterSubcluster = (data: any) => action(UtilTypes.GRUPO_CLUSTER_SUBCLUSTER, data);
export const getGrupoClusterSubclusterSuccess = (data: any) => action(UtilTypes.GRUPO_CLUSTER_SUBCLUSTER_SUCCESS, { data });
export const getGrupoClusterSubclusterFailure = (err: any) => action(UtilTypes.GRUPO_CLUSTER_SUBCLUSTER_FAILURE, { err });


/*  */