import { Reducer } from "@reduxjs/toolkit";
import { UsuarioState, UsuarioTypes } from "./types";

const INITIAL_STATE: UsuarioState = {
  data: {
    table: null,
    inativacaoMassa: null,
    resetSenhaMassa: null,
    importacao: null,
    usuario: null,
  },
  error: false,
  errorMessage: null,
  loading: false,
  loadingTable: false,
  loadingPersis: false,
  loadingInativacao: false,
};

const reducer: Reducer<UsuarioState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsuarioTypes.USUARIO_TABLE_REQUEST:
      return {
        ...state,
        error: false,
        loadingTable: true,
      };
    case UsuarioTypes.USUARIO_TABLE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
        loadingTable: false,
        data: {
          ...state.data,
          table: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_TABLE_REQUEST_FAILURE:
      return {
        ...state,
        error: true,
        loadingTable: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          table: null,
        },
      };

    case UsuarioTypes.USUARIO_INATIVAR:
      return {
        ...state,
        error: false,
        loading: true,
        loadingInativacao: true,
      };
    case UsuarioTypes.USUARIO_INATIVAR_SUCCESS:
      alert("Ação realizada com sucesso!");
      return {
        ...state,
        error: false,
        loading: false,
        loadingInativacao: false,
      };
    case UsuarioTypes.USUARIO_INATIVAR_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        loadingInativacao: false,
        errorMessage: action.payload.err,
      };

    case UsuarioTypes.USUARIO_ATIVAR:
      return {
        ...state,
        error: false,
        loading: true,
        loadingInativacao: true,
      };
    case UsuarioTypes.USUARIO_ATIVAR_SUCCESS:
      alert("Ação realizada com sucesso!");
      return {
        ...state,
        error: false,
        loading: false,
        loadingInativacao: false,
      };
    case UsuarioTypes.USUARIO_ATIVAR_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        loadingInativacao: false,
        errorMessage: action.payload.err,
      };

    case UsuarioTypes.USUARIO_IMPORTAR:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UsuarioTypes.USUARIO_IMPORTAR_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          importacao: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_IMPORTAR_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          importacao: null,
        },
      };

    case UsuarioTypes.USUARIO_IMPORTAR_T5:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UsuarioTypes.USUARIO_IMPORTAR_T5_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          importacao: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_IMPORTAR_T5_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          importacao: null,
        },
      };

    case UsuarioTypes.USUARIO_INATIVAR_MASSA:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UsuarioTypes.USUARIO_INATIVAR_MASSA_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          inativacaoMassa: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_INATIVAR_MASSA_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          inativacaoMassa: null,
        },
      };

    case UsuarioTypes.USUARIO_RESETAR_SENHA:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UsuarioTypes.USUARIO_RESETAR_SENHA_SUCCESS:
      alert("Ação realizada com sucesso!");
      return {
        ...state,
        error: false,
        loading: false,
      };
    case UsuarioTypes.USUARIO_RESETAR_SENHA_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
      };
    case UsuarioTypes.USUARIO_RESETAR_SENHA_MASSA:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UsuarioTypes.USUARIO_RESETAR_SENHA_MASSA_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          resetSenhaMassa: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_RESETAR_SENHA_MASSA_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          resetSenhaMassa: null,
        },
      };

    case UsuarioTypes.USUARIO_DATA:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UsuarioTypes.USUARIO_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          usuario: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_DATA_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.err,
        data: {
          ...state.data,
          usuario: null,
        },
      };

    case UsuarioTypes.USUARIO_SAVE:
      return {
        ...state,
        error: false,
        loadingPersis: true,
      };
    case UsuarioTypes.USUARIO_SAVE_SUCCESS:
      alert("Cadastro de usuário realizado com sucesso!");
      return {
        ...state,
        error: false,
        loadingPersis: false,
        data: {
          ...state.data,
          usuario: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_SAVE_FAILURE:
      return {
        ...state,
        error: true,
        loadingPersis: false,
        errorMessage:
          action.payload.err.response.data || action.payload.err.message,
      };

    case UsuarioTypes.USUARIO_UPDATE:
      return {
        ...state,
        error: false,
        loadingPersis: true,
      };
    case UsuarioTypes.USUARIO_UPDATE_SUCCESS:
      alert("Alteração de usuário realizado com sucesso!");
      return {
        ...state,
        error: false,
        loadingPersis: false,
        data: {
          ...state.data,
          usuario: action.payload.data,
        },
      };
    case UsuarioTypes.USUARIO_UPDATE_FAILURE:
      return {
        ...state,
        error: true,
        loadingPersis: false,
        errorMessage: action.payload.err,
      };

    case UsuarioTypes.CLEAR_USUARIO_DATA:
      return {
        ...state,
        error: false,
        loading: false,
        data: {
          ...state.data,
          usuario: null,
        },
      };

    default:
      return {
        ...state,
        error: false,
        loading: false,
        errorMessage: null,
      };
  }
};

export default reducer;
