import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import Input from "@mui/material/Input";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/pt-br";

import { DestaqueObj } from "../../store/ducks/destaque/types";
import ListaConteudoComponent from "../ListaConteudoComponent";
import { FormHelperText } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultDestaque: DestaqueObj = {
  dsDestaque: "",
  urlCapaDestaque: "",
  dtDestaqueInicio: null,
  dtDestaqueFim: null,
  icFixoPrimeiro: 0,
  conteudo: {},
  icDestaqueInicial: 0,
  icDestaqueVisualizado: 0,
};

const ModalCadastroDestaque = (props: any) => {
  const [isEdicao, setIsEdicao] = useState(false);
  const [destaque, setDestaque] = useState(defaultDestaque);
  const [capaDestaque, setCapaDestaque] = useState("");
  const [inativaGravar, setInativaGravar] = useState(true);
  const [imgDestaqueErro, setImgDestaqueErro] = useState(false);
  const [dsDestaqueErro, setDsDestaqueErro] = useState(false);
  const [dtIniDestaqueErro, setDtIniDestaqueErro] = useState(false);
  const [dtFimDestaqueErro, setDtFimDestaqueErro] = useState(false);
  const [conteudoErro, setConteudoErro] = useState(false);

  useEffect(() => {
    if (props.destaqueData) {
      handleRender(props.destaqueData);
    }
  }, [props.destaqueData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (
      !imgDestaqueErro &&
      !dsDestaqueErro &&
      !dtIniDestaqueErro &&
      !dtFimDestaqueErro &&
      !conteudoErro &&
      destaque.urlCapaDestaque !== "" &&
      destaque.dsDestaque !== "" &&
      destaque.dtDestaqueInicio !== null &&
      destaque.dtDestaqueFim !== null
    ) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [
    destaque,
    imgDestaqueErro,
    dsDestaqueErro,
    dtIniDestaqueErro,
    dtFimDestaqueErro,
    conteudoErro,
  ]);

  const handleRender = (destaqueData: any) => {
    if (destaqueData) {
      setIsEdicao(true);
      setDestaque({
        ...destaqueData,
      });
      setCapaDestaque("");
    }
  };

  const handleClose = () => {
    setIsEdicao(false);
    setDestaque(defaultDestaque);
    setCapaDestaque("");
    setInativaGravar(true);
    setDsDestaqueErro(false);
    setDtIniDestaqueErro(false);
    setDtFimDestaqueErro(false);
    setImgDestaqueErro(false);
    setConteudoErro(false);

    props.closeModal();
  };

  const handlePersist = (e: any) => {
    e.preventDefault();

    const formDestaque = new FormData();
    formDestaque.append("conteudoDestaque", JSON.stringify(destaque));

    if (capaDestaque != "") {
      formDestaque.append("fileCapa", capaDestaque);
    }

    props.persist(isEdicao, formDestaque);
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? "Editar Conteúdo" : "Cadastro de novo Conteúdo"}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {isEdicao && destaque.urlCapaDestaque && capaDestaque == "" ? (
                <FormControl sx={{ width: "100%" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="inherit"
                    component="p"
                  >
                    Capa
                  </Typography>
                  <div>
                    <Link
                      className="buttonArquivo"
                      href={destaque.urlCapaDestaque}
                      underline="none"
                      target="_blank"
                    >
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#eb4034" }}
                      >
                        Download
                      </Button>
                    </Link>
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => {
                        setImgDestaqueErro(true);
                        setDestaque({
                          ...destaque,
                          urlCapaDestaque: "",
                        });
                      }}
                    >
                      Remover
                    </Button>
                  </div>
                </FormControl>
              ) : (
                <FormControl sx={{ width: "100%" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="inherit"
                    component="p"
                  >
                    Capa{" "}
                    {destaque.icDestaqueInicial === 1 && (
                      <Typography
                        variant="inherit"
                        component="span"
                        color="error"
                      >
                        * Tamanho recomendado 300x675
                      </Typography>
                    )}
                  </Typography>
                  <Input
                    type="file"
                    error={imgDestaqueErro}
                    onChange={(e: any) => {
                      if (Array.from(e.target.files).length !== 0) {
                        if (validaNomeArquivo(e.target.files[0].name)) {
                          setImgDestaqueErro(
                            Array.from(e.target.files).length === 0
                          );
                          setDestaque({
                            ...destaque,
                            urlCapaDestaque: e.target.files[0].name || "",
                          });
                          setCapaDestaque(e.target.files[0]);
                        } else {
                          e.target.value = "";
                          setImgDestaqueErro(true);
                        }
                      } else {
                        e.target.value = "";
                        setImgDestaqueErro(true);
                      }
                    }}
                  />
                  {imgDestaqueErro ? (
                    <FormHelperText error={imgDestaqueErro}>
                      Campo Obrigatório.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: "100%" }}
                value={destaque.dsDestaque}
                label="Descrição do Destaque"
                error={dsDestaqueErro}
                helperText={dsDestaqueErro ? "Campo Obrigatório." : ""}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDsDestaqueErro(e.target.value === "");
                  setDestaque({
                    ...destaque,
                    dsDestaque: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <ListaConteudoComponent
                  error={conteudoErro}
                  conteudos={destaque.conteudo}
                  multiple={false}
                  onChangeConteudos={(value: any) => {
                    setConteudoErro(value.length === 0);
                    setDestaque({
                      ...destaque,
                      conteudo: value[0] ? value[0] : {},
                    });
                  }}
                />
                {conteudoErro ? (
                  <FormHelperText error={conteudoErro}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DesktopDatePicker
                  onError={(e) => {
                    if (e) {
                      setDtIniDestaqueErro(true);
                    } else {
                      setDtIniDestaqueErro(false);
                    }
                  }}
                  label="Data de Início"
                  value={destaque.dtDestaqueInicio}
                  minDate={dayjs("1900-01-01")}
                  onChange={(newValue) => {
                    setDtIniDestaqueErro(false);
                    setDestaque({
                      ...destaque,
                      dtDestaqueInicio: newValue?.format(),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={dtIniDestaqueErro}
                      helperText={dtIniDestaqueErro ? "Campo Obrigatório." : ""}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DesktopDatePicker
                  onError={(e) => {
                    if (e) {
                      setDtFimDestaqueErro(true);
                    } else {
                      setDtFimDestaqueErro(false);
                    }
                  }}
                  label="Data Final"
                  value={destaque.dtDestaqueFim}
                  minDate={dayjs("1900-01-01")}
                  onChange={(newValue) => {
                    setDtFimDestaqueErro(false);
                    setDestaque({
                      ...destaque,
                      dtDestaqueFim: newValue?.format(),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={dtFimDestaqueErro}
                      helperText={dtFimDestaqueErro ? "Campo Obrigatório." : ""}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              <FormControl>
                <FormLabel>Travar em Primeiro</FormLabel>
                <RadioGroup
                  row
                  value={destaque.icFixoPrimeiro}
                  onChange={(e) => {
                    setDestaque({
                      ...destaque,
                      icFixoPrimeiro: parseInt(e.target.value),
                    });
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel>Destaque inicial</FormLabel>
                <RadioGroup
                  row
                  value={destaque.icDestaqueInicial}
                  onChange={(e) => {
                    setDestaque({
                      ...destaque,
                      icDestaqueInicial:
                        destaque.icDestaqueInicial === 0 ? 1 : 0,
                    });
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">
              {isEdicao ? "Gravar" : "Cadastrar"}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroDestaque;
