import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Icon from "@mui/material/Icon";

export default function AcaoMunicipioMenu({ onRelacionar }: any) {
  return (
    <List>
      <ListItem
        className="menu-item"
        key="vincular"
        disablePadding
        sx={{ display: "block" }}
      >
        <ListItemButton onClick={onRelacionar}>
          <ListItemIcon>
            <Icon>compare_arrows</Icon>
          </ListItemIcon>
          <ListItemText primary={"Relacionar Município"} sx={{ opacity: 1 }} />
        </ListItemButton>
      </ListItem>
    </List>
  );
}
