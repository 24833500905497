export enum AuthTypes {
    AUTH_LOGIN = 'auth/AUTH_LOGIN',
    AUTH_LOGIN_REQUEST = 'auth/AUTH_LOGIN_REQUEST',
    AUTH_LOGIN_REQUEST_SUCCESS = 'auth/AUTH_LOGIN_REQUEST_SUCCESS',
    AUTH_LOGIN_REQUEST_FAILURE = 'auth/AUTH_LOGIN_REQUEST_FAILURE',
    AUTH_LOGOUT = 'auth/AUTH_LOGOUT',
    AUTH_LOGOUT_SUCCESS = 'auth/AUTH_LOGOUT_SUCCESS',
    AUTH_LOGOUT_FAILURE = 'auth/AUTH_LOGOUT_FAILURE',
    REFRESH_TOKEN = 'auth/REFRESH_TOKEN',
    REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE',
}

export interface Auth {
    idUsuario?: number,
    idPerfil?: number 
    nmUsuario?: string,
    emailUsuario?: string,
    accessToken?: string,
    chaveToken?: string,
    type?: string,
    isExpirado?: boolean,
    isExpiradoMobile?: boolean,
    isFirstAccess?: boolean
    authorized: boolean,
}


export interface AuthState {
    readonly data: Auth | null,
    readonly loading: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
}