import { Reducer } from "@reduxjs/toolkit";
import { ChatBotState, ChatBotTypes } from "./types";

const INITIAL_STATE: ChatBotState = {
    error: false,
    errorMessage: null,
};

const reducer: Reducer<ChatBotState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ChatBotTypes.CHATBOT_DELETE_LINK_AUX_REQ:
        case ChatBotTypes.CHATBOT_DELETE_LINK_AUX_REQ_SUCCESS:
            return {
                error: false,
                errorMessage: null,
            }

        case ChatBotTypes.CHATBOT_DELETE_LINK_AUX_REQ_FAILURE:
            return {
                error: true,
                errorMessage: action.payload.err.data,
            }
    
        default :
            return {
                ...state,
                error: false,
                errorMessage: null
            };
    }
}

export default reducer;