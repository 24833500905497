import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

import * as UtilsActions from "../../store/ducks/utils/actions";
import { ApplicationState } from "../../store";

function ListaConteudo({
  utils,
  getConteudoList,
  conteudos,
  onChangeConteudos,
  multiple,
  error,
}: any) {
  const [conteudoList, setConteudosList] = React.useState<any>([]);
  const [conteudoSelecionados, setConteudosSelecionados] = React.useState<any>(
    []
  );
  const [selectAll, setSelectAll] = React.useState(false);
  const [lazyItems, setLazyItems] = React.useState<any>([]);
  const [lazyLoading, setLazyLoading] = React.useState(false);

  const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(
    null
  );

  React.useEffect(() => {
    getConteudoList();
  }, []);

  React.useEffect(() => {
    if (utils.data.conteudo) {
      setConteudosList(utils.data.conteudo);
      if (utils.data.conteudo.length > 0) {
        setSelectAll(conteudos.length == utils.data.conteudo.length);
      }
    }
  }, [utils.data.conteudo]);

  React.useEffect(() => {
    if (conteudos) {
      setConteudosSelecionados(
        multiple
          ? conteudos.map((item: any) => item.idConteudo)
          : conteudos.idConteudo
          ? [conteudos.idConteudo]
          : []
      );
    }
  }, [conteudos]);

  const onLazyLoad = (event: any) => {
    setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, 300);
  };

  return (
    <MultiSelect
      style={{
        width: "100%",
      }}
      selectionLimit={multiple ? 99999 : 1}
      showSelectAll={multiple}
      value={conteudoSelecionados}
      options={conteudoList}
      display="chip"
      onChange={(e) => {
        if (e.value) {
          setConteudosSelecionados(e.value);
          setSelectAll(e.value.length === conteudoList.length);
          onChangeConteudos(
            conteudoList.filter((muni: any) =>
              e.value.includes(muni.idConteudo)
            )
          );
        } else {
          setSelectAll(false);
          setConteudosSelecionados([]);
          onChangeConteudos([]);
        }
      }}
      selectAll={selectAll}
      optionLabel="nmConteudo"
      optionValue="idConteudo"
      placeholder={
        multiple ? "Selecione os Conteúdos" : "Selecione um Conteúdo"
      }
      filter
      showClear={true}
      className={error ? "p-multiselect-error" : ""}
      maxSelectedLabels={3}
      selectedItemsLabel={`${
        conteudoSelecionados ? conteudoSelecionados.length : 0
      } conteúdos selecionados`}
      emptyFilterMessage="Não encontrado"
      filterPlaceholder="Busque por um conteúdo"
      resetFilterOnHide={true}
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: onLazyLoad,
        itemSize: 43,
        showLoader: true,
        loading: lazyLoading,
        delay: 1,
        loadingTemplate: (options) => {
          return (
            <div
              className="flex align-items-center p-2"
              style={{ height: "43px" }}
            >
              <Skeleton width={options.even ? "70%" : "60%"} height="1.5rem" />
            </div>
          );
        },
      }}
    />
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaConteudo);
