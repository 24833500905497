import { action } from "typesafe-actions";
import { ConteudoTypes } from "./types";

export const getConteudoTable = (data: any) => action(ConteudoTypes.CONTEUDO_TABLE_REQUEST, data);
export const getConteudoTableSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_TABLE_REQUEST_SUCCESS, { data });
export const getConteudoTableFailure = (err: any) => action(ConteudoTypes.CONTEUDO_TABLE_REQUEST_FAILURE, { err });

export const onInativarConteudo = (data: any) => action(ConteudoTypes.CONTEUDO_INATIVA_REQUEST, data);
export const onInativarConteudoSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarConteudoFailure = (err: any) => action(ConteudoTypes.CONTEUDO_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarConteudo = (data: any) => action(ConteudoTypes.CONTEUDO_ATIVA_REQUEST, data);
export const onAtivarConteudoSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarConteudoFailure = (err: any) => action(ConteudoTypes.CONTEUDO_ATIVA_REQUEST_FAILURE, { err });

export const getConteudoData = (data: any) => action(ConteudoTypes.CONTEUDO_DATA_REQUEST, data);
export const getConteudoDataSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_DATA_REQUEST_SUCCESS, { data });
export const getConteudoDataFailure = (err: any) => action(ConteudoTypes.CONTEUDO_DATA_REQUEST_FAILURE, { err });

export const onSaveConteudo = (data: any) => action(ConteudoTypes.CONTEUDO_SAVE_REQUEST, data);
export const onSaveConteudoSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_SAVE_REQUEST_SUCCESS, { data });
export const onSaveConteudoFailure = (err: any) => action(ConteudoTypes.CONTEUDO_SAVE_REQUEST_FAILURE, { err });

export const onUpdateConteudo = (data: any) => action(ConteudoTypes.CONTEUDO_UPDATE_REQUEST, data);
export const onUpdateConteudoSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateConteudoFailure = (err: any) => action(ConteudoTypes.CONTEUDO_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoConteudo = (data: any) => action(ConteudoTypes.CONTEUDO_PUSH_REQUEST, data);
export const onNotificacaoConteudoSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoConteudoFailure = (err: any) => action(ConteudoTypes.CONTEUDO_PUSH_REQUEST_FAILURE, { err });

export const getConteudoTipoList = () => action(ConteudoTypes.CONTEUDO_TIPO_REQUEST);
export const getConteudoTipoListSuccess = (data: any) => action(ConteudoTypes.CONTEUDO_TIPO_REQUEST_SUCCESS, { data });
export const getConteudoTipoListFailure = (err: any) => action(ConteudoTypes.CONTEUDO_TIPO_REQUEST_FAILURE, { err });

export const clearConteudoData = () => action(ConteudoTypes.CLEAR_CONTEUDO_DATA);