import { Reducer } from "@reduxjs/toolkit";
import { MunicipioState, MunicipioTypes } from "./types";

const INITIAL_STATE: MunicipioState = {
    data: {
        table: null,
        municipio: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<MunicipioState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MunicipioTypes.MUNICIPIO_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case MunicipioTypes.MUNICIPIO_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case MunicipioTypes.MUNICIPIO_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };


        case MunicipioTypes.MUNICIPIO_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case MunicipioTypes.MUNICIPIO_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    municipio: action.payload.data
                }
            };
        case MunicipioTypes.MUNICIPIO_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    municipio: null
                }
            };


        case MunicipioTypes.CLEAR_MUNICIPIO_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    municipio: null
                }
            };

        case MunicipioTypes.MUNICIPIO_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case MunicipioTypes.MUNICIPIO_SAVE_REQUEST_SUCCESS:
            alert('Relação de Município realizada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    municipio: action.payload.data
                }
            };
        case MunicipioTypes.MUNICIPIO_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        default:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
};

export default reducer;