import styled from "@emotion/styled";
import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import "./style.css";

const StyledNode = styled.div`
padding: 5px;
border-radius: 8px;
display: inline-block;
border: 1px solid black;
`;


const ChatbotDiagram = React.forwardRef((props: any, ref: any) => {

    const generateTreeNode = (arrayDiagram: any) => {
        return arrayDiagram.map((item: any, index: number) => (
            <TreeNode key={`node-${index}-${item.id}`} label={<StyledNode>{item.label}</StyledNode>}>
                {generateTreeNode(item.children)}
            </TreeNode>
        ))
    }

    return (
        <div className="diagram" ref={ref}>
            <Tree
                lineWidth={'2px'}
                lineColor={'black'}
                lineBorderRadius={'10px'}
                label={<StyledNode>Conectado Chatbot</StyledNode>}
            >
                {generateTreeNode(props.diagram)}
            </Tree>
        </div>
    )
});

export default ChatbotDiagram;