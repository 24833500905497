import * as React from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { ApplicationState } from "../../store";
import { getMenuInfo } from "../../store/ducks/utils/actions";

import ListDrawerMenu from '../ListDrawerMenu/ListDrawerMenu'
import "./styles.css";
import Loading from "../LoadingComponent";

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  border: "0",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function DrawerMenu(props: any) {
  const [open, setOpen] = React.useState(true);
  const [menuList, setMenuList] = React.useState([])

  React.useEffect(() => {
    if (props.authorized) {
      const idUsuario = props.idUsuario;
      props.getMenuInfo(idUsuario);
    }
  }, [])

  React.useEffect(() => {
    if (props.utils.data.menu) {
      // To show Justificativa name on sidebar
      setMenuList(props.utils.data.menu);
    }
  }, [props.utils.data.menu])

  const toggleDrawerOpenOrClose = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ backgroundColor: "#454545" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawerOpenOrClose}
            edge="start"
            sx={{
              marginRight: 5,
              backgroundColor: "#eb4034",
              borderRadius: 0,
              transform: "rotate(90deg)",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          borderRight: "0!important",
          border: "none",
        }}
      >
        <DrawerHeader style={{ backgroundColor: "#454545" }}>
          <div className="headLogoImg">
            <img src={require("../../assets/img/logo_top.png")} />
          </div>
          <IconButton
            onClick={toggleDrawerOpenOrClose}
            sx={{
              backgroundColor: "#eb4034",
              borderRadius: 0,
              transform: "rotate(0deg)",
              transition: "all 300ms ease-in-out",
              ...(!open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ color: "#fff" }} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box className="sideInfos" sx={{ ...(!open && { display: "none" }) }}>
          <p>Bem-Vindo!</p>
          <p>{props.nmUsuario}</p>
          <IconButton style={{ fontSize: 20 }} onClick={() => props.logoutApp(props.accessToken)}>
            Sair <ExitToAppIcon />
          </IconButton>
        </Box>
        <Divider />
        <ListDrawerMenu open={open} menuList={menuList} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} >
        <DrawerHeader />
        <Outlet context={[props.refreshToken, props.logoutApp]} />
        <Loading loading={props.utils.loading} />
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getMenuInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawerMenu);