import { action } from "typesafe-actions";
import { ClusterTypes } from "./types";

export const getClusterTable = (data: any) => action(ClusterTypes.CLUSTER_TABLE_REQUEST, data);
export const getClusterTableSuccess = (data: any) => action(ClusterTypes.CLUSTER_TABLE_REQUEST_SUCCESS, { data });
export const getClusterTableFailure = (err: any) => action(ClusterTypes.CLUSTER_TABLE_REQUEST_FAILURE, { err });

export const onInativarCluster = (data: any) => action(ClusterTypes.CLUSTER_INATIVA_REQUEST, data);
export const onInativarClusterSuccess = (data: any) => action(ClusterTypes.CLUSTER_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarClusterFailure = (err: any) => action(ClusterTypes.CLUSTER_INATIVA_REQUEST_FAILURE, { err });

export const getClusterData = (data: any) => action(ClusterTypes.CLUSTER_DATA_REQUEST, data);
export const getClusterDataSuccess = (data: any) => action(ClusterTypes.CLUSTER_DATA_REQUEST_SUCCESS, { data });
export const getClusterDataFailure = (err: any) => action(ClusterTypes.CLUSTER_DATA_REQUEST_FAILURE, { err });

export const onSaveCluster = (data: any) => action(ClusterTypes.CLUSTER_SAVE_REQUEST, data);
export const onSaveClusterSuccess = (data: any) => action(ClusterTypes.CLUSTER_SAVE_REQUEST_SUCCESS, { data });
export const onSaveClusterFailure = (err: any) => action(ClusterTypes.CLUSTER_SAVE_REQUEST_FAILURE, { err });

export const onUpdateCluster = (data: any) => action(ClusterTypes.CLUSTER_UPDATE_REQUEST, data);
export const onUpdateClusterSuccess = (data: any) => action(ClusterTypes.CLUSTER_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateClusterFailure = (err: any) => action(ClusterTypes.CLUSTER_UPDATE_REQUEST_FAILURE, { err });

export const clearClusterData = () => action(ClusterTypes.CLEAR_CLUSTER_DATA);