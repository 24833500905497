import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import * as NivelActions from '../../../store/ducks/chatbot/nivel/actions';
import * as ChatBotActions from '../../../store/ducks/chatbot/action'

import AcaoMenu from "../../../Components/AcaoMenu";
import { ApplicationState } from "../../../store";
import Loading from "../../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../../enums/EntidadesAcoesConfirmacao";
import { ChatBotNivelTable, ReqParamsChaveEmUso } from "../../../store/ducks/chatbot/nivel/types";
import ChatbotNivelTableComponent from "../../../Components/TableChatbotNivel";
import ModalCadastroNivel from "../../../Components/ModalCadastroChatbotNivel";

function ChatBotNivel({
    chatbot,
    nivel,
    getChatBotNivelTable,
    getChatBotNivel,
    getChatBotNivelDelete,
    getChatBotNivelSave,
    getChatBotNivelUpdate,
    clearChatBotNivel,
    getChatBotNivelKeyEmUso,
    getChatBotNivelQtdChaves,
    deleteChatBotLinkAuxiliar
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState({});
    const [table, setTable] = useState<ChatBotNivelTable>();
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(nivel.error || chatbot.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação.\nDescrição do erro: ' + (nivel.errorMessage ?? chatbot.errorMessage));
        }
    }, [nivel.error, chatbot.error]);

    useEffect(() => {
        if(!nivel.loadingPersis && !nivel.error) {
            handleCloseAndReload()
        }
    }, [nivel.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen){
            getChatBotNivelTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(nivel.data.table != null){
            setTable(nivel.data.table);
        }
    }, [nivel.data]);

    useEffect(() => {
        if(!nivel.loadingInativacao){
            getChatBotNivelTable(body);
        }
    }, [nivel.loadingInativacao]);

    const getChatBotNivelTableData = () => {
        getChatBotNivelTable(body);
    }

    const handleCadastro = () => {
        clearChatBotNivel();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione um nível que gostaria de alterar');
        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas um nível para alteração!');
        } else {
            getChatBotNivel(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
        
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione os níveis que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.CHATBOT_NIVEL_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                getChatBotNivelDelete(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersitChatBotNivel = (edicao: boolean, nivel: any) => {
        if(edicao) {
            getChatBotNivelUpdate(nivel);
        } else {
            getChatBotNivelSave(nivel);
        }
    }

    const handleCloseAndReload = () => {
        clearChatBotNivel();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
        if(idsSelecionado[0] && modalCadastroOpen){
            getChatBotNivel(idsSelecionado[0]);
        }
    }

    const handleChangeChave = (objChange: any) => {
        let reqParam: ReqParamsChaveEmUso = {
            chave: objChange.chave,
        };
        if(objChange.categoria && objChange.categoria.idCategoria){
            reqParam.idCategoria = objChange.categoria.idCategoria;
        } 
        if(objChange.nivelPai && objChange.nivelPai.idNivel) {
            reqParam.idNivelPai = objChange.nivelPai.idNivel;
        }

        getChatBotNivelKeyEmUso(reqParam);
    }

    return (
        <div className="pageBox">
            <Loading loading={nivel.loading || nivel.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroNivel 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()}
                nivelData={nivel.data.nivel} 
                persist={handlePersitChatBotNivel}
                qtdChave={nivel.data.qtdChave ? nivel.data.qtdChave + 1 : 1}
                onChangeChave={handleChangeChave}
                errorChave={nivel.errorChave}
                getQtdChaves={(id:number) => getChatBotNivelQtdChaves(id)}
                onDeleteLinkAuxiliar={(idNivel: number) => {
                    setAlertConfirmacaoOpen(true);
                    setAlertConfirmacaoTexto(EntidadesAcoes.CHATBOT_LINK_AUXILIAR_EXCLUSAO);
                    setAlertConfirmacaoAcao(() => () => {
                        deleteChatBotLinkAuxiliar(idNivel);
                        setAlertConfirmacaoOpen(false);
                    })
                }}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <ChatbotNivelTableComponent 
                    loading={nivel.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};


const mapStateToProps = (state: ApplicationState) => ({
    nivel: state.nivel,
    chatbot: state.chatbot,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...NivelActions, ...ChatBotActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotNivel);