import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'

import { ConteudoObj } from "../../store/ducks/conteudo/types";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaMunicipioComponentTeste from "../ListaMunicipioComponentTeste";
import ListaTemaComponent from "../ListaTemaComponent";
import { FormHelperText } from "@mui/material";
import { validaNomeArquivo } from "../../services/Uteis";
import { api } from "../../services";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const defaultConteudo: ConteudoObj = {
  nmConteudo: '',
  dsConteudo: '',
  dtConteudoInicio: null,
  dtConteudoFim: null,
  dsTempoEstimado: '',
  pathConteudo: '',
  imgConteudo: '',
  conteudoTipo: null,
  urlCompartilhamentoConteudo: '',
  icLivre: 0,
  isExterno: 0,
  onApp: 0,
  urlExternaConteudo: '',
  conteudoMuncipios: [],
  conteudoPerfis: [],
  conteudoTemas: []
};

const ModalCadastroConteudo = (props: any) => {

  const [isEdicao, setIsEdicao] = useState(false);
  const [conteudo, setConteudo] = useState(defaultConteudo);
  const [conteudoExterno, setConteudoExterno] = useState('');
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [municipios_, setMunicipios_] = useState([]);
  const [arquivoConteudo, setArquivoConteudo] = useState('');
  const [capaConteudo, setCapaConteudo] = useState('');
  const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
  const [inativaGravar, setInativaGravar] = useState(true);
  const [conteudoExternoErro, setConteudoExternoErro] = useState(false);
  const [arqConteudoErro, setArqConteudoErro] = useState(false);
  const [imgConteudoErro, setImgConteudoErro] = useState(false);
  const [nmConteudoErro, setNmConteudoErro] = useState(false);
  const [dsConteudoErro, setDsConteudoErro] = useState(false);
  const [dtIniConteudoErro, setDtIniConteudoErro] = useState(false);
  const [dtFimConteudoErro, setDtFimConteudoErro] = useState(false);
  const [dsTempoEstimadoErro, setDsTempoEstimadoErro] = useState(false);
  const [perfilErro, setPerfilErro] = useState(false);
  const [municipioErro, setMunicipioErro] = useState(false);
  const [temaErro, setTemaErro] = useState(false);
  const [tpConteudoErro, setTpConteudoErro] = useState(false);

  useEffect(() => {
    if (props.conteudoData) {
      handleRender(props.conteudoData);
    }
  }, [props.conteudoData]);

  useEffect(() => {
    if (props.conteudoDataMunicipio && props.id) {
      handleMunicipioData(props.conteudoDataMunicipio);
    }
  }, [props.conteudoDataMunicipio]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);


  useEffect(() => {
    if (!conteudoExternoErro && !arqConteudoErro && !imgConteudoErro && !nmConteudoErro && !dsConteudoErro && !dtIniConteudoErro && !dtFimConteudoErro && !dsTempoEstimadoErro && !perfilErro && !municipioErro && !temaErro && !tpConteudoErro
      && ((conteudo.isExterno && conteudo.urlExternaConteudo !== '') 
      ||
      (!conteudo.isExterno && conteudo.pathConteudo !== '')) && conteudo.imgConteudo !== '' && titulo !== '' && descricao !== '' && conteudo.dtConteudoInicio !== null && conteudo.dtConteudoFim !== null
      && conteudo.dsTempoEstimado !== '' && conteudo.conteudoTipo !== null && conteudo.conteudoTemas.length !== 0 && conteudo.conteudoPerfis.length !== 0) {
        setInativaGravar(false);
    } else {
        setInativaGravar(true);
    }
  }, [conteudo, arqConteudoErro, imgConteudoErro, nmConteudoErro, dsConteudoErro, dtIniConteudoErro, dtFimConteudoErro, dsTempoEstimadoErro, perfilErro, municipioErro, temaErro, tpConteudoErro, conteudoExternoErro, conteudoExterno, titulo, descricao])


  const handleMunicipioData = async (data: any) => {
    setMunicipios_(await data );
  }

  async function getConteudoDataMunicipioTotal(data: any) {
    const idConteudo = data;
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`conteudo/findMunicipioById?idConteudo=${idConteudo}`, {headers: {'Authorization': `Bearer ${token}`}})
        return response;
    } catch (error) {
        console.log(error);
    }
  }
    async function getConteudoDataMunicipioLimited(data: any) {
      const idConteudo = data;
      const token = localStorage.getItem('accessToken');
      try {
          const { data: response } = await api.get(`conteudo/findMunicipioByIdLimit?idConteudo=${idConteudo}`, {headers: {'Authorization': `Bearer ${token}`}})
          return response;
      } catch (error) {
          console.log(error);
      }
  };
         


  const handleRender = (conteudoData: any) => {
    if (conteudoData) {
      
      setIsEdicao(true)
      setConteudo({
        ...conteudoData
      });
      setTitulo(String(conteudoData.nmConteudo));
      setDescricao(String(conteudoData.dsConteudo));
      
      if(conteudoData.isExterno){
        setConteudoExterno(String(conteudoData.urlExternaConteudo));
      }


      setArquivoConteudo('');
      setCapaConteudo('');
    }
  }

  const handleClose = () => {
    setIsEdicao(false);
    setConteudo(defaultConteudo);
    setArquivoConteudo('');
    setCapaConteudo('');
    setMunicipios_([]);
    setConteudoExterno('');
    setInativaGravar(true);
    setNmConteudoErro(false);
    setDsConteudoErro(false);
    setDtIniConteudoErro(false);
    setDtFimConteudoErro(false);
    setDsTempoEstimadoErro(false);
    setPerfilErro(false);
    setMunicipioErro(false);
    setTemaErro(false);
    setArqConteudoErro(false);
    setImgConteudoErro(false);
    setTpConteudoErro(false);

    props.closeModal();
  }

  
  
  const handlePersist = async (e: any) => {
    conteudo.nmConteudo = titulo;
    conteudo.dsConteudo = descricao;
    e.preventDefault();
    if(municipios_.length === 351 && isEdicao){
      conteudo.conteudoMuncipios = await getConteudoDataMunicipioTotal(props.id);
    }
    else{
      conteudo.conteudoMuncipios = municipios_;
    }
    
   
    const formConteudo = new FormData();
    formConteudo.append('conteudo', JSON.stringify(conteudo));
    if (arquivoConteudo != '') {
      formConteudo.append('fileArquivo', arquivoConteudo);
    }
    if (capaConteudo != '') {
      formConteudo.append('fileImg', capaConteudo);
    }
    props.persist(isEdicao, formConteudo);
   
  
  }

  return (
    <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if (res !== 'backdropClick') {
        handleClose()
      }
    }}
    >
      <Box sx={style} >
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? 'Editar Conteúdo' : 'Cadastro de novo Conteúdo'}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6} >
              {
                conteudo.isExterno ? (
                  <TextField
                    required
                    sx={{ width: '100%' }}
                    value={conteudoExterno}
                    label="Link do conteúdo externo"
                    error={conteudoExternoErro}
                    helperText={conteudoExternoErro ? "Campo Obrigatório." : ''}
                    onChange={(e) => {
                      setConteudoExterno(e.target.value);
                      setConteudoExternoErro(e.target.value === "");
                      setConteudo({
                        ...conteudo,
                        urlExternaConteudo: e.target.value
                      })
                    }}
                    onPaste={(e) => {
                      setConteudoExterno(e.clipboardData.getData('Text'));
                      setConteudoExternoErro(e.clipboardData.getData('Text') === "");
                      setConteudo({
                        ...conteudo,
                        urlExternaConteudo: e.clipboardData.getData('Text')
                      })
                      e.preventDefault();
                    }}
                    onCut={(e) => {
                      setConteudoExterno("");
                      setConteudoExternoErro("" === "");
                      setConteudo({
                        ...conteudo,
                        urlExternaConteudo: ""
                      })
                    }}
                  />
                ) : (
                  isEdicao && conteudo.pathConteudo && arquivoConteudo == '' ? (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Arquivo
                      </Typography>
                      <div>
                        <Link className="buttonArquivo" href={conteudo.pathConteudo} underline="none" target="_blank" >
                          <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                        </Link>
                        <Button variant="text" color="error" onClick={() => {
                          setArqConteudoErro(true);
                          setConteudo({
                            ...conteudo,
                            pathConteudo: ''
                          })
                        }}>Remover</Button>
                      </div>
                    </FormControl>
                  ) : (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Arquivo
                      </Typography>
                      <Input type='file' error={arqConteudoErro} onChange={(e: any) => {
                        if (Array.from(e.target.files).length !== 0) {
                          if (validaNomeArquivo(e.target.files[0].name)) {
                            setArqConteudoErro(Array.from(e.target.files).length === 0);
                            setConteudo({
                              ...conteudo,
                              pathConteudo: e.target.files[0].name || null
                            })
                            setArquivoConteudo(e.target.files[0])
                          } else {
                            e.target.value = "";
                            setArqConteudoErro(true);
                          }
                        } else {
                          e.target.value = "";
                          setArqConteudoErro(true);
                        }
                      }} />
                      {arqConteudoErro ? <FormHelperText error={arqConteudoErro}>Campo Obrigatório.</FormHelperText> : null}
                    </FormControl>
                  )
                )
              }
            </Grid>
            <Grid item xs={6} >
              {
                isEdicao && conteudo.imgConteudo && capaConteudo == '' ? (
                  <FormControl sx={{ width: '100%' }} >
                    <Typography id="modal-modal-title" variant="inherit" component="p">
                      Capa
                    </Typography>
                    <div>
                      <Link className="buttonArquivo" href={conteudo.imgConteudo} underline="none" target="_blank" >
                        <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                      </Link>
                      <Button variant="text" color="error" onClick={() => {
                        setImgConteudoErro(true);
                        setConteudo({
                          ...conteudo,
                          imgConteudo: ''
                        })
                      }}>Remover</Button>
                    </div>
                  </FormControl>
                ) : (
                  <FormControl sx={{ width: '100%' }} >
                    <Typography id="modal-modal-title" variant="inherit" component="p">
                      Capa
                    </Typography>
                    <Input type='file' error={imgConteudoErro} onChange={(e: any) => {
                      if (Array.from(e.target.files).length !== 0) {
                        if (validaNomeArquivo(e.target.files[0].name)) {
                          setImgConteudoErro(Array.from(e.target.files).length === 0);
                          setConteudo({
                            ...conteudo,
                            imgConteudo: e.target.files[0].name || null
                          })
                          setCapaConteudo(e.target.files[0])
                        } else {
                          e.target.value = "";
                          setImgConteudoErro(true);
                        }
                      } else {
                        e.target.value = "";
                        setImgConteudoErro(true);
                      }
                    }} />
                    {imgConteudoErro ? <FormHelperText error={imgConteudoErro}>Campo Obrigatório.</FormHelperText> : null}
                  </FormControl>
                )
              }
            </Grid>

            <Grid item xs={6} >
              <TextField
                required
                sx={{ width: '100%' }}
                value={titulo}
                label="Nome do Conteúdo"
                error={nmConteudoErro}
                helperText={nmConteudoErro ? "Campo Obrigatório." : ''}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setNmConteudoErro(e.target.value === "");
                  setTitulo(e.target.value);
                }}
                onPaste={(e) => {
                  console.log("PASTE")
                  setNmConteudoErro(e.clipboardData.getData('Text') === "");
                  setTitulo(e.clipboardData.getData('Text'));
                  e.preventDefault();
                }}
                onCut={(e) => {
                  setTitulo("");
                  setNmConteudoErro("" === "");
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                sx={{ width: '100%' }}
                value={descricao}
                label="Descrição do Conteúdo"
                error={dsConteudoErro}
                helperText={dsConteudoErro ? "Campo Obrigatório." : ''}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDsConteudoErro(e.target.value === "");
                  setDescricao(e.target.value);
                }}
                onPaste={(e) => {
                  setDsConteudoErro(e.clipboardData.getData('Text') === "");
                  setDescricao(e.clipboardData.getData('Text'));
                  e.preventDefault();
                }}
                onCut={(e) => {
                  setDescricao("");
                  setDsConteudoErro("" === "");
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <DesktopDatePicker
                  onError={(e) => {
                    if (e) {
                      setDtIniConteudoErro(true);
                    } else {
                      setDtIniConteudoErro(false);
                    }
                  }}
                  label="Data de Início"
                  value={conteudo.dtConteudoInicio}
                  minDate={dayjs('1900-01-01')}
                  onChange={(newValue) => {
                    setDtIniConteudoErro(false);
                    setConteudo({
                      ...conteudo,
                      dtConteudoInicio: newValue?.format()
                    })
                  }}
                  renderInput={(params) => <TextField {...params} error={dtIniConteudoErro} helperText={dtIniConteudoErro ? "Campo Obrigatório." : ''} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <DesktopDatePicker
                  onError={(e) => {
                    if (e) {
                      setDtFimConteudoErro(true);
                    } else {
                      setDtFimConteudoErro(false);
                    }
                  }}
                  label="Data Final"
                  value={conteudo.dtConteudoFim}
                  minDate={dayjs('1900-01-01')}
                  onChange={(newValue) => {
                    setDtFimConteudoErro(false);
                    setConteudo({
                      ...conteudo,
                      dtConteudoFim: newValue?.format()
                    })
                  }}
                  renderInput={(params) => <TextField {...params} error={dtFimConteudoErro} helperText={dtFimConteudoErro ? "Campo Obrigatório." : ''} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <TextField
                required
                sx={{ width: '100%' }}
                value={conteudo.dsTempoEstimado}
                label="Duração em Minutos"
                error={dsTempoEstimadoErro}
                helperText={dsTempoEstimadoErro ? "Campo Obrigatório." : ''}
                onChange={(e) => {
                  setDsTempoEstimadoErro(e.target.value === "");
                  setConteudo({
                    ...conteudo,
                    dsTempoEstimado: e.target.value
                  })
                }}
                onPaste={(e) => {
                  setDsTempoEstimadoErro(e.clipboardData.getData('Text') === "");
                  setConteudo({
                    ...conteudo,
                    dsTempoEstimado: e.clipboardData.getData('Text')
                  })
                  e.preventDefault();
                }}
                onCut={(e) => {
                  setConteudo({
                    ...conteudo,
                    dsTempoEstimado: ""
                  })
                  setDsTempoEstimadoErro("" === "");
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl sx={{ width: '100%' }} >
                <InputLabel id="demo-controlled-open-select-label">Tipo do Conteudo</InputLabel>
                <Select
                  required
                  value={conteudo.conteudoTipo != null ? conteudo.conteudoTipo.idConteudoTipo : 0}
                  label="Tipo do Conteúdo"
                  defaultValue={0}
                  error={tpConteudoErro}
                  onChange={(e) => {
                    setTpConteudoErro(e.target.value === "0");
                    setConteudo({
                      ...conteudo,
                      conteudoTipo: {
                        idConteudoTipo: parseInt(e.target.value.toString())
                      }
                    })
                  }}
                >
                  <MenuItem value={"0"}>Escolha...</MenuItem>
                  {
                    props.listConteudoTipo ? (
                      props.listConteudoTipo.map((item: any, idx: any) => (
                        <MenuItem key={'contTipo-' + idx} value={item.idConteudoTipo}>{item.nmConteudoTipo}</MenuItem>
                      ))
                    ) : null
                  }
                </Select>
                {tpConteudoErro ? <FormHelperText error={tpConteudoErro}>Campo Obrigatório.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }} >
                <ListaPerfilComponent
                  error={perfilErro}
                  perfis={conteudo.conteudoPerfis}
                  onChangePerfis={(value: any) => {
                    setPerfilErro(value.length === 0)
                    setConteudo({
                      ...conteudo,
                      conteudoPerfis: value
                    })
                  }}
                />
                {perfilErro ? <FormHelperText error={perfilErro}>Campo Obrigatório.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }} >
                <ListaMunicipioComponentTeste
                  error={municipioErro}
                  municipios={municipios_}
                  callbackList={getConteudoDataMunicipioTotal}
                  id={props.id}
                  onChangeMunicipios={(value: any) => {
                    setMunicipioErro(value.length === 0)
                    setMunicipios_(value);
                  }}
                />
                {municipioErro ? <FormHelperText error={municipioErro}>Campo Obrigatório.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }} >
                <ListaTemaComponent
                  error={temaErro}
                  temas={conteudo.conteudoTemas}
                  multiple={true}
                  onChangeTemas={(value: any) => {
                    setTemaErro(value.length === 0)
                    setConteudo({
                      ...conteudo,
                      conteudoTemas: value
                    })
                  }}
                />
                {temaErro ? <FormHelperText error={temaErro}>Campo Obrigatório.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl>
                <FormLabel>Biblioteca</FormLabel>
                <RadioGroup
                  row
                  value={conteudo.icLivre}
                  onChange={(e) => {
                    setConteudo({
                      ...conteudo,
                      icLivre: parseInt(e.target.value)
                    })
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
              <FormLabel>Conteúdo Externo</FormLabel>
                <RadioGroup
                  row
                  value={conteudo.isExterno}
                  onChange={(e) => {
                    setConteudo({
                      ...conteudo,
                      isExterno: parseInt(e.target.value)
                    })
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {
            conteudo.isExterno ?(
            <Grid item xs={3}>
              <FormControl>
                <FormLabel>Apresentar no App</FormLabel>
                <RadioGroup
                  row
                  value={conteudo.onApp}
                  onChange={(e) => {
                    setConteudo({
                      ...conteudo,
                      onApp: parseInt(e.target.value)
                    })
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>) : null
            }
            {
              conteudo.urlCompartilhamentoConteudo ? (
                <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    disabled
                    sx={{ width: '80%' }}
                    value={conteudo.urlCompartilhamentoConteudo}
                    label="Link de Compartilhamento"
                  />
                  <Tooltip title={copiado}>
                    <IconButton sx={{ fontSize: 25, marginLeft: '5px', cursor: 'pointer' }} onClick={() => {
                      navigator.clipboard.writeText(conteudo.urlCompartilhamentoConteudo || '').then(() => {
                        setCopiado('Copiado!');
                      }).catch(() => {
                        setCopiado('Não foi possível copiar o link de compartilhamento!');
                      }).finally(() => {
                        setTimeout(() => {
                          setCopiado('Copiar link de compartilhamento');
                        }, 1500);
                      });
                    }} >
                      <Icon >content_copy</Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : null
            }
          </Grid>
          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
            {
              isEdicao ?
                <Button variant="contained" onClick={() => {
                  props.enviaNotificacao(conteudo.idConteudo)
                }}>Enviar Notificação</Button> : null
            }
          </div>
        </form>
      </Box>

    </Modal>
  )

};

export default ModalCadastroConteudo;