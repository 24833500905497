import { Dayjs } from "dayjs";

export enum SubclusterTypes {
  SUBCLUSTER_TABLE_REQUEST = "subcluster/SUBCLUSTER_TABLE_REQUEST",
  SUBCLUSTER_TABLE_REQUEST_SUCCESS = "subcluster/SUBCLUSTER_TABLE_RQUEST_SUCCESS",
  SUBCLUSTER_TABLE_REQUEST_FAILURE = "subcluster/SUBCLUSTER_TABLE_REQUEST_FAILURE",

  SUBCLUSTER_INATIVA_REQUEST = "subcluster/SUBCLUSTER_INATIVA_REQUEST",
  SUBCLUSTER_INATIVA_REQUEST_SUCCESS = "subcluster/SUBCLUSTER_INATIVA_REQUEST_SUCCESS",
  SUBCLUSTER_INATIVA_REQUEST_FAILURE = "subcluster/SUBCLUSTER_INATIVA_REQUEST_FAILURE",

  CLEAR_SUBCLUSTER_DATA = "subcluster/CLEAR_SUBCLUSTER_DATA",

  SUBCLUSTER_DATA_REQUEST = "subcluster/SUBCLUSTER_DATA_REQUEST",
  SUBCLUSTER_DATA_REQUEST_SUCCESS = "subcluster/SUBCLUSTER_DATA_REQUEST_SUCCESS",
  SUBCLUSTER_DATA_REQUEST_FAILURE = "subcluster/SUBCLUSTER_DATA_REQUEST_FAILURE",

  SUBCLUSTER_SAVE_REQUEST = "subcluster/SUBCLUSTER_SAVE_REQUEST",
  SUBCLUSTER_SAVE_REQUEST_SUCCESS = "subcluster/SUBCLUSTER_SAVE_REQUEST_SUCCESS",
  SUBCLUSTER_SAVE_REQUEST_FAILURE = "subcluster/SUBCLUSTER_SAVE_REQUEST_FAILURE",

  SUBCLUSTER_UPDATE_REQUEST = "subcluster/SUBCLUSTER_UPDATE_REQUEST",
  SUBCLUSTER_UPDATE_REQUEST_SUCCESS = "subcluster/SUBCLUSTER_UPDATE_REQUEST_SUCCESS",
  SUBCLUSTER_UPDATE_REQUEST_FAILURE = "subcluster/SUBCLUSTER_UPDATE_REQUEST_FAILURE",
}

export interface SubclusterObjTable {
  id?: number;
  idSubCluster?: string | number;
  nmSubCluster?: string;
  dsSubCluster?: string;
}

export interface SubclusterObj {
  idSubcluster?: string | number;
  idCluster?: string | number;
  nmSubcluster?: string;
  dsSubcluster?: string;
}

export interface SubclusterTable {
  [x: string]: any;
  content: Array<Object>;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  numberOfElements: number;
}

export interface SubclusterPage {
  readonly subcluster: SubclusterObj | null;
  readonly table: SubclusterTable | null;
}

export interface SubclusterState {
  readonly data: SubclusterPage;
  readonly loading: boolean;
  readonly loadingTable: boolean;
  readonly loadingPersis: boolean;
  readonly loadingInativacao: boolean;
  readonly error: boolean;
  readonly errorMessage: string | null;
}
