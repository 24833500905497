import { Reducer } from "@reduxjs/toolkit";
import { TrilhaState, TrilhaTypes } from "./types";

const INITIAL_STATE: TrilhaState = {
    data: {
        table: null,
        trilha: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<TrilhaState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TrilhaTypes.TRILHA_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case TrilhaTypes.TRILHA_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case TrilhaTypes.TRILHA_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case TrilhaTypes.TRILHA_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case TrilhaTypes.TRILHA_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case TrilhaTypes.TRILHA_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case TrilhaTypes.TRILHA_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case TrilhaTypes.TRILHA_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case TrilhaTypes.TRILHA_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case TrilhaTypes.TRILHA_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case TrilhaTypes.TRILHA_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    trilha: action.payload.data
                }
            };
        case TrilhaTypes.TRILHA_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    trilha: null
                }
            };


        case TrilhaTypes.CLEAR_TRILHA_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    trilha: null
                }
            };

        case TrilhaTypes.TRILHA_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case TrilhaTypes.TRILHA_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Trilha realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    trilha: action.payload.data
                }
            };
        case TrilhaTypes.TRILHA_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case TrilhaTypes.TRILHA_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case TrilhaTypes.TRILHA_UPDATE_REQUEST_SUCCESS:
            alert('Alteração de Trilha realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    trilha: action.payload.data
                }
            };
        case TrilhaTypes.TRILHA_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case TrilhaTypes.TRILHA_PUSH_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case TrilhaTypes.TRILHA_PUSH_REQUEST_SUCCESS:
            alert('As notificações entraram na fila para envio! Pode levar alguns minutos para que os usuários recebam.');
            return {
                ...state,
                error: false,
                loading: false,
            };
        case TrilhaTypes.TRILHA_PUSH_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
            };

        default :
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
};

export default reducer;