import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';

import * as UtilsActions from '../../store/ducks/utils/actions';
import { ApplicationState } from "../../store";

function ListaCluster({
    multiple = true,
    utils,
    getAllClusterList,
    clusters,
    onChangeClusters,
    error
}: any) {

    const [clustersList, setClustersList] = React.useState<any>([]);
    const [clustersSelecionados, setClustersSelecionados] = React.useState<any>([]);
    const [selectAll, setSelectAll] = React.useState(false);
    const [lazyItems, setLazyItems] = React.useState<any>([]);
    const [lazyLoading, setLazyLoading] = React.useState(false);

    const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    React.useEffect(() => {
        getAllClusterList()
    }, []);

    React.useEffect(() => {
        setClustersList(utils.data.clusters);
        if (utils.data.clusters && utils.data.clusters.length > 0) {
            if(multiple) {
                setSelectAll(clusters.length == utils.data.clusters.length);
            }
        }

    }, [utils.data.clusters]);

    React.useEffect(() => {
        if (clusters) {
            setClustersSelecionados(multiple ? clusters.map((item: any) => item.idCluster)
            : clusters.idCluster ? [clusters.idCluster] : []);
        }
    }, [clusters]);

    const onLazyLoad = (event: any) => {
        setLazyLoading(true);

        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }

        loadLazyTimeout.current = setTimeout(() => {
            const { first, last } = event;
            const _lazyItems = [...lazyItems];

            for (let i = first; i < last; i++) {
                _lazyItems[i] = { label: `Item #${i}`, value: i };
            }

            setLazyItems(_lazyItems);
            setLazyLoading(false);
        }, 300);
    }

    return (
        <MultiSelect
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }}
            {...(!multiple && { selectionLimit: 1 })}
            showSelectAll={multiple}
            value={clustersSelecionados}
            options={clustersList}
            display="chip"
            onChange={(e) => {
                if (e.value) {
                    setClustersSelecionados(e.value);
                    setSelectAll(e.value.length === clustersList.length);
                    onChangeClusters(clustersList.filter((clust: any) => e.value.includes(clust.idCluster)));
                } else {
                    setClustersSelecionados([])
                    setSelectAll(false);
                    onChangeClusters([])
                }
            }}
            selectAll={selectAll}
            optionLabel="nmCluster"
            optionValue='idCluster'
            placeholder={
                multiple ? "Selecione os Clusters" : "Selecione um Cluster"
            }
            filter
            showClear={true}
            className={error ? "p-multiselect-error" : ''}
            maxSelectedLabels={3}
            selectedItemsLabel={`${clustersSelecionados ? clustersSelecionados.length : 0} clusters selecionados`}
            emptyFilterMessage='Não encontrado'
            filterPlaceholder={
                multiple ? "Busque por clusters" : "Busque por um cluster"
            }
            resetFilterOnHide={true}
            virtualScrollerOptions={{
                lazy: true, onLazyLoad: onLazyLoad, itemSize: 43, showLoader: true, loading: lazyLoading, delay: 1, loadingTemplate: (options) => {
                    return (
                        <div className="flex align-items-center p-2" style={{ height: '43px' }}>
                            <Skeleton width={options.even ? '70%' : '60%'} height="1.5rem" />
                        </div>
                    )
                }
            }}
        />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    utils: state.utils
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCluster);