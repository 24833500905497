import React from "react"

import "./style.css";

export default function Loading(props: any) {
    return (
        props.loading ? (
            <div className="main">
                <div className="inner">
                    <div className="gif"></div>
                </div>
            </div> 
        ) : null
    )
}