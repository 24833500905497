import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getConteudoDataFailure,
    getConteudoDataSuccess,
    getConteudoTableFailure,
    getConteudoTableSuccess,
    onAtivarConteudoFailure,
    onAtivarConteudoSuccess,
    onInativarConteudoFailure,
    onInativarConteudoSuccess,
    onSaveConteudoFailure, 
    onSaveConteudoSuccess,
    onUpdateConteudoFailure,
    onUpdateConteudoSuccess,
    onNotificacaoConteudoFailure,
    onNotificacaoConteudoSuccess,
    getConteudoTipoListFailure,
    getConteudoTipoListSuccess
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getConteudoTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/listAllConteudoTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getConteudoTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getConteudoTableFailure(error.message));
    }
};


function* onInativarConteudo(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarConteudoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarConteudoFailure(error.message));
    }
};

function* onAtivarConteudo(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarConteudoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarConteudoFailure(error.message));
    }
};

function* getConteudoData(data: any): any {
    const idConteudo = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`conteudo/findById?idConteudo=${idConteudo}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getConteudoDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getConteudoDataFailure(error.message));
    }
};



function* onSaveConteudo(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('conteudo/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveConteudoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveConteudoFailure(error.message));
    }
};

function* onUpdateConteudo(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('conteudo/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(onUpdateConteudoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateConteudoFailure(error.message));
    }
};

function* onNotificacaoConteudo(data: any): any {
    const idConteudo = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`conteudo/notificaConteudo?idConteudo=${idConteudo}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoConteudoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoConteudoFailure(error.message));
    }
};

function* getConteudoTipoList(): any {
    const token = localStorage.getItem('accessToken');

    const doRequest = () => {
        return api.get('conteudo/listConteudoTipo', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getConteudoTipoListSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getConteudoTipoListFailure(error.message));
    }
}

export {
    getConteudoData,
    getConteudoTable,
    onAtivarConteudo,
    onInativarConteudo,
    onSaveConteudo,
    onUpdateConteudo,
    onNotificacaoConteudo,
    getConteudoTipoList,
}
