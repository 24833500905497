import { action } from "typesafe-actions";
import { ChatBotCategoriaTypes } from "./types";

export const getChatBotCategoriaTable = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_TABLE_REQ, data);
export const getChatBotCategoriaTableSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_TABLE_REQ_SUCCESS, { data });
export const getChatBotCategoriaTableFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_TABLE_REQ_FAILURE, { err });

export const getChatBotCategoria = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_REQ, data);
export const getChatBotCategoriaSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_REQ_SUCCESS, { data });
export const getChatBotCategoriaFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_REQ_FAILURE, { err });

export const getChatBotCategoriaSave = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_SAVE_REQ, data);
export const getChatBotCategoriaSaveSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_SAVE_REQ_SUCCESS, { data });
export const getChatBotCategoriaSaveFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_SAVE_REQ_FAILURE, { err });

export const getChatBotCategoriaUpdate = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_UPDATE_REQ, data);
export const getChatBotCategoriaUpdateSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_UPDATE_REQ_SUCCESS, { data });
export const getChatBotCategoriaUpdateFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_UPDATE_REQ_FAILURE, { err });

export const getChatBotCategoriaDelete = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DELETE_REQ, data);
export const getChatBotCategoriaDeleteSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DELETE_REQ_SUCCESS, { data });
export const getChatBotCategoriaDeleteFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DELETE_REQ_FAILURE, { err });

export const getChatBotCategoriaKeyEmUso = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_KEY_REQ, data);
export const getChatBotCategoriaKeyEmUsoSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_KEY_REQ_SUCCESS, { data });
export const getChatBotCategoriaKeyEmUsoFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_KEY_REQ_FAILURE, { err });

export const getChatBotCategoriaList = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_LIST_REQ, data);
export const getChatBotCategoriaListSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_LIST_REQ_SUCCESS, { data });
export const getChatBotCategoriaListFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_LIST_REQ_FAILURE, { err });

export const getChatBotCategoriaDiagrama = () => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DIAGRAMA_REQ);
export const getChatBotCategoriaDiagramaSuccess = (data: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DIAGRAMA_REQ_SUCCESS, { data });
export const getChatBotCategoriaDiagramaFailure = (err: any) => action(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DIAGRAMA_REQ_FAILURE, { err });

export const clearChatBotCategoria = () => action(ChatBotCategoriaTypes.CLEAR_CHATBOT_CATEGORIA);