import { Reducer } from "@reduxjs/toolkit";
import { ComunicadoState, ComunicadoTypes } from "./types";

const INITIAL_STATE: ComunicadoState = {
    data: {
        table: null,
        comunicado: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<ComunicadoState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ComunicadoTypes.COMUNICADO_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case ComunicadoTypes.COMUNICADO_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case ComunicadoTypes.COMUNICADO_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };

        case ComunicadoTypes.COMUNICADO_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case ComunicadoTypes.COMUNICADO_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case ComunicadoTypes.COMUNICADO_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case ComunicadoTypes.COMUNICADO_ATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case ComunicadoTypes.COMUNICADO_ATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case ComunicadoTypes.COMUNICADO_ATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };

        case ComunicadoTypes.COMUNICADO_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ComunicadoTypes.COMUNICADO_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    comunicado: action.payload.data
                }
            };
        case ComunicadoTypes.COMUNICADO_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    comunicado: null
                }
            };


        case ComunicadoTypes.CLEAR_COMUNICADO_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    comunicado: null
                }
            };

        case ComunicadoTypes.COMUNICADO_SAVE_REQUEST:
            alert('Cadastro de Comunicado realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case ComunicadoTypes.COMUNICADO_SAVE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    comunicado: action.payload.data
                }
            };
        case ComunicadoTypes.COMUNICADO_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case ComunicadoTypes.COMUNICADO_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case ComunicadoTypes.COMUNICADO_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Comunicado realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    comunicado: action.payload.data
                }
            };
        case ComunicadoTypes.COMUNICADO_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case ComunicadoTypes.COMUNICADO_PUSH_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ComunicadoTypes.COMUNICADO_PUSH_REQUEST_SUCCESS:
            alert('As notificações entraram na fila para envio! Pode levar alguns minutos para que os usuários recebam.');
            return {
                ...state,
                error: false,
                loading: false,
            };
        case ComunicadoTypes.COMUNICADO_PUSH_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
            };

        default :
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
};

export default reducer;