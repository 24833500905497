export enum ChatBotSaudacaoTypes {
    CHATBOT_SAUDACAO_TABLE_REQ = 'chatbot/saudacao/CHATBOT_SAUDACAO_TABLE_REQ',
    CHATBOT_SAUDACAO_TABLE_REQ_SUCCESS = 'chatbot/saudacao/CHATBOT_SAUDACAO_TABLE_REQ_SUCCESS',
    CHATBOT_SAUDACAO_TABLE_REQ_FAILURE = 'chatbot/saudacao/CHATBOT_SAUDACAO_TABLE_REQ_FAILURE',

    CHATBOT_SAUDACAO_REQ = 'chatbot/saudacao/CHATBOT_SAUDACAO_REQ',
    CHATBOT_SAUDACAO_REQ_SUCCESS = 'chatbot/saudacao/CHATBOT_SAUDACAO_REQ_SUCCESS',
    CHATBOT_SAUDACAO_REQ_FAILURE = 'chatbot/saudacao/CHATBOT_SAUDACAO_REQ_FAILURE',

    CHATBOT_SAUDACAO_SAVE_REQ = 'chatbot/saudacao/CHATBOT_SAUDACAO_SAVE_REQ',
    CHATBOT_SAUDACAO_SAVE_REQ_SUCCESS = 'chatbot/saudacao/CHATBOT_SAUDACAO_SAVE_REQ_SUCCESS',
    CHATBOT_SAUDACAO_SAVE_REQ_FAILURE = 'chatbot/saudacao/CHATBOT_SAUDACAO_SAVE_REQ_FAILURE',

    CHATBOT_SAUDACAO_UPDATE_REQ = 'chatbot/saudacao/CHATBOT_SAUDACAO_UPDATE_REQ',
    CHATBOT_SAUDACAO_UPDATE_REQ_SUCCESS = 'chatbot/saudacao/CHATBOT_SAUDACAO_UPDATE_REQ_SUCCESS',
    CHATBOT_SAUDACAO_UPDATE_REQ_FAILURE = 'chatbot/saudacao/CHATBOT_SAUDACAO_UPDATE_REQ_FAILURE',

    CHATBOT_SAUDACAO_DELETE_REQ = 'chatbot/saudacao/CHATBOT_SAUDACAO_DELETE_REQ',
    CHATBOT_SAUDACAO_DELETE_REQ_SUCCESS = 'chatbot/saudacao/CHATBOT_SAUDACAO_DELETE_REQ_SUCCESS',
    CHATBOT_SAUDACAO_DELETE_REQ_FAILURE = 'chatbot/saudacao/CHATBOT_SAUDACAO_DELETE_REQ_FAILURE',

    CLEAR_CHATBOT_SAUDACAO = 'chatbot/saudacao/CLEAR_CHATBOT_SAUDACAO',
}

export interface ChatBotSaudacaoTable {
    [x: string]: any
    content: Array<ChatBotSaudacaoObj>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface ChatBotSaudacaoPage {
    readonly saudacao: ChatBotSaudacaoObj | null,
    readonly table: ChatBotSaudacaoTable | null
}

export interface ChatBotSaudacaoObj {
    id?: number,
    idSaudacao?: number,
    titulo?: string,
    descricao?: string,
    ativo?: boolean,
}

export interface ChatBotSaudacaoState {
    readonly data: ChatBotSaudacaoPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
}