import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { 
    getChatBotCategoriaDeleteSuccess, 
    getChatBotCategoriaDeleteFailure,
    getChatBotCategoriaSaveSuccess,
    getChatBotCategoriaSaveFailure,
    getChatBotCategoriaSuccess,
    getChatBotCategoriaFailure,
    getChatBotCategoriaTableSuccess,
    getChatBotCategoriaTableFailure,
    getChatBotCategoriaUpdateSuccess,
    getChatBotCategoriaUpdateFailure,
    getChatBotCategoriaKeyEmUsoSuccess,
    getChatBotCategoriaKeyEmUsoFailure,
    getChatBotCategoriaListSuccess,
    getChatBotCategoriaListFailure,
    getChatBotCategoriaDiagramaSuccess,
    getChatBotCategoriaDiagramaFailure
} from "./actions";

function* getChatBotCategoriaTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('chatbot/categoria/lista-paginada', null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: body
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotCategoriaTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaTableFailure(error.message));
    }
}

function* getChatBotCategoriaDiagrama(): any {
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('chatbot/categoria/lista-diagrama', null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotCategoriaDiagramaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaDiagramaFailure(error.message));
    }
}

function* getChatBotCategoriaSave(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('chatbot/categoria', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotCategoriaSaveSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaSaveFailure(error.message));
    }
}

function* getChatBotCategoriaUpdate(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('chatbot/categoria', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotCategoriaUpdateSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaUpdateFailure(error.message));
    }
}

function* getChatBotCategoriaDelete(data: any): any {
    const idCategoria = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.delete('chatbot/categoria/delete-massa', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: idCategoria
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotCategoriaDeleteSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaDeleteFailure(error.message));
    }
}

function* getChatBotCategoria(data: any): any {
    const idCategoria = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/categoria',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                idCategoria
            }
            
        }).then( res => res.data)
        
    }

    try {
        yield put(getChatBotCategoriaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaFailure(error.message));
    }
}

function* getChatBotCategoriaKeyEmUso(data: any): any {
    const chave = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/categoria/chave-em-uso',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                ...chave
            }
            
        }).then( res => res.data)
    }

    try {
        yield put(getChatBotCategoriaKeyEmUsoSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaKeyEmUsoFailure(error.response));
    }
}

function* getChatBotCategoriaList(data: any): any {
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get('chatbot/categoria/listar',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then( res => res.data)
    }

    try {
        yield put(getChatBotCategoriaListSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getChatBotCategoriaListFailure(error.response));
    }
}

export {
    getChatBotCategoria,
    getChatBotCategoriaSave,
    getChatBotCategoriaUpdate,
    getChatBotCategoriaDelete,
    getChatBotCategoriaTable,
    getChatBotCategoriaKeyEmUso,
    getChatBotCategoriaList,
    getChatBotCategoriaDiagrama
}