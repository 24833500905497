import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { deleteChatBotLinkAuxiliarFailure, deleteChatBotLinkAuxiliarSuccess } from "./action";

function* deleteChatBotLinkAuxiliar(data: any): any {
    const idNivel = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.delete('chatbot/link-auxiliar', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: {
                idNivel
            }
            
        }).then( res => res.data)
        
    }

    try {
        yield put(deleteChatBotLinkAuxiliarSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(deleteChatBotLinkAuxiliarFailure(error.message));
    }
}

export {
    deleteChatBotLinkAuxiliar
}