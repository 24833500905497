import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import "dayjs/locale/pt-br";

import { MunicipioObj } from "../../store/ducks/municipios/types";
import ListaGrupoComponent from "../ListaGrupoComponent";
import ListaCluster from "../ListaClusterComponent";
import ListaSubcluster from "../ListaSubclusterComponent";
import {
  FormControl,
  FormHelperText,
} from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};



const ModalCadastroMunicipio = (props: any) => {
  const [municipio, setMunicipio] = useState<MunicipioObj>({});
  const [grupoError, setGrupoErro] = useState(false);
  const [clusteError, setClusterErro] = useState(false);
  const [subClusterError, setSubClusterErro] = useState(false);
  const [inativaGravar, setInativaGravar] = useState(true);

  useEffect(() => {
    if (props.municipiosData) {
      handleRender(props.municipiosData);
    }
  }, [props.municipiosData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (!grupoError && !clusteError && !subClusterError) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [grupoError, clusteError, subClusterError]);

  const handleRender = (municipiosData: any) => {
    if (municipiosData) {
      setMunicipio({
        ...municipiosData,
      });
    }
  };

  const handleClose = () => {
    setInativaGravar(false);
    setMunicipio({});

    props.closeModal();
  };

  const handlePersist = (e: any) => {
    e.preventDefault();
    let postObj = {
      idMunicipio: municipio.idMunicipio,
      idGrupo: municipio.grupo?.idGrupo,
      idCluster: municipio.cluster?.idCluster,
      idSubCluster: municipio.subcluster?.idSubcluster
    }
    props.persistMunicipio(postObj);
  };

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Relacionar Municípios
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled
                sx={{ width: "100%" }}
                value={municipio.nmMunicipio}
                label="Municipio"
                inputProps={{ maxLength: 200 }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled
                sx={{ width: "100%" }}
                value={municipio.estado?.dsEstado}
                label="Estado"
                inputProps={{ maxLength: 200 }}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <ListaGrupoComponent
                  multiple={false}
                  grupos={municipio.grupo}
                  onChangeGrupos={(value: any) => {
                    setGrupoErro(value.length === 0);
                    setMunicipio({
                      ...municipio,
                      grupo: value[0] ? value[0] : {}
                    })
                  }}
                />
                {grupoError ? (
                  <FormHelperText error={grupoError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <ListaCluster
                  multiple={false}
                  clusters={municipio.cluster}
                  onChangeClusters={(value: any) => {
                    setClusterErro(value.length === 0);
                    setMunicipio({
                      ...municipio,
                      cluster: value[0] ? value[0] : {}
                    })
                  }}
                />
                {clusteError ? (
                  <FormHelperText error={clusteError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <ListaSubcluster
                  multiple={false}
                  subclusters={municipio.subcluster}
                  onChangeSubclusters={(value: any) => {
                    setSubClusterErro(value.length === 0);
                    setMunicipio({
                      ...municipio,
                      subcluster: value[0] ? value[0] : {}
                    })
                  }}
                />
                {subClusterError ? (
                  <FormHelperText error={subClusterError}>
                    Campo Obrigatório.
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>

          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">
              Gravar
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroMunicipio;
