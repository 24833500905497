import { action } from "typesafe-actions";
import { SegurancaNoTrabalhoTypes } from "./types";

export const getSegurancaNoTrabalhoTable = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_TABLE_REQUEST, data);
export const getSegurancaNoTrabalhoTableSuccess = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_TABLE_REQUEST_SUCCESS, { data });
export const getSegurancaNoTrabalhoTableFailure = (err: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_TABLE_REQUEST_FAILURE, { err });

export const onInativarSegurancaNoTrabalho = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_INATIVA_REQUEST, data);
export const onInativarSegurancaNoTrabalhoSuccess = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarSegurancaNoTrabalhoFailure = (err: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_INATIVA_REQUEST_FAILURE, { err });

export const onAtivarSegurancaNoTrabalho = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_ATIVA_REQUEST, data);
export const onAtivarSegurancaNoTrabalhoSuccess = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_ATIVA_REQUEST_SUCCESS, { data });
export const onAtivarSegurancaNoTrabalhoFailure = (err: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_ATIVA_REQUEST_FAILURE, { err });

export const getSegurancaNoTrabalhoData = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_DATA_REQUEST, data);
export const getSegurancaNoTrabalhoDataSuccess = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_DATA_REQUEST_SUCCESS, { data });
export const getSegurancaNoTrabalhoDataFailure = (err: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_DATA_REQUEST_FAILURE, { err });

export const onSaveSegurancaNoTrabalho = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_SAVE_REQUEST, data);
export const onSaveSegurancaNoTrabalhoSuccess = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_SAVE_REQUEST_SUCCESS, { data });
export const onSaveSegurancaNoTrabalhoFailure = (err: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_SAVE_REQUEST_FAILURE, { err });

export const onUpdateSegurancaNoTrabalho = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_UPDATE_REQUEST, data);
export const onUpdateSegurancaNoTrabalhoSuccess = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateSegurancaNoTrabalhoFailure = (err: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_UPDATE_REQUEST_FAILURE, { err });

export const onNotificacaoSegurancaNoTrabalho = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_PUSH_REQUEST, data);
export const onNotificacaoSegurancaNoTrabalhoSuccess = (data: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_PUSH_REQUEST_SUCCESS, { data });
export const onNotificacaoSegurancaNoTrabalhoFailure = (err: any) => action(SegurancaNoTrabalhoTypes.SEGURANCA_PUSH_REQUEST_FAILURE, { err });

export const clearSegurancaNoTrabalhoData = () => action(SegurancaNoTrabalhoTypes.CLEAR_SEGURANCA_DATA);