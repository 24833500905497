export enum UsuarioTypes {
  USUARIO_TABLE_REQUEST = 'usuario/USUARIO_TABLE_REQUEST',
  USUARIO_TABLE_REQUEST_SUCCESS = 'usuario/USUARIO_TABLE_RQUEST_SUCCESS',
  USUARIO_TABLE_REQUEST_FAILURE = 'usuario/USUARIO_TABLE_REQUEST_FAILURE',
  USUARIO_ATIVAR = 'usuario/USUARIO_ATIVAR',
  USUARIO_ATIVAR_SUCCESS = 'usuario/USUARIO_ATIVAR_SUCCESS',
  USUARIO_ATIVAR_FAILURE = 'usuario/USUARIO_ATIVAR_FAILURE',
  USUARIO_INATIVAR = 'usuario/USUARIO_INATIVAR',
  USUARIO_INATIVAR_SUCCESS = 'usuario/USUARIO_INATIVAR_SUCCESS',
  USUARIO_INATIVAR_FAILURE = 'usuario/USUARIO_INATIVAR_FAILURE',
  USUARIO_INATIVAR_MASSA = 'usuario/USUARIO_INATIVAR_MASSA',
  USUARIO_INATIVAR_MASSA_SUCCESS = 'usuario/USUARIO_INATIVAR_MASSA_SUCCESS',
  USUARIO_INATIVAR_MASSA_FAILURE = 'usuario/USUARIO_INATIVAR_MASSA_FAILURE',
  USUARIO_IMPORTAR = 'usuario/USUARIO_IMPORTAR',
  USUARIO_IMPORTAR_SUCCESS = 'usuario/USUARIO_IMPORTAR_SUCCESS',
  USUARIO_IMPORTAR_FAILURE = 'usuario/USUARIO_IMPORTAR_FAILURE',
  USUARIO_IMPORTAR_T5 = 'usuario/USUARIO_IMPORTAR_T5',
  USUARIO_IMPORTAR_T5_SUCCESS = 'usuario/USUARIO_IMPORTAR_T5_SUCCESS',
  USUARIO_IMPORTAR_T5_FAILURE = 'usuario/USUARIO_IMPORTAR_T5_FAILURE',
  USUARIO_RESETAR_SENHA = 'usuario/USUARIO_RESETAR_SENHA',
  USUARIO_RESETAR_SENHA_SUCCESS = 'usuario/USUARIO_RESETAR_SENHA_SUCCESS',
  USUARIO_RESETAR_SENHA_FAILURE = 'usuario/USUARIO_RESETAR_SENHA_FAILURE',
  USUARIO_RESETAR_SENHA_MASSA = 'usuario/USUARIO_RESETAR_SENHA_MASSA',
  USUARIO_RESETAR_SENHA_MASSA_SUCCESS = 'usuario/USUARIO_RESETAR_SENHA_MASSA_SUCCESS',
  USUARIO_RESETAR_SENHA_MASSA_FAILURE = 'usuario/USUARIO_RESETAR_SENHA_MASSA_FAILURE',
  USUARIO_DATA = 'usuario/USUARIO_DATA',
  CLEAR_USUARIO_DATA = 'usuario/CLEAR_USUARIO_DATA',
  USUARIO_DATA_SUCCESS = 'usuario/USUARIO_DATA_SUCCESS',
  USUARIO_DATA_FAILURE = 'usuario/USUARIO_DATA_FAILURE',
  USUARIO_SAVE = 'usuario/USUARIO_SAVE',
  USUARIO_SAVE_SUCCESS = 'usuario/USUARIO_SAVE_SUCCESS',
  USUARIO_SAVE_FAILURE = 'usuario/USUARIO_SAVE_FAILURE',
  USUARIO_UPDATE = 'usuario/USUARIO_UPDATE',
  USUARIO_UPDATE_SUCCESS = 'usuario/USUARIO_UPDATE_SUCCESS',
  USUARIO_UPDATE_FAILURE = 'usuario/USUARIO_UPDATE_FAILURE',
};


export interface UsuarioObj {
  cpf: string,
  emailPessoaFisica: string,
  loginNet: string,
  nmPessoaFisica: string,
  tipoProfissional: string,
  senha: string,
  idEstado: string | number,
  idMunicipio: string | number,
  idUnidadeNegocio: string | number,
  idPerfil: string | number,
  idRegional: string | number,
  idGrupo: string | number,
  idCluster: string | number,
  idSubcluster: string | number,
  idUsuario?: number,
};

export interface UsuarioTableObj {
  id: number,
  idUsuario: number,
  status: number,
  nmUsuario: string,
  cpf: string,
  nmPerfil: string,
  emaiUsuario: string
}

export interface UsuarioTable {
  [x: string]: any
  content: Array<Object>,
  totalPages: number,
  totalElements: number,
  size: number,
  number: number,
  numberOfElements: number
}

export interface UsuarioPage {
  readonly usuario: any | null,
  readonly table: UsuarioTable | null,
  readonly inativacaoMassa: {
    file: any,
    success: boolean
  } | null,
  readonly importacao: {
    file: any,
    success: boolean
  } | null,
  readonly resetSenhaMassa: {
    file: any,
    success: boolean
  } | null,
}

export interface UsuarioState {
  readonly data: UsuarioPage,
  readonly loading: boolean,
  readonly loadingTable: boolean,
  readonly loadingPersis: boolean,
  readonly error: boolean,
  readonly errorMessage: string | null,
  readonly loadingInativacao: boolean,
}