import React, { useEffect, useState } from "react";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";

import * as ChatBotActions from '../../../store/ducks/chatbot/action';
import * as CategoriaActions from '../../../store/ducks/chatbot/categoria/actions';
import * as NivelActions from '../../../store/ducks/chatbot/nivel/actions';

import { ApplicationState } from "../../../store";
import { ChatBotCategoriaObjTable } from "../../../store/ducks/chatbot/categoria/types";
import { ChatBotNivelObjTable } from "../../../store/ducks/chatbot/nivel/types";

import { Button, Divider, Typography } from "@mui/material";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import "./style.css";
import ChatbotDiagram from "../../../Components/ChatbptDiagramPDF/ChatbotDiagram";

function ChatbotDiagrama({ chatbot, categoria, getChatBotCategoriaDiagrama }: any) {

    const [diagram, setDiagram] = useState<any>([]);

    const diagramRef = React.useRef<HTMLElement>(null);

    useEffect(() => {
        getChatBotCategoriaDiagrama();
    }, [])

    const reactToPrintContent = React.useCallback(() => {
        return diagramRef.current;
    }, [diagramRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.

        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

        // Good
        return (
            <Button>DOWNLOAD</Button>
        ); // eslint-disable-line max-len
    }, []);

    useEffect(() => {
        if (categoria.data.diagrama != null) {
            let arrDiagram: any[] = [];
            categoria.data.diagrama.forEach((item: ChatBotCategoriaObjTable) => {
                arrDiagram.push({
                    id: item.id,
                    label: `${item.key} - ${item.titulo}`,
                    children: parseObjToDiagram(item.subRows)
                })
            });
            setDiagram(arrDiagram);
        }
    }, [categoria.data]);

    const parseObjToDiagram = (object: Array<ChatBotNivelObjTable>) => {
        let arrDiagram: any[] = [];
        if (object != null && object.length > 0) {
            object.forEach(item => {
                arrDiagram.push({
                    id: item.id,
                    label: `${item.key} - ${item.titulo}`,
                    children: parseObjToDiagram(item.subRows)
                })
            });
        };
        return arrDiagram;
    }

    const handleDownloadPdf = async () => {
        const element = diagramRef.current;
        if (element != null) {
            const canvas = await returnCanvas() as HTMLCanvasElement;

            if (canvas != null) {
                const data = canvas.toDataURL('image/png');

                const pdf = new jsPDF({orientation: "landscape", format: 'b0'});
                const imgProperties = pdf.getImageProperties(data);
                console.log('img height', imgProperties.height);
                console.log('img width', imgProperties.width);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                console.log('pdf width', pdfWidth);
                const pdfHeight =
                    (imgProperties.height * pdfWidth) / imgProperties.width;
                console.log('pdf height', pdfHeight);
                pdf.addImage(data, 'PNG', 0, (pdfHeight / 2), pdfWidth, pdfHeight);
                pdf.save('diagrama-chatbot-conectado.pdf');
            }
        }
    };

    const handleDownloadImage = async () => {
        const element = diagramRef.current;
        if (element) {
            const canvas = await returnCanvas() as HTMLCanvasElement;

            if(canvas != null) {
                const data = canvas.toDataURL('image/jpg');
                const link = document.createElement('a');
    
                if (typeof link.download === 'string') {
                    link.href = data;
                    link.download = 'diagrama-chatbot-conectado.jpg';
    
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    window.open(data);
                }
            }
        }
    };

    const returnCanvas = () => {
        if(diagramRef.current != null) {
            window.scrollTo(0,0)
            var useWidth = diagramRef.current.scrollWidth;
            return html2canvas(diagramRef.current, {
                width: useWidth,
                scrollX: useWidth
            });

        } 

        return null;
    }

    return (
        <div className="pageBox" >
            <div id="diagramBox">
                <Typography variant="h6" component="h6">
                    Diagrama de Categorias e Níves
                </Typography>
                {/* <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle="diagrama-chatbot-conectado"
                    removeAfterPrint
                    trigger={reactToPrintTrigger}
                /> */}
                <Button onClick={handleDownloadImage}>DOWNLOAD IMAGEM</Button>
                <Button onClick={handleDownloadPdf}>DOWNLOAD PDF</Button>
                <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
                <div className="diagram-container">
                    <ChatbotDiagram ref={diagramRef} diagram={diagram} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    categoria: state.categoria,
    nivel: state.nivel,
    chatbot: state.chatbot,
    utils: state.utils
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ ...CategoriaActions, ...ChatBotActions, ...NivelActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotDiagrama);
