import { action } from "typesafe-actions";
import { JustificativaTypes } from "./types";

export const getJustificativaTable = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_TABLE_REQUEST, data);
export const getJustificativaTableSuccess = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_TABLE_REQUEST_SUCCESS, { data });
export const getJustificativaTableFailure = (err: any) => action(JustificativaTypes.JUSTIFICATIVA_TABLE_REQUEST_FAILURE, { err });

export const getJustificativaTipoList = () => action(JustificativaTypes.JUSTIFICATIVA_TIPO_REQUEST);
export const getJustificativaTipoListSuccess = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_TIPO_REQUEST_SUCCESS, { data });
export const getJustificativaTipoListFailure = (err: any) => action(JustificativaTypes.JUSTIFICATIVA_TIPO_REQUEST_FAILURE, { err });

export const onInativarJustificativa = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_INATIVA_REQUEST, data);
export const onInativarJustificativaSuccess = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarJustificativaFailure = (err: any) => action(JustificativaTypes.JUSTIFICATIVA_INATIVA_REQUEST_FAILURE, { err });

export const getJustificativaData = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_DATA_REQUEST, data);
export const getJustificativaDataSuccess = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_DATA_REQUEST_SUCCESS, { data });
export const getJustificativaDataFailure = (err: any) => action(JustificativaTypes.JUSTIFICATIVA_DATA_REQUEST_FAILURE, { err });

export const onSaveJustificativa = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_SAVE_REQUEST, data);
export const onSaveJustificativaSuccess = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_SAVE_REQUEST_SUCCESS, { data });
export const onSaveJustificativaFailure = (err: any) => action(JustificativaTypes.JUSTIFICATIVA_SAVE_REQUEST_FAILURE, { err });

export const onUpdateJustificativa = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_UPDATE_REQUEST, data);
export const onUpdateJustificativaSuccess = (data: any) => action(JustificativaTypes.JUSTIFICATIVA_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateJustificativaFailure = (err: any) => action(JustificativaTypes.JUSTIFICATIVA_UPDATE_REQUEST_FAILURE, { err });

export const clearJustificativaData = () => action(JustificativaTypes.CLEAR_JUSTIFICATIVA_DATA);