import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

import * as UtilsActions from "../../store/ducks/utils/actions";
import { ApplicationState } from "../../store";

function ListaGestorComponent({
  utils,
  gestores,
  onChangeGestores,
  getGestoresList,
  error,
}: any) {
  const [gestoresList, setGestoresList] = React.useState<any>([]);
  const [gestoresSelecionados, setGestoresSelecionados] = React.useState<any>(
    []
  );
  const [selectAll, setSelectAll] = React.useState(false);
  const [lazyItems, setLazyItems] = React.useState<any>([]);
  const [lazyLoading, setLazyLoading] = React.useState(false);

  const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(
    null
  );

  React.useEffect(() => {
    getGestoresList();
  }, []);

  React.useEffect(() => {
    setGestoresList(utils.data.gestores);
    if (utils.data.gestores.length > 0) {
      setSelectAll(gestores.length == utils.data.gestores.length);
    }
  }, [utils.data.gestores]);

  React.useEffect(() => {
    if (gestores) {
      setGestoresSelecionados(gestores.map((item: any) => item.idUsuario));
    }
  }, [gestores]);

  const onLazyLoad = (event: any) => {
    setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, 300);
  };

  return (
    <MultiSelect
      style={{
        width: "100%",
        height: "100%",
      }}
      value={gestoresSelecionados}
      options={gestoresList}
      display="chip"
      onChange={(e) => {
        if (e.value) {
          setGestoresSelecionados(e.value);
          setSelectAll(e.value.length === gestoresList.length);
          onChangeGestores(
            gestoresList.filter((esta: any) => e.value.includes(esta.idUsuario))
          );
        } else {
          setGestoresSelecionados([]);
          setSelectAll(false);
          onChangeGestores([]);
        }
      }}
      selectAll={selectAll}
      optionLabel="pessoaFisica.nmPessoaFisica"
      optionValue="idUsuario"
      placeholder="Selecione os Gestores"
      filter
      showClear={true}
      className={error ? "p-multiselect-error" : ""}
      maxSelectedLabels={3}
      selectedItemsLabel={`${
        gestoresSelecionados ? gestoresSelecionados.length : 0
      } gestores selecionados`}
      emptyFilterMessage="Não encontrado"
      filterPlaceholder="Busque por um gestores"
      resetFilterOnHide={true}
      virtualScrollerOptions={{
        lazy: true,
        onLazyLoad: onLazyLoad,
        itemSize: 43,
        showLoader: true,
        loading: lazyLoading,
        delay: 1,
        loadingTemplate: (options) => {
          return (
            <div
              className="flex align-items-center p-2"
              style={{ height: "43px" }}
            >
              <Skeleton width={options.even ? "70%" : "60%"} height="1.5rem" />
            </div>
          );
        },
      }}
    />
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaGestorComponent);
