import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'

import { UrlExternaObj } from "../../store/ducks/pesquisaExt/types";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaMunicipioComponent from "../ListaMunicipioComponent";
import { FormHelperText } from "@mui/material";


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const defaultUrlExterna: UrlExternaObj = {
  nmUrlExterna: '',
  dsUrlExterna: '',
  linkUrlExterna: '',
  dataInicio: null,
  dataFim: null,
  publicarMobile: 0,
  urlMunicipios: [],
  urlPerfis: []
};

const ModalCadastroPesquisaExt = (props: any) => {

  const [isEdicao, setIsEdicao] = useState(props.isEdicao);
  const [urlExt, setUrlExt] = useState(defaultUrlExterna);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [nmUrlExtErro, setNmUrlExtErro] = useState(false);
  const [dsUrlExtErro, setDsUrlExtErro] = useState(false);
  const [linkExternoErro, setLinkExternoErro] = useState(false);
  const [dtIniUrlExtErro, setDtIniUrlExtErro] = useState(false);
  const [dtFimUrlExtErro, setDtFimUrlExtErro] = useState(false);
  const [perfilErro, setPerfilErro] = useState(false);
  const [municipioErro, setMunicipioErro] = useState(false);

  useEffect(() => {
    if (props.urlExtData) {
      setIsEdicao(true);
      setUrlExt({
        ...props.urlExtData
      });
    }
  }, [props.urlExtData]);

  useEffect(() => {
    if (!nmUrlExtErro && !dsUrlExtErro && !dtIniUrlExtErro && !dtFimUrlExtErro && !linkExternoErro && !perfilErro && !municipioErro
      && urlExt.nmUrlExterna !== '' && urlExt.dsUrlExterna !== '' && urlExt.dataInicio !== null && urlExt.dataFim !== null
      && urlExt.linkUrlExterna !== '' && urlExt.urlMunicipios.length !== 0 && urlExt.urlPerfis.length !== 0) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [urlExt, nmUrlExtErro, dsUrlExtErro, dtIniUrlExtErro, dtFimUrlExtErro, linkExternoErro, perfilErro, municipioErro])


  const handleClose = () => {
    setIsEdicao(false);
    setUrlExt(defaultUrlExterna);
    setInativaGravar(true);
    setNmUrlExtErro(false);
    setDsUrlExtErro(false);
    setDtIniUrlExtErro(false);
    setDtFimUrlExtErro(false);
    setLinkExternoErro(false);
    setPerfilErro(false);
    setMunicipioErro(false);

    props.closeModal();
  }

  const handlePersist = (e: any) => {
    e.preventDefault();

    props.persist(isEdicao, urlExt);
  }

  return (
    <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if (res !== 'backdropClick') {
        handleClose()
      }
    }}
    >
      <Box sx={style} >
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? 'Editar de Pesquisa Externa' : 'Cadastro de novo Pesquisa Externa'}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
            <Grid item xs={3} >
              <TextField
                required
                sx={{ width: '100%' }}
                value={urlExt.nmUrlExterna}
                label="Nome da Pesquisa Externa"
                error={nmUrlExtErro}
                helperText={nmUrlExtErro ? "Campo Obrigatório." : ''}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setNmUrlExtErro(e.target.value === "");
                  setUrlExt({
                    ...urlExt,
                    nmUrlExterna: e.target.value
                  })
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                sx={{ width: '100%' }}
                value={urlExt.dsUrlExterna}
                label="Descrição da Pesquisa Externa"
                error={dsUrlExtErro}
                helperText={dsUrlExtErro ? "Campo Obrigatório." : ''}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDsUrlExtErro(e.target.value === "");
                  setUrlExt({
                    ...urlExt,
                    dsUrlExterna: e.target.value
                  })
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                required
                sx={{ width: '100%' }}
                value={urlExt.linkUrlExterna}
                label="Link da Pesquisa Externa"
                error={linkExternoErro}
                helperText={linkExternoErro ? "Campo Obrigatório." : ''}
                onChange={(e) => {
                  setLinkExternoErro(e.target.value === "");
                  setUrlExt({
                    ...urlExt,
                    linkUrlExterna: e.target.value
                  })
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }} >
                <ListaPerfilComponent
                  error={perfilErro}
                  perfis={urlExt.urlPerfis}
                  onChangePerfis={(value: any) => {
                    setPerfilErro(value.length === 0)
                    setUrlExt({
                      ...urlExt,
                      urlPerfis: value
                    })
                  }}
                />
                {perfilErro ? <FormHelperText error={perfilErro}>Campo Obrigatório.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }} >
                <ListaMunicipioComponent
                  error={municipioErro}
                  municipios={urlExt.urlMunicipios}
                  onChangeMunicipios={(value: any) => {
                    setMunicipioErro(value.length === 0)
                    setUrlExt({
                      ...urlExt,
                      urlMunicipios: value
                    })
                  }}
                />
                {municipioErro ? <FormHelperText error={municipioErro}>Campo Obrigatório.</FormHelperText> : null}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <DesktopDatePicker
                  onError={(e) => {
                    if (e) {
                      setDtIniUrlExtErro(true);
                    } else {
                      setDtIniUrlExtErro(false);
                    }
                  }}
                  label="Data de Início"
                  value={urlExt.dataInicio}
                  minDate={dayjs('1900-01-01')}
                  onChange={(newValue) => {
                    setDtIniUrlExtErro(false);
                    setUrlExt({
                      ...urlExt,
                      dataInicio: newValue?.format()
                    })
                  }}
                  renderInput={(params) => <TextField {...params} error={dtIniUrlExtErro} helperText={dtIniUrlExtErro ? "Campo Obrigatório." : ''} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <DesktopDatePicker
                  onError={(e) => {
                    if (e) {
                      setDtFimUrlExtErro(true);
                    } else {
                      setDtFimUrlExtErro(false);
                    }
                  }}
                  label="Data Final"
                  value={urlExt.dataFim}
                  minDate={dayjs('1900-01-01')}
                  onChange={(newValue) => {
                    setDtFimUrlExtErro(false);
                    setUrlExt({
                      ...urlExt,
                      dataFim: newValue?.format()
                    })
                  }}
                  renderInput={(params) => <TextField {...params} error={dtFimUrlExtErro} helperText={dtFimUrlExtErro ? "Campo Obrigatório." : ''} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
              <FormControl>
                <FormLabel>Publicar no App</FormLabel>
                <RadioGroup
                  row
                  value={urlExt.publicarMobile}
                  onChange={(e) => {
                    setUrlExt({
                      ...urlExt,
                      publicarMobile: parseInt(e.target.value)
                    })
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Sim" />
                  <FormControlLabel value={0} control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
            {
              isEdicao ?
                <Button variant="contained" onClick={() => {
                  props.enviaNotificacao(urlExt.idUrlExterna)
                }}>Enviar Notificação</Button> : null
            }
          </div>
        </form>
      </Box>

    </Modal>
  )

};

export default ModalCadastroPesquisaExt;