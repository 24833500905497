import { api } from "../../../services";
import { call, put } from "redux-saga/effects";
import { 
    getTrilhaDataFailure,
    getTrilhaDataSuccess,
    getTrilhaTableFailure,
    getTrilhaTableSuccess,
    onAtivarTrilhaFailure,
    onAtivarTrilhaSuccess,
    onInativarTrilhaFailure,
    onInativarTrilhaSuccess,
    onSaveTrilhaFailure, 
    onSaveTrilhaSuccess,
    onUpdateTrilhaFailure,
    onUpdateTrilhaSuccess,
    onNotificacaoTrilhaFailure,
    onNotificacaoTrilhaSuccess,
} from "./actions";
import { refreshToken } from "../auth/actions";

function* getTrilhaTable(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('trilha/listAllTrilhaTable', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getTrilhaTableSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getTrilhaTableFailure(error.message));
    }
};


function* onInativarTrilha(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('trilha/desativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onInativarTrilhaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onInativarTrilhaFailure(error.message));
    }
};

function* onAtivarTrilha(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('trilha/ativar', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onAtivarTrilhaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onAtivarTrilhaFailure(error.message));
    }
};

function* getTrilhaData(data: any): any {
    const idTrilha = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.get(`trilha/findById?idTrilha=${idTrilha}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(getTrilhaDataSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(getTrilhaDataFailure(error.message));
    }
};

function* onSaveTrilha(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.put('trilha/save', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onSaveTrilhaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onSaveTrilhaFailure(error.message));
    }
};

function* onUpdateTrilha(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post('trilha/update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onUpdateTrilhaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onUpdateTrilhaFailure(error.message));
    }
};

function* onNotificacaoTrilha(data: any): any {
    const idTrilha = data.payload;
    const token = localStorage.getItem('accessToken');
    
    const doRequest = () => {
        return api.post(`trilha/notificaTrilha?idTrilha=${idTrilha}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            
        }).then( res => res.data)
        
    }

    try {

        yield put(onNotificacaoTrilhaSuccess(yield call(doRequest)));
    } catch (error: any) {
        yield put(onNotificacaoTrilhaFailure(error.message));
    }
};

export {
    getTrilhaData,
    getTrilhaTable,
    onAtivarTrilha,
    onInativarTrilha,
    onSaveTrilha,
    onUpdateTrilha,
    onNotificacaoTrilha,
}
