import { Reducer } from "@reduxjs/toolkit";
import { SubclusterState, SubclusterTypes } from "./types";

const INITIAL_STATE: SubclusterState = {
    data: {
        table: null,
        subcluster: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false,
};

const reducer: Reducer<SubclusterState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SubclusterTypes.SUBCLUSTER_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case SubclusterTypes.SUBCLUSTER_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case SubclusterTypes.SUBCLUSTER_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };


        case SubclusterTypes.SUBCLUSTER_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case SubclusterTypes.SUBCLUSTER_INATIVA_REQUEST_SUCCESS:
            alert('Ação realizada com sucesso!');
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao: false,
            };
        case SubclusterTypes.SUBCLUSTER_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err,
            };



        case SubclusterTypes.SUBCLUSTER_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case SubclusterTypes.SUBCLUSTER_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    subcluster: action.payload.data
                }
            };
        case SubclusterTypes.SUBCLUSTER_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    subcluster: null
                }
            };


        case SubclusterTypes.CLEAR_SUBCLUSTER_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    subcluster: null
                }
            };

        case SubclusterTypes.SUBCLUSTER_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case SubclusterTypes.SUBCLUSTER_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Subcluster realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    subcluster: action.payload.data
                }
            };
        case SubclusterTypes.SUBCLUSTER_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };

        case SubclusterTypes.SUBCLUSTER_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true,
            };
        case SubclusterTypes.SUBCLUSTER_UPDATE_REQUEST_SUCCESS:
            alert('Edição de Subcluster realizada com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    subcluster: action.payload.data
                }
            };
        case SubclusterTypes.SUBCLUSTER_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err,
            };


        default:
            return {
                ...state,
                error: false,
                loading: false,
                errorMessage: null
            };
    }
};

export default reducer;