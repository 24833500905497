import { Reducer } from "@reduxjs/toolkit";
import { RelatorioTypes, RelatorioState } from "./types";

const INITIAL_STATE: RelatorioState = {
    data: null,
    loading: false,
    error: false,
    errorMessage: null
}

const reducer: Reducer<RelatorioState> = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case RelatorioTypes.EXTRAIR_RELATORIO_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case RelatorioTypes.EXTRAIR_RELATORIO_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                data: action.payload.data
            };
        case RelatorioTypes.EXTRAIR_RELATORIO_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: null
            };
        default:
            return {
    ...state,
    error: false,
    loading: false,
    errorMessage: null
};
    }
}

export default reducer;