import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import * as SaudacaoActions from '../../../store/ducks/chatbot/saudacao/actions';
import * as ChatBotActions from '../../../store/ducks/chatbot/action'

import AcaoMenu from "../../../Components/AcaoMenu";
import { ApplicationState } from "../../../store";
import Loading from "../../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../../enums/EntidadesAcoesConfirmacao";
import { ChatBotSaudacaoTable } from "../../../store/ducks/chatbot/saudacao/types";
import ChatbotSaudacaoTableComponent from "../../../Components/TableChatbotSaudacao";
import ModalCadastroSaudacao from "../../../Components/ModalCadastroChatbotSaudacao";

function ChatBotSaudacao({
    chatbot,
    saudacao,
    getChatBotSaudacaoTable,
    getChatBotSaudacao,
    postChatBotSaudacaoSave,
    putChatBotSaudacaoUpdate,
    deleteChatBotSaudacao,
    clearChatBotSaudacao,
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState({});
    const [table, setTable] = useState<ChatBotSaudacaoTable>();
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

    useEffect(() => {
        if(saudacao.error || chatbot.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + (saudacao.errorMessage ?? chatbot.errorMessage));
        }
    }, [saudacao.error, chatbot.error]);

    useEffect(() => {
        if(!saudacao.loadingPersis) {
            handleCloseAndReload()
        }
    }, [saudacao.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen){
            getChatBotSaudacaoTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(saudacao.data.table != null){
            setTable(saudacao.data.table);
        }
    }, [saudacao.data]);

    useEffect(() => {
        if(!saudacao.loadingInativacao){
            getChatBotSaudacaoTable(body);
        }
    }, [saudacao.loadingInativacao]);

    const getChatBotSaudacaoTableData = () => {
        getChatBotSaudacaoTable(body);
    }

    const handleCadastro = () => {
        clearChatBotSaudacao();
        setModalCadastroOpen(true)
    }

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione uma saudação que gostaria de alterar');
        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas uma saudação para alteração!');
        } else {
            getChatBotSaudacao(idsSelecionado[0]);
            setModalCadastroOpen(true);
        }
        
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione as saudações que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.CHATBOT_SAUDACAO_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                deleteChatBotSaudacao(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersitChatBotSaudacao = (edicao: boolean, saudacao: any) => {
        if(edicao) {
            putChatBotSaudacaoUpdate(saudacao);
        } else {
            postChatBotSaudacaoSave(saudacao);
        }
    }

    const handleCloseAndReload = () => {
        clearChatBotSaudacao();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
        if(idsSelecionado[0] && modalCadastroOpen){
            getChatBotSaudacao(idsSelecionado[0]);
        }
    }

    return (
        <div className="pageBox">
            <Loading loading={saudacao.loading || saudacao.loadingPersis} />
            <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
            <ModalCadastroSaudacao 
                modalCadastroOpen={modalCadastroOpen} 
                closeModal={() => handleCloseAndReload()}
                saudacaoData={saudacao.data.saudacao} 
                persist={handlePersitChatBotSaudacao}
            />
            <div className="menuLateral">
                <Typography variant="h6" noWrap component="div" sx={{
                    width: '100%'
                }} >
                    Ações
                    <AcaoMenu
                        onCadastro={handleCadastro} 
                        onEditar={handleEdicao} 
                        onInativar={handleInativacao} 
                    />
                </Typography>
            </div>
            <div className="table">
                <ChatbotSaudacaoTableComponent 
                    loading={saudacao.loadingTable} 
                    data={table} 
                    body={body}
                    onChange={changeTable} 
                    selection={idsSelecionado}
                    idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
            </div>
        </div>
    )
};


const mapStateToProps = (state: ApplicationState) => ({
    saudacao: state.saudacao,
    chatbot: state.chatbot,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...SaudacaoActions, ...ChatBotActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotSaudacao);