import { action } from "typesafe-actions";
import { SubclusterTypes } from "./types";

export const getSubclusterTable = (data: any) => action(SubclusterTypes.SUBCLUSTER_TABLE_REQUEST, data);
export const getSubclusterTableSuccess = (data: any) => action(SubclusterTypes.SUBCLUSTER_TABLE_REQUEST_SUCCESS, { data });
export const getSubclusterTableFailure = (err: any) => action(SubclusterTypes.SUBCLUSTER_TABLE_REQUEST_FAILURE, { err });

export const onInativarSubcluster = (data: any) => action(SubclusterTypes.SUBCLUSTER_INATIVA_REQUEST, data);
export const onInativarSubclusterSuccess = (data: any) => action(SubclusterTypes.SUBCLUSTER_INATIVA_REQUEST_SUCCESS, { data });
export const onInativarSubclusterFailure = (err: any) => action(SubclusterTypes.SUBCLUSTER_INATIVA_REQUEST_FAILURE, { err });

export const getSubclusterData = (data: any) => action(SubclusterTypes.SUBCLUSTER_DATA_REQUEST, data);
export const getSubclusterDataSuccess = (data: any) => action(SubclusterTypes.SUBCLUSTER_DATA_REQUEST_SUCCESS, { data });
export const getSubclusterDataFailure = (err: any) => action(SubclusterTypes.SUBCLUSTER_DATA_REQUEST_FAILURE, { err });

export const onSaveSubcluster = (data: any) => action(SubclusterTypes.SUBCLUSTER_SAVE_REQUEST, data);
export const onSaveSubclusterSuccess = (data: any) => action(SubclusterTypes.SUBCLUSTER_SAVE_REQUEST_SUCCESS, { data });
export const onSaveSubclusterFailure = (err: any) => action(SubclusterTypes.SUBCLUSTER_SAVE_REQUEST_FAILURE, { err });

export const onUpdateSubcluster = (data: any) => action(SubclusterTypes.SUBCLUSTER_UPDATE_REQUEST, data);
export const onUpdateSubclusterSuccess = (data: any) => action(SubclusterTypes.SUBCLUSTER_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateSubclusterFailure = (err: any) => action(SubclusterTypes.SUBCLUSTER_UPDATE_REQUEST_FAILURE, { err });

export const clearSubclusterData = () => action(SubclusterTypes.CLEAR_SUBCLUSTER_DATA);