import { ChatBotNivelObjTable } from "../nivel/types"
import { ChatBotLinkAuxiliarDTO } from "../types"








export enum ChatBotCategoriaTypes {
    CHATBOT_CATEGORIA_TABLE_REQ = 'chatbot/categoria/CHATBOT_CATEGORIA_TABLE_REQ',
    CHATBOT_CATEGORIA_TABLE_REQ_SUCCESS = 'chatbot/categoria/CHATBOT_CATEGORIA_TABLE_REQ_SUCCESS',
    CHATBOT_CATEGORIA_TABLE_REQ_FAILURE = 'chatbot/categoria/CHATBOT_CATEGORIA_TABLE_REQ_FAILURE',

    CHATBOT_CATEGORIA_REQ = 'chatbot/categoria/CHATBOT_CATEGORIA_REQ',
    CHATBOT_CATEGORIA_REQ_SUCCESS = 'chatbot/categoria/CHATBOT_CATEGORIA_REQ_SUCCESS',
    CHATBOT_CATEGORIA_REQ_FAILURE = 'chatbot/categoria/CHATBOT_CATEGORIA_REQ_FAILURE',

    CHATBOT_CATEGORIA_SAVE_REQ = 'chatbot/categoria/CHATBOT_CATEGORIA_SAVE_REQ',
    CHATBOT_CATEGORIA_SAVE_REQ_SUCCESS = 'chatbot/categoria/CHATBOT_CATEGORIA_SAVE_REQ_SUCCESS',
    CHATBOT_CATEGORIA_SAVE_REQ_FAILURE = 'chatbot/categoria/CHATBOT_CATEGORIA_SAVE_REQ_FAILURE',

    CHATBOT_CATEGORIA_UPDATE_REQ = 'chatbot/categoria/CHATBOT_CATEGORIA_UPDATE_REQ',
    CHATBOT_CATEGORIA_UPDATE_REQ_SUCCESS = 'chatbot/categoria/CHATBOT_CATEGORIA_UPDATE_REQ_SUCCESS',
    CHATBOT_CATEGORIA_UPDATE_REQ_FAILURE = 'chatbot/categoria/CHATBOT_CATEGORIA_UPDATE_REQ_FAILURE',

    CHATBOT_CATEGORIA_DELETE_REQ = 'chatbot/categoria/CHATBOT_CATEGORIA_DELETE_REQ',
    CHATBOT_CATEGORIA_DELETE_REQ_SUCCESS = 'chatbot/categoria/CHATBOT_CATEGORIA_DELETE_REQ_SUCCESS',
    CHATBOT_CATEGORIA_DELETE_REQ_FAILURE = 'chatbot/categoria/CHATBOT_CATEGORIA_DELETE_REQ_FAILURE',

    CHATBOT_CATEGORIA_KEY_REQ = 'chatbot/categoria/CHATBOT_CATEGORIA_KEY_REQ',
    CHATBOT_CATEGORIA_KEY_REQ_SUCCESS = 'chatbot/categoria/CHATBOT_CATEGORIA_KEY_REQ_SUCCESS',
    CHATBOT_CATEGORIA_KEY_REQ_FAILURE = 'chatbot/categoria/CHATBOT_CATEGORIA_KEY_REQ_FAILURE',

    CHATBOT_CATEGORIA_LIST_REQ = 'chatbot/categoria/CHATBOT_CATEGORIA_LIST_REQ',
    CHATBOT_CATEGORIA_LIST_REQ_SUCCESS = 'chatbot/categoria/CHATBOT_CATEGORIA_LIST_REQ_SUCCESS',
    CHATBOT_CATEGORIA_LIST_REQ_FAILURE = 'chatbot/categoria/CHATBOT_CATEGORIA_LIST_REQ_FAILURE',


    CLEAR_CHATBOT_CATEGORIA = 'chatbot/categoria/CLEAR_CHATBOT_CATEGORIA',
    CHATBOT_CATEGORIA_DIAGRAMA_REQ = "chatbot/categoria/CHATBOT_CATEGORIA_DIAGRAMA_REQ",
    CHATBOT_CATEGORIA_DIAGRAMA_REQ_SUCCESS = "chatbot/categoria/CHATBOT_CATEGORIA_DIAGRAMA_REQ_SUCCESS",
    CHATBOT_CATEGORIA_DIAGRAMA_REQ_FAILURE = "chatbot/categoria/CHATBOT_CATEGORIA_DIAGRAMA_REQ_FAILURE"
}

export interface ChatBotCategoriaList {
    idCategoria: number,
    titulo: string,
}

export interface ChatBotCategoriaObjTable {
    id: number,
    idCategoria: number,
    titulo: string,
    contexto: string,
    key: number,
    subRows: Array<ChatBotNivelObjTable>
}

export interface ChatBotCategoriaTable {
    [x: string]: any
    content: Array<ChatBotCategoriaObjTable>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface ChatBotCategoriaPage {
    readonly categoria: ChatBotCategoriaObj | null,
    readonly table: ChatBotCategoriaTable | null
    readonly lista: Array<ChatBotCategoriaList>
    readonly diagrama: Array<ChatBotCategoriaTable>
}

export interface ChatBotCategoriaObj {
    idCategoria?: number,
    titulo?: string,
    contexto?: string,
    keyCategoria?: number,
    linkConteudoContexto?: string | null,
    linksAuxiliares?: Array<ChatBotLinkAuxiliarDTO>,
    idConteudoInterno?: number | null
}

export interface ChatBotCategoriaState {
    readonly data: ChatBotCategoriaPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
    readonly errorChave: boolean,
}