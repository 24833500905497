import { Dayjs } from "dayjs"

export enum ComunicadoTypes {
    COMUNICADO_TABLE_REQUEST = 'comunicado/COMUNICADO_TABLE_REQUEST',
    COMUNICADO_TABLE_REQUEST_SUCCESS = 'comunicado/COMUNICADO_TABLE_RQUEST_SUCCESS',
    COMUNICADO_TABLE_REQUEST_FAILURE = 'comunicado/COMUNICADO_TABLE_REQUEST_FAILURE',

    COMUNICADO_ATIVA_REQUEST = 'comunicado/COMUNICADO_ATIVA_REQUEST',
    COMUNICADO_ATIVA_REQUEST_SUCCESS = 'comunicado/COMUNICADO_ATIVA_REQUEST_SUCCESS',
    COMUNICADO_ATIVA_REQUEST_FAILURE = 'comunicado/COMUNICADO_ATIVA_REQUEST_FAILURE',

    COMUNICADO_INATIVA_REQUEST = 'comunicado/COMUNICADO_INATIVA_REQUEST',
    COMUNICADO_INATIVA_REQUEST_SUCCESS = 'comunicado/COMUNICADO_INATIVA_REQUEST_SUCCESS',
    COMUNICADO_INATIVA_REQUEST_FAILURE = 'comunicado/COMUNICADO_INATIVA_REQUEST_FAILURE',
    
    CLEAR_COMUNICADO_DATA = 'comunicado/CLEAR_COMUNICADO_DATA',

    COMUNICADO_DATA_REQUEST = 'comunicado/COMUNICADO_DATA_REQUEST',
    COMUNICADO_DATA_REQUEST_SUCCESS = 'comunicado/COMUNICADO_DATA_REQUEST_SUCCESS',
    COMUNICADO_DATA_REQUEST_FAILURE = 'comunicado/COMUNICADO_DATA_REQUEST_FAILURE',

    COMUNICADO_SAVE_REQUEST = 'comunicado/COMUNICADO_SAVE_REQUEST',
    COMUNICADO_SAVE_REQUEST_SUCCESS = 'comunicado/COMUNICADO_SAVE_REQUEST_SUCCESS',
    COMUNICADO_SAVE_REQUEST_FAILURE = 'comunicado/COMUNICADO_SAVE_REQUEST_FAILURE',

    COMUNICADO_UPDATE_REQUEST = 'comunicado/COMUNICADO_UPDATE_REQUEST',
    COMUNICADO_UPDATE_REQUEST_SUCCESS = 'comunicado/COMUNICADO_UPDATE_REQUEST_SUCCESS',
    COMUNICADO_UPDATE_REQUEST_FAILURE = 'comunicado/COMUNICADO_UPDATE_REQUEST_FAILURE',

    COMUNICADO_PUSH_REQUEST = 'comunicado/COMUNICADO_PUSH_REQUEST',
    COMUNICADO_PUSH_REQUEST_SUCCESS = 'comunicado/COMUNICADO_PUSH_REQUEST_SUCCESS',
    COMUNICADO_PUSH_REQUEST_FAILURE = 'comunicado/COMUNICADO_PUSH_REQUEST_FAILURE',
}

export interface ComunicadoObjTable {
    id?: number,
    idCursoComunicado?: number,
    nmCursoComunicado?: string,
    dsCursoComunicado?: string,
    dsTempoEstimado?: string
    nmTema?: string,
    qtdConteudos?: number
}


export interface ComunicadoObj {
    idCursoComunicado?: number,
    nmCursoComunicado?: string,
    dsCursoComunicado?: string,
    dtCursoComunicadoInicio?: null | Dayjs | string,
	dtCursoComunicadoFim?: null | Dayjs | string,
    dsTempoEstimado?: string,
    tema?: any | null,
    imgCursoComunicado?: string | null,
    urlCompartilhamentoCursoComunicado?: string,
	idDatabaseStatus?: number | null,
    cursoComunicadoMuncipios?: any | null,
    cursoComunicadoPerfis?: any | null,
    cursoComunicadoConteudos?: any | null,
    cursoComunicadoAvaliacoes?: any | null,
}


export interface ComunicadoTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface ComunicadoPage {
    readonly comunicado: ComunicadoObj | null,
    readonly table: ComunicadoTable | null,
}

export interface ComunicadoState {
    readonly data: ComunicadoPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
}