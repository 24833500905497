import { Reducer } from "@reduxjs/toolkit";
import { AuthTypes, AuthState, Auth } from "./types";

const INITIAL_AUTH: Auth = {
  authorized: false,
};

const INITIAL_STATE: AuthState = {
  data: INITIAL_AUTH,
  error: false,
  errorMessage: null,
  loading: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.AUTH_LOGIN:
      return {
        ...state,
        error: false,
        errorMessage: null,
        loading: true,
        data: {
          ...state.data,
          authorized: true,
          ...action.payload.data,
        },
      };
    case AuthTypes.AUTH_LOGIN_REQUEST:
      return { ...state, loading: true, error: false };

    case AuthTypes.AUTH_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };

    case AuthTypes.AUTH_LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.err,
        data: INITIAL_AUTH,
      };

    case AuthTypes.REFRESH_TOKEN:
      return { ...state, loading: true, error: false };

    case AuthTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };

    case AuthTypes.REFRESH_TOKEN_FAILURE:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        data: INITIAL_AUTH,
      };

    case AuthTypes.AUTH_LOGOUT:
      return { ...state, loading: true, error: false };

    case AuthTypes.AUTH_LOGOUT_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        data: INITIAL_AUTH,
      };

    case AuthTypes.AUTH_LOGOUT_FAILURE:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        data: INITIAL_AUTH,
      };

    default:
      return state;
  }
};

export default reducer;
