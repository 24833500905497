import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Input from '@mui/material/Input';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FormHelperText, Icon, IconButton, InputLabel, List, ListItem, MenuItem, Select, Tooltip } from "@mui/material";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'
import { api } from "../../services";

import { T1Obj } from "../../store/ducks/t1/types";
import ListaPerfilComponent from "../ListaPerfilComponent";
import ListaMunicipioComponentTeste from "../ListaMunicipioComponentTeste";
import ListaTemaComponent from "../ListaTemaComponent";
import ListaAvaliacaoComponent from "../ListaAvaliacaoComponent";
import ListaConteudoComponent from "../ListaConteudoComponent";
import TabPanel from "../TabPanel";
import { validaNomeArquivo } from "../../services/Uteis";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const defaultT1: T1Obj = {
  nmCursoT1: '',
  dsCursoT1: '',
  dtCursoT1Inicio: null,
  dtCursoT1Fim: null,
  dsTempoEstimado: '',
  imgCursoT1: '',
  urlCompartilhamentoCursoT1: '',
  tema: [],
  cursoT1Muncipios: [],
  cursoT1Perfis: [],
  cursoT1Conteudos: [],
  cursoT1Avaliacoes: []
};

const ModalCadastroT1 = (props: any) => {

  const [isEdicao, setIsEdicao] = useState(false);
  const [t1, setT1] = useState(defaultT1);
  const [capaT1, setCapaT1] = useState('');
  const [municipios_, setMunicipios_] = useState([]);
  const [copiado, setCopiado] = useState('Copiar link de compartilhamento');
  const [conteudosOrdenados, setConteudoOrdenados] = useState([]);
  const [tab, setTab] = useState(0);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [imgT1Erro, setImgT1Erro] = useState(false);
  const [nmT1Erro, setNmT1Erro] = useState(false);
  const [dsT1Erro, setDsT1Erro] = useState(false);
  const [dtIniT1Erro, setDtIniT1Erro] = useState(false);
  const [dtFimT1Erro, setDtFimT1Erro] = useState(false);
  const [dsTempoEstimadoErro, setDsTempoEstimadoErro] = useState(false);
  const [perfilErro, setPerfilErro] = useState(false);
  const [municipioErro, setMunicipioErro] = useState(false);
  const [temaErro, setTemaErro] = useState(false);
  const [conteudoErro, setConteudoErro] = useState(false);

  useEffect(() => {
    if (props.t1Data) {
      handleMunicipioData();
      handleRender(props.t1Data);
    }
  }, [props.t1Data]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    if (!imgT1Erro && !nmT1Erro && !dsT1Erro && !dtIniT1Erro && !dtFimT1Erro && !dsTempoEstimadoErro && !perfilErro && !municipioErro && !temaErro && !conteudoErro
      && t1.imgCursoT1 !== '' && t1.nmCursoT1 !== '' && t1.dsCursoT1 !== '' && t1.dtCursoT1Inicio !== null && t1.dtCursoT1Fim !== null
      && t1.dsTempoEstimado !== '' && t1.tema.length !== 0 && t1.cursoT1Muncipios.length !== 0 && t1.cursoT1Perfis.length !== 0 && t1.cursoT1Conteudos.length !== 0) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [t1, imgT1Erro, nmT1Erro, dsT1Erro, dtIniT1Erro, dtFimT1Erro, dsTempoEstimadoErro, perfilErro, municipioErro, temaErro, conteudoErro])


  const validaOrdenacao = (array: any) => {
    let ordenado = array.map((item: any, idx: number) => {
      let obj = conteudosOrdenados.find((_item: any) => _item.idConteudo == item.idConteudo);
      if (obj != undefined) {
        item = obj;
      } else {
        item.ordemConteudo = conteudosOrdenados.length + 1;
      }
      return item;
    });

    setConteudoOrdenados(ordenado);
    setT1({
      ...t1,
      cursoT1Conteudos: ordenado,
    });
  }

  const handleRender = (t1Data: any) => {
    if (t1Data) {
      delete t1Data.tema.dsTema;
      setIsEdicao(true)
      setT1({
        ...t1Data
      });
      setCapaT1('');
    }
  }

  const handleClose = () => {
    setIsEdicao(false);
    setT1(defaultT1);
    setCapaT1('');
    setMunicipios_([]);
    setTab(0);
    setInativaGravar(true);
    setNmT1Erro(false);
    setDsT1Erro(false);
    setDtIniT1Erro(false);
    setDtFimT1Erro(false);
    setDsTempoEstimadoErro(false);
    setPerfilErro(false);
    setMunicipioErro(false);
    setTemaErro(false);
    setConteudoErro(false);
    setImgT1Erro(false);

    props.closeModal();
  }

  const handleMunicipioData = async () => {
    if(props.id != undefined){
      setMunicipios_(await getT1DataMunicipioLimited(props.id) );
    }
  }

  async function getT1DataMunicipioTotal(data: any) {
    const idCursoT1 = data;
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`cursoT1/findMunicipioById?idCursoT1=${idCursoT1}`, {headers: {'Authorization': `Bearer ${token}`}})
        return response;
    } catch (error) {
        console.log(error);
    }
  }
    async function getT1DataMunicipioLimited(data: any) {
      const idCursoT1 = data;
      const token = localStorage.getItem('accessToken');
      try {
          const { data: response } = await api.get(`cursoT1/findMunicipioByIdLimit?idCursoT1=${idCursoT1}`, {headers: {'Authorization': `Bearer ${token}`}})
          return response;
      } catch (error) {
          console.log(error);
      }
  };

  const handlePersist = async (e: any) => {
    e.preventDefault();
    if(t1.cursoT1Muncipios.length === 351 && isEdicao){
      t1.cursoT1Muncipios = await getT1DataMunicipioTotal(props.id);
    }
    const formT1 = new FormData();

    if (t1.cursoT1Conteudos && t1.cursoT1Conteudos.length > 0) {
      t1.cursoT1Conteudos.forEach((conteudo: any, idx: number) => {
        if (conteudo.ordemConteudo == undefined || conteudo.ordemConteudo == 0) {
          conteudo.ordemConteudo = (idx + 1);
        }
      })
    }

    formT1.append('cursoT1', JSON.stringify(t1));

    if (capaT1 != '') {
      formT1.append('fileImg', capaT1);
    }


    props.persist(isEdicao, formT1);
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if (res !== 'backdropClick') {
        handleClose()
      }
    }}
    >
      <Box sx={style} >
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? 'Editar Treinamento de 1 Ponto' : 'Cadastro de novo Treinamento de 1 Ponto'}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <TabPanel value={tab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6} >
                {
                  isEdicao && t1.imgCursoT1 && capaT1 == '' ? (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <div>
                        <Link className="buttonArquivo" href={t1.imgCursoT1} underline="none" target="_blank" >
                          <Button variant="contained" sx={{ backgroundColor: '#eb4034' }} >Download</Button>
                        </Link>
                        <Button variant="text" color="error" onClick={() => {
                          setImgT1Erro(true);
                          setT1({
                            ...t1,
                            imgCursoT1: ''
                          })
                        }}>Remover</Button>
                      </div>
                    </FormControl>
                  ) : (
                    <FormControl sx={{ width: '100%' }} >
                      <Typography id="modal-modal-title" variant="inherit" component="p">
                        Capa
                      </Typography>
                      <Input type='file' error={imgT1Erro} onChange={(e: any) => {
                        if (Array.from(e.target.files).length !== 0) {
                          if (validaNomeArquivo(e.target.files[0].name)) {
                            setImgT1Erro(Array.from(e.target.files).length === 0);
                            setT1({
                              ...t1,
                              imgCursoT1: e.target.files[0].name || ''
                            })
                            setCapaT1(e.target.files[0])
                          } else {
                            e.target.value = "";
                            setImgT1Erro(true);
                          }
                        } else {
                          e.target.value = "";
                          setImgT1Erro(true);
                        }
                      }} />
                      {imgT1Erro ? <FormHelperText error={imgT1Erro}>Campo Obrigatório.</FormHelperText> : null}
                    </FormControl>
                  )
                }
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={5} >
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={t1.nmCursoT1}
                  label="Nome do T1"
                  error={nmT1Erro}
                  helperText={nmT1Erro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setNmT1Erro(e.target.value === "");
                    setT1({
                      ...t1,
                      nmCursoT1: e.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={t1.dsCursoT1}
                  label="Descrição do T1"
                  error={dsT1Erro}
                  helperText={dsT1Erro ? "Campo Obrigatório." : ''}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDsT1Erro(e.target.value === "");
                    setT1({
                      ...t1,
                      dsCursoT1: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtIniT1Erro(true);
                      } else {
                        setDtIniT1Erro(false);
                      }
                    }}
                    label="Data de Início"
                    value={t1.dtCursoT1Inicio}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtIniT1Erro(false);
                      setT1({
                        ...t1,
                        dtCursoT1Inicio: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtIniT1Erro} helperText={dtIniT1Erro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                  <DesktopDatePicker
                    onError={(e) => {
                      if (e) {
                        setDtFimT1Erro(true);
                      } else {
                        setDtFimT1Erro(false);
                      }
                    }}
                    label="Data Final"
                    value={t1.dtCursoT1Fim}
                    minDate={dayjs('1900-01-01')}
                    onChange={(newValue) => {
                      setDtFimT1Erro(false);
                      setT1({
                        ...t1,
                        dtCursoT1Fim: newValue?.format()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} error={dtFimT1Erro} helperText={dtFimT1Erro ? "Campo Obrigatório." : ''} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  required
                  sx={{ width: '100%' }}
                  value={t1.dsTempoEstimado}
                  label="Duração em Minutos"
                  error={dsTempoEstimadoErro}
                  helperText={dsTempoEstimadoErro ? "Campo Obrigatório." : ''}
                  onChange={(e) => {
                    setDsTempoEstimadoErro(e.target.value === "");
                    setT1({
                      ...t1,
                      dsTempoEstimado: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaTemaComponent
                    error={temaErro}
                    temas={t1.tema}
                    multiple={false}
                    onChangeTemas={(value: any) => {
                      setTemaErro(value.length === 0)
                      setT1({
                        ...t1,
                        tema: value[0] ? value[0] : {}
                      })
                    }}
                  />
                  {temaErro ? <FormHelperText error={temaErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>


              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaPerfilComponent
                    error={perfilErro}
                    perfis={t1.cursoT1Perfis}
                    onChangePerfis={(value: any) => {
                      setPerfilErro(value.length === 0)
                      setT1({
                        ...t1,
                        cursoT1Perfis: value
                      })
                    }}
                  />
                  {perfilErro ? <FormHelperText error={perfilErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaMunicipioComponentTeste
                    error={municipioErro}
                    municipios={municipios_}
                    callbackList={getT1DataMunicipioTotal}
                    id={props.id}
                    onChangeMunicipios={(value: any) => {
                      setMunicipioErro(value.length === 0)
                      setT1({
                        ...t1,
                        cursoT1Muncipios: value
                      })
                    }}
                  />
                  {municipioErro ? <FormHelperText error={municipioErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} >
                  <ListaConteudoComponent
                    error={conteudoErro}
                    conteudos={t1.cursoT1Conteudos}
                    multiple={true}
                    onChangeConteudos={(value: any) => {
                      setConteudoErro(value.length === 0)
                      setT1({
                        ...t1,
                        cursoT1Conteudos: value
                      });
                      validaOrdenacao(value);
                    }}
                  />
                  {conteudoErro ? <FormHelperText error={conteudoErro}>Campo Obrigatório.</FormHelperText> : null}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <ListaAvaliacaoComponent
                  avaliacoes={t1.cursoT1Avaliacoes}
                  onChangeAvaliacoes={(value: any) => setT1({
                    ...t1,
                    cursoT1Avaliacoes: value
                  })}
                />
              </Grid>

              {
                t1.urlCompartilhamentoCursoT1 ? (
                    <Grid item xs={8} sx={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            disabled
                            sx={{width: '80%'}}
                            value={t1.urlCompartilhamentoCursoT1}
                            label="Link de Compartilhamento"
                        />
                        <Tooltip title={copiado}>
                            <IconButton  sx={{ fontSize: 25, marginLeft: '5px', t1r: 'pointer' }} onClick={() => {
                                navigator.clipboard.writeText(t1.urlCompartilhamentoCursoT1 || '').then(() => {
                                    setCopiado('Copiado!');
                                }).catch(() => {
                                    setCopiado('Não foi possível copiar o link de compartilhamento!');
                                }).finally(() => {
                                    setTimeout(() => {
                                        setCopiado('Copiar link de compartilhamento');
                                    }, 1500);
                                });
                            }} >
                                <Icon >content_copy</Icon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ) : null
              }
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div style={{ maxHeight: 400, overflow: 'auto', width: '100%' }}>
              <List className="conteudoOrdemList" >
                {
                  t1.cursoT1Conteudos && t1.cursoT1Conteudos.length > 0 ? (
                    t1.cursoT1Conteudos.map((conteudo: any, idx: number) => (
                      <ListItem key={`${conteudo.idConteudo}`}>
                        <TextField
                          disabled
                          style={{ width: 400 }}
                          value={conteudo.nmConteudo}
                          label="Conteúdo"
                        />
                        <FormControl sx={{ width: 200, marginLeft: '1em' }} >
                          <InputLabel id="demo-controlled-open-select-label">Ordem do Conteúdo</InputLabel>
                          <Select
                            value={conteudo.ordemConteudo != 0 ? conteudo.ordemConteudo : (idx + 1)}
                            label="Ordem do Conteúdo"
                            onChange={(e) => {
                              let auxArr = t1.cursoT1Conteudos;
                              auxArr[idx].ordemConteudo = parseInt(e.target.value.toString());
                              setT1({
                                ...t1,
                                cursoT1Conteudos: auxArr
                              })
                            }}
                          >
                            {
                              t1.cursoT1Conteudos.map((item: any, _idx: any) => (
                                <MenuItem key={`listItemOrdem-${idx}-${_idx}`} value={(_idx + 1)}>{(_idx + 1)}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </ListItem>
                    ))
                  ) : null
                }
              </List>
            </div>
          </TabPanel>
          <Divider style={{ marginTop: '1em' }} />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
              <Tab label="Informações" />
              <Tab label="Ordenação de Conteúdos" disabled={!(t1.cursoT1Conteudos && t1.cursoT1Conteudos.length > 0)} />
            </Tabs>
          </Box>
          <div className="cadastroFooter">
            <Button variant="contained" disabled={inativaGravar} type="submit">{isEdicao ? 'Gravar' : 'Cadastrar'}</Button>
            {
              isEdicao ?
                <Button variant="contained" onClick={() => {
                  props.enviaNotificacao(t1.idCursoT1)
                }}>Enviar Notificação</Button> : null
            }
          </div>
        </form>
      </Box>

    </Modal>
  )

};

export default ModalCadastroT1;