export enum RelatorioTypes {
    EXTRAIR_RELATORIO_REQUEST = 'relatorio/EXTRAIR_RELATORIO_REQUEST',
    EXTRAIR_RELATORIO_REQUEST_SUCCESS = 'relatorio/URL_EXTERNA_TABLE_RQUEST_SUCCESS',
    EXTRAIR_RELATORIO_REQUEST_FAILURE = 'relatorio/EXTRAIR_RELATORIO_REQUEST_FAILURE',
}

export interface RelatorioState {
    readonly data: any,
    readonly loading: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
}