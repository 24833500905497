import React from "react";
import { Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./style.css";

interface Menu {
    idItemMenu: number,
    idItemPai: number,
    dsIconMenu: string,
    itemPath: string,
    nmItem: string,
    itemOrdem?: string
}

const ListDrawerMenu = ({ open, menuList }: any) => {
    const [anchorElConteudo, setAnchorElConteudo] = React.useState(null);
    const [anchorElChatbot, setAnchorEChatbotl] = React.useState(null);

    const handleClick = (event: any, conteudo: boolean) => {
        if (anchorElConteudo !== event.currentTarget || anchorElChatbot !== event.currentTarget) {
            if (conteudo) {
                setAnchorElConteudo(event.currentTarget);
            } else {
                setAnchorEChatbotl(event.currentTarget);
            }
        }
    }

    const handleClose = () => {
        setAnchorElConteudo(null);
        setAnchorEChatbotl(null);
    }

    const renderSubMenuConteudo = (item: Menu, idx: any) => {
        return (
            <Menu
                id="simple-menu"
                anchorEl={anchorElConteudo}
                open={Boolean(anchorElConteudo)}
                onClose={handleClose}
                onMouseLeave={handleClose}
                MenuListProps={{ onMouseLeave: handleClose, sx: { padding: 0 } }}
            >
                <MenuItem onClick={handleClose} sx={{
                    width: '100%',
                    backgroundColor: '#454545',
                    ":hover": {
                        backgroundColor: '#2b2b2b'
                    }
                }}>
                    <Link key={`link-conteudo-1`} to='conteudos' style={{
                        display: 'flex'
                    }}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                                color: '#fff'
                            }}
                        >
                            <Icon>article</Icon>
                        </ListItemIcon>
                        <ListItemText primary="Conteúdos" sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{
                    width: '100%',
                    backgroundColor: '#454545',
                    ":hover": {
                        backgroundColor: '#2b2b2b'
                    }
                }}>
                    <Link key={`link-conteudo-1`} to='destaques' style={{
                        display: 'flex'
                    }}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                                color: '#fff'
                            }}
                        >
                            <Icon>announcement</Icon>
                        </ListItemIcon>
                        <ListItemText primary="Destaques" sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                    </Link>
                </MenuItem>
            </Menu>
        )
    }

    const renderSubMenuChatbot = (item: Menu, idx: any) => {
        return (
            <Menu
                id="simple-menu"
                anchorEl={anchorElChatbot}
                open={Boolean(anchorElChatbot)}
                onClose={handleClose}
                onMouseLeave={handleClose}
                MenuListProps={{ onMouseLeave: handleClose, sx: { padding: 0 } }}
            >
                <MenuItem onClick={handleClose} sx={{
                    width: '100%',
                    backgroundColor: '#454545',
                    ":hover": {
                        backgroundColor: '#2b2b2b'
                    }
                }}>
                    <Link key={`link-${item.idItemMenu}-${idx}-1`} to={'chatbot/saudacao'} style={{
                        display: 'flex'
                    }}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                                color: '#fff'
                            }}
                        >
                            <Icon>{'comment_bank'}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'Saudação'} sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{
                    width: '100%',
                    backgroundColor: '#454545',
                    ":hover": {
                        backgroundColor: '#2b2b2b'
                    }
                }}>
                    <Link key={`link-${item.idItemMenu}-${idx}-1`} to={'chatbot/categoria'} style={{
                            display: 'flex'
                        }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '#fff'
                                }}
                            >
                                <Icon>{'list_alt'}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={'Categorias e Níveis'} sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                        </Link>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{
                    width: '100%',
                    backgroundColor: '#454545',
                    ":hover": {
                        backgroundColor: '#2b2b2b'
                    }
                }}>
                    <Link key={`link-${item.idItemMenu}-${idx}-1`} to={'chatbot/diagrama'} style={{
                            display: 'flex'
                        }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '#fff'
                                }}
                            >
                                <Icon>{'schema'}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={'Diagrama'} sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                        </Link>
                </MenuItem>
            </Menu>
        )
    }

    const renderSubMenuItem = (item: Menu, idx: any) => {
        return (
            <ListItem key={`item-${item.idItemMenu}-${idx}`} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                    onClick={(e) => handleClick(e, item.idItemMenu == 9)}
                    onMouseOver={(e) => handleClick(e, item.idItemMenu == 9)}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: '#fff'
                        }}
                    >
                        <Icon>{item.idItemMenu == 9 ? 'article' : 'forum'}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={item.idItemMenu == 9 ? "Conteúdos" : "Chatbot"} sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                </ListItemButton>
                {
                    item.idItemMenu == 9 ? renderSubMenuConteudo(item, idx) : 
                        item.idItemMenu == 40 ? renderSubMenuChatbot(item, idx) : null
                }
                {/* <Menu
                    id="simple-menu"
                    anchorEl={item.idItemMenu == 9 ? anchorElConteudo : anchorElChatbot}
                    open={Boolean(item.idItemMenu == 9 ? anchorElConteudo : anchorElChatbot)}
                    onClose={handleClose}
                    onMouseLeave={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose, sx: { padding: 0 } }}
                >
                    {
                        item.idItemMenu == 40 ?

                            <MenuItem onClick={handleClose} sx={{
                                width: '100%',
                                backgroundColor: '#454545',
                                ":hover": {
                                    backgroundColor: '#2b2b2b'
                                }
                            }}>
                                <Link key={`link-${item.idItemMenu}-${idx}-1`} to={'chatbot/saudacao'} style={{
                                    display: 'flex'
                                }}>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: '#fff'
                                        }}
                                    >
                                        <Icon>{'comment_bank'}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={'Saudação'} sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                                </Link>
                            </MenuItem>
                            : null
                    }
                    <MenuItem onClick={handleClose} sx={{
                        width: '100%',
                        backgroundColor: '#454545',
                        ":hover": {
                            backgroundColor: '#2b2b2b'
                        }
                    }}>
                        <Link key={`link-${item.idItemMenu}-${idx}-1`} to={item.idItemMenu == 9 ? 'conteudos' : 'chatbot/categoria'} style={{
                            display: 'flex'
                        }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '#fff'
                                }}
                            >
                                <Icon>{item.idItemMenu == 9 ? 'article' : 'list_alt'}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={item.idItemMenu == 9 ? 'Conteúdos' : 'Categorias e Níveis'} sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                        </Link>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} sx={{
                        width: '100%',
                        backgroundColor: '#454545',
                        ":hover": {
                            backgroundColor: '#2b2b2b'
                        }
                    }}>
                        <Link key={`link-${item.idItemMenu}-${idx}-2`} to={item.idItemMenu == 9 ? 'destaques' : 'chatbot/nivel' } style={{
                            display: 'flex'
                        }}>
                            <ListItemIcon
                                sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                                color: '#fff'
                                }}
                            >
                                <Icon>{item.idItemMenu == 9 ? 'announcement' : 'ballot' }</Icon>
                            </ListItemIcon>
                            <ListItemText primary={item.idItemMenu == 9 ? 'Destaques' : 'Nível' } sx={{ opacity: open ? 1 : 0,  color: '#fff', display: open ? 'block' : 'none' }} />
                        </Link>
                    </MenuItem> 
                </Menu> */}
            </ListItem>
        );
    }

    return (
        <List className="drawerMenuList" >
            {
                menuList.map((item: Menu, idx: any) => (
                    item.idItemMenu == 9 || item.idItemMenu == 40 ? renderSubMenuItem(item, idx) : (
                        <Link key={`link-${item.itemPath}-${idx}`} to={item.itemPath}>
                            <ListItem key={`${item.itemPath}-${idx}`} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: '#fff'
                                        }}
                                    >
                                        <Icon>{item.dsIconMenu}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={item.nmItem} sx={{ opacity: open ? 1 : 0, color: '#fff', display: open ? 'block' : 'none' }} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    )

                ))
            }
        </List>
    )
};

export default ListDrawerMenu;
