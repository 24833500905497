import React, { useEffect, useRef, useState } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { TemaObjTable } from "../../store/ducks/temas/types";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const defaultTema: TemaObjTable = {
    nmTema: '',
    dsTema: ''
};

const ModalCadastroTema = (props: any) => {

    const [isEdicao, setIsEdicao] = useState(props.isEdicao);
    const [tema, setTema] = useState<TemaObjTable>(defaultTema);

    useEffect(() => {
        if(props.temaData){
            setIsEdicao(true);
            setTema({
                ...tema,
                ...props.temaData
            });
        }
    }, [props.temaData]);

    const handlePersist = (e: any) => {
        e.preventDefault();
        
        const temaPersist: TemaObjTable = {
            idTema: tema.idTema,
            nmTema: tema.nmTema,
            dsTema: tema.dsTema
        };

        props.persistTema(isEdicao, temaPersist);
    }

    const handleClose = () => {
        setIsEdicao(false);
        setTema(defaultTema);

        props.closeModal();
    }

    const buscaTemaExistente = () => {
        if(!isEdicao && tema.nmTema != '' && tema.dsTema != '') {
            props.buscaTemaExistente(tema);
        }
    }

    return (
        <Modal open={props.modalCadastroOpen} onClose={(e: any, res: any) => {
      if(res !== 'backdropClick') {
        handleClose()
      }
    }}
        >
            <Box sx={style} >
                <div className="modalHead">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {isEdicao ? 'Editar de Tema' : 'Cadastro de novo Tema'}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Fechar
                    </Button>
                </div>
                <Divider />
                <form className="modalMid-form" onSubmit={handlePersist}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <TextField
                                required
                                sx={{width: '100%'}}
                                value={tema.nmTema}
                                label="Nome do Tema"
                                onChange={(e) => {
                                    setTema({
                                        ...tema,
                                        nmTema: e.target.value
                                    })
                                }}
                                onBlur={buscaTemaExistente}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                sx={{width: '100%'}}
                                required
                                value={tema.dsTema}
                                label="Descrição do Tema"
                                onChange={(e) => {
                                    setTema({
                                        ...tema,
                                        dsTema: e.target.value
                                    })
                                }}
                                onBlur={buscaTemaExistente}
                            />
                        </Grid>
                    </Grid>
                    <div className="cadastroFooter">
                        <Divider />
                        <Button variant="contained" type="submit">{isEdicao ? 'Gravar' : 'Cadastrar' }</Button>
                    </div>
                </form>
            </Box>
            
        </Modal>
    )

};

export default ModalCadastroTema;