import * as React from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { persistor, store } from './store';
import AppRouter from './Route/AppRouter';
import Loading from './Components/LoadingComponent';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#454545'
    },
    secondary: {
      main: '#eb4034'
    }
  }
});

function App() {
  return(
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App;
